import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { OctAutocomplete } from '@oceantech/oceantech-ui';
import LazyLoadTable from '../../component/lazy-load-table/LazyLoadTable';
import { CODE, LEVEL, LOAI_PHONG, RESPONSE_MESSAGE } from '../../utils/Constant';
import { formatDateToYYYYMMDD, formatSummaryStatus } from '../../utils/FormatUtils';
import { getListKhoaPhong } from '../../utils/ServicesUtils';
import { columns } from '../constants/Columns';
import { LIST_ORDER, WORK_FLOW_STATUS_CDHA } from '../constants/Constants';
import { IBenhNhanV3CDHA } from '../models/ModelsPhanHeCDHAVaTDCN';
import { getRispacsGroups } from '../services/PhanHeCDHAVaTDCNService';
import useBarcodeScan from '../../../hook/useBarcodeScan';
import ContextMenu from '../../component/ContextMenuV3';
import { IContextMenu } from '../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy';
import { speaker } from '../../utils/Speaker';

type Props = {
	setInfoPhieuCDHA: (benhNhan: IBenhNhanV3CDHA) => void;
	statusSummary: any;
	setFilter: React.Dispatch<any>;
	filter?: any;
	statusChangedEvent: any;
}

const BangDsPhieuCDHA = (props: Props) => {
	const { setInfoPhieuCDHA, statusSummary = [], setFilter, filter, statusChangedEvent } = props;
	const [contextMenu, setContextMenu] = useState<null | { x: number; y: number }>(null);
	const contextRef = useRef<HTMLDivElement | null>(null);
	const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
	const [idSelected, setIdSelected] = useState<string>("");
	const [openDialogLichSuKhamBenh, setOpenDialogLichSuKhamBenh] = useState<boolean>(false)
	const [selectedPhieu, setSelectedPhieu] = useState<any>();
	const [searchObject, setSearchObject] = useState<any>(null);
	const [rowSeclectedByContextMenu, setRowSeclectedByContextMenu] = useState<any>();

	const filterRef = useRef<any>(null);

	useEffect(() => {
		setCodeCollapses([...MenuTree.filter.map((item) => item.code)]);
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (idSelected.includes("goiBenhNhan")) {
			toast.warning("Gọi bệnh nhân thành công!");
			handleSelected("goiBenhNhan");
		}
		if (idSelected.includes("lskb")) {
			setOpenDialogLichSuKhamBenh(true)
			handleSelected("thayTheDichVuKhac");
		}
	}, [idSelected]);

	const handleSelected = (code: string) => {
		if (idSelected === code) {
			setIdSelected("");
		} else {
			setIdSelected(code);
		}
	};
	const handleClickOutside = (e: any) => {
		if (contextRef.current && !contextRef.current.contains(e.target as Node)) {
			setContextMenu(null);
		}
	};

	const MenuTree = {
		filter: [
			{
				code: "benhNhan",
				name: "Bệnh nhân",
				filter: [{ code: "goiBenhNhan", name: "Gọi bệnh nhân" }],
			},
			{
				code: "benhAn",
				name: "Bệnh án",
				filter: [
					{ code: "lskb", name: "Lịch sử khám bệnh" },
				],
			},
		],
	};
	const handleSelectedBenhNhan = async (phieuCDHA: IBenhNhanV3CDHA, callbackFn?: () => void) => {
		setInfoPhieuCDHA(phieuCDHA);
		setSelectedPhieu(phieuCDHA);
		callbackFn?.();
	};

	const handleClickStatus = (item: any) => {
		setFilter({ ...filter, filterBy: 'currStatusCode', filterValue: item?.statusCode });
	}

	useEffect(() => {
		if (!filter) return;
		
		const params = {
			keyword: filter?.keyword,
			isBhyt: filter?.typeCheckBHYT ? true : false,
			isVienPhi: filter?.typeCheckVP ? true : false,
			sortBy: filter?.formal?.code,
			descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
			fromDate: formatDateToYYYYMMDD(filter?.period?.fromDate),
			toDate: formatDateToYYYYMMDD(filter?.period?.toDate),
			filterBy: filter?.filterBy || null,
			filterValue: filter?.filterValue || null,
			fulfillDeptCode: filter?.fulfillDeptCode || null,
		};

		setSearchObject(params);
		filterRef.current = params;
	}, [filter])

	const searchBarcode = async (value: string) => { 
		try {
			const { data } = await getRispacsGroups({
				...filterRef.current,
				keyword: value,
			});

			if (CODE.SUCCESS === data?.code) {
				const patientSelected = data?.data?.content?.[0];

				if (!patientSelected) {
					toast.warn('Không tìm thấy thông tin phiếu với mã bệnh nhân: ' + value);
					return;
				}

				handleSelectedBenhNhan(patientSelected, () => {
					toast.success(`Lấy thông tin phiếu với mã bệnh nhân: ${value} thành công`);
				});
			}
		} catch (error) {
			console.error(error);
		    toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	useBarcodeScan({
		searchBarcode
	});

	const CODE_MENU = {
		GOI_BN: "goiBn",
	  };
	
	const dropListChiDinhDichVu: IContextMenu[] = [
		{
		  title: "CDHA",
		},
		{
		  icon: <i className="bi bi-volume-up-fill text-pri"></i>,
		  code: CODE_MENU.GOI_BN,
		  name: "Gọi bệnh nhân",
		},
	  ];
  const handleContextMenu = (e: any, row: any) => {
    if(row?.currStatusCode === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code) {
      setRowSeclectedByContextMenu(row);
      setContextMenu({ x: e.clientX, y: e.clientY });
    }
  };
  const handleGoiBN = (data: any) => {
		let stt = data?.ordinalNumber;
		let name = data?.patientName;
		let diaDiem = data?.fulfillDeptName || "phòng khám";
		if (stt && diaDiem) {
			let content = `Mời khách hàng, ${name} , có số thứ tự, ${stt}, tới ${diaDiem})`;
			speaker.handleSpeak(content);
		} else {
			toast.warning("Bệnh nhân không đủ thông tin");
		}
	};
	const handleClickOptionContextMenu = (value: any) => {
		const menuActions = {
			[CODE_MENU.GOI_BN]: () => handleGoiBN(rowSeclectedByContextMenu),
		};
		menuActions[value?.code]?.();
		setContextMenu(null);
	};

	return (
		<div className="flex flex-column flex-1 pt-3 spaces h-calc-vh-210">
			<div className="spaces h-calc-vh-290">
				<LazyLoadTable
					columns={columns}
					axiosReq={getRispacsGroups}
					searchData={searchObject}
					urlData="data.data.content"
					className="spaces h-calc-vh-290 pb-3"
					onRowClick={row => handleSelectedBenhNhan(row)}
					rowSeclected={selectedPhieu}
					dependencies={[props.statusChangedEvent]}
					handleContextMenu={handleContextMenu}
				/>
				{contextMenu && (
					<ContextMenu
						handleClickOptionContextMenu={handleClickOptionContextMenu}
						handleCloseMenu={() => setContextMenu(null)}
						data={dropListChiDinhDichVu}
						target={contextMenu}
					/>
				)}
			</div>
			<div className="spaces px-12">
				<OctAutocomplete
					options={[]}
					placeholder="Tất cả khoa phòng"
					menuPlacement="top"
					searchFunction={getListKhoaPhong}
					searchObject={{ level: LEVEL.PHONG, type: LOAI_PHONG.PHONG_CDHA }}
					onChange={(option) => {
						setFilter({
							...filter,
							fulfillDeptCode: option?.code,
						});
					}}
				/>
			</div>
			<div className="d-flex justify-content-center spaces h-30">
				<Row className="d-flex spaces w-100 mb-10">
					{
						statusSummary?.length > 0 && statusSummary.map((item: any, index: any) => {
							return <Col key={index} xs={6} className="spaces mb-3" onClick={() => handleClickStatus(item)}>
								{formatSummaryStatus(item)}
							</Col>
						})
					}
				</Row>
			</div>
		</div>
	);
};

export default BangDsPhieuCDHA;
