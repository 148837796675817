import { FunctionComponent, memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
import ContextMenu from "../../component/ContextMenuV3";
import { IContextMenu } from "../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { RESPONSE_MESSAGE } from "../../utils/Constant";
import { localStorageItem } from '../../utils/LocalStorage';
import { WfWorkflowsReq } from "../models/WfWorkflows";
import { updateWfWorkflows } from "../services/WfWorkflowsService";
import wfAxiosGenerator from "../utils/WfAxiosGenerator";
interface WfAction {
    componentName: string,
    nameReq: string,
    submit: () => void,
}
interface WfProps {
    tracker: string;
    wfWorkflowsReq: WfWorkflowsReq;
    additionalFunc?: (currStatusId?: string | undefined, codeButton?: string | undefined) => void;
    handleClick?: (props: WfAction) => void;
    forceRender?: boolean;
    configuration?: any
    title?: string
    contextMenu: { x: number; y: number }
    setContextMenu: React.Dispatch<React.SetStateAction<{
        x: number;
        y: number;
    } | null>>
}

const BASE_ENDPOINT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL || "";

const WfContextMenu: FunctionComponent<WfProps> = memo((wfProps: WfProps) => {
    const { contextMenu, setContextMenu, tracker, wfWorkflowsReq } = wfProps;
    const [wfAxiosRequests, setWfAxiosRequests] = useState<any>();
    const [dropListMenu, setDropListMenu] = useState<IContextMenu[]>([]);

    const updateStates = () => {
        setWfAxiosRequests(wfAxiosGenerator(BASE_ENDPOINT, wfProps?.configuration));
    };

    const changeStatus = async (wfWorkflowsReq: WfWorkflowsReq | undefined) => {
        if (!wfWorkflowsReq) return;
        try {
            await updateWfWorkflows(tracker, wfWorkflowsReq);
        } catch (error) {
            console.error(error);
            toast.error(RESPONSE_MESSAGE.ERROR);
        }
    };

    const handleButtonClick = (additionalButton: any) => {
        const submit = () => {
            additionalButton.handleClick(wfProps);
        }
        if (additionalButton?.componentName && wfProps.handleClick) {
            wfProps.handleClick({
                componentName: additionalButton.componentName,
                nameReq: additionalButton.name,
                submit: submit,
            });
        } else {
            submit();
        }
    };

    const handleClickOptionContextMenu = async (value: any) => {
        // await changeStatus(wfWorkflowsReq);
        value?.handleClick?.();
        handleButtonClick?.(value);
        setContextMenu?.(null);
    };

    useEffect(() => {
         updateStates()
    }, []);

    useEffect(() => {
        let newList: IContextMenu[] = wfAxiosRequests?.axiosRequests?.map((item: any) => {
            return {
                ...(item?.iconClassName && { icon: <i className={item?.iconClassName} /> }),
                name: item?.name,
                handleClick: item?.handleClick,
                componentName: item?.componentName
            }
        });
        if (wfProps?.title) {
            newList?.unshift({
                title: wfProps?.title,
            });
        };
        setDropListMenu(newList);
    }, [wfAxiosRequests?.axiosRequests]);

    return (
        <>
            <ContextMenu
                handleClickOptionContextMenu={handleClickOptionContextMenu}
                handleCloseMenu={() => setContextMenu?.(null)}
                data={dropListMenu}
                target={contextMenu}
            />
        </>
    );
});

export default WfContextMenu;
