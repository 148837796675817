import React, { useContext, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import LabelRequired from '../../../../component/LabelRequired'
import TextField from '../../../../component/TextField'
import { useFormikContext } from 'formik'
import ModalChonKetQuaDichVu from '../../../components/tab-kham-benh/modal-chon-ket-qua-dich-vu/ModalChonKetQuaDichVu'
import { PhanHeTiepDonContext } from '../../../contexts/PhanHeTiepDonContext'
import { KetQuaDichVu } from '../../../models/ThongTinKhamBenhModel'

type Props = {}

const ThongTinRaVien = (props: Props) => {
    const { values, setFieldValue, handleChange } = useFormikContext<any>();
    const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
    const [openModalChonKetQuaDichVu, setOpenModalChonKetQuaDichVu] = useState<boolean>(false);


    const handleSelectKetQua = (selectedData: KetQuaDichVu[]) => {
        let htClinicalResults = selectedData?.map(item =>
            `${item.tenDichVu} ${item.ketQua !== null ? '(' + item.ketQua + ')' : ''}`
        ).join(', ');
        setFieldValue('hdClinicalResults', htClinicalResults);
    };

    return (
        <div className="spaces pt-10 h-calc-vh-250 overflow-auto">
            <Row className="spaces pt-10 mx-0">
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Tình trạng bệnh nhân" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdPatientCondition"
                        values={values?.hdPatientCondition || ""}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Quá trình bệnh lý và diễn biến lâm sàng" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdClinicalSigns"
                        values={values?.hdClinicalSigns || ""}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Hướng điều trị tiếp theo" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdTreatmentPlan"
                        values={values?.hdTreatmentPlan || ""}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} className='d-flex flex-end'>
                    <Button
                        className="bg-white spaces px-2"
                        onClick={() => setOpenModalChonKetQuaDichVu(true)}
                    >
                        <u className="fw-bold color-17A2B8 fs-13px">Chọn kết quả</u>
                    </Button>
                </Col>
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Tóm tắt kết quả CLS có giá trị chẩn đoán" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdClinicalResults"
                        values={values?.hdClinicalResults || ""}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Lời dặn của bác sĩ" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdDoctorInstructions"
                        values={values?.hdDoctorInstructions || ""}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} className='d-flex align-items-start mb-3'>
                    <LabelRequired label="Phương pháp điều trị" className="spaces W-160 mt-2 mr-5" />
                    <TextField
                        className="resize-none w-100"
                        as="textarea"
                        rows="3"
                        name="hdTreatmentMethod"
                        values={values?.hdTreatmentMethod || ""}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            {openModalChonKetQuaDichVu && (
                <ModalChonKetQuaDichVu
                    open={openModalChonKetQuaDichVu}
                    handleClose={() => setOpenModalChonKetQuaDichVu(false)}
                    onSave={handleSelectKetQua}
                    visitId={benhNhanInfo?.thongTinKhamBenh?.visit?.id}
                />
            )}
       </div>
    )
}

export default ThongTinRaVien