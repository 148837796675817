import ThongTinPhieu from '../../../tab-thuoc/components/thong-tin-phieu/ThongTinPhieu';
import { useTabVatTuContext } from '../../context';

const ThongTinPhieuVatTu = () => {
	const { donVatTuDetail } = useTabVatTuContext();

	return <ThongTinPhieu thongTinPhieu={donVatTuDetail} />;
};

export default ThongTinPhieuVatTu;
