import React, { ChangeEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { AutoSizer, Column, Table, ColumnProps, RowMouseEventHandlerParams, TableCellProps } from "react-virtualized";
import "react-virtualized/styles.css";
import { SELECTION_MODE } from "../../../utils/Constant";
import "../table.scss";
import "./SimpleTableStyle.scss";
import clsx from 'clsx';
interface simpleTableProps<T extends object> {
  data: any;
  columns: columnNamesType[];
  selectionMode?: typeof SELECTION_MODE[keyof typeof SELECTION_MODE];
  verticalScroll?: boolean;
  height?: number;
  width?: number;
  scrollable?: boolean;
  type?: string;
  headerClasses?: string;
  dataChecked?: any[];
  setDataChecked?: (dataChecked: any) => void;
  uniquePrefix?: string;
  handleRenderCell: (columnData: any, keyData: string, rowData: any) => void;
  onRowClick?: (params: RowMouseEventHandlerParams) => void;
  keySearch?: string;
}

export interface columnNamesType extends Partial<ColumnProps> {
  name: string;
  field: string;
  sorting?: boolean;
  action?: boolean;
  headerStyle?: React.CSSProperties | object;
  cellStyle?: React.CSSProperties | object;
  isVisible?: boolean;
  render?: (data: any, index: number, itemList: any) => any;
  textAlign?: string;
  highlight?: boolean;
}

function SimpleTable<T extends object>(props: simpleTableProps<T>) {
  const {
    data,
    height,
    scrollable,
    dataChecked,
    setDataChecked,
    uniquePrefix = "id",
    handleRenderCell,
    onRowClick,
    keySearch
  } = props;
  const [visibleColumns, setVisibleColumns] = useState<columnNamesType[]>([]);
  const [itemList, setItemList] = useState<any>(data || []);
  const [selectedRows, setSelectedRows] = useState<any[]>(dataChecked || []);
  const intl = useIntl();

  const styles: object = {
    height: height || "auto",
    overflowY: scrollable && "auto",
  };

  useEffect(() => {
    setVisibleColumns(
      props.columns.map((column) => ({
        ...column,
        isVisible: true,
      }))
    );
  }, [props.columns]);

  useEffect(() => {
    setSelectedRows(dataChecked || []);
  }, [dataChecked]);

  useEffect(() => {
    let selectedRowsIds = selectedRows?.map((row) => row?.[uniquePrefix]);
    
    let _data = data?.map((item: any) => {
      return {
        ...item,
        isChecked: selectedRowsIds.includes(item?.[uniquePrefix]) ? true : false
      };
    });
    setItemList(_data);
  }, [data, selectedRows]);

  const handleCheckBox = (checked: boolean, index: number) => {
    let _listData = [...itemList];
    _listData[index].isChecked = checked;
    setItemList([..._listData]);
    let updatedSelectedRows = [...selectedRows];
    const selectedItem = _listData[index];
    if (checked) {
      updatedSelectedRows.push(selectedItem);
    } else {
      updatedSelectedRows = updatedSelectedRows.filter(
        (item: any) => item?.[uniquePrefix] !== selectedItem?.[uniquePrefix]
      );
    }
    setSelectedRows(updatedSelectedRows);
    setDataChecked && setDataChecked(updatedSelectedRows);
  };

  const handleRadio = (checked: boolean, index: number) => {
    itemList[index].isChecked = checked;
    itemList.map((element: any, indexData: number) => {
      element.isChecked = indexData === index;
      return element;
    });
    setItemList([...itemList]);
    setSelectedRows([itemList[index]]);
    setDataChecked && setDataChecked([itemList[index]]);
  };

  const columns: columnNamesType[] = [
    ...visibleColumns,
  ];

  const noRowsRenderer = () => {
    return <div className='noRows'>{intl.formatMessage({ id: "TABLE.DATA.EMPTY" })}</div>;
  }

  return (
    <div className="table-responsive customs-collapse-row m-0 overflow-auto" style={styles}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={35}
            rowHeight={40}
            rowCount={itemList.length}
            rowGetter={({ index }) => itemList[index]}
            headerClassName="headerColumn text-white"
            rowStyle={{ borderBottom: "1px solid #ced4da" }}
            noRowsRenderer={noRowsRenderer}
            onRowClick={(info: RowMouseEventHandlerParams) => {
              onRowClick && onRowClick(info);
            }}
          >
            {columns.map((col: any) => {
              return col?.field === 'checkbox' ? (
					<Column
						key={col.field}
						label={col.name}
						dataKey={col.field}
						width={col.width}
						minWidth={col.minWidth}
						className={clsx('text-center', col?.cellStyle)}
						cellRenderer={({ rowIndex, rowData }) => (
							<Form.Check
								className="checkBox border-check-color"
								checked={rowData?.isChecked || false}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									handleCheckBox(event?.target?.checked, rowIndex)
								}
							/>
						)}
						{...col}
					/>
				) : col?.field === 'radio' ? (
					<Column
						key={col.field}
						label={col.name}
						dataKey={col.field}
						width={col.width}
						minWidth={col.minWidth}
						className={clsx('text-center', col?.cellStyle)}
						cellRenderer={({ rowIndex, rowData }) => (
							<Form.Check
								className="radio border-check-color"
								type="radio"
								name="radio"
								checked={rowData?.isChecked || false}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									handleRadio(event?.target?.checked, rowIndex)
								}
							/>
						)}
						{...col}
					/>
				) : (
					<Column
						key={col.field}
						label={col.name}
						dataKey={col.field}
						width={col.width}
						minWidth={col?.minWidth}
						cellDataGetter={({ columnData, dataKey, rowData }) =>
							handleRenderCell(columnData, dataKey, rowData)
						}
						style={{ textAlign: col?.textAlign }}
						cellRenderer={({ cellData }: TableCellProps) => {
              const invalidCellHighLight =
					      !keySearch || !itemList.length || !col.highlight;

              if (invalidCellHighLight) return <span>{cellData}</span>;
              
							const cellValue = cellData || '';
              const lowercaseValue = `${cellValue}`.toLowerCase();
							let result = '';

							for (let index = 0; index < lowercaseValue.length; index++) {
								const character = lowercaseValue[index];
                const cellValueIndex = cellValue[index];
                
								if (keySearch.includes(character)) {
									result += `<strong>${cellValueIndex}</strong>`;
								} else {
									result += cellValueIndex;
								}
							}

							return (
									<span
										dangerouslySetInnerHTML={{
											__html: result,
										}}
									></span>
							);
						}}
						{...col}
					/>
				);
            })}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
}

export default SimpleTable;
