//@ts-nocheck
import "../../../../../app/modules/styles/index.scss"
import { MenuItem } from './MenuItem';
import { useRef, useEffect, useState } from 'react';
import { KEY_LOCALSTORAGE } from "../../../../../app/modules/auth/core/_consts";
import { localStorageItem } from "../../../../../app/modules/utils/LocalStorage";
import { KTSVG } from "../../../../helpers";
import { TMenu } from "../../../../../app/pages/Homepage/constant";

export function MenuInner() {
  const containerRef = useRef(null);
  const [dragStart, setDragStart] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const menusByPermission: TMenu[] = localStorageItem.get(KEY_LOCALSTORAGE.ID_TOKEN_DECODE)?.["menus"]?.["oceantech-emr-server"] || [];
  const linkTo = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["oauthClientUrl"] || "/home"


  const handleMouseDown = (e: any) => {
    setDragStart(e.clientX);
    setScrollStart(containerRef?.current.scrollLeft);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    const dragDelta = e.clientX - dragStart;
    containerRef.current.scrollLeft = scrollStart - dragDelta;
  };
  const scrollToLeft = () => {
    containerRef.current.scrollLeft -= 200;
  };

  const scrollToRight = () => {
    containerRef.current.scrollLeft += 200;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleButtonClick = () => {
    window.location.href = linkTo;
  }

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        {/* <MenuItem
                to={linkTo}
                icon='./media/icons/menu.svg'
              /> */}
        <span className='menu-icon spaces px-10 cursor-pointer' onClick={handleButtonClick}>
          <KTSVG path={'./media/icons/menu.svg'} className='svg-icon-2' />
        </span>
        <i className="bi bi-chevron-bar-left fs-2 cursor-pointer" onClick={scrollToLeft}></i>
        <div className='header-menu-container' ref={containerRef} onMouseDown={handleMouseDown}>
          {menusByPermission?.map((module: TMenu, index: number) => {
            return (
              <MenuItem
                key={index}
                title={module?.name}
                to={module.uri}
              />
            )
          })}
        </div>
      </div>
      <i className="bi bi-chevron-bar-right fs-2 cursor-pointer" onClick={scrollToRight}></i>
    </div>
  );
}