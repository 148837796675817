export const WfConstTracker = {
	PATIENT: 'patient',
	CLINICAL: 'clinical',
	TEST: 'test',
	RISPACS: 'rispacs',
	SURGERY: 'surgery',
	SAMPLE: 'sample',
	CUSTOMER: 'customer',
	ORDER_CIS: 'order_cis',
};

export const WfConstTrackerClinical = {
	ORDER_GROUP: 'order_group',
	MEDICAL_ITEMS: 'medical_items',
};

export const WORKFLOW_FIELD_PROPERTY = {
	READONLY: 'R',
	WRITE: 'W',
	REQUIRED: 'Re',
	DISABLED: 'D',
	HIDDEN: 'H',
};

export const WORKFLOW_FIELD_PROPERTY_PROP_MAPPER = {
	[WORKFLOW_FIELD_PROPERTY.READONLY]: 'readOnly',
	[WORKFLOW_FIELD_PROPERTY.DISABLED]: 'disabled',
	[WORKFLOW_FIELD_PROPERTY.HIDDEN]: 'hidden',
  	[WORKFLOW_FIELD_PROPERTY.WRITE]: 'writable',
  	[WORKFLOW_FIELD_PROPERTY.REQUIRED]: 'required',
};

export const WORKFLOW_STATUS = {
	CHUA_LAY_MAU: 'sample_new',
	DA_LAY_MAU: 'sample_collected',
};