import { CODE, CODE_SUCCESS, ERROR_MESSAGE, KEY, RESPONSE_MESSAGE, TERM_CLASS_IDS, TERMS_CLASS_IDS_DICH_VU } from "./Constant";
import { getJsonGenerateForm } from "../phan-he-quan-tri-he-thong/tab-loai-dich-vu/LoaiDichVuServices";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import Fuse, { IFuseOptions } from "fuse.js";
import { WF_STATUS } from "../phan-he-kham-doan/constants/PhanHeKhamDoan";
import { localStorageItem } from "./LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { COMPONENT_TYPE } from "../component/generate-form/GenerateFormConfig";
import * as Yup from "yup";
import { formatDateDTO } from "./FormatUtils";
import { WfWorkflowsReq } from "../workflows/models/WfWorkflows";
import { updateWfWorkflows } from "../workflows/services/WfWorkflowsService";
import { getTermByClassId, getTermClasses } from "./ServicesUtils";


const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
export const removeEventEnter = (e: any) => {
  if (e.key === KEY.ENTER) {
    e.preventDefault();
  }
};

export const totalPrice = (data: any, name: string) => {
  const totalPrice = data?.reduce((total: number, item: any) => {
    const totalChildren = item?.items?.reduce(
      (totalChild: number, child: any) => {
        return totalChild + (child[name] || 0);
      },
      0
    );
    return total + totalChildren;
  }, 0);
  return totalPrice || 0;
};

export const formatDate = (data: number) => {
  return data < 10 ? `0${data}` : data;
};
export const RomanNumeralsConverter = (number: number) => {
  if (isNaN(number)) {
    return;
  }

  const romanNumerals: { value: number; numeral: string }[] = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" },
  ];

  let result = "";
  for (let i = 0; i < romanNumerals.length; i++) {
    while (number >= romanNumerals[i].value) {
      result += romanNumerals[i].numeral;
      number -= romanNumerals[i].value;
    }
  }

  return result;
};

export function generateSecureRandomId(length: number = 16) {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
}

export const generateForm = async (codeAPI: string, setIsLoading: any) => {
  setIsLoading(true);
  try {
    let dataFieldAuto = localStorage.getItem(`form-${codeAPI}`);
    if (dataFieldAuto) {
      return JSON.parse(dataFieldAuto) || {};
    } else {
      let res = await getJsonGenerateForm(codeAPI);
      if (res?.data?.code === CODE_SUCCESS) {
        localStorage.setItem(`form-${codeAPI}`, res?.data?.data);
        return JSON.parse(res?.data?.data);
      } else {
        toast.error(ERROR_MESSAGE);
      }
    }
  } catch (error) {
    toast.error(ERROR_MESSAGE);
  } finally {
    setIsLoading(false);
  }
};
export const getSimpleCategory = (searchObject: any) => {
  let url = API_PATH + "/simple-categories/search";
  return axios.get(url, { params: searchObject });
};

export const removeVietnameseTones = (str: string = "") => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
  str = str.replace(/đ/g,"d");
  
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");

  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g," ");
  str = str.trim();

  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  return str;
}

export function customDebounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}
export const flatArrayByChildrenName = (arr: any, childName: string) => {
  let result: any = [];

  arr.forEach((item: any) => {
    result.push(item);
    if (item[childName] && item[childName].length > 0) {
      result = result.concat(flatArrayByChildrenName(item[childName], childName));
    }
  });

  return result;
}

export const handleConvertBirthdate = (data: any) => {
  if (data?.birthDate) {
    return moment(data?.birthDate).format("DD/MM/YYYY");
  } else if (data?.dobYear) {
    return data?.dobYear;
  } else if (data?.dobYear && data?.dobMonth) {
    return data?.dobMonth + "/" + data?.dobYear;
  }
};

export const handleConvertAddress = (data: any) => {
  if (!data) return "";

  let subDistrictName = data?.communeName || data?.subDistrictName;

  if (data?.provinceName) {
    return (
      (data?.address ? data?.address + ", " : "") + 
      (subDistrictName ? subDistrictName + ", " : "") + 
      (data?.districtName ? data?.districtName + ", " : "") + 
      data?.provinceName
    );
  } else {
    return "";
  }
};


export const handleSearchByFuse = (listData: any[], textSearch: string, setListData: (data: any) => void, 
  extraOptions?: IFuseOptions<any>) => {
  const fuseOptions = {
    isCaseSensitive: false, //phân biệt chữ hoa chữ thường
    includeScore: true,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 2, //kết quả trả về cả những phần tử khớp theo số lượng ký tự truyền vào
    // location: 0,
    // threshold: 1,
    // distance: 100,
    useExtendedSearch: true, //search nâng cao, phụ thuộc các dấu =, ", '
    ignoreLocation: true,
    ignoreFieldNorm: true,
    fieldNormWeight: 1,
    keys: ["code", "name"],
    ...extraOptions
  };    

  const fuse = new Fuse(listData, fuseOptions);
  if(textSearch !== "") {
    const lstServices = fuse.search(textSearch).map((ser: any) => {
      return ser?.item;
    });
    setListData(lstServices);
  } else {
    setListData(listData);
  }
}

export const handleNameStatus = (statusCode: string) => {
  let result: string = "";
  switch (statusCode) {
    case WF_STATUS.CHUA_DEN_KHAM.CODE:
      result = WF_STATUS.CHUA_DEN_KHAM.NAME;
      break;
    case WF_STATUS.DA_DEN_KHAM.CODE:
      result = WF_STATUS.DA_DEN_KHAM.NAME;
      break;
    case WF_STATUS.DA_KHAM_XONG.CODE:
      result = WF_STATUS.DA_KHAM_XONG.NAME;
      break;
    case WF_STATUS.DUYET_BENH_AN.CODE:
      result = WF_STATUS.DUYET_BENH_AN.NAME;
      break;
    default:
      break;
  };
  return result;
};

export const checkTypeOfFile = (file: any) => {
  let isExcelFile =
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel' ||
    file.name.endsWith('.xlsx') ||
    file.name.endsWith('.xls');
  let isPDFFile = file.type === 'application/pdf' || file.name.endsWith('.pdf');
  return { isExcelFile, isPDFFile };
};

export const generateValidationSchema = (data: any, formCode: string) => {
  const handleValidate = () => {
    let objValidation: any = {};
    const checkValidate = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );

      newLstData?.forEach((value: any) => {
        if (value.validate?.required) {
          objValidation[value.key] = Yup.string()
            .required('Bắt buộc nhập')
            .nullable();
        } else if (value?.type === COMPONENT_TYPE.COLUMNS && value?.columns && value?.columns?.length) {
          value?.columns?.forEach((lstValue: any) => {
            checkValidate(lstValue);
          });
        }
      });
    };
    checkValidate(data);
    return Yup.object({
      [formCode]: Yup.object(objValidation),
    });
  };

  return handleValidate();
};

export const generateInitValues = (data: any, formCode?: string) => {
  const handleValues = () => {
    let values: any = {};

    const checkValue = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );
      newLstData?.forEach((data: any) => {
        switch (data.type) {
          case COMPONENT_TYPE.TEXTFIELD:
          case COMPONENT_TYPE.DATEPICKER:
          case COMPONENT_TYPE.TEXTAREA:
          case COMPONENT_TYPE.DAY:
          case COMPONENT_TYPE.TIME:
          case COMPONENT_TYPE.EMAIL:
          case COMPONENT_TYPE.PHONE:
            values[data.key] = data.defaultValue;
            break;
          case COMPONENT_TYPE.DATETIME:
            values[data.key] = data.defaultDate === "newDate" ? formatDateDTO(new Date()) : data.defaultDate;
            break;
          case COMPONENT_TYPE.NUMBER:
            values[data.key] = parseFloat(data.defaultValue) || '';
            break;
          case COMPONENT_TYPE.SELECT:
            const fieldValue = data.defaultValue
              ? JSON.parse(data.defaultValue)
              : {
                [data.key]: "",
                [`${data.key}Id`]: "",
                [`${data.key}Name`]: "",
              };
            values = {
              ...values,
              ...fieldValue,
            };
            break;
          case COMPONENT_TYPE.CHECKBOX:
          case COMPONENT_TYPE.RADIO:
            values[data.key] = data.defaultValue == "true" ? "1" : "0";
            break;
          case COMPONENT_TYPE.COLUMNS:
            data?.columns?.forEach((lstData: any) => {
              checkValue(lstData);
            });
            break
          default:
            break;
        };
      });
    };

    checkValue(data);
    console.log(values)
    console.log(formCode)

    return formCode ? { [formCode]: values } : values;
  };

  return handleValues();
};

export const changeWfStatus = async (tracker: string, wfWorkflowsReq: WfWorkflowsReq | undefined) => {
  if (!wfWorkflowsReq) return;
  try {
    await updateWfWorkflows(tracker, wfWorkflowsReq);
  } catch (error) {
    console.error(error);
    toast.error(RESPONSE_MESSAGE.ERROR);
  }
};

export const getCategory = async (key: string | number) => {
	try {
		let result;

		switch (key) {
			case TERM_CLASS_IDS.KHAM_BENH:
				result = await getTermClasses({ classIds: key });
				break;

			case TERMS_CLASS_IDS_DICH_VU:
				result = await getTermClasses({ classIds: TERMS_CLASS_IDS_DICH_VU });
				break;

			case TERM_CLASS_IDS.ICD10:
			case TERM_CLASS_IDS.HTXT:
			case TERM_CLASS_IDS.KQDT:
				result = await getTermByClassId({ classId: key });
				break;
		}
		
		const { code, data } = result?.data;		
		
		if (CODE.SUCCESS === code) {
			return getTermData(data, key);
		}

		return [];
	} catch (error) {
		console.error(error);
	}
};

const getTermData = (response: any, termType: number | string) => {
	switch (termType) {
		case TERM_CLASS_IDS.KHAM_BENH:
			return response?.[0]?.terms?.[0]?.subs || [];
		case TERM_CLASS_IDS.ICD10:
		case TERM_CLASS_IDS.HTXT:
		case TERM_CLASS_IDS.KQDT:
		case TERMS_CLASS_IDS_DICH_VU:
			return response || [];
		default:
			return response?.content || [];
	}
};