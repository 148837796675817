import React, { useContext } from "react";
import { Column } from "react-table";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { CODE, SELECTION_MODE } from "../../../utils/Constant";
import { DanhSachBenhNhanInterface } from "../../models/KhamDoanModel";
import WfStatusSummary from "../../../workflows/components/WfStatusSummary";
import { getDetailOrderKhamBenh, getDetailKhamBenh } from "./services/KhamLamSangSevice"
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { AppContext } from "../../../appContext/AppContext";
import { KhamLamSangContext, KhamLamSangContextProps } from "./KhamLamSang"
import { formatTrangThaiBenhNhan } from "../../../utils/FormatUtils";
import { WfSummaryStatusRes } from "./models/WfWorkflows";

interface Props {
  setSelectedRow: React.Dispatch<any>;
  selectedRow: any;
  dsBenhNhan: any;
  countPaid: any;
  summaries: any[];
  setColorFilter: (data: any) => void;
}
const TableDanhSachBenhNhan = ({
  dsBenhNhan,
  summaries,
  setColorFilter
}: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { setTTNhanVien, setTTDetailKhamBenh } = useContext<KhamLamSangContextProps>(KhamLamSangContext)

  const getDetailNV = async (values: any) => {
    try {
      let { data } = await getDetailOrderKhamBenh({
        visitId: values?.visitId,
        orderId: values?.orderId,
      });
      
      if (CODE.SUCCESS === data?.code) {
        setTTNhanVien({
          ...data?.data,
          id:values?.staffId || data?.data?.id
        });
        
        data?.data?.encounter && detailKhamBenh(data?.data?.encounter);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const detailKhamBenh = async (values: any) => {
    try {
      let { data } = await getDetailKhamBenh(values?.id);
      if (CODE.SUCCESS === data?.code) {
        setTTDetailKhamBenh(data?.data)
        setIsLoading(false);
      } else {
        setTTDetailKhamBenh({})
      }
    } catch (error) {
      setTTDetailKhamBenh({})
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlerSelectData = (value: any) => {
    setTTDetailKhamBenh({});
    setTTNhanVien({});
    getDetailNV(value[0]);
  }

  const getPatientName = (patient: any) => {
    return (
      <div className="fs-3">
        <div>{patient?.name}</div>
        <div>{`${patient?.patientCode || ""} - ${
          patient?.examPackageName || ""
        }`}</div>
      </div>
    );
  };

  const DanhSachBenhNhanColumns: ReadonlyArray<
    Column<DanhSachBenhNhanInterface>
  > = [
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"TT"}
            className=" text-center text-white align-middle bg-pri"
          />
        ),
        id: "TT",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={formatTrangThaiBenhNhan(props.data[props.row.index].color)}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"STT"}
            className=" text-center text-white align-middle bg-pri"
          />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={
              props.row.index + 1}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"Tên nhân viên"}
            className="text-center text-white align-middle bg-pri min-w-150px"
          />
        ),
        id: "name",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="s-2"
            data={getPatientName(props.data[props.row.index])}
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"Tuổi"}
            className=" text-center text-white align-middle bg-pri min-w-50px"
          />
        ),
        id: "age",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={props.data[props.row.index]?.age}
            className="text-center"
          />
        ),
      },
    ];

  const handleSearchByStatus = (status: WfSummaryStatusRes) => {
    setColorFilter(status?.color);
  }

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="spaces h-calc-vh-245 mb-3">
        <TableCustom<any>
          className={"h-100"}
          data={dsBenhNhan}
          columns={DanhSachBenhNhanColumns}
          selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
          getSelectedRowsData={handlerSelectData}
          verticalScroll={true}
        />
      </div>
      <WfStatusSummary summaryStatus={summaries} onClick={handleSearchByStatus} />
    </div>
  );
};

export { TableDanhSachBenhNhan };
