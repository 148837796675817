import clsx from 'clsx';
import { useState } from 'react';
import { base64ImageToBlob, getPDFURLPreviewFromBase64 } from '../../utils/FormatUtils';
import { ConfirmDialog } from '../ConfirmDialog';
import ImageEditor from '../image-editor';
import ImageLightbox from '../image-lightbox';
import ImageItem from './components/ImageItem';
import { ImageActionType, ImageItemProps, ImageListProps } from './types';

import './style.scss';

const ImageList = ({ data, className, setData, ...props }: ImageListProps) => {
	const [actionActive, setActionActive] = useState<{
		open: boolean;
		data: ImageItemProps | null;
		action: ImageActionType;
	}>({
		action: 'view',
		open: false,
		data: null,
	});

	const onCrop = (base64Value: string) => {
		let listTemp = [...data];
		const imageCropBlob = base64ImageToBlob(base64Value);
		const imageCropFile = new File([imageCropBlob], 'image-crop.jpeg');

		listTemp.splice((actionActive.data?.index ?? 0) + 1, 0, {
			src: base64Value,
			file: imageCropFile,
		});
		listTemp = listTemp.map((item, index) => ({
			...item,
			index,
		}));
		setData(listTemp);
		resetSelected();
	};

	const onDelete = () => {
		const image = actionActive.data;

		if (!image) return;

		if (!image.id) {
			setData(data.filter(item => item.index !== image.index));
		} else {
			const newList = data.map(item => {
				if (item.index === image.index) {
					return {
						...item,
						deleted: true,
					};
				}
				return item;
			});
			setData(newList);
		}

		resetSelected();
	};

	const resetSelected = () => {
		setActionActive({ open: false, data: null, action: 'view' });
	};

	const onImageActionClick = (action: ImageActionType, image: any) => {
		setActionActive({
			action,
			data: image,
			open: true,
		});
	};

	return (
		<div className={clsx('spaces w-100', className)} {...props}>
			{!!data.length &&
				data
					.filter(image => !image.deleted)
					.map((image: ImageItemProps) => (
						<ImageItem
							key={image.index}
							index={image.index}
							title={image.title}
							onActionClick={onImageActionClick}
							{...image}
						/>
					))}

			{actionActive.action === 'crop' && actionActive.open && (
				<ImageEditor
					open={actionActive.open}
					onClose={resetSelected}
					onSave={(result: string) => {
						onCrop(result);
					}}
					src={actionActive.data!.src}
				/>
			)}

			{actionActive.action === 'view' && actionActive.open && (
				<ImageLightbox
					open={actionActive.open}
					onClose={resetSelected}
					images={data.filter(image => !image.deleted)}
					indexActive={actionActive.data?.index}
				/>
			)}

			{actionActive.action === 'delete' && actionActive.open && (
				<ConfirmDialog
					className="z-index-1060"
					show={actionActive.open}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa ảnh này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						resetSelected();
					}}
					onYesClick={onDelete}
				/>
			)}
		</div>
	);
};

export default ImageList;
