import { createContext } from "react";

type ContextType = {
    benhNhanInfo: any;
    setBenhNhanInfo: (data: any) => void;
    phieuXetNghiemInfo: any;
    setPhieuXetNghiemInfo: (data: any) => void;
    selectedBenhNhan: any;
    setSelectedBenhNhan: (data: any) => void;
};

const initValue: ContextType = {
    benhNhanInfo: {},
    setBenhNhanInfo: () => {},
    phieuXetNghiemInfo: {},
    setPhieuXetNghiemInfo: () => {},
    selectedBenhNhan: {},
    setSelectedBenhNhan: () => {},
};

export const PhanHeXetNghiemContext = createContext<ContextType>(initValue);
