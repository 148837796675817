import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputSearch from "../../../component/InputSearch";
import SimpleTable from "../../../component/table/simple-table/SimpleTable";
import { getICD10Columns } from "./Column";
import { handleSearchByFuse } from "../../../utils/AppFunction";
import { RowMouseEventHandlerParams } from "react-virtualized";
import { KEY } from "../../../utils/Constant";
import { useDebounce } from "../../../../../_metronic/helpers";
interface Props {
  data: any[];
  handleClose: () => void;
  setMauSelected?: (data: any) => void;
  selectionMode?: "checkbox" | "radio";
  dataChecked?: any;
}

const ModalICD10: FC<Props> = ({
  data,
  handleClose,
  setMauSelected,
  selectionMode = "radio",
  dataChecked,
}) => {
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [dataDanhSachMau, setDataDanhSachMau] = useState<any[]>([]);
  const [textSearch, setTextSearch] = useState<string>("");
  const [listICD10, setListICD10] = useState<any>([]);
  const textSearchDebounce = useDebounce(textSearch.trim(), 300);

  const updatePageData = () => {
    try {
      let newData: any[] = JSON.parse(JSON.stringify(data));
      setDataDanhSachMau(newData || []);
      setListICD10(newData || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  useEffect(() => {
    if(dataChecked?.length > 0) {
      setSelectedRow(dataChecked);
    }
  }, [dataChecked]);

  useEffect(() => {
    listICD10.length > 0 && handleSearchByFuse(listICD10, textSearchDebounce ?? '', setDataDanhSachMau);
  }, [textSearchDebounce]);

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value ? `'${value}` : '';
    setTextSearch(newValue);
  };

  const handleOnRowClickSingle = (rowData: any) => {
    let _listData = [...dataDanhSachMau];
    _listData[rowData.index].isChecked = true;
    
    setDataDanhSachMau(_listData);
    setSelectedRow([_listData[rowData.index]]);
  }

  const handleOnRowClickCheckbox = (rowData: any) => {
    if(!rowData) return;
    
    let _listData = [...dataDanhSachMau];
    _listData[rowData.index].isChecked = true;
    let updatedSelectedRows = [...selectedRow];
    const selectedItem = _listData[rowData.index];

    if (!rowData?.rowData?.isChecked) {
      updatedSelectedRows.push(selectedItem);
    } else {
      updatedSelectedRows = updatedSelectedRows.filter(
        (item: any) => item?.["code"] !== selectedItem?.["code"]
      );
    }
    
    setDataDanhSachMau([..._listData]);
    setSelectedRow(updatedSelectedRows);
  }

  return (
    <>
      <Modal
        show
        centered
        size="xl"
        className="dialog-background"
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="text-transform-none">Bệnh ICD10</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="spaces py-5">
            <InputSearch
              placeholder="Tìm kiếm"
              handleChange={handleChange}
              className="spaces h-25 pr-4 radius-3"
            />
          </div>
          <SimpleTable
            data={dataDanhSachMau || []}
            columns={getICD10Columns(selectionMode)}
            height={450}
            width={1092}
            scrollable={true}
            setDataChecked={setSelectedRow}
            dataChecked={selectedRow}
            handleRenderCell={handleRenderCell}
            uniquePrefix="code"
            onRowClick={(rowData: RowMouseEventHandlerParams) => {
              selectionMode === "radio" ? handleOnRowClickSingle(rowData) : handleOnRowClickCheckbox(rowData);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill spaces W-80"
            onClick={() => {
              setMauSelected?.(
                selectionMode === "radio" ? selectedRow[0] || null : selectedRow
              );
              handleClose();
            }}
          >
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalICD10;
