import { WORKFLOW_FIELD_PROPERTY, WORKFLOW_FIELD_PROPERTY_PROP_MAPPER } from '../Constant';
import { WfTrackerField } from '../models/WfWorkflows';

export const getTrackerFieldProperty = (properties: string) => {
	let fieldProps = {};
	const propertyArray = properties.split(',');

	Object.values(WORKFLOW_FIELD_PROPERTY).forEach(property => {
		if (propertyArray.includes(property)) {
			const newProp = WORKFLOW_FIELD_PROPERTY_PROP_MAPPER[property];
			fieldProps = {
				...fieldProps,
				[newProp]: true,
			};
		}
	});

	return fieldProps;
};

export const convertTrackerFieldResponseToObject = (trackerFields: WfTrackerField[]) => {
	if (!(trackerFields ?? []).length) return;

	return trackerFields.reduce((result, field) => {
		result = {
			...result,
			[field.termField]: getTrackerFieldProperty(field.attribute),
		};

		return result;
	}, {});
};
