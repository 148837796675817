import { ThanhToanHDContext } from "../ThanhToanHD";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../appContext/AppContext";
import { DanhSachHDThanhToanColumns } from "../ThanhToanHDConstant";
import { searchByPage } from "../../../services/KhamDoanHopDongServices";
import { TableCustom } from "../../../../component/table/table-custom/TableCustom";
import {
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
  SELECTION_MODE
} from "../../../../utils/Constant";
import FilterSidebar from "../../../../component/filter-sidebar/FilterSidebar";
import "../../../PhanHeKhamDoan.scss";
import { filterDateList } from "../../../constants/PhanHeKhamDoan";

const DSHopDongThanhToan: FC<any> = (props) => {
  const { setHDSelected } = useContext(ThanhToanHDContext);
  const { setIsLoading } = useContext(AppContext);
  const [DSHopDong, setDSHopDong] = useState([]);

  const handlerSelectData = (data: any) => {
    setHDSelected(data[0] || {});
  };

  const updatePageData = async (dataSearch: any) => {
    try {
      setIsLoading(true);
      const { data } = await searchByPage({
        name: dataSearch?.keyword,
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        startDate: dataSearch?.toDate || null,
        endDate: dataSearch?.fromDate || null,
        filterStartDate: dataSearch?.typeRadio?.filterStartDate,
        filterEndDate: dataSearch?.typeRadio?.filterEndDate
      });
      setDSHopDong(data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData(null);
  }, []);

  return (
    <div className="dsHopDong bg-white position-relative spaces pt-10 gap-8">
      <FilterSidebar
        title="Danh sách hợp đồng"
        onSearch={updatePageData}
        listMenuItem={[]}
        handleSelectOption={() => {}}
        isShowMenuItem={false}
        isShowSort={false}
        isFilterByRadio={true}
        formCheckList={filterDateList}
      />
      <div className="d-flex flex-column justify-content-between h-100 pt-3">
        <div className="spaces h-calc-vh-230">
          <TableCustom
            className={"h-bang-ds-bn"}
            data={DSHopDong}
            columns={DanhSachHDThanhToanColumns}
            selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
            getSelectedRowsData={handlerSelectData}
            verticalScroll={true}
          />
        </div>
      </div>
    </div>
  );
};

export default DSHopDongThanhToan;
