import { ComponentPropsWithoutRef, useMemo } from 'react';
import { getPDFURLPreviewFromBase64 } from '../../../utils/FormatUtils';

type Props = ComponentPropsWithoutRef<'iframe'> & {
	src: string;
	title?: string;
};

const IframePreview = ({ src, title, ...props }: Props) => {
	const srcPreview = useMemo(() => {
		return getPDFURLPreviewFromBase64(src);
	}, [src]);

	return (
		<iframe
			title={title}
			src={srcPreview}
			width="100%"
			height="100%"
			style={{
				scrollbarWidth: 'none',
			}}
			{...props}
		/>
	);
};

export default IframePreview;
