import React, { memo, useState } from 'react';
import { Image } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import TextField from '../../../../component/TextField';
import ModalLuocDoPTTT from '../ModalLuocDoPTTT';

type Props = {
	value: any;
	isView?: boolean;
	setFieldValue: (name: string, value: string) => void;
};

const LuocDoPTTTField = ({ value, isView, setFieldValue }: Props) => {
	const [openDrawDialog, setOpenDrawDialog] = useState(false);

	return (
		<>
			<LabelRequired label="Lược đồ PTTT" />
			{value ? (
				<Image
					src={value}
					rounded
					width="100%"
					height="200"
					alt="Lược đồ PTTT"
					className="border"
					onClick={() => {
						setOpenDrawDialog(true);
					}}
				/>
			) : (
				<TextField
					disabled={isView}
					as="textarea"
					rows={2}
					className="spaces h-200"
					onClick={() => {
						setOpenDrawDialog(true);
					}}
                    name='surgerySurgicalProcedureDiagram-textarea'
					value=""
				/>
			)}
			{openDrawDialog && (
				<ModalLuocDoPTTT
					open={openDrawDialog}
					onClose={() => {
						setOpenDrawDialog(false);
					}}
					onSave={(data: string) => {
						setFieldValue('surgerySurgicalProcedureDiagram', data);
						setOpenDrawDialog(false);
					}}
					value={value}
				/>
			)}
		</>
	);
};

export default memo(LuocDoPTTTField);
