import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, InputGroup, Row, Stack } from 'react-bootstrap';
import LabelRequired from '../component/LabelRequired';
import DatePickerCustom from '../component/date-picker/DatePickerCustom';
import TextValidator from '../component/TextValidator';
import { OctTable } from '@oceantech/oceantech-ui';
import { CODE, DEFAULT_PAGE_INDEX, KEY, overflowHiddenTable, textSystem } from '../utils/Constant';
import { formatDateTime, formatTrangThaiBenhNhan } from '../utils/FormatUtils';
import CustomIconButton from '../component/custom-icon-button/CustomIconButton';
import SelectTree from '../component/SelectTree';
import { IconMenu } from '../component/IconSvg';
import { AppContext } from '../appContext/AppContext';
import { searchByPage } from './services/ChamSocKhachHangService';
import moment from 'moment';
import { getStatusDesc } from '../phan-he-kham-benh/services/KhamBenhService';
import { WfConstTracker } from '../workflows/Constant';
import ShowStatus from '../component/ShowStatus';
import ModalDsDichVu from './components/ModalDsDichVu';
import ModalDsThuoc from './components/ModalDsThuoc';

type Props = {}

const ChamSocKhachHang = (props: Props) => {
    const { setIsLoading } = useContext(AppContext);
    const currentDate = moment().format("YYYY-MM-DD");
    const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
    const [idSelected, setIdSelected] = useState<string>("");
    const [fromDateSearch, setFromDateSearch] = useState<any>(currentDate);
    const [toDateSearch, setToDateSearch] = useState<any>(currentDate);
    const [keyword, setKeyword] = useState<string>("");
    const [itemSelectedTree, setItemSelectedTree] = useState<any>(null);
    const [treeData, setTreeData] = useState<any>({
        code: "all",
        name: "Tất cả bệnh nhân",
        icon: <IconMenu />,
        filter: [],
    });
    const [dsBenhNhan, setDsBenhNhan] = useState<any>([]);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });
    const [objectSearch, setObjectSearch] = useState<any>({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: 15,
    });
    const [openModalDsDichVu, setOpenModalDsDichVu] = useState<boolean>(false);
    const [openModalDsThuoc, setOpenModalDsThuoc] = useState<boolean>(false);
    const [rowSelected, setRowSelected] = useState<any>(null);

    const dsBenhNhanColumns = [
        {
            name: "TT",
            field: "",
            headerStyle: {
                minWidth: "50px",
            },
            cellStyle: {
                textAlign: "center",
                paddingRight: "0px",
            },
            render: (rowData: any, index: number, stt: number) =>
                formatTrangThaiBenhNhan(rowData?.color),
        },
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: "50px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        },
        {
            name: "Mã BN",
            field: "patientCode",
            headerStyle: {
                minWidth: "100px",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            name: "Họ và tên",
            field: "name",
            headerStyle: {
                minWidth: "200px",
            },
        },
        {
            name: "SĐT",
            field: "phoneNumber",
            headerStyle: {
                minWidth: "125px",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            name: "Giới tính",
            field: "gender",
            headerStyle: {
                minWidth: "120px",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            name: "Đợt khám gần nhất",
            field: "visitDateStopped",
            headerStyle: {
                minWidth: "175px",
            },
            render: (rowData: any) =>
                rowData?.visitDateStopped
                    ? formatDateTime(rowData?.visitDateStopped)
                    : "",
        },
        {
            name: "Dịch vụ khám",
            field: "insCode",
            headerStyle: {
                minWidth: "125px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData: any) => {
                return (
                    <div className="d-flex justify-content-center ">
                        <CustomIconButton
                            variant="edit"
                            title='Xem'
                            onClick={() => { 
                                setRowSelected(rowData);
                                setOpenModalDsDichVu(true);
                            }}
                        >
                             <i className="bi bi-three-dots fs-4 text-pri"></i>
                        </CustomIconButton>
                    </div>
                );
            },
        },
        {
            name: "Thuốc chỉ định",
            field: "visitReasonName",
            headerStyle: {
                minWidth: "125px",
            },
            render: (rowData: any) => {
                return (
                    <div className="d-flex justify-content-center ">
                        <CustomIconButton
                            variant="edit"
                            title='Xem'
                            onClick={() => {
                                setRowSelected(rowData);
                                setOpenModalDsThuoc(true);
                            }}
                        >
                             <i className="bi bi-three-dots fs-4 text-pri"></i>
                        </CustomIconButton>
                    </div>
                );
            },
        },
        {
            name: "Thời gian sau khám",
            field: "postExaminationPeriod",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                // ...overflowHiddenTable,
                maxWidth: "200px",
            },
        },
        {
            name: "Thời gian liên lạc",
            field: "termSvcName",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                // ...overflowHiddenTable,
                maxWidth: "230px",
            },
            render: (rowData: any) =>
                rowData?.encounterDatetime
                    ? formatDateTime(rowData?.encounterDatetime)
                    : "",
        },
        {
            name: "Ghi chú",
            field: "note",
            headerStyle: {
                minWidth: "200px",
            },
            cellStyle: {
                // ...overflowHiddenTable,
                maxWidth: "200px",
            },
        },
    ];

    const updatePageData = async () => {
        try {
            setIsLoading(true);
            let {
                data: { data },
            } = await searchByPage({
                pageIndex: objectSearch.pageIndex,
                pageSize: objectSearch.pageSize,
                fromDate: fromDateSearch ? fromDateSearch : null,
                toDate: toDateSearch ? toDateSearch : null,
                keyword,
                filterBy: itemSelectedTree?.parrentCode,
                filterValue: itemSelectedTree?.name,
            });
            setDsBenhNhan(data?.customers?.content || []);
            setTreeData({
                code: "all",
                name: "Tất cả bệnh nhân",
                icon: <IconMenu />,
                filter: data?.treeList,
            });
            // setStatuses(data?.statuses);
            setConfigTable({
                totalPages: data?.customers?.totalPages,
                totalElements: data?.customers?.totalElements,
                numberOfElements: data?.customers?.numberOfElements,
            });
        } catch (error) {
            console.error("error", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetStatusDesc = async () => {
        try {
            let { data } = await getStatusDesc(WfConstTracker.CUSTOMER);
            if (data?.code === CODE.SUCCESS) {
                setStatuses(data?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === KEY.ENTER) {
            updatePageData();
        }
    };

    const getSelectedItem = (item: any) => {
        setItemSelectedTree(item);
    };

    useEffect(() => {
        updatePageData();
        handleGetStatusDesc();
    }, [
        objectSearch.pageIndex,
        objectSearch.pageSize,
        fromDateSearch,
        toDateSearch,
        itemSelectedTree,
    ]);

    return (
        <Row className="d-flex spaces m-0 mt-10 h-100 max-h-calc-100 bg-white">
            <Col xs="2" className="spaces p-0">
                <SelectTree
                    className="w-100 h-1000 border border-0 overflow-auto"
                    codeCollapses={codeCollapses}
                    handleChangeCollapsesCode={setCodeCollapses}
                    idSelected={idSelected}
                    handleChangeSelectId={setIdSelected}
                    selectTree={treeData}
                    getSelectedItem={getSelectedItem}
                />
            </Col>
            <Col
                xs="10"
                className="spaces p-10 d-flex flex-column justify-content-between"
            >
                <div>
                    <div className="d-flex justify-content-end">
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className="justify-content-end"
                        >
                            <div className="d-flex align-items-center spaces h-32 mb-3">
                                <LabelRequired
                                    className="min-w-60px fw-bold"
                                    label="Từ ngày"
                                />
                                <DatePickerCustom
                                    name="tuNgay"
                                    value={fromDateSearch}
                                    setDateValue={date => {
                                        setFromDateSearch(date);
                                    }}
                                    dateFormatOutput="YYYY-MM-DD"
                                />
                            </div>
                            <div className="d-flex align-items-center spaces h-32 mb-3">
                                <LabelRequired
                                    className="min-w-65px fw-bold"
                                    label="Đến ngày"
                                />
                                <DatePickerCustom
                                    name="denNgay"
                                    value={toDateSearch}
                                    setDateValue={date => {
                                        setToDateSearch(date);
                                    }}
                                    dateFormatOutput="YYYY-MM-DD"
                                />
                            </div>
                            <div className="spaces w-200">
                                <InputGroup className="spaces h-32">
                                    <TextValidator
                                        className="spaces h-29 w-100"
                                        name="keyword"
                                        placeholder="Tìm kiếm"
                                        value={keyword}
                                        onChange={(e: any) => {
                                            setKeyword(e?.target?.value);
                                        }}
                                        onKeyDown={handleKeyPress}
                                    />
                                    <Button className="btn-fill spaces h-29" onClick={(e: any) => { }}>
                                        <span>Tìm kiếm</span>
                                    </Button>
                                </InputGroup>
                            </div>
                        </Stack>
                    </div>
                    <div className="spaces h-calc-vh-300">
                        <OctTable
                            id="list-patients"
                            className="h-100 table-background-th"
                            data={dsBenhNhan || []}
                            columns={dsBenhNhanColumns}
                            searchObject={objectSearch}
                            setSearchObject={setObjectSearch}
                            notDelete={true}
                            notEdit={false}
                            noToolbar={true}
                            totalPages={configTable.totalPages}
                            totalElements={configTable.totalElements}
                            numberOfElements={configTable.numberOfElements}
                            //   handleDoubleClick={handleSelectBenhNhan}
                            //   setDataChecked={setSelectedBenhNhan}
                            //   selectionMode={TYPE.SINGLE_NO_RADIO_BUTTON}
                            fixedColumnsCount={-1}
                            isShowSelection={false}
                            clearToolbar={true}
                        />
                    </div>

                </div>
                <Stack
                    direction="horizontal"
                    gap={3}
                >
                    <ShowStatus statuses={statuses} />
                </Stack>
            </Col>
            {openModalDsDichVu && (
                <ModalDsDichVu benhNhanSelected={rowSelected} handleClose={() => setOpenModalDsDichVu(false)} />
            )}
            {openModalDsThuoc && (
                <ModalDsThuoc benhNhanSelected={rowSelected} handleClose={() => setOpenModalDsThuoc(false)} />
            )}
        </Row>
    )
}

export default ChamSocKhachHang