import { useTabThuocContext } from '../../context';
import ThongTinPhieu from './ThongTinPhieu';

const ThongTinPhieuWrapper = () => {
	const { donThuocInfo } = useTabThuocContext();

	return <ThongTinPhieu thongTinPhieu={donThuocInfo} />;
};

export default ThongTinPhieuWrapper;
