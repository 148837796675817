import clsx from 'clsx';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

type Props = {
	label?: string;
	value: string;
	length: number;
	className?: string;
	inputClassName?: string;
	required?: boolean;
	labelClassName?: string;
};

const InputOTP = ({
	value,
	length,
	className,
	inputClassName,
	label,
	required,
	labelClassName,
}: Props) => {
	const [fieldValue, setFieldValue] = useState<string[]>([]);

	useEffect(() => {
		setFieldValue(value.split(''));
	}, [value]);

	const onInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;
		const target = e.target as HTMLInputElement;

		const tempValue = [...fieldValue];
		tempValue.splice(index, 1, value);
		setFieldValue(tempValue);

		if (index < length - 1) {
			moveNext(target);
		}
	};

	const moveNext = (target: HTMLInputElement) => {
		if (target.nextSibling) {
			const inputNextElement = target.nextSibling as HTMLInputElement;
			inputNextElement.focus();
		}
	};

	const moveBack = (target: HTMLInputElement) => {
		if (target.previousSibling) {
			const inputPrevElement = target.previousSibling as HTMLInputElement;
			inputPrevElement.focus();
		}
	};

	const onInputKeydown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		const target = e.target as HTMLInputElement;

		switch (e.key) {
			case 'Delete':
				const tempValue = [...fieldValue];
				tempValue.splice(index, 1, '');
				setFieldValue(tempValue);
				break;
			case 'ArrowLeft':
				if (index >= 0) {
					moveBack(target);
				}
				break;
			case 'ArrowRight':
				if (index < length - 1) {
					moveNext(target);
				}
				break;
			default:
				break;
		}
	};

	return (
		<Form.Group className={clsx('d-flex oct-field-wrapper oct-input-otp', className)}>
			<Form.Label
				className={clsx(
					'spaces text-lable-input max-content-width mb-0 me-2 min-w-fit-content',
					labelClassName
				)}
				dangerouslySetInnerHTML={{
					__html: required
						? `${label}<strong class='text-danger ps-1'>(*)</strong>`
						: `${label}`,
				}}
			></Form.Label>
			<div className="d-flex align-items-end">
				{Array.from({ length }, (_, index) => index).map(field => (
					<Form.Control
						key={field}
						name={`otp-input-${field}`}
						className={clsx(
							'spaces mr-4 p-6 h-29 w-30px text-center radius-4',
							inputClassName
						)}
						value={fieldValue[field]}
						maxLength={1}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onInputChange(e, field)}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onInputKeydown(e, field)}
					/>
				))}
			</div>
		</Form.Group>
	);
};

export default InputOTP;
