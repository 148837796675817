import { useCallback, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LabelRequired from '../../component/LabelRequired';
import { PATIENT_TYPE } from '../../phan-he-vien-phi/const/VienPhiConstant';
import DynamicComponentXN from '../../phan-he-xet-nghiem/components/DynamicComponentXN';
import ModalNhapSoLuongInBarcode from '../../phan-he-xet-nghiem/components/tab-lay-mau-benh-pham/ModalNhapSoLuongInBarcode';
import { CODE } from '../../utils/Constant';
import { formatDateTime } from '../../utils/FormatUtils';
import WfButton from '../../workflows/components/WfButton';
import { WfConstTracker, WORKFLOW_STATUS } from '../../workflows/Constant';
import { usePhanHeLayMauContext } from '../context/PhanHeLayMauContext';
import { getPhieuLayMauInfo, printBarCode } from '../services';
import BangLayMauBenhPham from './BangLayMauBenhPham';
import DanhSachBenhNhan from './DanhSachBenhNhan';

const LayMauBenhPham = () => {
	const { benhNhanInfo, phieuLayMauInfo, setPhieuLayMauInfo } = usePhanHeLayMauContext();

	const [statusChangedEvent, setStatusChangedEvent] = useState<number>();
	const [dataDynamic, setDataDynamic] = useState<any>();
	const [shouldOpenModalNhapSoLuongInBarcode, setShouldOpenModalNhapSoLuongInBarcode] =
		useState<boolean>(false);

	const { patient, visit, provisionalDiagnosisName } = benhNhanInfo || {};

	const actionChanged = useCallback(
		async (currStatusCode: string | undefined) => {
			toast.success(
				currStatusCode === WORKFLOW_STATUS.DA_LAY_MAU
					? 'Lấy mẫu thành công'
					: 'Hủy lấy mẫu thành công'
			);
			handleGetPhieuXetNghiem();
			setStatusChangedEvent(Math.random());
		},
		[benhNhanInfo, statusChangedEvent]
	);

	const actionClick = (data: any) => {
		setDataDynamic(data);
	};

	const handleGetPhieuXetNghiem = async () => {
		const { code, data } = await getPhieuLayMauInfo(benhNhanInfo?.id);

		if (CODE.SUCCESS === code) {
			const danhSachMau = data?.orders?.map((item: any) => ({
				...item,
				...item?.term,
				subs: item?.term?.subs?.map((sub: any) => ({
					...sub,
					orderId: item?.orderId,
					statusCode: item?.statusCode,
					statusColor: item?.statusColor,
				})),
				statusCode: item?.statusCode,
				statusColor: item?.statusColor,
			}));
			setPhieuLayMauInfo({
				thongTinPhieu: data,
				danhSachMau,
			});
		}
	};

	return (
		<div className="reception-list bg-gray">
			<Row className="phanHeVienPhi h-100">
				<div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white spaces pt-10">
					<div className="flex-1">
						<DanhSachBenhNhan statusChangedEvent={statusChangedEvent} />
					</div>
				</div>
				<div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
					<div className="d-flex">
						<div className="spaces w-60 border bg-white d-flex">
							<h4 className="text-title fw-bold fs-4 spaces mb-1 py-5 px-10">
								Thông tin phiếu
							</h4>
						</div>
						<div className="bg-white spaces w-40 border">
							<h4 className="text-title fw-bold fs-4 spaces mb-1 py-5 px-10">
								Thông tin bệnh nhân
							</h4>
						</div>
					</div>
					<div className="d-flex spaces mb-6">
						<div className="bg-white spaces w-60 border py-5 px-10">
							<div className="grid-8-4 spaces gap-16 mb-1">
								<div className="d-flex spaces">
									<div className="spaces w-17 min-w-26-percent text-title fw-bold fw-bold fs-4 line-h-1">
										Barcode:
									</div>
									<div className="spaces w-75 color-disable text-title text-truncate fw-bold fs-4">
										{phieuLayMauInfo?.thongTinPhieu?.barcode || '...'}
									</div>
								</div>
								{benhNhanInfo?.code && (
									<Button
										className="btn-fill spaces py-2 h-34"
										id='lay-mau-barcode-print-button'
										onClick={() => setShouldOpenModalNhapSoLuongInBarcode(true)}
									>
										In Barcode
									</Button>
								)}
							</div>
							<div className="bg-white">
								<div className="d-flex spaces mb-1">
									<LabelRequired
										label="Mã phiếu:"
										className="spaces w-17 min-w-76 fw-bold"
									/>
									<div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
										{phieuLayMauInfo?.thongTinPhieu?.code || '...'}
									</div>
								</div>
								<div className="d-flex spaces mb-1">
									<LabelRequired
										label="Chỉ định:"
										className="spaces w-17 min-w-76 fw-bold"
									/>
									<div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
										{phieuLayMauInfo?.thongTinPhieu?.indicationPerson
											? `${phieuLayMauInfo?.thongTinPhieu?.indicationPerson}`
											: '...'}{' '}
										-{' '}
										{phieuLayMauInfo?.thongTinPhieu?.indicationTime
											? formatDateTime(
													phieuLayMauInfo?.thongTinPhieu?.indicationTime
											  )
											: '...'}{' '}
										-{' '}
										{phieuLayMauInfo?.thongTinPhieu?.indicationDeptName
											? phieuLayMauInfo?.thongTinPhieu?.indicationDeptName
											: visit?.visitObjectTypeCode ===
											  PATIENT_TYPE.KHAM_DOAN.code
											? 'Phòng khám sức khỏe'
											: '...'}
									</div>
								</div>
								<div className="d-flex spaces mb-1">
									<LabelRequired
										label="Lấy mẫu:"
										className="spaces w-17 min-w-76 fw-bold"
									/>
									<div className="spaces text-visit-infor w-83 flex-grow-1 text-truncate">
										{`${phieuLayMauInfo?.thongTinPhieu?.sampler || '...'} - ${
											phieuLayMauInfo?.thongTinPhieu?.samplingStartTime
												? formatDateTime(
														phieuLayMauInfo?.thongTinPhieu
															?.samplingStartTime
												  )
												: '...'
										} - ${
											phieuLayMauInfo?.thongTinPhieu?.samplingDeptName ||
											'...'
										}`}
									</div>
								</div>
							</div>
						</div>

						<div className="bg-white spaces w-40 py-5 border px-10">
							<div className="d-flex spaces mb-6">
								<LabelRequired label="Bệnh nhân:" className="spaces w-25 fw-bold" />
								<div className="flex-grow-1 w-75 text-truncate">
									{patient?.code || '...'} - {patient?.personName || '...'} -{' '}
									{patient?.gender || '...'} - {patient?.age || '...'}
								</div>
							</div>
							<div className="d-flex spaces mb-6">
								<LabelRequired
									label="Mã vào khám:"
									className="spaces w-25 fw-bold"
								/>
								<div className="flex-grow-1 w-75 text-truncate">
									{visit?.code || '...'}
								</div>
							</div>
							<div className="d-flex spaces mb-6">
								<LabelRequired label="Chẩn đoán:" className="spaces w-25 fw-bold" />
								<div className="flex-grow-1 w-75 text-truncate">
									{provisionalDiagnosisName || '...'}
								</div>
							</div>
						</div>
					</div>
					<div className="spaces pt-6 bg-white flex-1">
						<BangLayMauBenhPham />
					</div>
					<div className="d-flex flex-start spaces px-12 pb-12 pt-5 gap-10 bg-white">
						{benhNhanInfo?.id && (
							<WfButton
								tracker={WfConstTracker.SAMPLE}
								objectId={benhNhanInfo?.id}
								additionalFunc={actionChanged}
								handleClick={actionClick}
								additonalButtonParams={{
									id: benhNhanInfo?.id,
								}}
							></WfButton>
						)}
					</div>
				</div>

				{dataDynamic && <DynamicComponentXN data={dataDynamic} setData={setDataDynamic} />}

				{shouldOpenModalNhapSoLuongInBarcode && (
					<ModalNhapSoLuongInBarcode
						quantity={phieuLayMauInfo?.thongTinPhieu?.orders?.length || 1}
						handleClose={() => setShouldOpenModalNhapSoLuongInBarcode(false)}
						params={{
							id: benhNhanInfo?.id,
						}}
						fetchExport={printBarCode}
					/>
				)}
			</Row>
		</div>
	);
};

export default LayMauBenhPham;
