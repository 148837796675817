import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, FormCheck, Row } from 'react-bootstrap'
import LabelRequired from '../../../../component/LabelRequired'
import AutocompleteV2 from '../../../../component/AutocompleteObjectV2'
import TextField from '../../../../component/TextField'
import { useFormikContext } from 'formik'
import { getTermClasses } from '../../../../utils/ServicesUtils'
import { KEY_DS_DANH_MUC_TIEP_DON, TERM_CLASS_IDS } from '../../../../utils/Constant'
import ICD10 from '../../../components/ICD10'
import { IDanhSachDanhMuc } from '../../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel'
import { getIndexedDBItem } from '../../../../utils/IndexedDB'
import ModalChonKetQuaDichVu from '../../../components/tab-kham-benh/modal-chon-ket-qua-dich-vu/ModalChonKetQuaDichVu'
import { KetQuaDichVu } from '../../../models/ThongTinKhamBenhModel'
import { PhanHeTiepDonContext } from '../../../contexts/PhanHeTiepDonContext'
import DatePickerCustom from '../../../../component/date-picker/DatePickerCustom'
import ProvisonalDiagnosis from '../../../components/ProvisonalDiagnosis'

type Props = {}

const ThongTinChuyenTuyen = (props: Props) => {
    const { values, setFieldValue, handleChange, setValues, errors, touched } = useFormikContext<any>();
    const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
    const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
    const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);
    const [selectionModeICD10, setSelectionModeICD10] = useState<'checkbox' | 'radio'>('radio');
    const [listICD10, setListICD10] = useState<any>([]);
    const [listComorbidity, setListComorbidity] = useState<any[]>([]);
    const [openModalChonKetQuaDichVu, setOpenModalChonKetQuaDichVu] = useState<boolean>(false);


    const handleGetListICD10 = async () => {
        try {
            let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
            setListICD10(res?.listICD10 || []);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectKetQua = (selectedData: KetQuaDichVu[]) => {
        let htClinicalResults = selectedData?.map(item =>
            `${item.tenDichVu} ${item.ketQua !== null ? '(' + item.ketQua + ')' : ''}`
        ).join(', ');
        setFieldValue('htClinicalResults', htClinicalResults);
    }

    const handleChangeICD10 = (data: any) => {
        setValues({
            ...values,
            ...data,
        });
    }

    useEffect(() => {
        handleGetListICD10();
    }, []);

    return (
        <Row className='spaces pt-10 h-calc-vh-250 overflow-auto'>
            <Col xs={6}>
                <Row>
                    <Col xs={5}>
                        <LabelRequired label="Thời gian chuyển tuyến" className="min-w-125px" />
                        <DatePickerCustom
                            name="htTime"
                            value={values?.htTime}
                            setDateValue={date => {
                                setFieldValue('htTime', date);
                            }}
                            errors={errors?.htTime}
                            touched={touched?.htTime}
						/>
                    </Col>
                    <Col xs={7}>
                        <LabelRequired label="Người chuyển" className="min-w-125px" />
                        <TextField
                            name="htPerson"
                            className="w-100"
                            value={values?.htPerson || ""}
                            readOnlyText
                        />
                    </Col>
                </Row>
                <div className="spaces mt-5 mb-n2 d-flex flex-row justify-content-between">
                    <LabelRequired label="Chẩn đoán ban đầu" className="min-w-125px" />
                    <Button
                        className="bg-white spaces mb-2 px-2"
                        onClick={() => setShouldOpenModalSelectICD10(true)}
                    >
                        <u className="fw-bold color-17A2B8 fs-13px">Chọn bệnh</u>
                    </Button>
                </div>
                <Row className="align-items-center">
                    <ProvisonalDiagnosis
                        encounterDiagnosisValues={values}
                        encounterDiagnosisErrors={errors}
                        encounterDiagnosisTouched={touched}
                        benhNhanInfo={benhNhanInfo}
                        listComorbidity={listComorbidity}
                        setListComorbidity={setListComorbidity}
                        onChange={handleChangeICD10}
                        icd10Field='htDiagnosis'
                        comorbidityField='htComorbidity'
                        handleChangeEncounterDiagnosis={setFieldValue}
                    />
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Bệnh kèm theo" />
                        <TextField
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htComorbidity"
                            value={values?.htComorbidity || ""}
                            readOnlyText
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mt-3'>
                    <Col xs={5}>
                        <LabelRequired label="Mã bệnh viện" className="min-w-125px" />
                        <AutocompleteV2
                            options={[]}
                            name="htHospitalCode"
                            value={null}
                            className="name autocomplete-custom radius spaces width-100 "
                        />
                    </Col>
                    <Col xs={7}>
                        <LabelRequired label="Tên bệnh viện" className="min-w-125px" />
                        <AutocompleteV2
                            options={[]}
                            name="autocomplete"
                            value={null}
                            className="name autocomplete-custom radius spaces width-100 "
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Dấu hiệu lâm sàng" className="min-w-125px" />
                        <TextField
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htClinicalSigns"
                            value={values?.htClinicalSigns || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mt-3'>
                    <Col xs={12}>
                        <div className="spaces mb-n2 d-flex flex-row justify-content-between">
                            <LabelRequired label="Kết quả lâm sàng" className="min-w-125px" />
                            <Button
                                className="bg-white spaces mb-2 px-2"
                                onClick={() => setOpenModalChonKetQuaDichVu(true)}
                            >
                                <u className="fw-bold color-17A2B8 fs-13px">Chọn kết quả</u>
                            </Button>
                        </div>
                        <TextField
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htClinicalResults"
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Lời dặn bác sĩ" className="min-w-125px" />
                        <TextField
                            className="resize-none"
                            as="textarea"
                            rows="7"
                            name="htDoctorInstructions"
                            value={values?.htDoctorInstructions || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='align-items-center mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Ghi chú" className="min-w-125px" />
                        <TextField
                            className="resize-none"
                            as="textarea"
                            rows="7"
                            name="htNotes"
                            value={values?.htNotes || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={6}>
                <Row>
                    <Col xs={12}>
                        <LabelRequired label="Phương pháp kỹ thuật, thủ thuật, thuốc đã được sử dụng trong điều trị" />
                        <TextField 
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htTreatmentMethod"
                            value={values?.htTreatmentMethod || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Tình trạng người bệnh lúc chuyển tuyến" />
                        <TextField 
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htPatientCondition"
                            value={values?.htPatientCondition || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Hướng điều trị" />
                        <TextField 
                            className="resize-none"
                            as="textarea"
                            rows="4"
                            name="htTreatmentPlan"
                            value={values?.htTreatmentPlan || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Phương tiện vận chuyển" />
                        <TextField 
                            name="htTransportation"
                            value={values?.htTransportation || ""}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Họ tên, chức danh người hộ tống" />
                        <TextField 
                            name="htEscort"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <div className="d-flex justify-content-between spaces mb-2">
                            <LabelRequired label="Hình thức chuyển tuyến" className="min-w-125px" />
                            <div className='d-flex'>
                                <FormCheck
                                    type='radio'
                                    label="Chuyển đúng tuyến CMKT"
                                    className='spaces mr-10'
                                    name="htInNetwork"
                                    value="true"
                                    checked={values?.htInNetwork === "true"}
                                    onChange={handleChange}
                                />
                                <FormCheck
                                    type='radio'
                                    label="Chuyển trái tuyến CMKT"
                                    name="htInNetwork"
                                    value="false"
                                    checked={values?.htInNetwork === "false"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <AutocompleteV2
                            options={[]}
                            value={values?.htModality || null}
                            name="htModality"
                            searchFunction={getTermClasses}
                            searchObject={{ classIds: TERM_CLASS_IDS.HT_CHUYEN_TUYEN }}
                            urlData="data.data.0.terms"
                            onChange={(selectedOption) => setFieldValue("htModality", selectedOption)}
                            // touched={touched?.htModality}
                            // errors={errors?.htModality}
                            className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Lý do chuyển tuyến" className="min-w-125px" />
                        <AutocompleteV2
                            options={[]}
                            value={values?.htReason || null}
                            name="htReason"
                            searchFunction={getTermClasses}
                            searchObject={{ classIds: TERM_CLASS_IDS.LD_CHUYEN_TUYEN }}
                            urlData="data.data.0.terms"
                            onChange={(selectedOption) => setFieldValue("htReason", selectedOption)}
                            // touched={touched?.htReason}
                            // errors={errors?.htReason}
                            className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <FormCheck
                            type='checkbox'
                            label="Phù hợp với quy định chuyển tuyến"
                            className='spaces mr-10'
                        />
                        <FormCheck
                            type='checkbox'
                            label="Không phù hơp với khả năng đáp ứng của cơ sở khám bệnh, chữa bệnh"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Lý do chuyển tuyến của bác sĩ" className="min-w-125px" />
                        <AutocompleteV2
                            options={[]}
                            value={values?.htReasonDoctor || null}
                            name="htReasonDoctor"
                            searchFunction={getTermClasses}
                            searchObject={{ classIds: TERM_CLASS_IDS.LD_CHUYEN_TUYEN_BS }}
                            urlData="data.data.0.terms"
                            onChange={(selectedOption) => setFieldValue("htReasonDoctor", selectedOption)}
                            // touched={touched?.htReasonDoctor}
                            // errors={errors?.htReasonDoctor}
                            className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                        />
                        <TextField 
                            className="resize-none"
                            as="textarea"
                            rows="3"
                            name="htReasonDetails"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Chuyển tuyến" className="min-w-125px" />
                        <AutocompleteV2
                            options={[]}
                            value={values?.htFor || null}
                            name="htFor"
                            searchFunction={getTermClasses}
                            searchObject={{ classIds: TERM_CLASS_IDS.CHUYEN_TUYEN }}
                            urlData="data.data.0.terms"
                            onChange={(selectedOption) => setFieldValue("htFor", selectedOption)}
                            // touched={touched?.htFor}
                            // errors={errors?.htFor}
                            className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                            menuPlacement='top'
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        <LabelRequired label="Thông tin điều trị tuyến dưới" className="min-w-125px mb-1" />
                        <Row>
                            <Col xs={6} className="d-flex mb-3 align-items-center text-lable-input">
                                <LabelRequired label="Từ ngày" className="min-w-60px" />
                                <DatePickerCustom
                                    name="htVisitStartDate"
                                    value={values?.htVisitStartDate}
                                    setDateValue={date => {
                                        setFieldValue('htVisitStartDate', date);
                                    }}
                                    errors={errors?.htVisitStartDate}
                                    touched={touched?.htVisitStartDate}
                                />
                            </Col>
                            <Col xs={6} className="d-flex mb-3 align-items-center text-lable-input">
                                <LabelRequired label="Đến ngày" className="min-w-65px" />
                                <DatePickerCustom
                                    name="htVisitEndDate"
                                    value={values?.htVisitEndDate}
                                    setDateValue={date => {
                                        setFieldValue('htVisitEndDate', date);
                                    }}
                                    errors={errors?.htVisitEndDate}
                                    touched={touched?.htVisitEndDate}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <ICD10
                shouldOpenModalICD10={shouldOpenModalICD10}
                setShouldOpenModalICD10={setShouldOpenModalICD10}
                shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
                setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
                listICD10={listICD10}
                listComorbidity={listComorbidity}
                setListComorbidity={setListComorbidity}
                selectionModeICD10={selectionModeICD10}
                data={values}
                icd10Field="htDiagnosis"
                comorbidityField='htComorbidity'
                onChange={handleChangeICD10}
            />
            {openModalChonKetQuaDichVu && (
                <ModalChonKetQuaDichVu
                    open={openModalChonKetQuaDichVu}
                    handleClose={() => setOpenModalChonKetQuaDichVu(false)}
                    onSave={handleSelectKetQua}
                    visitId={benhNhanInfo?.thongTinKhamBenh?.visit?.id}
                />
            )}
        </Row>
    )
}

export default ThongTinChuyenTuyen