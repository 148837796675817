import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Button, ButtonProps, OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';

import './style.scss';

type Props = ButtonProps & {
	action?: string;
	title: string;
	icon?: string;
	className?: string;
	overlayProps?: OverlayTriggerProps;
	iconButton?: boolean;
	onClick: () => void;
};

const ActionButton = ({
	title,
	icon,
	action,
	onClick,
	overlayProps,
	className,
	iconButton = true,
	...props
}: Props) => {
	return (
		<OverlayTrigger
			overlay={<Tooltip id={action}>{title}</Tooltip>}
			delay={{
				show: 10,
				hide: 10,
			}}
			placement="bottom"
			{...overlayProps}
		>
			<Button
				variant="dark"
				onClick={onClick}
				className={clsx('oct-image-action-button', className)}
				{...props}
			>
				{icon && <i className={icon}></i>}
				{!iconButton && <span>{title}</span>}
			</Button>
		</OverlayTrigger>
	);
};

export default ActionButton;
