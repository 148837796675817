import { ImageItemAction } from './types';

export const IMAGE_ACTIONS: ImageItemAction[] = [
	{
		title: 'Xem',
		action: 'view',
		icon: 'bi bi-eye',
	},
	{
		title: 'Cắt ảnh',
		action: 'crop',
		icon: 'bi bi-crop',
	},
	{
		title: 'Xóa ảnh',
		action: 'delete',
		icon: 'bi bi-trash',
	},
];
