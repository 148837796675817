import React, { createContext, ReactNode, useContext, useState } from 'react';

type Context = {
	benhNhanInfo: any;
	setBenhNhanInfo: (data: any) => void;
	phieuLayMauInfo: any;
	setPhieuLayMauInfo: (data: any) => void;
	selectedBenhNhan: any;
	setSelectedBenhNhan: (data: any) => void;
};

const PhanHeLayMauContext = createContext<Context>({
	benhNhanInfo: {},
	setBenhNhanInfo: () => {},
	phieuLayMauInfo: {},
	setPhieuLayMauInfo: () => {},
	selectedBenhNhan: {},
	setSelectedBenhNhan: () => {},
});

export const PhanHeLayMauContextProvider = ({ children }: { children: ReactNode }) => {
	const [benhNhanInfo, setBenhNhanInfo] = useState<any>();
	const [phieuLayMauInfo, setPhieuLayMauInfo] = useState<any>();
	const [selectedBenhNhan, setSelectedBenhNhan] = useState<any>();

	return (
		<PhanHeLayMauContext.Provider
			value={{
				benhNhanInfo,
				setBenhNhanInfo,
				phieuLayMauInfo,
				setPhieuLayMauInfo,
				selectedBenhNhan,
				setSelectedBenhNhan
			}}
		>
			{children}
		</PhanHeLayMauContext.Provider>
	);
};

export const usePhanHeLayMauContext = () => useContext(PhanHeLayMauContext);
