import { TableCollapseCustom } from "../../component/table/table-collapse/TableCollapseCustom";
import { formatDateToDDMMYYYY } from "../../utils/FormatUtils";

const TableDanhSachSoThu = (props: any) => {
  let { dataDanhSachSoThu, handleDoubleClick } = props;

  const danhSachSoThuColumn = [
    {
      title: "Mã sổ", field: "ketQua", className: "spaces width-17",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""} ms-n2`}>{rowData?.code || ""}</span>
      }
    },
    {
      title: "Tên sổ", field: "ketQua", className: "spaces width-25",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.name || ""}</span>
      }
    },
    {
      title: "Ngày tạo", field: "ketQua", className: "spaces width-12",
      render: (rowData: any) => { 
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.createdDate ? formatDateToDDMMYYYY(rowData?.createdDate) : ""}</span>
      }
    },
    {
      title: "Người tạo", field: "ketQua", className: "spaces width-13",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.createdPerson || ""}</span>
      }
    },
    {
      title: "Tổng phiếu", field: "ketQua", className: "spaces width-11",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{(rowData?.invTotalNumber || rowData?.invTotalNumber === 0) ? rowData?.invTotalNumber : ""}</span>
      }
    },
    {
      title: "Số bắt đầu", field: "ketQua", className: "spaces width-10",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{(rowData?.invStartNumber || rowData?.invStartNumber === 0) ? rowData?.invStartNumber : ""}</span>
      }
    },
    {
      title: "Đã sử dụng", field: "ketQua", className: "spaces width-12",
      render: (rowData: any) => {
        return <span className={`${rowData?.isLocked ? "text-decoration-line-through text-danger" : ""}`}>{(rowData?.invUsed || rowData?.invUsed === 0) ? rowData?.invUsed : ""}</span>
      }
    },
  ]
  return (
    <TableCollapseCustom
      columns={danhSachSoThuColumn}
      data={dataDanhSachSoThu || []}
      childrenField="ledgers"
      className={`overflow-auto spaces h-calc-vh-368`}
      handleDoubleClick={handleDoubleClick}
    />
  );
};

export default TableDanhSachSoThu;
