import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Form, Overlay, Popover } from "react-bootstrap";
import { useDebounce } from "../../../../../_metronic/helpers";
import "../../../component/input-autocomplete/style.scss";
import { SELECTION_MODE } from "../../../utils/Constant";
import { IThuoc } from "../../models/ThuocModels";
import SimpleTable from "../../../component/table/simple-table/SimpleTable";
import Fuse from "fuse.js";
import { RowMouseEventHandlerParams } from "react-virtualized";

type Props = {
  value: string;
  maKhoThuoc?: string;
  setICD10Selected: (value: IThuoc) => void;
  label?: string;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  initialDanhSachThuoc?: any[];
  columns: any[];
  data: any[];
  disabled: boolean;
  keyword: string;
  name: string;
  errors?: any;
  touched?: any;
};

const SelectTableVirtualized = ({
  value,
  maKhoThuoc,
  setICD10Selected,
  containerClassName,
  inputClassName,
  labelClassName,
  label,
  initialDanhSachThuoc,
  columns,
  data,
  disabled,
  keyword,
  ...props
}: Props) => {
  const { values } = useFormikContext<any>();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isLoadedRef = useRef(false);

  const [tableData, setTableData] = useState<any[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [textSearchICD10, setTextSearchICD10] = useState<string>("");
  const textSearchDebounce = useDebounce(textSearchICD10.trim(), 400);
  const [rowSelected, setRowSelected] = useState<any>(null);
  const [field, meta] = useField(props?.name);
  useEffect(() => {
    setRowSelected(tableData[selectedIndex]);
  }, [tableData, selectedIndex]);
  useEffect(() => {
    window.addEventListener("click", checkMouseClickOutSide, true);

    return () => {
      window.removeEventListener("click", checkMouseClickOutSide, true);
    };
  }, [value]);
  useEffect(() => {
    isLoadedRef.current = false;
  }, [maKhoThuoc]);

  useEffect(() => {
    const fuseOptions = {
      isCaseSensitive: false, //phân biệt chữ hoa chữ thường
      includeScore: true,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 2, //kết quả trả về cả những phần tử khớp theo số lượng ký tự truyền vào
      useExtendedSearch: true, //search nâng cao, phụ thuộc các dấu =, ", '
      ignoreLocation: true,
      ignoreFieldNorm: true,
      fieldNormWeight: 1,
      keys: [keyword],
    };

    const fuse = new Fuse(data, fuseOptions);
    if (textSearchDebounce !== "") {
      const lstICD10 = fuse
        .search(textSearchDebounce ? `'${textSearchDebounce}` : "")
        .map((ser: any) => {
          return ser?.item;
        });
      setTableData(lstICD10);
    } else {
      setTableData([]);
    }
  }, [textSearchDebounce]);
  
  const checkMouseClickOutSide = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setIsShow(false);
      setTextSearchICD10(value);
    }
  };

  useEffect(() => {
    textSearchICD10 !== value && setTextSearchICD10(value);
  }, [value]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isShow) {
      setIsShow(true);
    };
    const inputValue =
      keyword === "code" ? e.target.value?.toUpperCase() : e.target.value;
    setTextSearchICD10(inputValue);
  };

  const onRowSelect = (rowData: any) => {
    if (!rowData) return;
    setICD10Selected({
      ...rowData,
      roa: rowData.ROAName,
    });
    setSelectedIndex(0);
    setIsShow(false);
  };

  const onContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const dataLength = tableData.length;

    switch (e.key) {
      case "ArrowDown":
        if (!isShow) {
          setIsShow(true);
          return;
        }
        setSelectedIndex(
          selectedIndex === dataLength - 1 ? 0 : selectedIndex + 1
        );
        break;
      case "ArrowUp":
        setSelectedIndex(
          selectedIndex === 0 ? dataLength - 1 : selectedIndex - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        onRowSelect({ rowData: rowSelected });
        break;
      case "Escape":
      case "Tab":
        setIsShow(false);
        break;
      case "Home":
        setSelectedIndex(0);
        break;
      case "End":
        setSelectedIndex(dataLength - 1);
        break;

      default:
        break;
    }
  };

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const renderTable = () => {
    return (
      <Popover
        style={{
          minWidth: inputRef.current?.offsetWidth,
        }}
        className="input-autocomplete-virtualize-popover"
      >
        <Popover.Body className="p-8 spaces">
          <SimpleTable
            data={tableData || []}
            columns={columns || []}
            height={300}
            width={1000}
            scrollable={true}
            type={SELECTION_MODE.SINGLE}
            //   setDataChecked={handleSelectService}
            dataChecked={[rowSelected]}
            handleRenderCell={handleRenderCell}
            onRowClick={(rowData: RowMouseEventHandlerParams) => {
              onRowSelect(rowData);
            }}
            //   keySearch={textSearchDebounce}
          />
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <div
      className={containerClassName}
      ref={containerRef}
      onKeyDown={onContainerKeyDown}
    >
      <div className="d-flex align-items-center">
        <Form.Group className="position-relative w-100">
          <Form.Control
            className={clsx(
              `spaces px-4 customs-input ${
                (meta.error && meta.touched) ||
                (props?.errors && props?.touched)
                  ? "is-invalid"
                  : ""
              }`,
              inputClassName
            )}
            ref={inputRef}
            value={textSearchICD10 || ""}
            onChange={onSearch}
            onFocus={() => {
              setIsShow(true);
            }}
            disabled={disabled}
            placeholder="Chẩn đoán theo ICD10"
            {...props}
          />
          <Form.Control.Feedback
            type="invalid"
            tooltip
            className="field-tooltip-error"
          >
            {props.errors || meta.error}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <Overlay
        placement="bottom-start"
        target={inputRef.current}
        container={containerRef.current}
        offset={[0, 3]}
        show={isShow}
      >
        {renderTable()}
      </Overlay>
    </div>
  );
};

export default SelectTableVirtualized;
