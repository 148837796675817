import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../appContext/AppContext';
import AutocompleteV2 from '../../../../../component/AutocompleteObjectV2';
import { IconMenu } from '../../../../../component/IconSvg';
import InputSearch from '../../../../../component/InputSearch';
import SelectTree from '../../../../../component/SelectTree';
import { CODE, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { TreeChiDinhDichVu } from '../../../../constants/KhamBenh';
import { LOAI_VAT_TU } from '../../../../constants/VatTu';
import { IKhoThuoc } from '../../../../models/ThuocModels';
import { getDanhSachKhoThuoc } from '../../../../services/ThuocService';
import {
	getDanhSachLoaiVatTu
} from '../../../../services/VatTuService';
import { getListVatTu } from '../../common';
import { useKeVatTuContext } from '../../context';

type Props = {
	donVatTuDetail: any;
	disableSelectKhoVatTu?: boolean;
};

const TreeVatTu = ({ donVatTuDetail, disableSelectKhoVatTu }: Props) => {
	const { setIsLoading } = useContext(AppContext);
	const { setTreeNodeValue, setTreeValue, treeValue } = useKeVatTuContext();

	const [nodeIdSelected, setNodeIdSelected] = useState<string>('');
	const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
	const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
	const [danhSachTuVatTu, setDanhSachTuVatTu] = useState<IKhoThuoc[]>([]);

	const khoVatTuDefaultValue = danhSachTuVatTu.find(
		row => row.id === parseInt(donVatTuDetail?.warehouseId)
	);

	useEffect(() => {
		getTuVatTu();
	}, []);

	useEffect(() => {
		if (treeValue?.id) {
			getLoaiVatTu();
			if (nodeIdSelected) {
				setNodeIdSelected('');
				setTreeNodeValue([]);
			}
		}
	}, [treeValue?.id]);

	const getTuVatTu = async () => {
		try {
			const data: IKhoThuoc[] = await getDanhSachKhoThuoc();
			if (data) {
				setDanhSachTuVatTu(data);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const getLoaiVatTu = async () => {
		try {
			const { code, data } = await getDanhSachLoaiVatTu();
			if (CODE.SUCCESS === code && data) {
				const dataConverted = {
					code: 'all',
					name: 'Kho vật tư',
					icon: <IconMenu />,
					filter: [
						{
							name: 'Tất cả vật tư',
							code: 'all-items',
							filter: data.content,
						},
					],
				};
				setTreeData(dataConverted);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onNodeSelected = async (data: any) => {
		try {
			if (!data) return;

			const result = await getListVatTu({
				materialType: data.code,
				warehouseId: treeValue.id,
				materialGroup: LOAI_VAT_TU.VAT_TU,
				pageIndex: 1,
				pageSize: 100,
			}, setIsLoading);
			
			setTreeNodeValue(result ?? []);
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const keySearch = e.target.value;
	};

	return (
		<>
			<div className="spaces px-12 py-5 h-42">
				<InputSearch
					placeholder="Tìm kiếm"
					handleChange={onSearch}
					className="spaces h-32 pr-4 radius-3"
				/>
				<AutocompleteV2
					placeholder="Chọn kho vật tư"
					options={danhSachTuVatTu}
					name="tuVatTu"
					className="autocomplete-custom-tiep-nhan radius spaces h-26 mt-10"
					value={treeValue || khoVatTuDefaultValue}
					onChange={selectedOption => {
						setTreeValue(selectedOption);
					}}
					isDisabled={disableSelectKhoVatTu}
				/>
			</div>
			<div>
				{treeValue?.id && treeData && (
					<SelectTree
						className="spaces top-40 w-100 py-2 ps-4 flex-grow-1 border border-top-0 overflow-auto"
						idSelected={nodeIdSelected}
						handleChangeSelectId={setNodeIdSelected}
						getSelectedItem={onNodeSelected}
						selectTree={treeData}
						codeCollapses={codeCollapses}
						handleChangeCollapsesCode={setCodeCollapses}
					/>
				)}
			</div>
		</>
	);
};

export default TreeVatTu;
