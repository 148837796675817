import axios from 'axios';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
import { OctResponse } from '../../models/OctResponse';
import { localStorageItem } from '../../utils/LocalStorage';

const BASE_URL =
	localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.['apiUrl'] ||
	process.env.REACT_APP_API_URL;

export const getDanhSachBenhNhan = async (params: any) => {
	return axios.get<OctResponse<any>>(`${BASE_URL}/sampling/search`, {
		params,
	});
};

export const getStatusSummary = async (params: any) => {
	const { data } = await axios.get<OctResponse<any>>(`${BASE_URL}/sampling/summary-statuses`, {
		params,
	});
	return data;
};

export const getBenhNhanInfo = async (id: string) => {
	const { data } = await axios.get<OctResponse<any>>(`${BASE_URL}/sampling/${id}/patient`);
	return data;
};

export const getPhieuLayMauInfo = async (id: string) => {
	const { data } = await axios.get<OctResponse<any>>(`${BASE_URL}/sampling/${id}/detail`);
	return data;
};

export const printBarCode = async (params: any) => {
	return await axios.post<Blob>(`${BASE_URL}/sampling/print-barcode`, params, {
		responseType: 'blob',
	});
};
