import { OctAutocomplete } from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LazyLoadTable from "../../component/lazy-load-table/LazyLoadTable";
import { LIST_ORDER } from "../../phan-he-cdha-va-tdcn/constants/Constants";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import {
  ID_DANG_KY_KHAM,
  KEY_DS_DANH_MUC_TIEP_DON,
  MAX_SIZE
} from "../../utils/Constant";
import { formatDateToYYYYMMDD } from "../../utils/FormatUtils";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { statusOptions } from "../const/VienPhiConstant";
import { PhanHeVienPhiContext } from "../context/PhanHeVienPhiContext";
import { getListPatient } from "../services/PhanHeVienPhiServices";

interface Props {
  setSelectedRow: React.Dispatch<any>;
  selectedRow: any;
  dsBenhNhan: any;
  countPaid: any;
  filter: any;
  doSearch: (filter?: any) => void;
}

const TableDanhSachBenhNhan = ({
  setSelectedRow,
  selectedRow,
  dsBenhNhan,
  countPaid,
  filter,
  doSearch,
}: Props) => {
  let { setInforBenhNhan } = useContext(PhanHeVienPhiContext);
  const [searchObject, setSearchObject] = useState<any>(null);

  useEffect(() => {
    filter &&
      setSearchObject({
        keyword: filter?.keyword,
        sortBy: filter?.formal?.code,
        descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
        visitObjectTypeCodes: filter?.typeChecks?.length > 0 ? filter?.typeChecks?.join(",") : "",
        fromDate: formatDateToYYYYMMDD(filter.period?.fromDate),
        toDate: formatDateToYYYYMMDD(filter.period?.toDate),
        deptCode: filter?.deptCode || null,
        filterBy: filter?.filterBy || null,
        filterValue: filter?.filterValue ? "true" : "false"
      });
  }, [filter]);

  useEffect(() => {
    setInforBenhNhan(selectedRow[0]);
  }, [selectedRow[0]]);

  const getPatientName = (patient: any) => {
    return (
      <div className="text-system spaces ps-2">
        <div className={`${patient?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU ? "color-error" : ""}`}>{patient?.personName?.toUpperCase()}</div>
        <div>{`${patient?.code || ""} - ${
          patient?.visitObjectTypeName || ""
        } - ${patient?.gender || ""}`}</div>
      </div>
    );
  };

  const columns = [
    {
      name: "TT",
      field: "",
      headerStyle: {
        maxWidth: "30px",
      },
      render: (rowData: any, index: number) => rowData.paidEnough ? (
        <i className="fs-3 bi bi-circle-fill text-success"></i>
      ) : (
        <i className="fs-3 bi bi-circle-fill text-danger"></i>
      ),
    },
    {
      name: "Tên bệnh nhân",
      field: "",
      headerStyle: {
        minWidth: "200px",
      },
      render: (rowData: any, index: number) => getPatientName(rowData),
    },

    {
      name: "Ngoài giờ",
      field: "ngoaiGio",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();

  const handleSetDefaultData = async (data: IDanhSachDanhMuc) => {
    setDanhSachDanhMuc(data);
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      handleSetDefaultData(responseDsDanhmuc);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  return (
    <>
      <div className="spaces h-calc-vh-300">
        <LazyLoadTable
          columns={columns}
          axiosReq={getListPatient}
          searchData={searchObject}
          urlData="data.data.content"
          className="spaces h-calc-vh-300"
          onRowClick={(rowData) => setSelectedRow([rowData])}
          rowSeclected={selectedRow?.[0]}
          rowKey="visitId"
        />
      </div>
      <div className="d-flex flex-column spaces w-100 pb-3 gap-16 position-absolute bottom-15">
        <Row className="d-flex spaces w-100">
          <Col xs={12} className="spaces px-12">
            <OctAutocomplete
              options={danhSachDanhMuc?.listPhongKham || []}
              placeholder="Tất cả khoa phòng"
              searchObject={{ pageSize: MAX_SIZE }}
              onChange={(option) => {
                doSearch({
                  ...filter,
                  deptCode: option?.code,
                });
              }}
            />
          </Col>
        </Row>
        <Row className="d-flex spaces w-100 px-12 gap-10">
          <Col className="spaces p-0">
            <div
              className="status-1-bg text-center text-white rounded spaces px-8 py-4 cursor-pointer"
              onClick={() => {
                doSearch({ ...filter, filterBy: "paidEnough", filterValue: false });
              }}
            >
              <span className="body-normal-1">{`${
                statusOptions.chuaDongDu.name
              }: ${countPaid?.notPaidEnough || 0}`}</span>
            </div>
          </Col>
          <Col className="spaces p-0">
            <div
              className="status-4-bg text-center text-white rounded spaces px-8 py-4 cursor-pointer"
              onClick={() => {
                doSearch({ ...filter, filterBy: "paidEnough", filterValue: true });
              }}
            >
              <span className="body-normal-1">{`${
                statusOptions.daDongDu.name
              }: ${countPaid?.paidEnough || 0}`}</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { TableDanhSachBenhNhan };
