import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../appContext/AppContext';
import { IconBack } from '../../../../../component/IconSvg';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { IDonThuocMauRequest } from '../../../../models/ThuocModels';
import { getDetailDonThuocMau, saveDonThuocMau } from '../../../../services/ThuocService';
import DonThuocMauButton from '../../../modal-ke-thuoc/components/DonThuocMauButton';
import ButtonDonThuocCu from '../../../modal-ke-thuoc/components/header/ButtonDonThuocCu';
import { useKeVatTuContext } from '../../context';

type Props = {
	onClose: () => void;
};

const Actions = ({ onClose }: Props) => {
	const { vatTuChecked, setVatTuChecked, setTreeValue, treeValue } = useKeVatTuContext();
	const { setIsLoading } = useContext(AppContext);
	const { setFieldValue, values: formikValues } = useFormikContext<any>();

	const getDonMauParams = () => {
		const orderType = parseInt(ORDER_TYPE_ID.VAT_TU);
		const formValue = JSON.stringify({
			...formikValues,
			warehouseId: treeValue.id,
			warehouseName: treeValue.name,
			warehouseCode: treeValue.code,
		});
		const tableValue = vatTuChecked
			.filter(row => !row.deleted)
			.map((row: any) => ({
				orderType,
				orderTemplate: JSON.stringify(row),
			}));

		return {
			orderType,
			orderTemplate: formValue,
			lstOrderSetMemebers: tableValue,
		};
	};

	const onSelectDonVatTuMau = (data: IDonThuocMauRequest) => {
		const formValue = data.orderTemplate ? JSON.parse(data.orderTemplate) : {};
		const tableValue = data.lstOrderSetMemebers.map((row: any) => ({
			...JSON.parse(row.orderTemplate),
		}));
		setFieldValue('provisionalDiagnosis', formValue.provisionalDiagnosis);
		setFieldValue('provisionalDiagnosisName', formValue.provisionalDiagnosisName);
		setFieldValue('comorbidity', formValue.comorbidity);
		setVatTuChecked(tableValue);
		setTreeValue({
			id: formValue.warehouseId,
			name: formValue.warehouseName,
			code: formValue.warehouseCode,
		});
	};

	const saveDonMauValidation = () => {
		if (!formikValues.provisionalDiagnosisName) {
			toast.warn('Chưa nhập chẩn đoán bệnh');
			return false;
		}

		if (!vatTuChecked.length) {
			toast.warn('Chưa có vật tư được kê cho mẫu này');
			return false;
		}

		return true;
	};

	const selectDonMauValidation = () => {
		if (vatTuChecked.length) {
			toast.warn('Đã có vật tư được chỉ định. Không thể chọn đơn mẫu');
			return false;
		}
		return true;
	};

	return (
		<div className="d-flex align-items-center gap-3">
			<Button
				variant="bg-transparent text-hover-primary ps-0 d-flex align-items-center"
				onClick={onClose}
			>
				<IconBack />
				<span className="fw-500 ps-2">Quay lại</span>
			</Button>

			<DonThuocMauButton
				label="Đơn vật tư mẫu"
				getSaveParams={getDonMauParams}
				onSelectMau={onSelectDonVatTuMau}
				orderType={parseInt(ORDER_TYPE_ID.VAT_TU)}
				onSaveValidationFn={saveDonMauValidation}
				onSelectValidationFn={selectDonMauValidation}
			/>

			<ButtonDonThuocCu
				patientInfo={{
					visitId: '',
				}}
				onSelect={() => {}}
				label='Đơn vật tư cũ'
			/>
		</div>
	);
};

export default Actions;
