import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Column, Row, useExpanded, useRowSelect, useTable } from 'react-table';
import { TableCustomHeaderColumn } from '../../../../../component/table/components/TableCustomHeaderColumn';

interface Props<T extends object> {
	data: T[];
	columns: Readonly<Column<T>[]>;
	containerClassName?: string;
	tableClassName?: string;
	onSelect?: (selectedRows: T[]) => void;
	onRowDoubleClick?: (row: Row<T>) => void;
}

function TableGroupSelection<T extends object>({
	data,
	containerClassName,
	tableClassName,
	onSelect,
	onRowDoubleClick,
	columns: tableColumns,
}: Props<T>) {
	const [tableData, setTableData] = useState<T[]>([]);
	const { formatMessage } = useIntl();

	const {
		rows,
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		selectedFlatRows,
		columns,
	} = useTable<T>(
		{
			columns: tableColumns,
			data: tableData,
		},
		useExpanded,
		useRowSelect
	);

	useEffect(() => {
		setTableData(data);
	}, [data]);

	useEffect(() => {
		const selectedRowsData = selectedFlatRows.map(row => row.original);
		onSelect && onSelect(selectedRowsData);
	}, [selectedFlatRows.length]);

	const EmptyRow = () => {
		return (
			<tr>
				<td colSpan={columns.length} className="text-center py-2">
					{formatMessage({ id: 'TABLE.DATA.EMPTY' })}
				</td>
			</tr>
		);
	};

	const TableRow = ({ row }: { row: Row<T> }) => {
		const cellRef = useRef<HTMLTableCellElement>(null);
		return (
			<tr {...row.getRowProps()}>
				{row.cells.map(cell => {
					return (
						<td
							ref={cellRef}
							className={clsx('align-middle spaces py-4 px-8', {
								'selected-row': row.isSelected,
							})}
							onDoubleClick={() => {
								onRowDoubleClick && onRowDoubleClick(row);
							}}
							{...cell.getCellProps()}
						>
							<>{cell.render('Cell')}</>
						</td>
					);
				})}
			</tr>
		);
	};

	return (
		<div className={clsx('table-responsive w-100', containerClassName)}>
			<table
				className={clsx('align-middle table-bordered w-100', tableClassName)}
				{...getTableProps()}
			>
				<thead className="position-sticky top-0 z-index-1">
					{headerGroups.map(headerGroup => (
						<tr
							className="text-start border fw-bolder fs-8 gs-0 border bg-pri border-y-none"
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map(column => (
								<TableCustomHeaderColumn<T> key={column.id} column={column} />
							))}
						</tr>
					))}
				</thead>

				<tbody className="text-gray bg-white border" {...getTableBodyProps()}>
					{!tableData.length ? (
						<EmptyRow />
					) : (
						<>
							{rows.map((row, i) => {
								prepareRow(row);
								return <TableRow key={row.id} row={row} />;
							})}
						</>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default TableGroupSelection;
