import { OctAutocomplete } from "@oceantech/oceantech-ui";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import FilterSidebar from "../../../component/filter-sidebar/FilterSidebar";
import LazyLoadTable from "../../../component/lazy-load-table/LazyLoadTable";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  ID_DANG_KY_KHAM,
  LEVEL,
  LOAI_PHONG,
  RESPONSE_MESSAGE,
  UU_TIEN
} from "../../../utils/Constant";
import {
  formatDateToYYYYMMDD,
  formatTrangThaiBenhNhan,
} from "../../../utils/FormatUtils";
import { getListKhoaPhong } from "../../../utils/ServicesUtils";
import { WfSummaryStatusRes } from "../../../workflows/models/WfWorkflows";
import { convertListDichVuXetNgiem } from "../../common";
import { STATUS_SUMMARY, WF_STEP } from "../../const/constants";
import { PhanHeXetNghiemContext } from "../../context/PhanHeXetNghiemContext";
import {
  getBenhNhanInfo,
  getListPatientXN,
  getListStatusXN,
  getPhieuXetNghemInfo,
} from "../../service/tiepNhan";
import useBarcodeScan from "../../../../hook/useBarcodeScan";
import ContextMenu from "../../../component/ContextMenuV3";
import { speaker } from "../../../utils/Speaker";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";

const DsBenhNhan = (props: any) => {  
  const { setPhieuXetNghiemInfo, setBenhNhanInfo, selectedBenhNhan, setSelectedBenhNhan } = useContext(
    PhanHeXetNghiemContext
  );
  const { setIsLoading } = useContext(AppContext);
  const [summaryStatus, setSummaryStatus] = useState<WfSummaryStatusRes[]>([]);
  // const [selectedBenhNhan, setSelectedBenhNhan] = useState<any>();
  const [searchObject, setSearchObject] = useState<any>(null);
  const [listFilter, setListFilter] = useState<any>(null);
  const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	  }>(null);
  const [rowSeclectedByContextMenu, setRowSeclectedByContextMenu] = useState<any>();
  const searchParamsRef = useRef<any>({});

  useEffect(() => {
    listFilter &&
      setSearchObject({
        ...listFilter,
        order: null
      });
  }, [listFilter]);
  
  const getDanhSachTraiThaiXN = async (searchData?: any) => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 9999999,
      ...searchData,
    };
    try {
      const { data } = await getListStatusXN(searchObject);
      if (CODE.SUCCESS === data?.code) {
        const result = (data?.data || []).filter(
			    (status: any) => status.statusCode !== STATUS_SUMMARY.CHUA_LAY_MAU
		    );
        setSummaryStatus(result);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedBenhNhan = async (benhNhan: any, callbackFn?: () => void) => {
    try {
      setIsLoading(true);
      let { data: dataBN } = await getBenhNhanInfo(benhNhan?.id);
      let { data: dataXN } = await getPhieuXetNghemInfo(benhNhan?.id);
      if (CODE.SUCCESS === dataBN?.code && CODE.SUCCESS === dataXN?.code) {
        let thongTinPhieu = dataXN?.data;        
        const dsXetNghiem = convertListDichVuXetNgiem(thongTinPhieu.ordersList);
        setSelectedBenhNhan(benhNhan);
        setPhieuXetNghiemInfo({
          thongTinPhieu,
          dsXetNghiem,
        });
        setBenhNhanInfo({
          ...dataBN?.data,
          isPerform: false,
          testGroupId: benhNhan?.id,
        });
        callbackFn?.();
      } else {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "TT",
      field: "",
      headerStyle: {
        maxWidth: "30px",
      },
      render: (rowData: any, index: number) => formatTrangThaiBenhNhan(rowData?.color),
    },
    {
      name: "STTLM",
      field: "samplingOrdinalNumber",
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData: any, index: number) => <span className="text-system">{rowData?.samplingOrdinalNumber || ""}</span>
    },
    {
      name: "Barcode",
      field: "barcode",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData: any, index: number) => <span className="text-system">{rowData?.barcode || ""}</span>
    },
    {
      name: "Tên bệnh nhân",
      field: "",
      headerStyle: {
        minWidth: "150px",
      },
      render: (rowData: any, index: number) => {
        return (
          <div className="text-system text-truncate">
            <div className="flex m-0">
              <span className={`text-uppercase ${rowData?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU ? "color-error" : ""}`}>
                {rowData?.patientName}
              </span>
              {rowData?.visitSortWeight === UU_TIEN.TRUE ? <span className="text-danger">&nbsp;(Ưu tiên)</span> : ""}
            </div>
            <div className="flex m-0">
              <span className="text-uppercase fw-semibold">
                {rowData?.patientCode || ""}
              </span>
              <span className="px-1"> - </span>
              <span>
                {rowData?.visitObjectTypeName || ""}
              </span>
              <span className="px-1"> - </span>
              <span>{rowData?.gender || ""}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Mã BP",
      field: "code",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData: any, index: number) => <span className="text-system">{rowData?.code || ""}</span>
    },
  ];

  const doSearch = (props: any) => {
    const searchObject = {
      keyword: props?.keyword,
      barcode: props?.additionalFieldSearch || null,
      sortBy: props.formal?.value || listFilter?.sortBy || null,
      descending: props.order?.value === "DERCEASE" || !props.order,
      order: props.order,
      fromDate:
        formatDateToYYYYMMDD(props.period?.fromDate) || listFilter?.fromDate,
      toDate: formatDateToYYYYMMDD(props.period?.toDate) || listFilter?.toDate,
      filterBy: props?.filterBy || listFilter?.filterBy || null,
      filterValue: props?.filterValue || listFilter?.filterValue || null,
      fulfillDeptCode: props?.fulfillDeptCode || listFilter?.fulfillDeptCode || null,
    };
    searchParamsRef.current = searchObject;
    setListFilter({
      ...searchObject,
    });
  };

  useEffect(() => {
    searchObject && getDanhSachTraiThaiXN(searchObject);
  }, [props.statusChangedEvent, searchObject]);

  const LIST_FORMAL = [
    {
      id: 1,
      value: "indicationTime",
      name: "Ngày y lệnh",
    },
    {
      id: 2,
      value: "specimenCollectionStartTime",
      name: "Ngày lấy mẫu",
    },
    {
      id: 3,
      value: "testingStartTime",
      name: "Ngày thực hiện",
    },
    {
      id: 4,
      value: "resultReportingTime",
      name: "Ngày trả kết quả",
    },
  ];

  const searchBarcode = (barcode: string) => {
			getListPatientXN({
        ...searchParamsRef.current,
				barcode,
			})
      .then(({ data }) => {
				const list = data.data?.content ?? [];

				if (!list.length) {
					toast.warn(`Không tìm thấy phiếu xét nghiệm với mã barcode: ${barcode}`);
					return;
				}

				const patientSelected = list[0];
        
				handleSelectedBenhNhan(patientSelected, () => {
          toast.success(`Lấy thông tin phiếu xét nghiệm với barcode: ${barcode} thành công`);
        });
			})
      .catch((error) => {
        console.error(error);
        toast.warn(RESPONSE_MESSAGE.ERROR);
      });
	};

  useBarcodeScan({
    searchBarcode
  });

  const CODE_MENU = {
		GOI_BN: "goiBn",
	  };
	
	const dropListChiDinhDichVu: IContextMenu[] = [
		{
		  title: "Xét nghiệm",
		},
		{
		  icon: <i className="bi bi-volume-up-fill text-pri"></i>,
		  code: CODE_MENU.GOI_BN,
		  name: "Gọi bệnh nhân",
		},
	  ];
  const handleContextMenu = (e: any, row: any) => {
    if(row?.currStatusCode === WF_STEP.TEST_RECEIVE_SAMPLE) {
      setRowSeclectedByContextMenu(row);
      setContextMenu({ x: e.clientX, y: e.clientY });
    }
  };
  const handleGoiBN = (data: any) => {
		let stt = data?.samplingOrdinalNumber;
		let name = data?.patientName;
		let diaDiem = data?.fulfillDeptName || "phòng khám";
		if (stt && diaDiem) {
			let content = `Mời khách hàng, ${name} , có số thứ tự, ${stt}, tới ${diaDiem})`;
			speaker.handleSpeak(content);
		} else {
			toast.warning("Bệnh nhân không đủ thông tin");
		}
	};
	const handleClickOptionContextMenu = (value: any) => {
		const menuActions = {
			[CODE_MENU.GOI_BN]: () => handleGoiBN(rowSeclectedByContextMenu),
		};
		menuActions[value?.code]?.();
		setContextMenu(null);
	};

  return (
    <>
      <FilterSidebar
        title="Danh sách xét nghiệm"
        formalList={LIST_FORMAL}
        onSearch={doSearch}
        listMenuItem={[]}
        handleSelectOption={() => {}}
        additionalTextSearch={{
					name: 'barcode',
					placeholder: 'Barcode'
				}}
      />
      <div className="spaces h-calc-vh-170 d-flex flex-column justify-content-between pt-10">
        <div className="spaces h-calc-vh-333">
          <LazyLoadTable
            columns={columns}
            axiosReq={getListPatientXN}
            searchData={searchObject}
            urlData="data.data.content"
            className="spaces h-calc-vh-333"
            onRowClick={row => handleSelectedBenhNhan(row)}
            dependencies={[props.statusChangedEvent]}
            rowSeclected={selectedBenhNhan}
            handleContextMenu={handleContextMenu}
          />
          {contextMenu && (
						<ContextMenu
							handleClickOptionContextMenu={handleClickOptionContextMenu}
							handleCloseMenu={() => setContextMenu(null)}
							data={dropListChiDinhDichVu}
							target={contextMenu}
						/>
						)}
        </div>
        <Row className="d-flex spaces w-100">
          <Col xs={12} className="spaces ps-5 pb-3 pr-0">
            <OctAutocomplete
              options={[]}
              placeholder="Tất cả khoa phòng"
              menuPlacement="top"
              searchFunction={getListKhoaPhong}
              searchObject={{ type: LEVEL.PHONG, level: LOAI_PHONG.PHONG_XET_NGHIEM }}
              onChange={(option) => {
                doSearch({
                  ...listFilter,
                  fulfillDeptCode: option?.code,
                });
              }}
            />
          </Col>
        </Row>
        <Row className="count-status-xet-nghiem spaces w-100 gap-10 px-5 justify-content-center mx-auto">
          {summaryStatus.map((item, index) => (
            <div
              key={index}
              className="d-flex status text-white rounded spaces px-8 py-4 w-100 justify-content-center cursor-pointer"
              style={{ backgroundColor: item.color }}
              onClick={() => {
                doSearch({
                  ...listFilter,
                  filterBy: "currStatusCode",
                  filterValue: item?.statusCode,
                });
              }}
            >
              <span className="body-normal-1 spaces">{`${item.statusName}: ${item.summary}`}</span>
            </div>
          ))}
        </Row>
      </div>
    </>
  );
};

export default DsBenhNhan;
