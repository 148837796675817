import axios, { AxiosResponse } from "axios";
import { WfSummaryStatusRes, WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import { OctResponse } from "../../models/OctResponse";
import { paramsConfig } from "../../utils/ParamsUtils";
import { localStorageItem } from '../../utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts'

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_WORKFLOWS = BASE_URL + "/wf-workflows";

export const getWfWorkflows = (trackerCode: string, objectId: string|undefined) => {
    let url = ` ${API_PATH_WORKFLOWS}?tracker=${trackerCode}`;
    if(objectId) url+=`&objectId=${objectId}`;
   
    return axios.get(url);
}
export const updateWfWorkflows = (trackerCode: string, wfWorkflowsReq: WfWorkflowsReq ) : Promise<AxiosResponse<OctResponse<WfWorkflowsRes>>> => {
        let url = ` ${API_PATH_WORKFLOWS}/${trackerCode}`
        return axios.post(url, wfWorkflowsReq);
  };

export const getSummaryStatusByTracker = (trackerCode: string) : Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
  let url = `${API_PATH_WORKFLOWS}/${trackerCode}/status/summary`
  return axios.get(url);
}

export const getStatusByTrackerAndObjectIds = (tracker: string, objectIds: string): Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
  let url = `${API_PATH_WORKFLOWS}/by-ids`;
  return axios.get(url, paramsConfig({
    tracker,
    objectIds
  }));
}

export const changeStatus = (tracker: string, data: { objectId: string, status: number }[]) => {
  let url = `${API_PATH_WORKFLOWS}/batch/${tracker}`;
  return axios.post(url, data);
}