import { OctTable } from '@oceantech/oceantech-ui'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PhanHeTiepNhanContext } from '../../../PhanHeTiepNhan'
import { getDSPhongKham } from '../../../services/TiepNhanServices'
import { columnsThongTinPhongKham } from './ThongTinPhongKhamColumn'

type Props = {}

const TabThongTinPhongKham = (props: Props) => {
  const { thongTinBenhNhan } = useContext(PhanHeTiepNhanContext);
  const [listPhongKham, setListPhongKham] = useState<any[]>();

  const updatePageData = async () => {
    try {
      const { data } = await getDSPhongKham();
      const newArr = data?.data.map((item: any, index: number) => {
        return {
          ...item,
          id: index
        }
      })
      setListPhongKham(splitArray(newArr));
    } catch (error) {
      console.error(error)
    }
  }
  
  function splitArray(array: any[]) {
    const middleIndex = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middleIndex);
    const secondHalf = array.slice(middleIndex);
    return [firstHalf, secondHalf];
  }


  useEffect(() => {
    updatePageData();
  }, [thongTinBenhNhan?.isChangeThongTinBenhNhan]);

  return (
    <Row className="tableKhamBenh spaces pt-10 gap-8 mx-0 h-calc-vh-515">
      <Col className='p-0 h-100'>
        <OctTable
          id="list-patients"
          className="h-100 table-background-th"
          data={listPhongKham?.[0] || []}
          columns={columnsThongTinPhongKham}
          notDelete={true}
          notEdit={false}
          noToolbar={true}
          fixedColumnsCount={-1}
          isShowSelection={false}
          clearToolbar={true}
          noPagination
        />
      </Col>
      <Col className='p-0 h-100'>
        <OctTable
          id="list-patients"
          className="h-100 table-background-th"
          data={listPhongKham?.[1] || []}
          columns={columnsThongTinPhongKham}
          notDelete={true}
          notEdit={false}
          noToolbar={true}
          fixedColumnsCount={-1}
          isShowSelection={false}
          clearToolbar={true}
          noPagination
        />
      </Col>
    </Row>
  );
}

export default TabThongTinPhongKham