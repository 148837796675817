import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { Autocomplete } from "../../../../component/Autocomplete";
import LabelRequired from "../../../../component/LabelRequired";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import InfoPatientRight from "../../../../phan-he-kham-benh/components/InfoPatientRight";
import TextField from "../../../../component/TextField";
import WebcamCapture from "../../../../component/webcam-image/WebcamCapture";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import { IBenhNhanV3ChuyenKhoa } from "../../../models/PhanHeChuyenKhoaModel";
import ModalDSMauKetQuaThucHien from "../ModalDSMauKetQuaThucHien";
import ModalPickTimeBDThucHien from "../ModalPickTimeBDThucHien";
import ModalPickTimeKTThucHien from "../ModalPickTimeKTThucHien";
import NhapKetQuaCDHAEditor from "../../../../phan-he-cdha-va-tdcn/components/modals/NhapKetQuaCDHAEditor";
import { convertFileToHTML } from "../../../../phan-he-cdha-va-tdcn/services/PhanHeCDHAVaTDCNService";
import { CODE, RESPONSE_MESSAGE } from "../../../../utils/Constant";
import { AppContext } from "../../../../appContext/AppContext";
import GoiYField from "./GoiYField";

type Props = {
  handleClose: () => void;
  handleTraKetQua: () => void;
  infoBenhNhan?: IBenhNhanV3ChuyenKhoa;
  isViewModal?: boolean;
  workFlowStatus?: any;
  handleSubmit: () => void;
  noiDungMauKetQua: any;
  setNoiDungMauKetQua: (values: any) => void,
  inforResult: any;
  setInforResult: (values: any) => void,
};

export default function FormThongTinPTTT({
  handleClose,
  handleTraKetQua,
  infoBenhNhan,
  isViewModal,
  handleSubmit,
  noiDungMauKetQua,
  setNoiDungMauKetQua,
  inforResult,
  setInforResult,
}: Props) {
  const { setIsLoading } = useContext(AppContext);
  const [
    shouldOpenModalDSMauKetQuaThucHien,
    setShouldOpenModalDSMauKetQuaThucHien,
  ] = useState<boolean>(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<any>(null);
  const importFilenameRef = useRef<string>("");

  const [openWebcamCapture, setOpenWebcamCapture] = useState<boolean>();
  const [imgSrcs, setImageSrcs] = useState([]);
  const [
    shouldOpenModalPickTimeBDThucHien,
    setShouldOpenModalPickTimeBDThucHien,
  ] = useState<boolean>(false);
  const [
    shouldOpenModalPickTimeKTThucHien,
    setShouldOpenModalPickTimeKTThucHien,
  ] = useState<boolean>(false);
  const [ngay, setNgay] = useState({
    batDau: "",
    ketThuc: "",
  });
  const handleCloseModalMauKetQuaThucHien = () => {
    setShouldOpenModalDSMauKetQuaThucHien(false);
  };
  const handleCloseModalPickTimeBDThucHien = () => {
    setShouldOpenModalPickTimeBDThucHien(false);
  };
  const handleCloseModalPickTimeKTThucHien = () => {
    setShouldOpenModalPickTimeKTThucHien(false);
  };
  const handleLuuInTraKetQua = () => {
    handleTraKetQua();
    toast.success("Lưu / In / Trả kết quả thành công");
    handleClose();
  };
  const handleSelectMauKetQua = (mauKetQua: any) => {
    setShouldOpenModalDSMauKetQuaThucHien(false);
    setNoiDungMauKetQua(mauKetQua[0].original.noiDungMau);
  };

  const handleChangeInfor = (value: any, name: string) => {
    setInforResult({
      ...inforResult,
      [name]: value,
    });
  };

  const onImportFileKetQua = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e.target.files;

    if (!fileUpload?.length) return;

    const file = fileUpload[0];
    importFilenameRef.current = file.name;

		const formData = new FormData();
		formData.append('file', file);

		try {
      setIsLoading(true);
			const { code, data } = await convertFileToHTML(formData);
      
      if (code !== CODE.SUCCESS) {
				toast.error(RESPONSE_MESSAGE.ERROR);
				return;
			}

			setNoiDungMauKetQua(data);
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
      setIsLoading(false);
    }
  };

  const onExportFileKetQua = async () => {
    try {
      if (noiDungMauKetQua) {
        const blobResult = (await asBlob(noiDungMauKetQua)) as Blob;
        saveAs(blobResult, importFilenameRef.current);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitFormKQ = () => {
    if (!inforResult?.conclusion?.trim()) {
			toast.warn("Kết luận không được để trống");
			return;
		};
    handleSubmit();
  };

  return (
    <>
      <Modal.Body className="dialog-body border-bottom menu-button-cdha">
        <div
          className={`flex gap-3 underline spaces ${isViewModal ? "h-50" : ""}`}
        >
          {!isViewModal && (
            <>
              <input
                type="file"
                hidden
                ref={uploadFileRef}
                accept=".doc, .docx"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onImportFileKetQua(e);
                }}
              />
              <Dropdown className="dropdown-btn menu-icon">
                <Dropdown.Toggle className="btn-outline">
                  <i className="bi bi-list m-0 p-0"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      if (uploadFileRef.current) {
                        uploadFileRef.current.click();
                      }
                    }}
                  >
                    Nhập file kết quả
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onExportFileKetQua}>
                    Xuất file kết quả
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>Lấy ảnh từ máy tính</Dropdown.Item>
                  <Dropdown.Item>
                    Lấy ảnh từ kết nối chẩn đoán hình ảnh
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>Cấu hình thư viện nhận ảnh</Dropdown.Item>
                  <Dropdown.Item>Cấu hình tự động crop ảnh</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>Xuất raw kết quả</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                className="btn-fill min-w-50px"
                onClick={() => setShouldOpenModalDSMauKetQuaThucHien(true)}
              >
                Mẫu kết quả
                <i className="bi bi-caret-down-fill font-size-12px spaces ms-2"></i>
              </Button>
              <Button className="btn-fill min-w-50px">PACS</Button>
              <Button
                className="btn-fill min-w-50px"
                onClick={handleSubmitFormKQ}
              >
                Lưu
              </Button>
              <Button
                className="btn-fill min-w-50px"
                // onClick={handleLuuInTraKetQua}
              >
                Lưu / In / Trả kết quả
              </Button>
              <Button className="btn-fill min-w-50px">In kết quả</Button>
              <Button
                className="btn-fill min-w-50px"
                onClick={() => setOpenWebcamCapture(true)}
              >
                Chụp ảnh
              </Button>
            </>
          )}
          <div className="text-break fw-500 py-2 px-5 text-end position-absolute top-0 bottom-0 end-0 spaces w-40">
            <InfoPatientRight benhNhanInfo={infoBenhNhan} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Body className="dialog-body padding-3-20">
        <Row className="h-100">
          <Col xs={4} className="flex border">
            <div className="flex-item-start pt-5 spaces w-100">
              <Row>
                <Col xs={12}>
                  <div className="label-input-group mt-3 d-flex">
                    <LabelRequired
                      label="Bắt đầu"
                      className="label fw-500 spaces fw-bold w-30"
                    />
                    <TextField
                      className="input flex-1"
                      name="startTime"
                      labelClassName="max-w-90px"
                      type="dateTime-Local"
                      value={inforResult?.startTime || ""}
                      disabled={isViewModal}
                      onChange={(e: any) =>
                        handleChangeInfor(e.target.value, "startTime")
                      }
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="label-input-group mt-3 d-flex">
                    <LabelRequired
                      label="Kết thúc"
                      className="label fw-500 spaces fw-bold w-30"
                    />
                    <TextField
                      className="input flex-1"
                      name="endTime"
                      labelClassName="max-w-90px"
                      type="dateTime-Local"
                      value={inforResult?.endTime || ""}
                      disabled={isViewModal}
                      onChange={(e: any) =>
                        handleChangeInfor(e.target.value, "endTime")
                      }
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="label-input-group mt-3 d-flex">
                    <LabelRequired
                      label="TG thực hiện"
                      className="label fw-500 spaces fw-bold w-30"
                    />
                    <TextField
                      className="input flex-1"
                      name="executionTime"
                      labelClassName="max-w-90px"
                      type="text"
                      value={inforResult?.executionTime || ""}
                      disabled={isViewModal}
                      onChange={(e: any) =>
                        handleChangeInfor(e.target.value, "executionTime")
                      }
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="label-input-group mt-3 d-flex">
                    <LabelRequired
                      label="TG tối thiểu"
                      className="label fw-500 spaces fw-bold w-30"
                    />
                    <TextField
                      className="input flex-1"
                      name="minimumTime"
                      labelClassName="max-w-90px"
                      type="text"
                      value={inforResult?.minimumTime || ""}
                      disabled={isViewModal}
                      onChange={(e: any) =>
                        handleChangeInfor(e.target.value, "minimumTime")
                      }
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="label-input-group mt-3 d-flex">
                    <LabelRequired
                      label="Máy thực hiện"
                      className="label fw-500 spaces fw-bold w-30"
                    />
                    <Autocomplete
                      className="input flex-1"
                      options={[]}
                      name="mayThucHien"
                      isDisabled={isViewModal}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  className="d-flex w-100 spaces mt-10 gap-3 img-group"
                >
                  {/* <div className=""> */}
                  {imgSrcs.map((img: any, i: number) => (
                    <div className="item-img">
                      <img
                        key={i}
                        src={img?.src}
                        alt="Chuyên khoa"
                        className="w-100"
                      />
                    </div>
                  ))}
                  {/* </div> */}
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={8}
            className="d-flex ckeditor-custom modal-thuc-hien-chuyen-khoa pe-0"
          >
            <Row className="space w-100">
                <Col xs={12} className="min-h-500px mb-3">
												<NhapKetQuaCDHAEditor
													value={noiDungMauKetQua}
													disabled={isViewModal}
													onEditorChange={(
														value: string,
														editor: any
													) => {
														setNoiDungMauKetQua(value);
													}}
													onInit={(_evt, editor) => {
														editorRef.current = editor;
													}}
												/>
								</Col>
                <GoiYField 
                    name="conclusion"
                    onChange={(name, value) => {
                      handleChangeInfor(value, "conclusion")
                    }}
                    value={inforResult?.conclusion}
                    isView={isViewModal}
                    onDeleteClick={() => {
                      
                    }}
                    onSuggestClick={() => {
                      
                    }}
                />
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      {shouldOpenModalDSMauKetQuaThucHien && (
        <ModalDSMauKetQuaThucHien
          selectMauKetQua={handleSelectMauKetQua}
          handleClose={() => handleCloseModalMauKetQuaThucHien()}
        />
      )}
      {shouldOpenModalPickTimeBDThucHien && (
        <ModalPickTimeBDThucHien
          handleClose={() => handleCloseModalPickTimeBDThucHien()}
          ngay={ngay}
          handleSetNgay={setNgay}
        />
      )}
      {shouldOpenModalPickTimeKTThucHien && (
        <ModalPickTimeKTThucHien
          handleClose={() => handleCloseModalPickTimeKTThucHien()}
          ngay={ngay}
          handleSetNgay={setNgay}
        />
      )}
      {openWebcamCapture && (
        <WebcamCapture
          open={openWebcamCapture}
          handleClose={() => setOpenWebcamCapture(false)}
          imgSrcs={imgSrcs}
          setImageSrcs={setImageSrcs}
        />
      )}
    </>
  );
}
