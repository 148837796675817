import { TMenu } from "../../pages/Homepage/constant";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

export const KEY_AUTHORITIES = {
  ACCESS: {
    DAT_LICH_HEN: "CIS.MODULE.DAT_LICH_HEN",
    TIEP_DON: "CIS.MODULE.TIEP_DON",
    KHAM_BENH: "CIS.MODULE.KHAM_BENH",
    VIEN_PHI: "CIS.MODULE.VIEN_PHI",
    XET_NGHIEM: "CIS.MODULE.XET_NGHIEM",
    CDHA_TDCN: "CIS.MODULE.CDHA_TDCN",
    CHUYEN_KHOA: "CIS.MODULE.CHUYEN_KHOA",
    NHA_THUOC: "CIS.MODULE.NHA_THUOC",
    KHO_THUOC: "CIS.MODULE.KHO_THUOC",
    KHO_VAT_TU: "CIS.MODULE.KHO_VAT_TU",
    KHAM_DOAN: "CIS.MODULE.KHAM_DOAN.TRUY_CAP",
    KD_HOP_DONG: "CIS.MODULE.KHAM_DOAN.HOP_DONG",
    KD_KHAM_LAM_SANG: "CIS.MODULE.KHAM_DOAN.KLS",
    KD_THANH_TOAN: "CIS.MODULE.KHAM_DOAN.THANH_TOAN",
    QUAN_TRI_HE_THONG: "CIS.MODULE.QUAN_TRI_HE_THONG",
    BAO_CAO: "CIS.MODULE.BAO_CAO",
    LAY_MAU_XET_NGHIEM: "CIS.MODULE.PHAN_HE_LAY_MAU",
  },
  XETNGHIEM: {
    LAY_MAU: "",
    THUC_HIEN: "",
    TRA_KQ: "",
  },
  CDHA_TDCN: {
    THUC_HIEN: "",
    TRA_KQ: "",
  },
}

export const hasAuthority = (permission: string): boolean => {
  const authoritiesString = localStorage.getItem(KEY_LOCALSTORAGE.AUTHORITIES);
  const authorities = authoritiesString ? JSON.parse(authoritiesString) : {}
  return authorities[permission]
}

export const checkMenuByPermissions = (listMenu: TMenu[]): TMenu[] => {
  const checkedMenu: TMenu[] = [];
  // listMenu.forEach((menu) => {
  //   if (hasAuthority(menu.permission)) {
  //     const checkedMenuItems: TMenu = {
  //       ...menu
  //     };
  //     checkedMenu.push(checkedMenuItems);
  //   }
  // });

  return checkedMenu;
};
