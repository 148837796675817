import { ReactNode, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

type TFilterTree = {
  name: string;
  code: string;
  quantity?: number;
  icon?: ReactNode;
};

type TTree = {
  code: string;
  name: string;
  filter?: TFilterTree[];
  icon?: ReactNode;
  quantity?: number;
};

type TSelectTree = {
  code?: string;
  name?: string;
  filter: TTree[];
  icon?: ReactNode;
  quantity?: number;
};

type TProps = {
  className?: string;
  codeCollapses: string[];
  handleChangeCollapsesCode: React.Dispatch<React.SetStateAction<string[]>>;
  idSelected: string;
  handleChangeSelectId: React.Dispatch<React.SetStateAction<string>>;
  selectTree: TSelectTree;
  onClickIcon?: boolean | null;
  getSelectedItem?: (data: any) => void;
  countStatus?: any;
};

const SelectTree = ({
  codeCollapses,
  handleChangeCollapsesCode,
  idSelected,
  handleChangeSelectId,
  selectTree,
  className,
  onClickIcon,
  getSelectedItem,
  countStatus,
}: TProps) => {
  const handleCollapse = (code: string) => {
    if (codeCollapses.includes(code)) {
      handleChangeCollapsesCode(
        codeCollapses.filter((item: string) => item !== code)
      );
    } else {
      handleChangeCollapsesCode([...codeCollapses, code]);
    }
  };

  useEffect(() => {
    let newArr: any[] = [];
    newArr.push(selectTree.code);
    selectTree.filter.forEach(element => {
      newArr.push(element.code);
      if (element.filter) {
        newArr.push(...element.filter.map((item: any) => item.code));
      };
    });
    handleChangeCollapsesCode(newArr);
  }, [selectTree]);

  const handleSelected = (code: string): void => {
    if (idSelected === code) {
      handleChangeSelectId("");
    } else {
      handleChangeSelectId(code);
    }
  };

  return (
    <div
      className={`tree border border-left-0 ${className} p-2 position-relative`}
    >
      {selectTree.code && (
        <div
          onClick={() => handleCollapse(selectTree.code ?? "")}
          className="d-flex align-items-center ms-4 pb-2"
        >
          {!selectTree?.icon && (
            <span>
              {codeCollapses.includes(selectTree.code ?? "") ? (
                <i className="cursor-pointer bi bi-caret-down-fill color-blue-custom spaces pr-10"></i>
              ) : (
                <i className="cursor-pointer bi bi-caret-right-fill color-blue-custom spaces pr-10"></i>
              )}
            </span>
          )}
          <span className="fw-bold d-flex truncate-text color-blue-custom">
            {selectTree?.icon && (
              <span className="spaces pr-10 d-flex align-items-center text-pri">
                {selectTree?.icon}
              </span>
            )}{" "}
            {selectTree.name}
          </span>
        </div>
      )}
      {(selectTree.code ? codeCollapses.includes(selectTree.code) : true) &&
        selectTree.filter?.map((item, index) => (
          <div key={item?.code + "-" + index}>
            <div
              className={`${
                selectTree.code ? "pl-13 spaces" : "fw-bold"
              } d-flex row-tree fw-bold text-gray pb-2`}
              onClick={() => !onClickIcon && handleCollapse(item.code)}
            >
              <span>
                {
                  // item?.filter && item?.filter?.length > 0 &&
                  <div onClick={() => onClickIcon && handleCollapse(item.code)}>
                    {codeCollapses.includes(item.code) ? (
                      <i className="cursor-pointer bi bi-caret-down-fill text-black spaces pr-12"></i>
                    ) : (
                      <i className="cursor-pointer bi bi-caret-right-fill text-black spaces pr-12"></i>
                    )}
                  </div>
                }
              </span>
              <span
                className="cursor-pointer truncate-text d-flex"
                onClick={() => handleChangeSelectId(item.code)}
              >
                {item?.icon && (
                  <span className="spaces pr-10 d-flex align-items-center">
                    {item?.icon}
                  </span>
                )}
                {item.name}
              </span>
            </div>
            {codeCollapses.includes(item.code) &&
              item?.filter?.map((i, index) => (
                <div
                  key={i?.code + "-" + index}
                  className={
                    idSelected?.includes(i.code)
                      ? `isFilterSelected ${
                          item?.icon ? "pl-38" : "pl-23"
                        } spaces w-100 truncate-text d-flex align-items-center pb-4`
                      : `${
                          item?.icon ? "pl-38" : "pl-23"
                        } spaces w-100 hover-row truncate-text d-flex align-items-center cursor-pointer pb-4`
                  }
                  onClick={() => {
                    getSelectedItem && getSelectedItem(idSelected?.includes(i.code) ? null : { ...i, parrentCode: item?.code });
                    handleSelected(i.code);
                  }}
                >
                  <span className="truncate-text spaces pl-10 row-tree">
                    {i.name}
                  </span>{" "}
                  &nbsp;
                  {i.quantity ? (
                    <span className="quantity">{i.quantity}</span>
                  ) : (
                    item?.icon && <span className="spaces W-10">{i?.icon}</span>
                  )}
                </div>
              ))}
          </div>
        ))}
      <div className="position-absolute bottom-0 px-2 left-14 w-89">
        <Row>
          {countStatus &&
            countStatus.map((item: any) => {              
              return (
                <Col
                  key={item?.code}
                  xs={12}
                  className={`d-flex flex-center ${item?.className} mt-5`}
                >
                  {item?.name} : {item?.count}
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};

export default SelectTree;
