import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { IconButtonSave } from '../../../../../component/IconSvg';
import LabelRequired from '../../../../../component/LabelRequired';
import { formatMoney } from '../../../../../utils/FormatUtils';
import { CHI_PHI_THUOC_FIELDS, CHI_PHI_THUOC_TYPE } from '../../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { useKeVatTuContext } from '../../context';

const ChiPhiVatTu = () => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { vatTuChecked } = useKeVatTuContext();

	const thongTinLanKham = benhNhanInfo.thongTinKhamBenh.visit;
	const thongTinChiPhi = {
		tongChiPhi: thongTinLanKham.ttTong,
		bhyt: thongTinLanKham.ttBhyt,
		benhNhan: thongTinLanKham.ttNguoiBenh,
		tamUng: thongTinLanKham.ttTamUng,
		daThu: thongTinLanKham.ttDaThu,
		mienGiam: thongTinLanKham.ttDiscountInv,
		conDu: thongTinLanKham.ttConNo,
	};

	const tongChiPhiVatTu = vatTuChecked.length
		? vatTuChecked.reduce(
				(acc, row) => acc + (row.deleted ? 0 : (row.donGia || 0) * (row.soLuong || 0)),
				0
		  )
		: 0;

	const getChiPhiVatTu = (field: CHI_PHI_THUOC_TYPE) => {
		const MAP_FIELD_VALUE = {
			[CHI_PHI_THUOC_FIELDS.TONG_CHI_PHI]: thongTinChiPhi.tongChiPhi + tongChiPhiVatTu,
			[CHI_PHI_THUOC_FIELDS.BHYT]: thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.NGUON_KHAC]: 0,
			[CHI_PHI_THUOC_FIELDS.BENH_NHAN]:
				thongTinChiPhi.benhNhan + tongChiPhiVatTu - thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.TAM_UNG]: thongTinChiPhi.tamUng,
			[CHI_PHI_THUOC_FIELDS.DA_THU]: thongTinChiPhi.daThu,
			[CHI_PHI_THUOC_FIELDS.MIEN_GIAM]: thongTinChiPhi.mienGiam,
			[CHI_PHI_THUOC_FIELDS.CON_DU]:
				thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongChiPhiVatTu),
		};
		return MAP_FIELD_VALUE[field];
	};

	return (
		<div className="d-flex justify-content-between align-items-center spaces p-10 mt-6 bg-white">
			<div className="d-flex">
				<div>
					<div className="spaces d-flex width-50 align-items-center mb-10">
						<LabelRequired label="Tổng chi phí" className="spaces ms-0 min-w-150" />
						<span className="text-danger me-5 fw-500">
							{formatMoney(getChiPhiVatTu('TONG_CHI_PHI'))}
						</span>
					</div>
					<div className="spaces d-flex width-50 align-items-center">
						<LabelRequired label="Tạm ứng" className="spaces ms-0 min-w-150" />
						<span className="text-pri me-5 fw-500">
							{formatMoney(getChiPhiVatTu('TAM_UNG'))}
						</span>
					</div>
				</div>
				<div>
					<div className="spaces d-flex width-50 align-items-center mb-10">
						<LabelRequired label="BHYT" className="spaces ms-0 min-w-150" />
						<span className="text-pri me-5 fw-500">
							{formatMoney(getChiPhiVatTu('BHYT'))}
						</span>
					</div>
					<div className="spaces d-flex width-50 align-items-center">
						<LabelRequired label="Đã thu" className="spaces ms-0 min-w-150" />
						<span className="text-danger me-5 fw-500">
							{formatMoney(getChiPhiVatTu('DA_THU'))}
						</span>
					</div>
				</div>
				<div>
					<div className="spaces d-flex width-50 align-items-center mb-10">
						<LabelRequired label="Nguồn khác" className="spaces ms-0 min-w-150" />
						<span className="text-pri me-5 fw-500">
							{formatMoney(getChiPhiVatTu('NGUON_KHAC'))}
						</span>
					</div>
					<div className="spaces d-flex width-50 align-items-center">
						<LabelRequired label="Miễn giảm" className="spaces ms-0 min-w-150" />
						<span className="text-pri me-5 fw-500">
							{formatMoney(getChiPhiVatTu('MIEN_GIAM'))}
						</span>
					</div>
				</div>
				<div>
					<div className="spaces d-flex width-50 align-items-center mb-10">
						<LabelRequired label="Bệnh nhân" className="spaces ms-0 min-w-150" />
						<span className="text-pri me-5 fw-500">
							{formatMoney(getChiPhiVatTu('BENH_NHAN'))}
						</span>
					</div>
					<div className="spaces d-flex width-50 align-items-center">
						<LabelRequired
							label={getChiPhiVatTu('CON_DU') < 0 ? 'Còn nợ' : 'Còn dư'}
							className="spaces ms-0 min-w-150"
						/>
						<span className="text-pri me-5 fw-500">
							{formatMoney(Math.abs(getChiPhiVatTu('CON_DU')))}
						</span>
					</div>
				</div>
			</div>
			<div>
				<Button className="btn-fill" type="submit">
					<IconButtonSave /> Lưu lại
				</Button>
			</div>
		</div>
	);
};

export default ChiPhiVatTu;
