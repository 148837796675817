import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Button, FormCheck, Row } from 'react-bootstrap';
import DatePickerCustom from '../../../../../component/date-picker/DatePickerCustom';
import LabelRequired from '../../../../../component/LabelRequired';
import TextField from '../../../../../component/TextField';
import { IDanhSachDanhMuc } from '../../../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import { KEY_DS_DANH_MUC_TIEP_DON } from '../../../../../utils/Constant';
import { getIndexedDBItem } from '../../../../../utils/IndexedDB';
import ICD10 from '../../../../components/ICD10';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import ProvisonalDiagnosis from '../../../../components/ProvisonalDiagnosis';

const ThongTinBenh = () => {
	const { values, setFieldValue, errors, setValues, touched } = useFormikContext<any>();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const [listICD10, setListICD10] = useState<any>([]);
	const [listComorbidity, setListComorbidity] = useState<any[]>([]);
	const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);
	const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
	const [selectionModeICD10, setSelectionModeICD10] = useState<'checkbox' | 'radio'>('radio');

	const handleOpenSelectICD10 = () => {
		setShouldOpenModalSelectICD10(true);
	};

	useEffect(() => {
		getDanhMucICD10();
	}, []);

	const getDanhMucICD10 = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setListICD10(danhMuc.listICD10);
	};

	const handleChangeICD10 = (data: any) => {
		setValues({
			...values,
			...data,
		});
	};

	return (
		<>
			<div className="d-flex justify-content-between pt-3 w-80">
				<div className="spaces mb-4 d-flex align-items-center">
					<DatePickerCustom
						label="Ngày y lệnh"
						name="ngayYLenh"
						labelClassName="spaces ms-0 min-w-120"
						value={values.ngayYLenh}
						setDateValue={date => {
							setFieldValue('ngayYLenh', date);
						}}
						mode="date-time"
						errors={errors?.ngayYLenh}
						touched={touched?.ngayYLenh}
					/>
					<FormCheck
						className="ms-3 mt-1 spaces min-w-100"
						label="Thu tiền"
						type="checkbox"
						name="thuTien"
						checked={values.thuTien}
						onChange={e => setFieldValue('thuTien', e.target.checked)}
						isInvalid={!!errors.thuTien}
					/>
				</div>
				<Button
					className="bg-white spaces W-85 py-0 pr-5 mt-4"
					onClick={handleOpenSelectICD10}
				>
					<u className="fw-bold text-pri fs-13px">Chọn bệnh</u>
				</Button>
			</div>

			<div className="d-flex flex-wrap w-80">
				<LabelRequired label="Chẩn đoán" className="spaces min-w-120" />
				<Row className='flex-grow-1 position-relative'>
					<ProvisonalDiagnosis
						encounterDiagnosisValues={values}
						encounterDiagnosisErrors={errors}
						encounterDiagnosisTouched={touched}
						benhNhanInfo={benhNhanInfo}
						listComorbidity={listComorbidity}
						setListComorbidity={setListComorbidity}
						onChange={(data: any) =>
							setValues({
								...values,
								...data,
							})
						}
						handleChangeEncounterDiagnosis={setFieldValue}
					/>
				</Row>
			</div>

			<div className="spaces mt-4 w-80">
				<div className="d-flex">
					<div className="d-flex">
						<LabelRequired
							label="Bệnh kèm theo"
							className="d-inline-block spaces min-w-120-px text-truncate"
						/>
					</div>
					<div className="spaces position-relative w-100">
						<TextField
							className="w-100 d-flex flex-column align-items-start"
							inputClassName="flex-auto spaces h-25 radius-3px pre-line"
							name="comorbidity"
							labelClassName="ms-0 min-w-145px"
							// readOnlyText
						/>
					</div>
				</div>
			</div>

			<ICD10
				shouldOpenModalICD10={shouldOpenModalICD10}
				setShouldOpenModalICD10={setShouldOpenModalICD10}
				shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
				setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
				listICD10={listICD10}
				listComorbidity={listComorbidity}
				setListComorbidity={setListComorbidity}
				selectionModeICD10={selectionModeICD10}
				data={values}
				onChange={handleChangeICD10}
			/>
		</>
	);
};

export default ThongTinBenh;
