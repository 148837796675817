import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { getListDsNhanVien } from "../../services/KhamDoanHopDongServices";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../tab-quan-ly-hop-dong/QuanLyHopDong";
import { TableDanhSachBenhNhan } from "./TableDanhSachBenhNhan";
import TextValidator from "../../../component/TextValidator";
type Props = {
  selectedRow: any;
  handlerCloseHD: any,
  setSelectedRow: (row: any) => void;
  setFilter: (data: any) => void;
};

const DanhSachBenhNhan = (props: Props) => {
  let { handlerCloseHD, selectedRow, setSelectedRow } = props;
  const { setIsLoading } = useContext(AppContext);
  const { isSearchNV, TTHopDong, shouldUpdateSummary } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
  const [countPaid, setCountPaid] = useState<any>();
  const [dataSummary, setDataSummary] = useState<any[]>([]);
  const [dsBenhNhan, setDsBenhNhan] = useState<any>({
    data: [],
    totalElements: 1,
    totalPages: 1,
  });
  const [keyword, setKeyword] = useState<any>(null);
  const [statusFilter, setStatusFilter] = useState<any>(null);
  const [typeCheckboxs, setTypeCheckboxs] = useState<any[]>([]);
  
  const getDanhSachBenhNhan = async () => {
    if (TTHopDong?.id) {
      setIsLoading(true);
      let searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        keyword: keyword,
        contractId: TTHopDong?.id,
        statusIds: typeCheckboxs?.length > 0 ? typeCheckboxs.join(',') : statusFilter,
      };
      try {
        const { data } = await getListDsNhanVien(searchObject);
        if (CODE.SUCCESS === data?.code) {
          setDataSummary(data.data.summary);
          setDsBenhNhan({
            data: data?.data?.content || [],
            totalElements: data?.data?.totalElements,
            totalPages: data?.data?.totalPages,
          });
          setCountPaid({
            notPaidEnough: data?.data?.notPaidEnough,
            paidEnough: data?.data?.paidEnough,
          });
        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      } catch {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      } finally {
        setIsLoading(false);
      }
    } else {
      return setDsBenhNhan({
        data: [],
        totalElements: 1,
        totalPages: 1,
      })
    }
  };

  useEffect(() => {
    getDanhSachBenhNhan();
  }, [TTHopDong?.id, isSearchNV, statusFilter, typeCheckboxs, shouldUpdateSummary]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEY.ENTER) {
      getDanhSachBenhNhan()
    }
  };

  const handleFilterByStatus = (type: string, checked: boolean) => {
    if (checked) {
      setTypeCheckboxs((prev) => [...prev, type]);
    } else {
      setTypeCheckboxs((prev) => prev.filter((t) => t !== type));
    }
  }

  return (
    <div className="position-relative spaces gap-8 bg-white">
      <div className="justify-content-between spaces pl-8 mb-8">
        <div className="d-flex mb-2 bg-white">
          <Col xs="7" className="px-0 mt-1">
            <h4 className="text-title mb-0 fw-bold fs-4 spaces ">
              Danh sách nhân viên
            </h4>
          </Col>
          <Col
            xs="5"
            className="flex justify-content-end spaces pt-2 px-5 mt-6"
          >
            <Button
              className="customer-buttom-dk spaces min-w-120 h-30"
              onClick={handlerCloseHD}
            >
              <span className="customer-buttom-text">DS Hợp Đồng</span>
            </Button>
          </Col>
        </div>
        <div className="d-flex spaces  gap-10">
          <Row className="mx-0 mb-3">
            <Col xs="6" className="px-0">
              <FormCheck
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đăng ký"}
                onChange={(e) => handleFilterByStatus('12', e.target.checked)}
              />
            </Col>
            <Col xs="6" className="px-0">
              <FormCheck
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đang khám"}
                onChange={(e) => handleFilterByStatus('1', e.target.checked)}
              />
            </Col>
            <Col xs="6" className="px-0">
              <FormCheck
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đã trả kết quả"}
                onChange={(e) => handleFilterByStatus('15', e.target.checked)}
              />
            </Col>
            <Col xs="6" className="px-0">
              <FormCheck
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đã khám xong"}
                onChange={(e) => handleFilterByStatus('2', e.target.checked)}
              />
            </Col>
          </Row>
        </div>
        <div className="d-flex spaces mb-6 mr-5">
          <div className="flex-auto">
            <InputGroup className="spaces h-29 px-0">
              <TextValidator
                className="spaces h-29 min-w-177"
                name="keyword"
                placeholder="Tìm kiếm"
                value={keyword}
                onChange={(e: any) => {
                  setKeyword(e?.target?.value);
                }}
                onKeyDown={handleKeyPress}
              />
              <Button
                className="btn-fill spaces min-w-105 h-29 rounded-end-1 z-index-1055"
                onClick={getDanhSachBenhNhan}
              >
                Tìm kiếm
              </Button>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-column">
        <TableDanhSachBenhNhan
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          dsBenhNhan={dsBenhNhan?.data}
          dataSummary={dataSummary}
          countPaid={countPaid}
          setStatusFilter={setStatusFilter}
        />
      </div>
    </div>
  );
};

export default DanhSachBenhNhan;
