import { useEffect } from 'react';

type Props = {
	searchBarcode: (barcode: string) => void;
};

const useBarcodeScan = ({ searchBarcode }: Props) => {
	let barcodeScan = '';

	useEffect(() => {
		if (document.activeElement) {
			(document.activeElement as HTMLElement).blur();
		}

		if (document.hasFocus()) {
			document.addEventListener('keydown', onDocumentKeydown);
		}

		return () => {
			document.removeEventListener('keydown', onDocumentKeydown);
		};
	}, []);

	const onDocumentKeydown = (e: KeyboardEvent) => {
		const isFocusElement = document.querySelector(':focus');
		const endKeys = ['Enter', 'Tab'];
		const keyPressed = e.key;

		if (!e.altKey && !e.ctrlKey && !e.shiftKey && !endKeys.includes(keyPressed)) {
			barcodeScan += String.fromCharCode(e.keyCode);
		}

		if (endKeys.includes(keyPressed) && !isFocusElement) {
			if (barcodeScan) {
				searchBarcode(barcodeScan);
			}

			setTimeout(() => {
				barcodeScan = '';
			}, 100);
		}

		if (isFocusElement) {
			barcodeScan = '';
		}
	};
};

export default useBarcodeScan;
