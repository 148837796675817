import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { } from "@oceantech/oceantech-ui";
import React, { FC, useContext } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";
import OCTTextValidator from "../text-validator";
import "./confirmDialog.scss";
// import { formatDateAdvanceToString, formatStringDateToYYYYMMDD, formatDateToString, formatStringDateDTO } from '../../utils/FormatUtils';
import axios from "axios";
import { toast } from "react-toastify";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { AppContext } from "../../appContext/AppContext";
import { formatDateDTO } from "../../utils/FormatUtils";
import './generateForm.scss';
import {
    COMPONENT_TYPE,
    handleThrowResponseMessage,
    IObject,
    isSuccessfulResponse,
    replaceUrl
} from "./GenerateFormConfig";
import clsx from 'clsx';
import InputOTP from '../input-otp';
import InputCellGroup from '../input-cell-group';
interface IDialogProps {
    listFieldAuto?: any;
    onCloseClick?: () => void;
    handleSave?: (data: any) => void;
    onCancelClick?: () => void;
    title?: string;
    message?: string;
    isView?: boolean;
    isUpdate?: boolean;
    itemEdit?: IObject;
    validation?: IObject;
    isSave?: boolean;
    modelID?: any;
    handleSubmit?: any;
    setValues?: any;
    setTouched?: any;
    onChange?: any;
    values?: any;
    errors?: any;
    touched?: any;
    isValid?: any;
    handleChange?: any;
}
interface ItemTypeProps {
    autofocus?: boolean;
    clearOnHide?: boolean;
    conditional?: IObject;
    defaultValue?: string;
    hidden?: boolean;
    input?: boolean;
    inputType?: string;
    inputFormat?: string;
    inputMask?: string;
    key: string;
    label?: string;
    lablelPosition?: string;
    multiple?: boolean;
    placeholder?: string;
    persistent?: boolean;
    prefix?: string;
    protected?: boolean;
    properties?: IObject;
    spellcheck?: boolean;
    suffix?: string;
    unique?: boolean;
    width?: number;
    offset?: number;
    push?: number;
    pull?: number;
    type?: string;
    value?: string;
    tag?: keyof JSX.IntrinsicElements;
    tags?: string[];
    validate?: IObject;
    columns?: IObject;
    values?: IObject;
    components?: ItemTypeProps[];
    data?: IObject;
    filePattern?: string;
    fileMaxSize?: string;
    fileMinSize?: string;
    format?: string;
    url?: string;
    fields?: any;
    content?: string;
    hideLabel?: boolean;
    inline?: boolean;
    className?: string;
    overlay?: any;
    searchObject?: any;
    length?: number;
    customClass?: string;
    cells?: any;
}
const GenerateFormHTMLNo: FC<IDialogProps> = (props) => {
    const {
        isView,
        modelID,
        onChange,
        values,
        errors,
        touched,
        handleChange = () => { },
        listFieldAuto,
    } = props;
    const customValidation = { ...props.validation };
    const { lang } = useMultiLanguage();
    const { setIsLoading } = useContext(AppContext);


    const handleChangeFile = async (e: any, item: ItemTypeProps) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("uploadedFile", file);

        if (item.url) {
            try {
                setIsLoading(true);
                let res = await uploadFile(item?.url, formData);
                const data = res.data;
                if (isSuccessfulResponse(res.status)) {
                    setIsLoading(false);
                    let attachment = data.file || data?.data;
                    let attachments = [];
                    let fileDescriptionIds = [];

                    attachments.push(attachment);
                    fileDescriptionIds.push(attachment?.id);
                    await onChange(e.target.name, fileDescriptionIds);
                    toast.success(lang("GENERAL.SUCCESS"));
                } else {
                    handleThrowResponseMessage(res);
                }
            } catch {
                toast.error(lang("GENERAL.ERROR"));
            } finally {
                setIsLoading(false);
            }
        }
    };

    const uploadFile = (url: string | undefined | null, formData: any) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return axios.post(url || "", formData, config);
    };

    const handleChangeCheck = (name: string, value: any, modelID: string, e: any) => {
        onChange(name, value ? "1" : "0");
    };

    const handleChangeValueText = (name: string, value: any, modelID: string, dataComponent?: any) => {
        let valueConvert = value
        if (dataComponent?.inputType === "number") {
            valueConvert = Number(value)
        }
        if (dataComponent?.type === "datetime") {
            valueConvert = formatDateDTO(value)
        }
        onChange(name, valueConvert);
    }
    const handleChangeValueSelect = (value: any, modelID: string, dataComponent?: any) => {
        let type = "select";
        onChange(dataComponent?.key, value, type);
    }
    const handleGetDataByUrlData = (urlData?: string, searchObject?: any) => {
        return axios.get(urlData as string);
    };
    const handleChangeSelectBoxes = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number,
        source: string
    ) => {
        let newArray = values?.[source] || [];
        newArray[index].value = e.target.checked;

        onChange(source, newArray);
    };

    const renderHTML = (dataForm: any, configKey?: string) => {
        if (dataForm?.components || dataForm?.[configKey || "components"]) {
            let newArray = dataForm?.components || dataForm?.[configKey || ""] || [];

            if (Object.prototype.toString.call(newArray) === "[object Object]") {
                newArray = Object.entries(newArray).map(([key, value]) => {
                    return value;
                });
            }

            let filteredComponents = newArray?.filter(
                (item: ItemTypeProps) => item.type !== COMPONENT_TYPE.BUTTON
            );
            return filteredComponents?.map((dataComponent: ItemTypeProps) => {
                switch (dataComponent?.type) {
                    case COMPONENT_TYPE.EMAIL:
                    case COMPONENT_TYPE.PASSWORD:
                    case COMPONENT_TYPE.TEXTFIELD:
                        return (
                            <>
                                <Col
                                    lg={dataComponent?.width || 12}
                                    className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                                >
                                    <TextField
                                        hideLabel={dataComponent?.hideLabel}
                                        label={dataComponent?.label}
                                        name={dataComponent?.key}
                                        type={dataComponent.inputType}
                                        onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID)}
                                        // isRequired={dataComponent?.validate?.required}
                                        value={values?.[modelID]?.[dataComponent?.key] || ""}
                                        touched={touched?.[modelID]?.[dataComponent?.key]}
                                        errors={errors?.[modelID]?.[dataComponent?.key]}
                                        placeholder={dataComponent.placeholder}
                                        disabled={isView}
                                        className={dataComponent?.customClass}
                                    />
                                </Col>
                            </>
                        );
                    case COMPONENT_TYPE.COLUMNS:
                        return (
                            <Row>
                                {dataComponent?.columns &&
                                    dataComponent?.columns?.length > 0 &&
                                    dataComponent?.columns?.map((dataColumn: ItemTypeProps, index: number) => {
                                        const className = index === 0 
                                            ? "pe-0" 
                                            : index === dataComponent?.columns?.length - 1 
                                                ? "ps-0" 
                                                : "px-0";
                                        return (
                                            <Col 
                                                lg={{
                                                    span: dataColumn?.width,
                                                    offset: dataColumn?.offset || 0,
                                                }} 
                                                className={className}
                                            >
                                                    {renderHTML(dataColumn)}
                                            </Col>
                                        );
                                    })}
                            </Row>
                        );
                    case COMPONENT_TYPE.FILE:
                        return (
                            <>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className="text-lable-input lable m-0">
                                        {dataComponent?.label}
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name={dataComponent.key}
                                        max={dataComponent?.fileMaxSize}
                                        min={dataComponent?.fileMinSize}
                                        accept={dataComponent?.filePattern}
                                        onChange={(e) => handleChangeFile(e, dataComponent)}
                                        value={values?.[dataComponent?.key]}
                                        disabled={isView}
                                    />
                                </Form.Group>
                            </>
                        );
                    case COMPONENT_TYPE.DAY:
                        const isNgaySinh = dataComponent?.key === "ngaySinh";
                        return (
                            <>
                                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                                <Row>
                                    <Col
                                        lg={isNgaySinh ? 3 : 4}
                                        className="spaces pr-0 mb-3 pt-8"
                                    >
                                        <OCTTextValidator
                                            hideLabel={dataComponent?.hideLabel}
                                            lable={""}
                                            name={dataComponent.placeholder}
                                            type={"text"}
                                            onChange={handleChange}
                                            isRequired={dataComponent?.validate?.required}
                                            value={values?.[dataComponent?.key]}
                                            touched={touched?.[dataComponent?.key]}
                                            errors={errors?.[dataComponent?.key]}
                                            placeholder={""}
                                            disabled={isView}
                                        />
                                    </Col>
                                    <Col
                                        lg={isNgaySinh ? 3 : 4}
                                        className="spaces mb-3 pl-0 pr-0 pt-8"
                                    >
                                        <OCTTextValidator
                                            hideLabel={dataComponent?.hideLabel}
                                            lable={""}
                                            name={dataComponent.placeholder}
                                            type={"text"}
                                            onChange={handleChange}
                                            isRequired={dataComponent?.validate?.required}
                                            value={values?.[dataComponent?.key]}
                                            touched={touched?.[dataComponent?.key]}
                                            errors={errors?.[dataComponent?.key]}
                                            placeholder={""}
                                            disabled={isView}
                                        />
                                    </Col>
                                    <Col
                                        lg={isNgaySinh ? 3 : 4}
                                        className="spaces mb-3 pl-0 pt-8"
                                    >
                                        <OCTTextValidator
                                            hideLabel={dataComponent?.hideLabel}
                                            lable={""}
                                            name={dataComponent.placeholder}
                                            type={"text"}
                                            onChange={handleChange}
                                            isRequired={dataComponent?.validate?.required}
                                            value={values?.[dataComponent?.key]}
                                            touched={touched?.[dataComponent?.key]}
                                            errors={errors?.[dataComponent?.key]}
                                            placeholder={""}
                                            disabled={isView}
                                        />
                                    </Col>
                                    {isNgaySinh &&
                                        <>
                                            <Col
                                                lg={1}
                                                className="spaces mb-3 pl-0 pt-8"
                                            >
                                                <div className="spaces d-flex flex-center fw-bold">
                                                    -
                                                </div>
                                            </Col>

                                            <Col
                                                lg={2}
                                                className="spaces mb-3 pl-0 pt-8"
                                            >
                                                <OCTTextValidator
                                                    hideLabel={dataComponent?.hideLabel}
                                                    lable={""}
                                                    name={dataComponent?.key}
                                                    type={"text"}
                                                    onChange={handleChange}
                                                    isRequired={dataComponent?.validate?.required}
                                                    value={values?.[dataComponent?.key]}
                                                    touched={touched?.[dataComponent?.key]}
                                                    errors={errors?.[dataComponent?.key]}
                                                    placeholder={""}
                                                disabled={isView}
                                                />
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </>
                        );
                    case COMPONENT_TYPE.TIME:
                        return (
                            <>
                                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                                <Form.Control
                                    type="time"
                                    placeholder="Time"
                                    onChange={handleChange}
                                    value={values?.[dataComponent?.key]}
                                />

                            </>
                        );
                    case COMPONENT_TYPE.DATETIME:
                        return (
                            <>
                                <Col
                                    lg={dataComponent?.width ? dataComponent?.width : 12}
                                    className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                                >
                                    <TextField
                                        hideLabel={dataComponent?.hideLabel}
                                        lable={dataComponent?.label}
                                        name={dataComponent?.key}
                                        type={"date"}
                                        onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                                        // isRequired={dataComponent?.validate?.required}
                                        value={values?.[modelID]?.[dataComponent?.key] || ""}
                                        touched={touched?.[modelID]?.[dataComponent?.key]}
                                        errors={errors?.[modelID]?.[dataComponent?.key]}
                                        placeholder={dataComponent.placeholder}
                                        disabled={isView}
                                    />
                                </Col>

                            </>
                        );
                    case COMPONENT_TYPE.NUMBER:
                    case COMPONENT_TYPE.PHONE:
                        return (
                            <>
                                <Col
                                    lg={dataComponent?.width ? dataComponent?.width : 12}
                                    className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                                >
                                    <TextField
                                        hideLabel={dataComponent?.hideLabel}
                                        lable={dataComponent?.label}
                                        name={dataComponent?.key}
                                        type={"number"}
                                        onChange={(e: any) => handleChangeValueText(e.target.name, e.target.value, modelID, dataComponent)}
                                        // isRequired={dataComponent?.validate?.required}
                                        value={values?.[modelID]?.[dataComponent?.key] || ""}
                                        touched={touched?.[modelID]?.[dataComponent?.key]}
                                        errors={errors?.[modelID]?.[dataComponent?.key]}
                                        placeholder={dataComponent.placeholder}
                                        disabled={isView}
                                    />
                                </Col>
                            </>
                        );
                    case COMPONENT_TYPE.TEXTAREA:
                        return (
                            <>
                                <TextField
                                    name={dataComponent?.key}
                                    className={
                                        clsx(
                                            "text-field-label-down-line spaces min-height-90px pt-10",
                                            dataComponent?.customClass
                                        )
                                    } 
                                    labelClassName="ps-2"
                                    onChange={handleChange}
                                    touched={touched?.[dataComponent?.key]}
                                    errors={errors?.[dataComponent?.key]}
                                    as="textarea"
                                    disabled={isView}
                                    value={values?.[dataComponent?.key] || ""}
                                    label={!dataComponent?.hideLabel && dataComponent?.label}
                                    {...dataComponent?.properties}
                                />
                                {/* </Col> */}
                            </>
                        );
                    case COMPONENT_TYPE.CHECKBOX:
                        return (
                            <>
                                <Form.Group 
                                    className={clsx("spaces pt-10 form-check-label-black", dataComponent?.customClass)}
                                    controlId={dataComponent?.key}
                                >
                                    <FormCheck
                                        name={dataComponent.key}
                                        type="checkbox"
                                        label={dataComponent?.label}
                                        className="d-flex align-items-center spaces gap-7"
                                        checked={values?.[modelID]?.[dataComponent?.key] === "1"}
                                        onChange={(e: any) => handleChangeCheck(e.target.name, e.target.checked, modelID, e)}
                                        disabled={isView}
                                    />
                                </Form.Group>
                            </>
                        );
                    case COMPONENT_TYPE.SELECTBOXES:
                        return (
                            <>
                                <Col
                                    lg={dataComponent?.width || 12}
                                    className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                                >
                                {!dataComponent?.hideLabel 
                                    && !dataComponent.properties?.inline 
                                    &&
                                    <Form.Label className='text-lable-input'>{dataComponent?.label}</Form.Label>
                                }
                                <Form.Group 
                                    controlId={dataComponent?.key} 
                                    className={
                                        clsx({
                                          'd-flex gap-10 align-items-center spaces pt-8': dataComponent?.inline,
                                        },
                                        dataComponent?.customClass)
                                    }
                                >
                                    {!dataComponent?.hideLabel 
                                        && dataComponent.properties?.inline 
                                        &&
                                        <Form.Label className='text-lable-input'>
                                            {dataComponent?.label}
                                        </Form.Label>
                                    }
                                    {dataComponent?.values?.map(
                                        (dataItem: any, index: number) => (
                                            <Form.Check
                                                type="checkbox"
                                                label={dataItem?.label}
                                                checked={Boolean(
                                                    values?.[dataComponent?.key]?.[index]?.value
                                                )}
                                                onChange={(e) =>
                                                    handleChangeSelectBoxes(e, index, dataComponent?.key)
                                                }
                                                disabled={isView}
                                                inline={dataComponent?.inline}
                                            />
                                        )
                                    )}
                                </Form.Group>
                                </Col>
                            </>
                        );
                    case COMPONENT_TYPE.RADIO:
                        return (
                            <>
                                {!dataComponent?.hideLabel && <Form.Label>{dataComponent?.label}</Form.Label>}
                                <Form.Group className="d-flex spaces pt-8" controlId="formBasicCheckbox">
                                    {dataComponent?.values?.map((dataItem: any) => (
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label={dataItem?.label}
                                            value={dataItem.value}
                                            name={dataComponent?.key}
                                            checked={
                                                values?.[dataComponent?.key] === dataItem.value
                                            }
                                            onChange={handleChange}
                                            disabled={isView}
                                        />
                                    ))}
                                </Form.Group>
                            </>
                        );
                    case COMPONENT_TYPE.SELECT:
                        return (
                            <>
                                <Col
                                    lg={dataComponent?.width ? dataComponent?.width : 12}
                                    className="spaces"
                                >
                                    <AutocompleteObjectV2
                                        options={[]}
                                        isSearchDefauilt={true}
                                        name={dataComponent?.key}
                                        onChange={(e: any) => handleChangeValueSelect(e, modelID, dataComponent)}
                                        searchFunction={() =>
                                            handleGetDataByUrlData(
                                                replaceUrl(dataComponent?.data?.url || ""),
                                                dataComponent?.searchObject || {}
                                            )
                                        }
                                        value={values?.[modelID]?.[dataComponent?.key] || ""}
                                        touched={touched?.[modelID]?.[dataComponent?.key]}
                                        errors={errors?.[modelID]?.[dataComponent?.key]}
                                        placeholder={dataComponent?.placeholder || ""}
                                        searchObject={dataComponent?.searchObject || {}}
                                        className="autocomplete-custom-renderform mt-8 radius spaces width-100 h-29"
                                        isDisabled={isView}
                                    />
                                </Col>
                            </>
                        );
                    case COMPONENT_TYPE.BUTTON:
                        return (
                            <>
                                <Button>
                                    {lang(`BTN.${dataComponent?.label?.toUpperCase()}`)}
                                </Button>
                            </>
                        );
                    case COMPONENT_TYPE.CONTENT:
                        return (
                            <>
                                {/* <Form.Label>{dataComponent?.label || "Nội dung"}</Form.Label> */}
                                <div className="spaces pt-10 ">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        disabled={isView}
                                        onChange={(event: any, editor: any) => {
                                            onChange(dataComponent?.key, editor.getData());
                                        }}
                                        data={values?.[dataComponent?.key] || ""}
                                    // data={values?.[dataComponent?.key] || ""}
                                    />
                                </div>
                            </>
                        );
                    case COMPONENT_TYPE.HTML:
                        return (
                            <div className="spaces pt-10 min-w-360">
                                {dataComponent?.tag === "span" 
                                    ?
                                    <div className="">
                                        <LabelRequired className="min-w-100px" label={values?.[dataComponent?.key] || ""} />
                                    </div> 
                                    :
                                    (
                                        dataComponent?.tag === "p" 
                                            ?
                                            <div className={`spaces mt-1 ${dataComponent?.className}`}>
                                                <LabelRequired 
                                                    className={clsx("min-w-100px", dataComponent?.properties?.className ?? '')} 
                                                    isRequired={dataComponent?.className === "isRequired"} 
                                                    label={dataComponent?.content || ""} 
                                                />
                                            </div>
                                            : 
                                            <div className="hyperlink">{dataComponent?.label}</div>
                                    )
                                }
                            </div>
                        );
                    case COMPONENT_TYPE.OTP:
                        return (
                            <Col
                                lg={dataComponent?.width || 12}
                                className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                            >
                                <InputOTP 
                                    length={dataComponent?.length || 2}
                                    value={values?.[dataComponent?.key] || ""}
                                    label={dataComponent.hideLabel ? '' : dataComponent?.label}
                                />
                            </Col>
                        );
                    case COMPONENT_TYPE.INPUT_CELL_GROUP:
                        return (
                            <Col
                                lg={dataComponent?.width || 12}
                                className={`spaces pt-8 ${dataComponent?.overlay?.style}`}
                            >
                                <InputCellGroup 
                                    cells={dataComponent?.cells || []}
                                    value={values?.[dataComponent?.key] || ""}
                                    label={dataComponent.hideLabel ? '' : dataComponent?.label}
                                />
                            </Col>
                        );
                    default:
                        return <></>;
                }
            });
        } else {
            return <></>;
        }
    };

    return (
        <Form >
            {renderHTML(listFieldAuto)}
        </Form>
    );
};

export default GenerateFormHTMLNo;
