import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CODE, ORDER_TYPE_ID, PATH_NAME, RESPONSE_MESSAGE } from '../../../utils/Constant';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { getDrugGroupListByVisitId } from '../../services/ThuocService';
import TableVatTu from './components/table-vat-tu';
import ThaoTacPhieuVatTu from './components/thao-tac-phieu-vat-tu';
import ThongTinPhieu from './components/thong-tin-phieu-vat-tu';
import { TabVatTuContextProvider } from './context';

type Props = {
	danhSachPhieu?: boolean;
};

const TabVatTu = ({ danhSachPhieu = true }: Props) => {
	const { pathname } = useLocation();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { orderId } = benhNhanInfo?.thongTinKhamBenh || {};

	const [donVatTuList, setDonVatTuList] = useState([]);

	useEffect(() => {
		if (orderId) {
			getDanhSachDonVatTu();
		}
	}, [orderId, benhNhanInfo?.updateTab]);

	const getDanhSachDonVatTu = async () => {
		try {
			const { data, code } = await getDrugGroupListByVisitId({
				orderId,
				type: ORDER_TYPE_ID.VAT_TU,
			});

			if (CODE.SUCCESS === code) {
				setDonVatTuList(data);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<Row className={`spaces h-calc-vh-${PATH_NAME.KHAM_BENH === pathname ? '85' : '123'}`}>
			<div>
				<TabVatTuContextProvider
					initialValues={{
						initialDonVatTuList: donVatTuList,
					}}
				>
					<ThongTinPhieu />
					<TableVatTu />
					<ThaoTacPhieuVatTu getDanhSachDonVatTu={getDanhSachDonVatTu} />
				</TabVatTuContextProvider>
			</div>
		</Row>
	);
};

export default TabVatTu;
