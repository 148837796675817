import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import TextField from "../../../component/TextField";
import LabelRequired from "../../../component/LabelRequired";
import { Formik } from "formik";
import InfoPatientRight from "../../../phan-he-kham-benh/components/InfoPatientRight";
import { IApprovalVienPhi } from "../../models/VienPhiInfoModels";
import * as Yup from "yup";
import { formatDateDTO } from "../../../utils/FormatUtils";
import { paymentCancelApproval } from "../../services/PhanHeVienPhiServices";
import { CODE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";

type Props = {
  onClose: () => void;
  infoBenhNhan: any;
  dataUser: any;
  invoicesInfor: any[];
	handleGetPaymentInfor: () => void;
};

const initialValues: IApprovalVienPhi = {
  paymentCancellationTime: "",
  paymentCancellationPerson: "",
  paymentCancellationReason: ""
};

const PopupCancelPaymentApprove = (props: Props) => {
  const { onClose, infoBenhNhan, dataUser, invoicesInfor, handleGetPaymentInfor } = props;
  const [dataInfor, setDataInfor] = useState<any>();

  useEffect(() => {
    setDataInfor({
      ...dataInfor,
      paymentCancellationTime: formatDateDTO(new Date()),
      paymentCancellationPerson: dataUser?.name
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const dataPayload = {
        ...dataInfor,
        visitId: invoicesInfor[0]?.visitId
      };
			
      const { data } = await paymentCancelApproval(dataPayload);
      if (CODE.SUCCESS === data?.code) {
        toast.success("Hủy duyệt thanh toán thành công!");
				onClose();
				handleGetPaymentInfor();
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch(e) {
      console.error(e);
    }
  };

  const validationSchema = Yup.object({
    paymentCancellationReason: Yup.string().nullable().required("Bắt buộc nhập")
  });

  return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={() => handleSubmit()}
		>
			{({ values, handleSubmit, setFieldValue, errors, touched, resetForm }) => (
				<Modal
					show
					animation
					centered
					className="dialog-background"
					dialogClassName="modal-md"
				>
					<Modal.Header className="py-3 header-modal">
						<Modal.Title className="text-pri">Gỡ duyệt thanh toán</Modal.Title>
						<button className="btn-close" onClick={onClose}></button>
					</Modal.Header>
					<Modal.Body className="spaces p-10">
						<Form>
							<div className="spaces text-break fw-500 py-2 px-5 text-end top-0 bottom-0 end-0 w-100">
								<InfoPatientRight benhNhanInfo={infoBenhNhan} />
							</div>
							<Row>
								<Col xs="7">
									<div className="d-flex align-items-center spaces h-32 mb-3">
										<LabelRequired
											className="min-w-125px fw-bold"
											label="Ngày yêu cầu"
										/>
										<DatePickerCustom
											name="paymentCancellationTime"
											value={dataInfor?.paymentCancellationTime}
											setDateValue={date => {
												setDataInfor({
													...dataInfor,
													paymentCancellationTime: date,
												});
												setFieldValue('paymentCancellationTime', date);
											}}
											mode="date-time"
											disabled
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="12">
									<div className="d-flex align-items-center spaces h-32 mb-3">
										<LabelRequired
											className="min-w-125px fw-bold"
											label="Người yêu cầu"
										/>
										<TextField
											className="spaces width-100"
											name="paymentCancellationPerson"
											disabled={true}
											value={dataInfor?.paymentCancellationPerson || ''}
											onChange={(e: any) => {
												setDataInfor({
													...dataInfor,
													paymentCancellationPerson: e.target.value,
												});
												setFieldValue(
													'paymentCancellationPerson',
													e.target.value
												);
											}}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs="12">
									<div className="d-flex align-items-center spaces mb-3">
										<LabelRequired
											className="min-w-125px fw-bold"
											label="Lý do gỡ duyệt"
											isRequired
										/>
										<TextField
											as="textarea"
											className="spaces width-100"
											rows={4}
											name="paymentCancellationReason"
											value={dataInfor?.paymentCancellationReason || ''}
											onChange={(e: any) => {
												setDataInfor({
													...dataInfor,
													paymentCancellationReason: e.target.value,
												});
												setFieldValue(
													'paymentCancellationReason',
													e.target.value
												);
											}}
											errors={errors?.paymentCancellationReason}
											touched={touched?.paymentCancellationReason}
										/>
									</div>
								</Col>
							</Row>
						</Form>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-end py-1">
						<Button
							className="btn-fill min-w-80px"
							type="submit"
							onClick={() => handleSubmit()}
						>
							<span>Lưu</span>
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
  );
};

export default PopupCancelPaymentApprove;
