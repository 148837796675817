import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import FilterSidebar from "../../../component/filter-sidebar/FilterSidebar";
import LazyLoadTable from "../../../component/lazy-load-table/LazyLoadTable";
import { getPaymentDetail } from "../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import {
  CODE,
  ID_DANG_KY_KHAM,
  KEY_DS_DANH_MUC_TIEP_DON,
  MAX_SIZE,
  RESPONSE_MESSAGE,
  SEARCH_OBJECT_MAX_SIZE,
  UU_TIEN,
} from "../../../utils/Constant";
import {
  customTrangThaiBenhNhan,
  formatDateToYYYYMMDD,
  formatTrangThaiBenhNhan,
} from "../../../utils/FormatUtils";
import WfStatusSummary from "../../../workflows/components/WfStatusSummary";
import { WfConstTracker } from "../../../workflows/Constant";
import { WfSummaryStatusRes } from "../../../workflows/models/WfWorkflows";
import { updateWfWorkflows } from "../../../workflows/services/WfWorkflowsService";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import ModalKhamKetHop from "../../modals/modal-kham-ket-hop/ModalKhamKetHop";
import { IBenhNhan } from "../../models/DSBenhNhanKhamBenhModels";
import {
  cancelMedicalExam,
  getDSKhamBenh,
  getDSTrangThai,
  getThongTinKhamBenh,
  inBangKeChiPhiKhamBenh,
  inGiayNghiOm,
  inPhieuChiDinhChuaThanhToan,
  inPhieuChuyenTuyen,
  startMedicalExam,
} from "../../services/PhanHeTiepDonServer";
import ModalMoLaiBenhAn from "../modal-mo-lai-benh-an/ModalMoLaiBenhAn";
import ButtonIn from "../../../component/button-in-phieu";
import ModalThongTinNghiOm from "../modal-tt-nghi-om/ModalThonTinNghiOm";
import { OctAutocomplete } from "@oceantech/oceantech-ui";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import ContextMenu from "../../../component/ContextMenuV3";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { speaker } from "../../../utils/Speaker";

const TableDsBenhNhanKhamBenh = (props: any) => {
  const {
    setBenhNhanInfo,
    benhNhanInfo,
    setThongTinChiPhi,
    statusChangedEventFunc,
  } = useContext(PhanHeTiepDonContext);
  const { filter, setFilter } = props;
  const { setIsLoading } = useContext(AppContext);
  const [selectedBenhNhan, setSelectedBenhNhan] = useState<any>();
  const [summaryStatus, setSummaryStatus] = useState<WfSummaryStatusRes[]>([]);
  const [shouldOpenKhamKetHop, setShouldOpenKhamKetHop] =
    useState<boolean>(false);
  const [shouldOpenMoLaiBenhAn, setShouldOpenMoLaiBenhAn] =
    useState<boolean>(false);
  const [shouldOpenTTNghiOm, setShouldOpenTTNghiOm] =
    useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(null);
  const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();
  const [rowSeclectedByContextMenu, setRowSeclectedByContextMenu] = useState<any>();
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);

  useEffect(() => {
    filter &&
      setSearchObject({
        keyword: filter?.keyword || null,
        filter: filter?.formal?.id || 0,
        sortDirection: filter?.order?.id || null,
        fromDate: formatDateToYYYYMMDD(filter?.period?.fromDate) || null,
        toDate: formatDateToYYYYMMDD(filter?.period?.toDate) || null,
        color: filter?.status?.color || null,
        fulfillDeptCode: filter?.fulfillDeptCode || null,
      });
  }, [filter]);

  const updateTrangThai = async (filter?: any) => {
    setIsLoading(true);
    const searchObject = SEARCH_OBJECT_MAX_SIZE;
    const DEFAULT_FORMAL = 0;
    try {
      let { data } = await getDSTrangThai({
        ...searchObject,
        keyword: filter?.keyword || null,
        filter: filter?.formal?.id || DEFAULT_FORMAL,
        sortDirection: filter?.order?.id || null,
        fromDate: formatDateToYYYYMMDD(filter?.period?.fromDate) || null,
        toDate: formatDateToYYYYMMDD(filter?.period?.toDate) || null,
        color: filter?.status?.color || null,
      });
      if (data?.code === CODE.SUCCESS) {
        let newListStatus = data?.data || [];
        setSummaryStatus(newListStatus);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    filter && updateTrangThai(filter);
  }, [props.statusChangedEvent, filter]);

  useEffect(() => {
    selectedBenhNhan && handleGetThongTinKhamBenh(selectedBenhNhan);
  }, [selectedBenhNhan, benhNhanInfo?.shouldUpdate]);

  useEffect(() => {
    handleGetDsDanhMuc();
  }, [])

  const handleGetThongTinKhamBenh = async (dataBenhNhan: any) => {
    try {
      let visitId = dataBenhNhan?.visitId;
      let orderId = dataBenhNhan?.orderId;

      setIsLoading(true);
      if (visitId && orderId) {
        let { data } = await getThongTinKhamBenh({
          visitId,
          orderId,
        });
        let { data: dataChiPhi } = await getPaymentDetail(visitId);
        if (CODE.SUCCESS === dataChiPhi?.code) {
          setThongTinChiPhi({
            ...dataChiPhi?.data,
            ttDiscount:
              dataChiPhi?.data?.ttDiscountInv +
              dataChiPhi?.data?.ttDiscountPercent,
          });
        }
        if (CODE.SUCCESS === data?.code) {
          setBenhNhanInfo({
            ...benhNhanInfo,
            isKhamBenh: false,
            isExamined: false,
            thongTinKhamBenh: {
              ...benhNhanInfo?.thongTinKhamBenh,
              ...data?.data,
              ...dataBenhNhan,
              isConclusion: data?.data?.isConclusion,
            },
          });
        }
      } else {
        setBenhNhanInfo({
          ...benhNhanInfo,
          isKhamBenh: false,
          isExamined: false,
          thongTinKhamBenh: {},
        });
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedBenhNhan = async (benhNhans: IBenhNhan[]) => {
    setSelectedBenhNhan(benhNhans[0]);
  };

  const handleBatDauKham = async (e: any, rowData: any) => {
    try {
      e.stopPropagation();
      await updateWfWorkflows(WfConstTracker.CLINICAL, {
        objectId: rowData?.orderId,
        status: rowData?.newStatusId,
      });
      let { data } = await startMedicalExam({
        patientId: rowData?.patientId,
        visitId: rowData?.visitId,
        orderId: rowData?.orderId,
      });
      setBenhNhanInfo({
        ...benhNhanInfo,
        thongTinKhamBenh: {
          ...benhNhanInfo?.thongTinKhamBenh,
          isExamined: data.data?.isExamined,
          encounter: data.data?.encounter,
          encounterDiagnosis: data.data?.encounterDiagnosis,
        },
      });
      statusChangedEventFunc?.();
      await handleSelectedBenhNhan([
        {
          ...rowData,
          isMedicalExamStarted: true,
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleHuyKham = async (e: any, rowData: any) => {
    try {
      e.stopPropagation();
      setIsLoading(true);
      const OLD_STATUS = 8; // Trạng thái chưa bắt đầu khám
      await updateWfWorkflows(WfConstTracker.CLINICAL, {
        objectId: rowData?.orderId,
        status: OLD_STATUS,
      });
      await cancelMedicalExam(rowData?.orderId);
      setBenhNhanInfo({
        ...benhNhanInfo,
        thongTinKhamBenh: {
          ...benhNhanInfo?.thongTinKhamBenh,
          isMedicalExamStarted: false,
        },
      });
      statusChangedEventFunc?.();
      await handleSelectedBenhNhan([
        {
          ...rowData,
          isMedicalExamStarted: false,
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const doSearch = (data: any) => {
    setFilter({
      ...filter,
      ...data,
    });
  };

  const handleSearchByStatus = (status: WfSummaryStatusRes) => {
    let dataFilter = { ...filter, status };
    setFilter(dataFilter);
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setDanhSachDanhMuc(responseDsDanhmuc);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoiBN = (data: any) => {
    console.log(data);
    let stt = data?.ordinalNumber;
    let name = data?.name;
    let diaDiem = data?.fulfillDeptName;
    if (stt && diaDiem) {
      let content = `Mời khách hàng, ${name} , có số thứ tự, ${stt}, tới ${diaDiem})`;
      speaker.handleSpeak(content);
    } else {
      toast.warning("Bệnh nhân không đủ thông tin");
    }
  };

  const handleContextMenu = (e: any, row: any) => {
    setRowSeclectedByContextMenu(row);
    setContextMenu({ x: e.clientX, y: e.clientY });
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.GOI_BN]: () => handleGoiBN(rowSeclectedByContextMenu),
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const CODE_MENU = {
    GOI_BN: "goiBn",
  };

  const dropListChiDinhDichVu: IContextMenu[] = [
    {
      title: "Khám bệnh",
    },
    {
      icon: <i className="bi bi-volume-up-fill text-pri"></i>,
      code: CODE_MENU.GOI_BN,
      name: "Gọi bệnh nhân",
    },
  ];

  const LIST_FORMAL = [
    {
      id: 4,
      value: "stt",
      name: "STT",
    },
    {
      id: 2,
      value: "hoTen",
      name: "Họ tên",
    },
    {
      id: 3,
      value: "tuoi",
      name: "Tuổi",
    },
    {
      id: 1,
      value: "maBenhNhan",
      name: "Mã BN",
    },
  ];

  const DROP_LIST_PHIEU_IN = [
    {
      id: 1,
      button: <ButtonIn
        label="Bảng kê chi phí khám bệnh"
        fetchExport={inBangKeChiPhiKhamBenh}
        params={{
          visitId: benhNhanInfo?.thongTinKhamBenh?.visitId,
        }}
        isOnlyText
      />
    },
    {
      id: 2,
      button: <ButtonIn
        label="Giấy nghỉ ốm"
        fetchExport={inGiayNghiOm}
        params={{
          orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
        }}
        isOnlyText
      />
    },
    {
      id: 3,
      button: <span
        onClick={() => setShouldOpenTTNghiOm(true)}
      >Thông tin nghỉ ốm</span>
    },
    {
      id: 4,
      button: <ButtonIn
        label="Phiếu chỉ định chưa thanh toán"
        fetchExport={inPhieuChiDinhChuaThanhToan}
        params={{
          orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
        }}
        isOnlyText
      />
    },
    {
      id: 5,
      button: <ButtonIn
        label="Giấy chuyển tuyến"
        fetchExport={inPhieuChuyenTuyen}
        params={{
          orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
        }}
        isOnlyText
      />
    },
  ];

  const columns = [
    {
      name: "TT",
      field: "",
      headerStyle: {
        maxWidth: "35px",
      },
      render: (rowData: any, index: number) => {
        const { combined, component, color } = rowData || {};
        const iconType = combined
          ? component
            ? "plus-circle"
            : "arrow-right-circle"
          : null;

        return iconType
          ? customTrangThaiBenhNhan(color, iconType)
          : formatTrangThaiBenhNhan(color);
      },
    },
    {
      name: "STT",
      field: "",
      headerStyle: {
        maxWidth: "40px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData: any, index: number) => <span className="text-system">{rowData?.ordinalNumber || ""}</span>,
    },
    {
      name: "Tên bệnh nhân",
      field: "",
      headerStyle: {
        minWidth: "175px",
      },
      render: (rowData: any, index: number) => {
        return (
          <div className="text-system ">
            <div className="flex m-0">
              <span className={`text-uppercase ${rowData?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU ? "color-error" : ""}`}>{rowData?.name || ""}</span>
              {rowData?.visitSortWeight === UU_TIEN.TRUE ? <span className="text-danger">&nbsp;(Ưu tiên)</span> : ""}
            </div>
            <div className="flex m-0">
              <span className="text-uppercase fw-semibold">
                {rowData?.code || ""}
              </span>
              <span className="px-1"> - </span>
              <span>{rowData?.objectTypeName || ""}</span>
              <span className="px-1"> - </span>
              <span>{rowData?.gender || ""}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "",
      field: "",
      headerStyle: {
        width: "70px",
      },
      render: (rowData: any, index: number) => {
        return (
          <div className="d-flex justify-content-center">
            <>
              {!rowData?.isMedicalExamStarted && (
                <CustomIconButton
                  variant="edit"
                  title="Bắt đầu khám"
                  onClick={(e) => handleBatDauKham(e, rowData)}
                >
                  <i className="bi bi-play-circle-fill text-navy"></i>
                </CustomIconButton>
              )}

              {rowData?.isAllowCancel && (
                <CustomIconButton
                  variant="edit"
                  title="Hủy khám"
                  onClick={(e) => handleHuyKham(e, rowData)}
                >
                  <i className="bi bi-x-square-fill text-danger"></i>
                </CustomIconButton>
              )}

              {rowData?.isAllowCombined && (
                <CustomIconButton
                  variant="delete"
                  title="Khám kết hợp"
                  onClick={() => {
                    handleSelectedBenhNhan([rowData]);
                    setShouldOpenKhamKetHop(true);
                  }}
                >
                  <i className="bi bi-plus-square-fill text-success"></i>
                </CustomIconButton>
              )}

              {rowData?.isConclusion && (
                <CustomIconButton
                  variant="delete"
                  title="Mở lại bệnh án"
                  onClick={() => {
                    setShouldOpenMoLaiBenhAn(true);
                  }}
                >
                  <i className="bi bi-reply-fill text-muted"></i>
                </CustomIconButton>
              )}
            </>
          </div>
        );
      },
    },
  ];
  
  return (
    <>
      <FilterSidebar
        title="Danh sách bệnh nhân"
        formalList={LIST_FORMAL}
        onSearch={doSearch}
        listMenuItem={[]}
        handleSelectOption={() => {}}
        listPhieuIn={DROP_LIST_PHIEU_IN}
        disableInPhieuBtn={!benhNhanInfo?.thongTinKhamBenh?.visitId}
        isShowInPhieuBtn
        // handleGoiBenhNhan={() => handleGoiBN()}
        isShowMenuItem={false}
      />
      <div className="d-flex flex-column justify-content-between h-100 pt-3">
        <div className="spaces h-calc-vh-300">
          <LazyLoadTable
            columns={columns}
            axiosReq={getDSKhamBenh}
            searchData={searchObject}
            urlData="data.data.medicalExamBrief"
            className="spaces h-calc-vh-300"
            onRowClick={(rowData) => handleSelectedBenhNhan([rowData])}
            handleContextMenu={handleContextMenu}
            dependencies={[props.statusChangedEvent]}
            rowSeclected={selectedBenhNhan}
            rowKey="orderId"
          />
        </div>
        <div className="spaces px-12">
          <OctAutocomplete
            options={danhSachDanhMuc?.listPhongKham || []}
            placeholder="Tất cả khoa phòng"
            menuPlacement="top"
            searchObject={{ pageSize: MAX_SIZE }}
            onChange={(option) => {
              setFilter({
                ...filter,
                fulfillDeptCode: option?.code,
              });
            }}
          />
        </div>
        <WfStatusSummary
          summaryStatus={summaryStatus}
          onClick={handleSearchByStatus}
        />

        {shouldOpenKhamKetHop && (
          <ModalKhamKetHop
            open={shouldOpenKhamKetHop}
            handleClose={() => {
              setShouldOpenKhamKetHop(false);
            }}
            patientSelectedRow={selectedBenhNhan}
          />
        )}
        {shouldOpenMoLaiBenhAn && (
          <ModalMoLaiBenhAn
            open={shouldOpenMoLaiBenhAn}
            handleClose={() => {
              setShouldOpenMoLaiBenhAn(false);
            }}
          />
        )}
        {shouldOpenTTNghiOm && (
          <ModalThongTinNghiOm
            handleCloseModal={() => {
              setShouldOpenTTNghiOm(false);
            }}
          />
        )}
        {contextMenu && (
          <ContextMenu
            handleClickOptionContextMenu={handleClickOptionContextMenu}
            handleCloseMenu={() => setContextMenu(null)}
            data={dropListChiDinhDichVu}
            target={contextMenu}
          />
        )}
      </div>
    </>
  );
};

export default TableDsBenhNhanKhamBenh;
