import { Column } from 'react-table';
import { TableCustomHeader } from '../../../../../component/table/components/TableCustomHeader';
import { KetQuaDichVu } from '../../../../models/ThongTinKhamBenhModel';
import { formatDateTime } from '../../../../../utils/FormatUtils';
import IndeterminateCheckbox from './IndeterminateCheckbox';

export const KetQuaDichVuColumns: Column<KetQuaDichVu>[] = [
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={<span className="text-white">Tên dịch vụ</span>}
				className="p-table text-center min-w-250px"
			/>
		),
		accessor: 'tenDichVu',
		Cell: ({ row, cell }) => (
			<div
				style={{
					paddingLeft: row.depth * 25,
				}}
			>
				{row.canExpand ? (
					<div className="d-flex align-items-center">
						<span className="spaces pe-8 pr-8" {...row.getToggleRowExpandedProps()}>
							{row.isExpanded ? (
								<i className="las la-angle-down"></i>
							) : (
								<i className="las la-angle-right"></i>
							)}
						</span>
						<IndeterminateCheckbox
							{...row.getToggleRowSelectedProps()}
							className="d-flex me-3"
						/>
						<span>{cell.value}</span>
					</div>
				) : (
					<div className="d-flex align-items-center spaces pl-21">
						<IndeterminateCheckbox
							{...row.getToggleRowSelectedProps()}
							className="d-flex me-3"
						/>
						<span>{cell.value}</span>
					</div>
				)}
			</div>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={<span className="text-white">Kết quả</span>}
				className="p-table text-center min-w-150px"
			/>
		),
		accessor: 'ketQua',
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={<span className="text-white">Khoa chỉ định</span>}
				className="p-table text-center min-w-150px"
			/>
		),
		accessor: 'khoaChiDinh',
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={<span className="text-white">Ngày y lệnh</span>}
				className="p-table text-center min-w-150px"
			/>
		),
		accessor: 'ngayYLenh',
		Cell: ({ value }) => {
			return (
				<div className="text-center">
					<span>{formatDateTime(value || '')}</span>
				</div>
			);
		},
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={<span className="text-white">Ngày trả kết quả</span>}
				className="p-table text-center min-w-150px"
			/>
		),
		accessor: 'ngayTraKetQua',
		Cell: ({ value }) => (
			<div className="text-center">
				<span>{formatDateTime(value || '')}</span>
			</div>
		),
	},
];
