import moment from "moment";
import React, { FC, useEffect, useState, useContext, useRef } from "react";
import { Button, Col, FormCheck, Row } from "react-bootstrap";
import AutocompleteObjectV2 from "../../../component/AutocompleteObjectV2";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import ModalTimKiemBenhNhan from "../../../component/modal-tim-kiem-benh-nhan/ModalTimKiemBenhNhan";
import {
  KEY,
  VARIABLE_STRING,
  KEY_DS_DANH_MUC_TIEP_DON,
  CODE,
  ID_DANG_KY_KHAM,
  UU_TIEN,
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
  TERM_CLASS_IDS,
} from "../../../utils/Constant";
import { CODE_DOI_TUONG, GIOI_TINH } from "../../constants/PhanHeTiepNhan";
import {
  CODE_GIOI_TINH,
  DEFAULT_CODE_SELECT_TIEPDON,
  KEY_SEARCH,
} from "../../constants/constants";
import { getBenhNhan, getListNguoiGioiThieu, getPatientId } from "../../services/TiepNhanServices";
import { benhNhanProps } from "../../tab-tiep-nhan/TiepNhan";
import ModalNhapTheBH from "./ModalNhapTheBH";
import { IDanhSachDanhMuc, QRCccdValue } from "../../models/TiepDonModel";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { calculateAge, formatDateTime } from "../../../utils/FormatUtils";
import { PhanHeTiepNhanContext } from "../../PhanHeTiepNhan";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import ScanQRCccdButton from "./ScanQRCccdButton";
import { getTermClasses } from "../../../utils/ServicesUtils";
import { getCategory } from "../../../utils/AppFunction";

const TTHanhChinhV2: FC<benhNhanProps> = React.memo(
  ({ values, errors, touched, setFieldValue, handleChecked, setValues }) => {
    const {
      convertDataBenhNhan,
      setOrderMain,
      thongTinBenhNhan,
      setThongTinBenhNhan,
    } = useContext<any>(PhanHeTiepNhanContext);
    const [isBHYT, setIsBHYT] = useState<boolean>(
      CODE_DOI_TUONG.BAO_HIEM === values?.visit?.visitObjectType?.code
    );
    const [objectSearch, setObjectSearch] = useState({
      phoneNumber: "",
      personName: "",
      soTheKCB: "",
      idNumber: "",
    });
    const regexNumber = /^[0-9]*$/;
    const [shouldOpenNhapTheBHDialog, setShouldOpenNhapTheBHDialog] =
      useState(false);
    const [openTimKiemBNDialog, setOpenTimKiemBNDialog] = useState(false);
    const [districts, setDistricts] = useState<any[]>([]);
    const [subDistricts, setSubDistricts] = useState<any[]>([]);
    const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();
    const [listNguoiGioiThieu, setListNguoiGioiThieu] = useState<any>([]);
    const [patientId, setPatientId] = useState<string>();
    const { setIsLoading } = useContext(AppContext);
    const [patienAge, setPatienAge] = useState<any>(null);
    const [danhMucDichVuKhamBenh, setDanhMucDichVuKhamBenh] = useState<any[]>([]);

    const monthRef = useRef<HTMLInputElement>(null);
    const yearRef = useRef<HTMLInputElement>(null);
    const occupationRef = useRef<any>(null);

    let patientValues = values?.patient,
      patientErrors = errors?.patient,
      patientTouched = touched?.patient;
    let visitValues = values?.visit,
      visitErrors = errors?.visit,
      visitTouched = touched?.visit;
    let personValues = patientValues?.person,
      personErrors = patientErrors?.person,
      personTouched = patientTouched?.person;
    let insuranceValues = values?.insurance;
    const isVisitStopped = visitValues?.stopped;

    const handleGetListNguoiGioiThieu = async () => {
      let searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        keyword: "",
      };
      try {
        let { data } = await getListNguoiGioiThieu(searchObject);
        if (data?.code === CODE.SUCCESS) {
          const transformedList = data?.data?.content.map((referrer: any)  => ({
            id: referrer.termModelId,
            code: referrer.introducerCode,
            name: referrer.introducerName
          }));
          setListNguoiGioiThieu(transformedList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleGetDsDanhMuc = async () => {
      try {
        const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
          KEY_DS_DANH_MUC_TIEP_DON
        );
        const dichVuKhamBenh = await getCategory(TERM_CLASS_IDS.KHAM_BENH);
        setDanhMucDichVuKhamBenh(dichVuKhamBenh);

        handleSetDefaultData(responseDsDanhmuc);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      let age = calculateAge(
        personValues?.dobDay,
        personValues?.dobMonth,
        personValues?.dobYear
      );
      setPatienAge(age);
    }, [personValues?.dobDay, personValues?.dobMonth, personValues?.dobYear]);

    useEffect(() => {
      handleGetDsDanhMuc();
      handleGetListNguoiGioiThieu()
    }, []);

    useEffect(() => {
      setDistricts(
        danhSachDanhMuc?.districts?.filter(
          (item: any) => item?.provinceId === patientValues?.province?.id
        ) || []
      );
    }, [patientValues?.province]);

    useEffect(() => {
      setSubDistricts(
        danhSachDanhMuc?.subDistricts?.filter(
          (item: any) => item?.districtId === patientValues?.district?.id
        ) || []
      );
    }, [patientValues?.district]);

    useEffect(() => {
      setIsBHYT(CODE_DOI_TUONG.BAO_HIEM === visitValues?.visitObjectType?.code);
    }, [visitValues?.visitObjectType]);

    useEffect(() => {
      if (patientId) {
        handleUpdateDataBenhNhan(patientId);
      }
    }, [patientId]);

    const handleSetDefaultData = async (data: IDanhSachDanhMuc) => {
      setDanhSachDanhMuc(data);      

      let listDKKham = data?.listDKKham;
      let listDanToc = data?.listDanToc;
      let listQuocTich = data?.listQuocTich;
      let listNgheNghiep = data?.listNgheNghiep;
      let listGioiTinh = data?.listGioiTinh;

      setFieldValue(VARIABLE_STRING.VISIT, {
        ...visitValues,
        visitType: listDKKham?.find(
          (item: any) => item?.id === ID_DANG_KY_KHAM.KHAM_BENH
        ),
        [VARIABLE_STRING.DOI_TUONG]:
          data?.listDoiTuong?.find(
            (item) => item?.code === CODE_DOI_TUONG.YEU_CAU
          ) || null,
      });
      setFieldValue(VARIABLE_STRING.PATIENT, {
        ...patientValues,
        person: {
          ...personValues,
          ccountry: listQuocTich?.find(
            (item: any) =>
              item?.code === DEFAULT_CODE_SELECT_TIEPDON.QUOC_TICH_VN
          ),
          gender: listGioiTinh?.find(
            (item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.GIOI_TINH
          ),
        },
        ethnicity: listDanToc?.find(
          (item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.DAN_TOC_KINH
        ),
        // occupation: listNgheNghiep?.find(
        //   (item: any) =>
        //     item?.code === DEFAULT_CODE_SELECT_TIEPDON.NGHE_NGHIEP_NHAN_DAN
        // ),
      });
    };

    const handleChangeTextSearch = (e: any) => {
      const { name, value } = e.target;
      if (name === KEY_SEARCH.SDT || name === KEY_SEARCH.CCCD) {
        if (regexNumber.test(value) || value === "") {
          setObjectSearch({ ...objectSearch, [name]: value });
        }
      } else {
        setObjectSearch({ ...objectSearch, [name]: value });
      }
    };

    const handleChangePatient = (name: string, value: any) => {
      switch (name) {
        case VARIABLE_STRING.TINH_HUYEN_XA:
          let subDistrict =
            danhSachDanhMuc?.subDistricts?.find(
              (item: any) => item?.shortName === value?.toUpperCase()
            ) || null;
          if (subDistrict) {
            let district =
              danhSachDanhMuc?.districts?.find(
                (item: any) => item?.id === subDistrict?.districtId
              ) || [];
            let province =
              danhSachDanhMuc?.provinces?.find(
                (item: any) => item?.id === district?.provinceId
              ) || [];
            setFieldValue(VARIABLE_STRING.PATIENT, {
              ...patientValues,
              [name]: value,
              [VARIABLE_STRING.PROVINCE]: province,
              [VARIABLE_STRING.DISTRICT]: district,
              [VARIABLE_STRING.COMMUNE]: subDistrict,
            });
            occupationRef.current?.focus();
          } else {
            setFieldValue(VARIABLE_STRING.PATIENT, {
              ...patientValues,
              [name]: value,
            });
          }
          break;
        case VARIABLE_STRING.PROVINCE:
          setFieldValue(VARIABLE_STRING.PATIENT, {
            ...patientValues,
            [VARIABLE_STRING.PROVINCE]: value,
            [VARIABLE_STRING.DISTRICT]: null,
            [VARIABLE_STRING.COMMUNE]: null,
          });
          break;
        case VARIABLE_STRING.DISTRICT:
          setFieldValue(VARIABLE_STRING.PATIENT, {
            ...patientValues,
            [VARIABLE_STRING.DISTRICT]: value,
            [VARIABLE_STRING.COMMUNE]: null,
          });
          break;
        case VARIABLE_STRING.COMMUNE:
          setFieldValue(VARIABLE_STRING.PATIENT, {
            ...patientValues,
            [name]: value,
            [VARIABLE_STRING.TINH_HUYEN_XA]: value?.shortName,
          });
          break;
        default:
          setFieldValue(VARIABLE_STRING.PATIENT, {
            ...patientValues,
            [name]: value,
          });
          break;
      }
    };

    const handleChangeVisit = (name: string, value: any) => {
      if (name === "service") {
        let deptObj = danhSachDanhMuc?.listPhongKham.find(
          (item: any) =>
            item?.code === value?.attributes?.deptClinicCode ||
            item?.code === value?.attributes?.deptLaboratoryCode
        );
        setFieldValue(VARIABLE_STRING.VISIT, {
          ...visitValues,
          [name]: value,
          dept: deptObj,
        });
      } else {
        setFieldValue(VARIABLE_STRING.VISIT, {
          ...visitValues,
          [name]: value,
        });
      }
    };

    const handleChangeVisitAttribute = (name: string, value: any) => {
      setFieldValue(VARIABLE_STRING.VISIT, {
        ...visitValues,
        attributes: {
          ...visitValues?.attributes,
          [name]: value,
        },
      });
    };

    const handleChangePersonInfo = (name: string, value: any) => {
      setFieldValue(VARIABLE_STRING.PATIENT, {
        ...patientValues,
        person: {
          ...personValues,
          [name]: value,
        },
      });
    };

    const handleChangeName = (name: string, value: any) => {
      const NAME_OF_MENS = "văn";
      const NAME_OF_WOMENS = "thị";
      let listText = value.trim().toLowerCase().split(/\s+/);
      let isMale = listText.includes(NAME_OF_MENS);
      let isFemale = listText.includes(NAME_OF_WOMENS);
      let gender = danhSachDanhMuc?.listGioiTinh?.find((item: any) => {
        if (isFemale) {
          return item?.code === CODE_GIOI_TINH.NU;
        }
        if (isMale) {
          return item?.code === CODE_GIOI_TINH.NAM;
        }
        return item?.code === DEFAULT_CODE_SELECT_TIEPDON.GIOI_TINH;
      });
      setFieldValue(VARIABLE_STRING.PATIENT, {
        ...patientValues,
        person: {
          ...personValues,
          ...(gender && { gender }),
          [name]: value.toUpperCase(),
        },
      });
    };

    const handleChangeInputDate = (name: string, value: any) => {
      const MAX_LENGHT_DAY = 2;
      const MAX_LENGHT_MONTH = 2;
      switch (name) {
        case "dobDay": {
          value?.length === MAX_LENGHT_DAY && monthRef.current?.focus();
          break;
        }
        case "dobMonth": {
          value?.length === MAX_LENGHT_MONTH && yearRef.current?.focus();
          break;
        }
        default:
          break;
      }
      handleChangePersonInfo(name, value);
    };

    const handleChangeInsurance = (name: string, value: any) => {
      setFieldValue(VARIABLE_STRING.INSURANCE, {
        ...insuranceValues,
        [name]: value,
      });
    };

    const handleSearchDsBenhNhan = (e: any) => {
      if (e.key === KEY.ENTER) {
        setPatientId("");
        setOpenTimKiemBNDialog(true);
      }
    };

    const handleSearchBenhNhan = async (e: any) => {
      const { name, value } = e.target;
      if (e.key === KEY.ENTER && value?.trim()) {
        try {
          setIsLoading(true);
          let { data } = await getBenhNhan({
            [name]: value,
          });
          if (CODE.SUCCESS === data?.code) {
            setPatientSearchData(data);
          } else {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
          }
        } catch (error) {
          toast.warning("Xảy ra lỗi, vui lòng thử lại!");
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const handleCloseModalTKBenhNhan = () => {
      setOpenTimKiemBNDialog(false);
      setObjectSearch({
        phoneNumber: "",
        personName: "",
        soTheKCB: "",
        idNumber: "",
      });
    };

    const handleUpdateDataBenhNhan = async (id: string) => {
      try {
        setIsLoading(true);
        const { data } = await getPatientId(id);
        if (CODE.SUCCESS === data?.code) {
          let dataConverted = convertDataBenhNhan({
            patient: data?.data || {},
            visit: {
              [VARIABLE_STRING.DOI_TUONG]:
                danhSachDanhMuc?.listDoiTuong?.find(
                  (item) => item?.code === CODE_DOI_TUONG.YEU_CAU
                ) || null,
            },
          });
          setValues?.(dataConverted);
          setThongTinBenhNhan({
            ...thongTinBenhNhan,
            isExamined: false,
            isMedicalExamSvcPaid: false,
          });
        } else {
          toast.warning("Xảy ra lỗi, vui lòng thử lại!");
        }
      } catch (error) {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const setPatientSearchData = (data: any) => {
      let dataConverted = convertDataBenhNhan({
        patient: data?.data || {},
        visit: {
          [VARIABLE_STRING.DOI_TUONG]:
            danhSachDanhMuc?.listDoiTuong?.find(
              (item) => item?.code === CODE_DOI_TUONG.YEU_CAU
            ) || null,
        },
      });
      setOrderMain([]);
      setValues?.(dataConverted);
      setThongTinBenhNhan({
        ...thongTinBenhNhan,
        isExamined: false,
        isMedicalExamSvcPaid: false,
      });
    };

    const onReadCccdQRData = async (value: QRCccdValue) => {
      try {
        const { data } = await getBenhNhan({
          idNumber: value.soCccd,
        });

        const isPatientExist = CODE.SUCCESS === data.code && data.data;

        if (isPatientExist) {
          setPatientSearchData(data);
          return;
        }

        setFieldValue(VARIABLE_STRING.PATIENT, {
          ...patientValues,
          person: {
            ...personValues,
            fullName: value.hoTen,
            dobDay: value.ngaySinh,
            dobMonth: value.thangSinh,
            dobYear: value.namSinh,
            idNumber: value.soCccd,
            gender: value.genderOption,
          },
          province: value?.provinceOption,
          district: value?.districtOption,
          commune: value?.wardOption,
          address: value?.town,
          administrativeUnit: value?.wardOption?.shortName,
        });
      } catch (error) {
        console.error(error);
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    };

    return (
      <div className="d-flex">
        <div className="border border-left-0 border-top-0 py-2 spaces width-72 pe-2">
          <div className="pb-2">
            <div className="d-flex align-items-end">
              <div className="spaces width-25 position-relative">
                <TextField
                  label="Điện thoại"
                  name="phoneNumber"
                  labelClassName="min-w-90px color-blue-custom"
                  className=" min-w-130px ps-29px"
                  value={objectSearch?.phoneNumber}
                  onChange={handleChangeTextSearch}
                  onKeyDown={(e: any) => handleSearchDsBenhNhan(e)}
                />
                <div className="spaces width-4">
                  <h6 className="position-absolute muc-huong">
                    <i className="bi bi-search fs-4 color-blue-custom d-block spaces mt-5"></i>
                  </h6>
                </div>
              </div>

              <div className="spaces width-25 position-relative">
                <TextField
                  label="Họ và tên"
                  name="personName"
                  labelClassName="ps-2 min-w-90px color-blue-custom"
                  className=" ps-29px"
                  value={objectSearch?.personName}
                  onChange={handleChangeTextSearch}
                  onKeyDown={(e: any) => handleSearchDsBenhNhan(e)}
                />
                <div className="spaces width-4">
                  <h6 className="position-absolute muc-huong">
                    <i className="bi bi-search fs-4 color-blue-custom d-block spaces mt-5"></i>
                  </h6>
                </div>
              </div>

              <div className="spaces width-25 position-relative">
                <TextField
                  label="Căn cước"
                  name="idNumber"
                  labelClassName="ps-2 min-w-90px color-blue-custom"
                  className=" ps-29px"
                  value={objectSearch?.idNumber}
                  onChange={handleChangeTextSearch}
                  onKeyDown={(e: any) => handleSearchDsBenhNhan(e)}
                />
                <div className="spaces width-4">
                  <h6 className="position-absolute muc-huong">
                    <i className="bi bi-search fs-4 color-blue-custom d-block spaces mt-5"></i>
                  </h6>
                </div>
              </div>

              <div className="spaces width-25 d-flex flex-end">
                <FormCheck
                  type="checkbox"
                  label="Ưu tiên"
                  name=""
                  checked={
                    visitValues?.sortWeight === UU_TIEN.TRUE ? true : false
                  }
                  onChange={(e) =>
                    handleChangeVisit(
                      "sortWeight",
                      e.target.checked ? UU_TIEN.TRUE : UU_TIEN.FALSE
                    )
                  }
                  className="d-flex align-items-center me-2"
                  disabled={isVisitStopped}
                />
              </div>
            </div>
          </div>
          <Row className="mx-0">
            <Col xs="4" className="d-flex px-0 mb-2 ">
              <LabelRequired className="min-w-90px" label="Đối tượng" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listDoiTuong || []}
                value={visitValues?.visitObjectType || null}
                name="visitObjectType"
                onChange={(selectedOption) =>
                  handleChangeVisit("visitObjectType", selectedOption)
                }
                touched={visitTouched?.visitObjectType}
                errors={visitErrors?.visitObjectType}
                isClearable={false}
                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                isDisabled={isVisitStopped}
              />
            </Col>

            <Col xs="4" className="px-0 mb-2">
              <TextField
                label={
                  <div>
                    Họ và tên
                    <span className="text-danger"> (*)</span>
                  </div>
                }
                name="fullName"
                labelClassName="ps-2 min-w-90px"
                value={personValues?.fullName || ""}
                onChange={(e: any) =>
                  handleChangeName(e.target.name, e.target.value)
                }
                touched={personTouched?.fullName}
                errors={personErrors?.fullName}
                disabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2">
              <TextField
                label="Điện thoại"
                name="phoneNumber"
                type="number"
                labelClassName="ps-2 min-w-90px"
                value={personValues?.phoneNumber || ""}
                onChange={(e: any) =>
                  handleChangePersonInfo(e.target.name, e.target.value)
                }
                touched={personTouched?.phoneNumber}
                errors={personErrors?.phoneNumber}
                disabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2">
              <TextField
                className="min-w-130px"
                label="Mã BN"
                name="code"
                labelClassName="ms-0 min-w-90px"
                value={patientValues?.code || ""}
                onKeyDown={(e: any) => handleSearchBenhNhan(e)}
                onChange={(e: any) =>
                  handleChangePatient(e.target.name, e.target.value)
                }
                touched={patientTouched?.code}
                errors={patientErrors?.code}
                disabled={
                  isVisitStopped ||
                  thongTinBenhNhan?.isExamined ||
                  patientValues?.id
                }
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired
                label="Ngày sinh"
                isRequired
                className="ps-2 min-w-90px"
              />
              <div className="spaces ms-0 d-flex min-w-130px">
                <div className="spaces width-20">
                  <TextField
                    name="dobDay"
                    type="text"
                    maxLength="2"
                    className="text-center"
                    value={personValues?.dobDay || ""}
                    onChange={(e: any) =>
                      handleChangeInputDate(e.target.name, e.target.value)
                    }
                    touched={personTouched?.dobDay}
                    errors={personErrors?.dobDay}
                    disabled={isVisitStopped}
                  />
                </div>
                <div className="spaces width-20">
                  <TextField
                    ref={monthRef}
                    name="dobMonth"
                    type="text"
                    maxLength="2"
                    className="text-center"
                    value={personValues?.dobMonth || ""}
                    onChange={(e: any) =>
                      handleChangeInputDate(e.target.name, e.target.value)
                    }
                    touched={personTouched?.dobMonth}
                    errors={personErrors?.dobMonth}
                    disabled={isVisitStopped}
                  />
                </div>
                <div className="spaces width-30">
                  <TextField
                    ref={yearRef}
                    name="dobYear"
                    type="text"
                    maxLength="4"
                    className="text-center"
                    value={personValues?.dobYear || ""}
                    onChange={(e: any) =>
                      handleChangePersonInfo(e.target.name, e.target.value)
                    }
                    touched={personTouched?.dobYear}
                    errors={personErrors?.dobYear}
                    disabled={isVisitStopped}
                  />
                </div>
                <div className="spaces width-5 d-flex flex-center fw-bold">
                  -
                </div>
                <div className="spaces width-25">
                  <TextField
                    name="dobYear"
                    type="text"
                    maxLength="4"
                    className="text-center"
                    disabled
                    value={patienAge ? patienAge : ""}
                  />
                </div>
              </div>
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <TextField
                className="min-w-130px no-spinners w-100"
                label="Căn cước"
                name="idNumber"
                labelClassName="ps-2 min-w-90px"
                value={personValues?.idNumber || ""}
                onKeyDown={(e: any) => handleSearchBenhNhan(e)}
                onChange={(e: any) =>
                  handleChangePersonInfo(
                    e.target.name,
                    e.target.value.toUpperCase()
                  )
                }
                touched={personTouched?.idNumber}
                errors={personErrors?.idNumber}
                disabled={isVisitStopped}
              />
              <ScanQRCccdButton onReadData={onReadCccdQRData} />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired label="Giới tính" className="min-w-90px" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listGioiTinh || []}
                value={personValues?.gender}
                name="gender"
                onChange={(selectedOption) =>
                  handleChangePersonInfo("gender", selectedOption)
                }
                touched={personTouched?.gender}
                errors={personErrors?.gender}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired label="Quốc tịch" className="ps-2 min-w-90px" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listQuocTich || []}
                name="ccountry"
                value={personValues?.ccountry}
                valueField="code"
                onChange={(selectedOption) => {
                  handleChangePersonInfo("ccountry", selectedOption);
                }}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                // getOptionLabel={(option) => `${option.code} - ${option.name}`}
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired label="Dân tộc" className="ps-2 min-w-90px" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listDanToc || []}
                value={patientValues?.ethnicity}
                name="ethnicity"
                onChange={(selectedOption) =>
                  handleChangePatient("ethnicity", selectedOption)
                }
                touched={patientTouched?.ethnicity}
                errors={patientErrors?.ethnicity}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                // getOptionLabel={(option) => `${option.name}`}
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2">
              <TextField
                className=" min-w-130px"
                label="SN/thôn/phố"
                name="address"
                value={patientValues?.address || ""}
                labelClassName="min-w-90px"
                onChange={(e: any) =>
                  handleChangePatient(e.target.name, e.target.value)
                }
                disabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2">
              <TextField
                className=" min-w-130px text-center"
                label="Tt/Hq/Xp"
                name="administrativeUnit"
                labelClassName="ps-2 min-w-90px"
                value={patientValues?.administrativeUnit || ""}
                onChange={(e: any) =>
                  handleChangePatient(e.target.name, e.target.value)
                }
                disabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired
                label="T/TP"
                // isRequired
                className="ps-2 min-w-90px"
              />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.provinces || []}
                value={patientValues?.province}
                name="province"
                onChange={(selectedOption) => {
                  handleChangePatient("province", selectedOption);
                }}
                touched={patientTouched?.province}
                errors={patientErrors?.province}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                // getOptionLabel={(option) => `${option.code} - ${option.name}`}
                placeholder="Tỉnh/Thành phố"
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired label="H/Q" className="min-w-90px" />
              <AutocompleteObjectV2
                options={patientValues?.province ? districts : []}
                value={patientValues?.district}
                name="district"
                onChange={(selectedOption) => {
                  handleChangePatient("district", selectedOption);
                }}
                touched={patientTouched?.district}
                errors={patientErrors?.district}
                dependencies={[patientValues?.province]}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                // getOptionLabel={(option) => `${option.code} - ${option.name}`}
                placeholder="Huyện/Quận"
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired label="X/P" className="ps-2 min-w-90px" />
              <AutocompleteObjectV2
                options={patientValues?.district ? subDistricts : []}
                value={patientValues?.commune}
                name="commune"
                onChange={(selectedOption) => {
                  handleChangePatient("commune", selectedOption);
                }}
                touched={patientTouched?.commune}
                errors={patientErrors?.commune}
                dependencies={[
                  patientValues?.province,
                  patientValues?.district,
                ]}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                // getOptionLabel={(option) => `${option.code} - ${option.name}`}
                placeholder="Xã/Phường"
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired className="ps-2 min-w-90px" label="Nghề nghiệp" />
              <AutocompleteObjectV2
                ref={occupationRef}
                options={danhSachDanhMuc?.listNgheNghiep || []}
                value={patientValues?.occupation}
                name="occupation"
                valueField="id"
                onChange={(selectedOption) => {
                  handleChangePatient("occupation", selectedOption);
                }}
                touched={patientTouched?.occupation}
                errors={patientErrors?.occupation}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired className="min-w-90px" label="ĐK khám" />
              <AutocompleteObjectV2
                // searchFunction={getListDangKyKham}
                // searchObject={{}}
                options={danhSachDanhMuc?.listDKKham || []}
                value={visitValues?.visitType}
                name="visitType"
                onChange={(selectedOption) =>
                  handleChangeVisit("visitType", selectedOption)
                }
                touched={visitTouched?.visitType}
                errors={visitErrors?.visitType}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={
                  isVisitStopped ||
                  thongTinBenhNhan?.isExamined ||
                  thongTinBenhNhan?.isMedicalExamSvcPaid
                }
              />
            </Col>
            <Col xs="4" className="px-0 mb-2">
              <TextField
                className=" min-w-130px"
                label="Lúc"
                name="appointmentDate"
                labelClassName="ps-2 min-w-90px"
                value={formatDateTime(visitValues?.dateStarted ?? new Date())}
                disabled
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <TextField
                label="Lý do khám"
                name="visitReason"
                labelClassName="ps-2 min-w-90px"
                className="w-100"
                value={visitValues?.visitReason || ""}
                onChange={(e: any) =>
                  handleChangeVisit(e.target.name, e.target.value)
                }
                touched={visitTouched?.visitReason}
                errors={visitErrors?.visitReason}
                disabled={isVisitStopped}
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired
                className=" min-w-90px"
                label="Dịch vụ"
                isRequired
              />
              <AutocompleteObjectV2
                options={danhMucDichVuKhamBenh}
                value={visitValues?.service || null}
                name="service"
                onChange={(selectedOption) => {
                  handleChangeVisit("service", selectedOption);
                }}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                touched={visitTouched?.service}
                errors={visitErrors?.service}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={
                  isVisitStopped ||
                  thongTinBenhNhan?.isExamined ||
                  thongTinBenhNhan?.isMedicalExamSvcPaid
                }
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired
                className="ps-2 min-w-125px"
                label="Phòng khám"
                isRequired
              />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listPhongKham || []}
                value={visitValues?.dept || null}
                name="dept"
                onChange={(selectedOption) => {
                  handleChangeVisit("dept", selectedOption);
                }}
                // getOptionLabel={(option) => `${option.code} - ${option.name}`}
                touched={visitTouched?.dept}
                errors={visitErrors?.dept}
                // searchFunction={getDanhSachYeuCauKham}
                // searchObject={{}}
                urlData={"data.data"}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={
                  isVisitStopped ||
                  thongTinBenhNhan?.isExamined ||
                  thongTinBenhNhan?.isMedicalExamSvcPaid
                }
              />
            </Col>
            <Col xs="4" className="px-0 mb-2 d-flex">
              <LabelRequired
                className="ps-2 min-w-165px"
                label="Nguồn/ Người giới thiệu"
              />
              <AutocompleteObjectV2
                options={listNguoiGioiThieu || []}
                value={patientValues?.referrer || null}
                name="referrer"
                onChange={(selectedOption) => {
                  handleChangePatient("referrer", selectedOption);
                }}
                getOptionLabel={(option) => `${option.name}`}
                touched={visitTouched?.referrer}
                errors={visitErrors?.referrer}
                urlData={"data.data"}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                isDisabled={isVisitStopped}
              />
            </Col>
          </Row>
        </div>

        <div className="border border-right-0 border-top-0 py-2 spaces width-28">
          <div className="d-flex mt-2 ps-2">
            <div className="w-100 spaces d-flex justify-content-between">
              <FormCheck
                type="checkbox"
                label="TE có mã BHXH"
                name="BHYT.TECoMaBHXH"
                onChange={(e) => handleChecked?.(e)}
                className="min-w-125px d-flex align-items-center gap-2"
              />

              <Button className="bg-blue spaces h-24 flex-center">
                <i className="bi bi-upc-scan d-block spaces pl-3"></i>
              </Button>
            </div>
          </div>

          <div className="d-flex mb-2 mt-1">
            <div className="spaces width-100 d-flex">
              <LabelRequired label="Số BHYT" className="ps-2 min-w-80px" />
              <div className="spaces ms-0 d-flex">
                <div className="spaces width-25">
                  <TextField
                    name="insCodeDoiTuong"
                    type="text"
                    maxLength="2"
                    disabled={!isBHYT}
                    className="text-center"
                    value={insuranceValues?.insCodeDoiTuong || ""}
                    onChange={(e: any) => {
                      handleChangeInsurance(e?.target?.name, e?.target?.value);
                    }}
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    name="insCodeMucHuong"
                    type="text"
                    maxLength="1"
                    disabled={!isBHYT}
                    className="text-center"
                    value={insuranceValues?.insCodeMucHuong || ""}
                    onChange={(e: any) => {
                      handleChangeInsurance(e?.target?.name, e?.target?.value);
                    }}
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    name="insCodeNoiPhatHanh"
                    type="text"
                    maxLength="2"
                    disabled={!isBHYT}
                    className="text-center"
                    value={insuranceValues?.insCodeNoiPhatHanh || ""}
                    onChange={(e: any) => {
                      handleChangeInsurance(e?.target?.name, e?.target?.value);
                    }}
                  />
                </div>
                <div className="spaces width-40">
                  <TextField
                    name="insCodeMaBhxh"
                    type="text"
                    maxLength="10"
                    disabled={!isBHYT}
                    className="text-center"
                    value={insuranceValues?.insCodeMaBhxh || ""}
                    onChange={(e: any) => {
                      handleChangeInsurance(e?.target?.name, e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex mb-2">
            <LabelRequired label="KCB BĐ" className="ps-2 min-w-80px" />
            <div className="spaces width-50">
              <TextField
                type="text"
                labelClassName="ps-2 min-w-80px"
                disabled={!isBHYT}
                name="insKcbbdCode"
                value={insuranceValues?.insKcbbdCode || ""}
                onChange={(e: any) =>
                  handleChangeInsurance(e.target.name, e.target.value)
                }
              />
            </div>
            <div className="spaces width-100">
              <TextField
                type="text"
                labelClassName="ps-2 min-w-80px"
                disabled={!isBHYT}
                name="insKcbbdName"
                value={insuranceValues?.insKcbbdName || ""}
                onChange={(e: any) =>
                  handleChangeInsurance(e.target.name, e.target.value)
                }
              />
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className="spaces width-100 d-flex align-items-center">
              <LabelRequired label="Hạn thẻ" className="ps-2 min-w-80px" />
              <div className="spaces d-flex me-2">
                <div className="spaces width-25">
                  <TextField
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                    type="number"
                    name="insFromDay"
                    value={insuranceValues?.insFromDay || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insFromMonth"
                    value={insuranceValues?.insFromMonth || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-50">
                  <TextField
                    type="number"
                    name="insFromYear"
                    value={insuranceValues?.insFromYear || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="4"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
              </div>
              <div className="spaces d-flex ms-2">
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insToDay"
                    value={insuranceValues?.insToDay || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insToMonth"
                    value={insuranceValues?.insToMonth || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-50">
                  <TextField
                    type="number"
                    name="insToYear"
                    value={insuranceValues?.insToYear || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="4"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className="spaces d-flex w-60">
              <LabelRequired label="Tuyến KCB" className="ps-2 min-w-80px" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listTuyenKCB || []}
                name="examLevel"
                value={visitValues?.attributes?.examLevel}
                onChange={(selectedOption) => {
                  handleChangeVisitAttribute("examLevel", selectedOption);
                }}
                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                isDisabled={!isBHYT}
              />
            </div>
            <div className="spaces d-flex w-40 position-relative">
              <TextField
                className="ps-29px no-spinners"
                label="Mức hưởng"
                name="insCoveragePercentage"
                value={insuranceValues?.insCoveragePercentage || ""}
                onChange={(e: any) =>
                  handleChangeInsurance(e.target.name, e.target.value)
                }
                type="number"
                labelClassName="ps-2 min-w-80px"
                disabled={!isBHYT}
              />
              <div className="spaces w-4">
                <h6 className="position-absolute muc-huong">%</h6>
              </div>
            </div>
          </div>

          <div className="spaces d-flex ps-2 mt-10 mb-4">
            <FormCheck
              type="checkbox"
              label="Chứng nhận không cùng chi trả trong năm"
              name="BHYT.chungNhanKhongCungChiTra"
              onChange={(e) => handleChecked?.(e)}
              className="min-w-150px d-flex align-items-center gap-2"
            />
          </div>

          <div className="d-flex mb-2">
            <div className="spaces d-flex ms-2 flex-grow-1">
              <LabelRequired
                label="Ngày miễn cùng chi trả" //Ngày miễn cùng chi trả
                className=" min-w-200px"
              />
              <div className="spaces d-flex flex-auto">
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insNgaymienchitraDay"
                    value={insuranceValues?.insNgaymienchitraDay || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insNgaymienchitraMonth"
                    value={insuranceValues?.insNgaymienchitraMonth || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-50">
                  <TextField
                    name="insNgaymienchitraYear"
                    value={insuranceValues?.insNgaymienchitraYear || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="4"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className="spaces d-flex ms-2 flex-grow-1">
              <LabelRequired
                label="Ngày đóng đủ 5 năm liên tục" //Ngày đóng đủ 5 năm liên tục
                className=" min-w-200px"
              />
              <div className="spaces d-flex flex-auto">
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insNgaydu5namDay"
                    value={insuranceValues?.insNgaydu5namDay || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-25">
                  <TextField
                    type="number"
                    name="insNgaydu5namMonth"
                    value={insuranceValues?.insNgaydu5namMonth || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="2"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
                <div className="spaces width-50">
                  <TextField
                    type="number"
                    name="insNgaydu5namYear"
                    value={insuranceValues?.insNgaydu5namYear || ""}
                    onChange={(e: any) =>
                      handleChangeInsurance(e.target.name, e.target.value)
                    }
                    maxLength="4"
                    className="text-center no-spinners"
                    disabled={!isBHYT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalNhapTheBH
          show={shouldOpenNhapTheBHDialog}
          handleCloseDialog={() => setShouldOpenNhapTheBHDialog(false)}
        />

        {openTimKiemBNDialog && (
          <ModalTimKiemBenhNhan
            // onSelectedPatient={(patient) => getPatientInformation(setValues, patient)}
            open={openTimKiemBNDialog}
            handleClose={handleCloseModalTKBenhNhan}
            objectSearchBN={objectSearch}
            setPatientSelectedId={(id: string) => setPatientId(id)}
          />
        )}
      </div>
    );
  }
);
export default TTHanhChinhV2;
