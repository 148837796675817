import { ID_DANG_KY_KHAM, UU_TIEN } from '../../utils/Constant';
import { formatTrangThaiBenhNhan } from '../../utils/FormatUtils';

export const COLUMN_PHIEU_LAY_MAU = [
	{
		name: 'TT',
		field: '',
		headerStyle: {
			maxWidth: '30px',
		},
		render: (rowData: any, index: number) => formatTrangThaiBenhNhan(rowData?.color),
	},
	{
		name: 'STT',
		field: 'samplingOrdinalNumber',
		headerStyle: {
			minWidth: '40px',
		},
		cellStyle: {
			textAlign: 'center',
		},
		render: (rowData: any, index: number) => <span className="text-system">{rowData?.samplingOrdinalNumber || ""}</span>
	},
	{
		name: 'Barcode',
		field: 'barcode',
		headerStyle: {
			minWidth: '70px',
		},
		cellStyle: {
			textAlign: 'center',
		},
		render: (rowData: any, index: number) => <span className="text-system">{rowData?.barcode || ""}</span>
	},
	{
		name: 'Tên bệnh nhân',
		field: '',
		render: (rowData: any, index: number) => {
			return (
				<div className="text-system text-truncate">
					<div className="flex m-0">
						<span
							className={`text-uppercase ${
								rowData?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU
									? 'color-error'
									: ''
							}`}
						>
							{rowData?.patientName}
						</span>
						{rowData?.visitSortWeight === UU_TIEN.TRUE ? (
							<span className="text-danger">&nbsp;(Ưu tiên)</span>
						) : (
							''
						)}
					</div>
					<div className="flex m-0">
						<span className="text-uppercase fw-semibold">
							{rowData?.patientCode || ''}
						</span>
						<span className="px-1"> - </span>
						<span>{rowData?.visitObjectTypeName || ''}</span>
						<span className="px-1"> - </span>
						<span>{rowData?.gender || ''}</span>
					</div>
				</div>
			);
		},
	},
	{
		name: 'Mã BP',
		field: 'code',
		headerStyle: {
			minWidth: '80px',
		},
		cellStyle: {
			textAlign: 'center',
		},
		render: (rowData: any, index: number) => <span className="text-system">{rowData?.code || ""}</span>
	},
];

export const DANH_SACH_BENH_NHAN_FILTER_OPTIONS = [
	{
		id: 1,
		value: 'indicationTime',
		name: 'Ngày y lệnh',
	},
	{
		id: 2,
		value: 'samplingStartTime',
		name: 'Ngày lấy mẫu',
	},
];

export const COLUMN_BANG_LAY_MAU = [
	{
		title: 'Mã DV',
		field: 'code',
		className: 'spaces width-10 text-center',
		render: (rowData: any) => {
			return (
				<div
					className={`${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.code}
				</div>
			);
		},
	},
	{
		title: 'Tên xét nghiệm',
		field: 'name',
		className: 'spaces width-35',
		render: (rowData: any) => {
			return (
				<div
					className={`${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.name}
				</div>
			);
		},
	},
	{
		title: 'Kết Quả',
		field: 'obs.value',
		className: 'spaces width-10 text-center',
		render: (rowData: any) => {
			return (
				<div
					className={`h-31 d-flex align-items-center justify-content-center ${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.obs?.value}
				</div>
			);
		},
	},
	{
		title: '',
		field: 'HL',
		className: 'spaces width-5 text-center',
		render: (rowData: any) => {
			return (
				<div
					className={`${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'H' : ''}
					{rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'L' : ''}
				</div>
			);
		},
	},
	{
		title: 'Đơn vị',
		field: 'donVi',
		className: 'spaces width-7 text-center',
		render: (rowData: any) => {
			return (
				<div
					className={`${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.numeric?.units}
				</div>
			);
		},
	},
	{
		title: 'GTBT',
		field: 'gtbt',
		className: 'spaces width-10 text-center',
		render: (rowData: any) => {
			return (
				<div
					className={`${
						(rowData?.obs?.value > rowData?.numeric?.hiNormal ? 'hiNormal' : '') +
						(rowData?.obs?.value < rowData?.numeric?.lowNormal ? 'lowNormal' : '')
					}`}
					style={{ color: rowData?.statusColor }}
				>
					{rowData?.numeric
						? rowData?.numeric?.lowNormal + ' - ' + rowData?.numeric?.hiNormal
						: ''}
				</div>
			);
		},
	},
	{ title: 'Loại bệnh phẩm', field: 'type', className: 'spaces width-13' },
	{ title: 'Ghi chú', field: 'description', className: 'spaces width-10' },
];
