import { Formik } from "formik";
import { FocusEvent, useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import TextField from "../../component/TextField";
import DynamicComponentXN from "../../phan-he-xet-nghiem/components/DynamicComponentXN";
import { changeWfStatus } from "../../utils/AppFunction";
import { CODE, MENU_ACTION, SELECTION_MODE, STATUS_CANCEL, TERM_TYPE } from "../../utils/Constant";
import { cancelService } from "../../utils/ServicesUtils";
import WfContextMenu from "../../workflows/components/WfContextMenu";
import { WfConstTracker } from "../../workflows/Constant";
import { IDanhSachDVCdhaTdcn } from "../models/ModelsPhanHeCDHAVaTDCN";
import {
  createObsValue,
} from "../services/PhanHeCDHAVaTDCNService";

type Props = {
  dataDanhSachDVCdha: IDanhSachDVCdhaTdcn[];
  infoBnSelectedCdha?: any;
  setDsDichVu?: any;
  handleDoubleClick?: (row: any) => void;
  isViewModal?: boolean;
  handleGetInfoBn: (item?: any) => void;
  setStatusChangedEvent: (data: any) => void;
};

const BangDsDichVuCDHA = (props: Props) => {
  let {
    infoBnSelectedCdha = {},
    setDsDichVu = () => {},
    handleDoubleClick = () => {},
    isViewModal,
    handleGetInfoBn
  } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [rowSelect, setRowSelect] = useState<any>([]);
  const [dataSubmit, setDataSubmit] = useState(props.dataDanhSachDVCdha);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const { setIsLoading } = useContext(AppContext);
  const [configurationContextMenu, setConfigurationContextMenu] = useState<any>(null);
  const [dataDynamic, setDataDynamic] = useState<any>();

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setRowSelect(row?.original);
    if (row?.original?.additionalConfiguration) {
      setConfigurationContextMenu(row?.original?.additionalConfiguration);
      setContextMenu({ x: e.clientX, y: e.clientY });
    } else {
      setConfigurationContextMenu(null);
    };
  };

  const actionClick = (data: any) => {
    setDataDynamic({ ...data });
  };

  const handleCancelService = async (value: any) => {
    setIsLoading(true);
    try {
      const wfWorkflowsReq = {
        objectId: rowSelect?.orderId,
        status: rowSelect?.oldStatusId,
      }
      await changeWfStatus(WfConstTracker.ORDER_CIS, wfWorkflowsReq);
      const obj = {
        termType: TERM_TYPE.CDHA,
        termOrderId: rowSelect?.termOrderId,
        cancelReason: value?.canceledReason,
        menuAction: MENU_ACTION.XN_CDHA
      }

      let { data } = await cancelService(obj);
      if (CODE.SUCCESS === data?.code && data?.data) {
        toast.success("Bỏ dịch vụ thành công!");
        handleGetInfoBn(infoBnSelectedCdha);
      } else {
        toast.error(data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setDataSubmit(props.dataDanhSachDVCdha);
  }, [props.dataDanhSachDVCdha]);

  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData?.obs?.conclusion) {
        setFieldLoading({ [rowData?.id]: true });
        const submitData = {
          personId: infoBnSelectedCdha?.patient?.personId,
          orderId: rowData?.orderId,
          values: {
            conclusion: value || null
          },
        };

        await createObsValue(submitData);
        toast.success( "Lưu kết luận thành công");
                
        const dsDichVu = props?.dataDanhSachDVCdha?.map((item: any) => {
          if (rowData?.id === item?.id) {
              return {
                  ...item,
                  termOrderId: item?.id,
                  obs: {
                    ...item?.obs,
                    conclusion: value
                  },
              }
          }

          return {
            ...item,
            termOrderId: item?.id,
          };
        });
        setDsDichVu(dsDichVu);
      }
    } catch (error) {
      toast.warning("Lỗi lưu kết luận, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  const generateInput = (rowData: any) => {
    return (
      <div className="d-flex align-items-center spaces w-100">
        <TextField
          className="spaces w-100"
          as="textarea"
          name="conclusion"
          defaultValue={rowData?.obs?.conclusion || ""}
          disabled={false}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleSaveObs(rowData, e.target.value);
          }}
        />
        {fieldLoading[rowData.id] && <span className="loading-spinner"></span>}
      </div>
    );
  };

  const columnsDSDVCdhaTdcn : ReadonlyArray<
  Column<any>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor}}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"TÊN DỊCH VỤ"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className={`s-2 fs-14`}
          data={props.data[props.row.index]?.name}
          style={{ color: props.data[props.row.index]?.statusColor}}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"SL"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "SL",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.quantity || props.data[props.row.index]?.isCanceled ? 0 : 1}
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor}}
        />
      ),
    },
    // {
    //   Header: (props) => (
    //     <TableCustomHeader<any>
    //       tableProps={props}
    //       title={"#ACC"}
    //       className=" text-center text-white align-middle bg-pri min-w-100px"
    //     />
    //   ),
    //   id: "ACC",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       data={props.data[props.row.index]?.acc}
    //       className="text-center fs-14"
    //     />
    //   ),
    // },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"KẾT LUẬN"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Kết luận",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={<Formik
            initialValues={{}}
            onSubmit={() => {}}
            className={`spaces w-100 fs-14`}
          >
            {(isViewModal || props.data[props.row.index]?.statusCode !== STATUS_CANCEL.EXCUTING) 
              ? <span style={{ color: props.data[props.row.index]?.statusColor}}>
                  {props.data[props.row.index]?.obs?.conclusion}
                </span> 
              : generateInput(props.data[props.row.index])
            }
          </Formik>}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"MÁY THỰC HIỆN"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Máy thực hiện",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.mayThucHien}
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor}}
        />
      ),
    },
  ];
  
  return (
    <>
      <TableCustom<any>
        className={"h-bang-ds-bn spaces h-calc-vh-273"}
        data={props.dataDanhSachDVCdha || []}
        columns={columnsDSDVCdhaTdcn}
        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
        handleDoubleClick={handleDoubleClick}
        verticalScroll={true}
        handleContextMenu={handleContextMenu}
      />

      {contextMenu && (
        <WfContextMenu
          tracker={WfConstTracker.ORDER_CIS}
          configuration={configurationContextMenu}
          wfWorkflowsReq={{
            objectId: rowSelect?.orderId,
            status: rowSelect?.oldStatusId,
          }}
          title="Phiếu thực hiện"
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          handleClick={actionClick}
        />
      )}

      {dataDynamic && (
        <DynamicComponentXN data={dataDynamic} setData={setDataDynamic}
          handleCancelService={handleCancelService}
        />
      )}
    </>
  );
};

export default BangDsDichVuCDHA;
