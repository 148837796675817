import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { forwardRef, useRef, useState } from 'react';

// TinyMCE core
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';
// Plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/vi';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
// Theme
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

import ImageEditor from '../image-editor';

type Props = Omit<IAllProps, 'licenseKey' | 'apiKey'> & {
	initialOptions?: any;
};

const RichTextEditor = forwardRef<Editor, Props>((props, ref) => {
	const [openImageEditor, setOpenImageEditor] = useState<boolean>(false);

	const imageEditRef = useRef<HTMLImageElement | null>(null);

	return (
		<>
			<Editor
				ref={ref}
				licenseKey="gpl"
				init={{
					branding: false,
					promotion: false,
					height: '100%',
					menubar: 'file edit view insert format tools table',
					plugins:
						'preview importcss searchreplace autolink ' +
						'directionality code visualblocks visualchars ' +
						'fullscreen image table ' +
						'insertdatetime advlist lists wordcount help ' +
						'link media',
					toolbar:
						'undo redo | ' +
						'blocks fontfamily fontsize | bold italic underline ' +
						'strikethrough | align numlist bullist | image ' +
						'table | lineheight outdent indent| forecolor backcolor ' +
						'removeformat | code fullscreen preview',
					content_style: `body { font-family: Times New Roman, Helvetica, Arial, sans-serif; font-size: 14pt };`,
					toolbar_mode: 'sliding',
					contextmenu: 'image table',
					resize: true,
					image_advtab: true,
					object_resizing: true,
					skin: 'default',
					skin_url: 'default',
					table_use_colgroups: false,
					content_css: [
						'/tinymce/skin/default.css',
						'/tinymce/skin/oxide.css',
						'/tinymce/skin/custom.css',
					],
					language: 'vi',
					language_url: '/tinymce/lang/vi.js',
					language_load: true,
					image_title: true,
					file_picker_types: 'image',
					resize_img_proportional: false,
					block_unsupported_drop: false,
					paste_data_images: true,
					file_picker_callback: callback => {
						const input = document.createElement('input');
						input.setAttribute('type', 'file');
						input.setAttribute('multiple', 'false');
						input.setAttribute('accept', 'audio/*, video/*, image/*');
						input.addEventListener('change', (e: Event) => {
							const target = e.target as HTMLInputElement;
							const files = target.files;
							const file = files?.[0];

							if (!file) return;

							const reader = new FileReader();
							reader.readAsDataURL(file);
							reader.onload = function () {
								const base64data = reader.result!.toString();
								callback(base64data, {
									title: file.name,
									alt: file.name,
								});
							};
						});
						input.click();
					},
					setup: editor => {
						editor.on('dblclick', e => {
							const target = e.target as HTMLElement;
							if (target.nodeName === 'IMG') {
								const imageSrc = target.getAttribute('src');

								if (!imageSrc) return;

								imageEditRef.current = target as HTMLImageElement;
								setOpenImageEditor(true);
							}
						});
					},
					...props.initialOptions,
				}}
				{...props}
			/>

			{openImageEditor && (
				<ImageEditor
					open={openImageEditor}
					onClose={() => {
						setOpenImageEditor(false);
					}}
					onSave={(value: string) => {
						if (imageEditRef.current) {
							imageEditRef.current.src = value;
						}
					}}
					src={imageEditRef.current!.src}
				/>
			)}
		</>
	);
});

export default RichTextEditor;
