import axios from "axios";
import { localStorageItem } from '../../utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts'
const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_LOAI_DICH_VU_QUAN_TRI_HE_THONG = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL + '/obs-complex-categories/SVC';
export const getJsonGenerateForm = async (code: string) => {
    let url = API_PATH + `/term-models/${code}/model-json`;
    return axios.get(url);
};
export const createLoaiDichVuQuanTriHeThong = async (dataBody: any) => {
    return axios.post(API_LOAI_DICH_VU_QUAN_TRI_HE_THONG, dataBody);
};
export const updateLoaiDichVuQuanTriHeThong = async (id: string, dataBody: any) => {
    return axios.put(API_LOAI_DICH_VU_QUAN_TRI_HE_THONG + '/' + id, dataBody);
};
export const searchLoaiDichVuQuanTriHeThong = async (dataBody: any) => {
    return axios.get(API_LOAI_DICH_VU_QUAN_TRI_HE_THONG + '/search', dataBody);
};