import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import LabelRequired from '../../../component/LabelRequired';
import TextField from '../../../component/TextField';

type Props = {
	open: boolean;
	onClose: () => void;
	onSave: (value: string) => void;
	tenMauKetQua?: string;
};

const ModalLuuMauKetQua = ({ open, onClose, onSave, tenMauKetQua }: Props) => {
	const [isConfirm, setIsConfirm] = useState(false);

	const onCancel = () => {
		isConfirm ? setIsConfirm(false) : onClose();
	};

	return (
		<Modal
			show={open}
			animation
			size="sm"
			centered
			className="dialog-background"
			contentClassName="spaces min-w-400"
		>
			<Formik<any>
				initialValues={{
					tenMauKetQua,
				}}
				onSubmit={values => {
					onSave(values);
				}}
			>
				{({ handleSubmit }) => (
					<Form>
						<Modal.Header className="py-3 header-modal">
							<Modal.Title className="text-pri">
								{`${tenMauKetQua ? 'Cập nhật' : 'Lưu'} mẫu kết quả`}
							</Modal.Title>
							<button className="btn-close" onClick={onClose}></button>
						</Modal.Header>

						<Modal.Body className="py-16 px-24 spaces">
							{isConfirm ? (
								<div>
									<LabelRequired
										label="Tên mẫu"
										className="spaces min-w-100-px"
									/>
									<TextField
										className="spaces w-100"
										inputClassName="w-100"
										name="tenMauKetQua"
										autoFocus
									/>
								</div>
							) : (
								<LabelRequired
									label={`Bạn có muốn ${
										tenMauKetQua ? 'cập nhật' : 'lưu'
									} lại mẫu kết quả này?`}
									className="w-100"
								/>
							)}
						</Modal.Body>

						<Modal.Footer className="d-flex justify-content-end">
							<Button className="btn-fill" onClick={onCancel}>
								{isConfirm ? 'Hủy' : 'Không'}
							</Button>
							<Button
								className="btn-fill"
								onClick={() => {
									isConfirm ? handleSubmit() : setIsConfirm(true);
								}}
							>
								{isConfirm ? 'Lưu' : 'Có'}
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default ModalLuuMauKetQua;
