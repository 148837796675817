import { Formik } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../appContext/AppContext";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import DanhSachPhieu from "../../component/DanhSachPhieu";
import InputSearch from "../../component/InputSearch";
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";
import {
  APP_DATE_TIME_FORMAT,
  CODE,
  DEFAULT_PAGE_INDEX,
  KEY,
  MAX_SIZE,
  RESPONSE_MESSAGE,
} from "../../utils/Constant";
import {
  convertNumberPrice,
  formatDateDTO,
  formatDateTime,
  formatDateToDDMMYYYY,
  formatMoney,
} from "../../utils/FormatUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { getColumnThongTinVienPhi } from "../columns/thong-tin-vien-phi/ColumnThongTinVienPhi";
import {
  CATEGORY_TYPE,
  HINH_THUC_TYPE,
  INV_TYPE_CODE,
  PATIENT_TYPE,
} from "../const/VienPhiConstant";
import {
  IHuyPhieu,
  IPhieu,
  ObjectSelectAutocomplete,
} from "../models/VienPhiInfoModels";
import {
  cancelInvoices,
  createInvoices,
  generateCodePayment,
  getDanhSachDichVu,
  getDanhSachHinhThucService,
  getDanhSachLoaiPhieuService,
  getDanhSachPhieuService,
  getInvoiceByIdService,
  inPhieuVienPhi,
  updateDiscount,
} from "../services/PhanHeVienPhiServices";
import { ConfirmDialog } from "./ConfirmDialog";
import { ConfirmDialog as ConfirmDialogCustom } from "../../component/ConfirmDialog";
import PaymentInfor from "./PaymentInfor";
import { handleConvertAddress } from "../../utils/AppFunction";
import NumberField from "../../component/number-field";
import PopupCancelPaymentApprove from "./popup-approve/PopupCancelPaymentApprove";
import PopupPaymentApprove from "./popup-approve/PopupPaymentApprove";
import ButtonInPhieu from "../../component/button-in-phieu";
import { MAP_EXPORT_PATH } from "../../component/button-in-phieu/constant";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { TableCollapseCustom } from "../../component/table/table-collapse/TableCollapseCustom";

type Props = {
  selectedRow: any;
  filter: any;
  setFilter: (data: any) => void;
  paymentInfor: any;
  handleGetPaymentInfor: () => void;
};
const initialValues: IPhieu = {
  loaiPhieu: null,
  hinhThuc: null,
  trangThai: "",
  invNgayVaoVien: null,
  invNgayRaVien: null,
  invCode: "",
  invCashier: "",
  invDate: null,
  invTotal: null,
  discount: null,
  discountPercent: null,
  invRevenue: null,
  invRepay: null,
  invTypeId: "",
  invTypeCode: "",
  invTypeName: "",
  invHinhThucId: "",
  invHinhThucName: "",
};

const ThongTinVienPhi = (props: Props) => {
  let { selectedRow, filter, setFilter, paymentInfor, handleGetPaymentInfor } =
    props;
  const intl = useIntl();
  const [listDanhSachPhieu, setListDanhSachPhieu] = useState<any[]>([]);
  const [isTaoPhieu, setIsTaoPhieu] = useState(false);
  const [showInforInvoices, setShowInforInvoices] = useState(false);
  const refForm = useRef<any>();
  const [dsHinhThuc, setDanhSachHinhThuc] = useState<any>([]);
  const [dsLoaiPhieu, setDanhSachLoaiPhieu] = useState<any>([]);
  const [dsLoaiPhieuBackup, setDanhSachLoaiPhieuBackup] = useState<any>([]);
  const { setIsLoading } = useContext(AppContext);
  const [dsThongTinDv, setDsThongTinDv] = useState<any[]>([]);
  const [dsThongTinDvBackup, setDsThongTinDvBackup] = useState<any>();
  const [dsThongTinDvFiltered, setDsThongTinDvFiltered] = useState<any>();
  const [invTypeCode, setInvTypeCode] = useState<any>(null);
  const [arraySelect, setArraySelect] = useState<any[]>([]);
  const [invoicesInfor, setInvoicesInfor] = useState<any>({
    invDate: new Date(),
  });
  let dataUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  const myRef = useRef<any>(null);
  const [width, setWidth] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [paymentItem, setPaymentItem] = useState<any>([]);
  const [keywordDV, setKeywordDV] = useState("");
  const setFieldValueRef: any = useRef(null);
  const [openApprovePopup, setOpenApprovePopup] = useState<boolean>(false);
  const [openCancelApprovePopup, setOpenCancelApprovePopup] =
    useState<boolean>(false);
  const [showApproveBtn, setShowApproveBtn] = useState<boolean>(false);
  const [openConfirmDialogCustom, setOpenConfirmDialogCustom] = useState(false);
  const [inputFocused, setInputFocused] = useState<any>(null);
  const [rowEdit, setRowEdit] = useState<any>(null);
  const [listDVSelect, setListDVSelect] = useState<any[]>([]);
  const [listDVDiscount, setListDVDiscount] = useState<any[]>([]);
  const [validationSchema, setValidationSchema] = useState(Yup.object({}));
  const [listDVCancel, setListDVCancel] = useState<any[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywordDV(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    KEY.ENTER === event.key && getDanhSachThongTinDichVu();
  };

  useEffect(() => {
    if (keywordDV.trim() === "") {
      getDanhSachThongTinDichVu();
    }
  }, [keywordDV]);

  const handleClickOutsideInput = (e: any) => {
    if (
      inputFocused &&
      rowEdit?.paymentPtPercentClone !== undefined &&
      +rowEdit?.paymentPtPercentClone !== +rowEdit?.paymentPtPercent &&
      !Array.from(e.target.classList).includes(inputFocused)
    ) {
      handleUpdateDiscount(rowEdit);
      setInputFocused(null);
    }
    if(inputFocused && !Array.from(e.target.classList).includes("input-col-vp")) {
      setInputFocused(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideInput);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideInput);
    };
  }, [inputFocused, rowEdit]);

  const handleSubmit = async (values: IHuyPhieu) => {
    setIsLoading(true);

    let formatData = {
      payload: {
        ...values,
        invCanceledDate: formatDateDTO(new Date()),
        invCanceledPerson: dataUser?.name,
        invCanceledType: "Tiền mặt",
      },
      id: invoicesInfor?.id,
    };

    try {
      const { data } = await cancelInvoices(formatData);
      if (CODE.SUCCESS === data?.code) {
        toast.success("Hủy phiếu thành công");
        handleReloadData(data?.data);
      } else {
        let message = data?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
        toast.warning(message);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
      setOpenConfirmDialog(false);
      setOpenConfirmDialogCustom(false);
    }
  };

  const handleOpenConfirmDialog = (noiDung: string) => {
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    if (myRef?.current?.offsetWidth) {
      setWidth(myRef?.current?.offsetWidth - 105);
    }
  }, [myRef?.current?.offsetWidth]);

  const handleGenerateInvCode = async () => {
    try {
      let { data } = await generateCodePayment(invTypeCode);
      if (CODE.SUCCESS === data?.code) {
        setInvoicesInfor((prevState: any) => {
          return {
            ...prevState,
            invCode: invoicesInfor?.inCode
              ? invoicesInfor?.inCode
              : data.data?.invCode,
            invLedgerId: data.data?.invLedgerId,
            invLedgerCode: data.data?.invLedgerCode,
            invLedgerName: data.data?.invLedgerName,
          };
        });
      } else {
        let message = data?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
        toast.warning(message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckIsPaidFull = (item: any) => {
    let count = 0;
    item?.orderData?.map((orderItem: any) => {
      if (orderItem?.isPaid) count++;
    });
    return count !== item?.orderData?.length;
  };

  const handleGetTotalPrice = (item: any) => {
    let total = item?.orderData?.reduce((sum: any, order: any) => {
      sum =
        sum +
        (order?.isPaid || !arraySelect.includes(order?.id) || order?.isCancel
          ? 0
          : order?.svcPtTotalClone || order?.svcPtTotal);
      return sum;
    }, 0);
    return convertNumberPrice(total) + "đ";
  };

  const handleGetNoiDungThu = (dataFormated: any) => {
    let text = "";
    let newListData: any[] = [];

    // dataFormated.map((item: any) => {
    //   item?.orderData?.map((orderItem: any) => (arraySelect.includes(orderItem?.id) && orderItem?.svcTotal > 0) && newListData.push(item))
    // });

    dataFormated?.map((item: any, index: any) => {
      text += handleCheckIsPaidFull(item)
        ? `Thu ${item?.termClassName.toLowerCase()}: ${handleGetTotalPrice(
            item
          )}${index !== dataFormated?.length - 1 ? ", " : ""}`
        : "";
    });
    return text;
  };

  const handleOnClickTaoPhieu = () => {
    setShowApproveBtn(true);
    const rowSelected = selectedRow[0];
    if (!rowSelected) {
      toast.warning("Vui lòng chọn bệnh nhân!");
      return;
    }
    setIsTaoPhieu(true);
    setShowInforInvoices(false);
    let initHinhThuc = dsHinhThuc?.find(
      (item: any) => item?.code === HINH_THUC_TYPE.TM.code
    );
    let initLoaiPhieu = dsLoaiPhieu?.find((item: any) =>
      (!listDanhSachPhieu.length ||
        (listDanhSachPhieu[0]?.invCanceled &&
          listDanhSachPhieu[0]?.invTypeCode ===
            INV_TYPE_CODE.PHIEU_THU.code)) &&
      (rowSelected?.visitObjectTypeName === PATIENT_TYPE.YEU_CAU.name ||
        rowSelected?.visitObjectTypeName === PATIENT_TYPE.VIEN_PHI.name)
        ? item?.code === INV_TYPE_CODE.PHIEU_THU.code
        : item?.code === INV_TYPE_CODE.PHIEU_TAM_UNG.code
    );

    if (initLoaiPhieu?.code === INV_TYPE_CODE.PHIEU_THU.code) {
      setInvTypeCode(INV_TYPE_CODE.PHIEU_THU.code);
      setSelectAllData();
    } else {
      setInvTypeCode(INV_TYPE_CODE.PHIEU_TAM_UNG.code);
    }
    setInvoicesInfor({
      invDate: new Date(),
      loaiPhieu: initLoaiPhieu,
      invRemark:
        initLoaiPhieu?.code === INV_TYPE_CODE.PHIEU_THU.code
          ? paymentInfor?.defaultContentPaymentReceipt
          : "Thu tạm ứng",
      hinhThuc: initHinhThuc,
      invTypeId: initLoaiPhieu?.id,
      invTypeCode: initLoaiPhieu?.code,
      invTypeName: initLoaiPhieu?.name,
      invHinhThucId: initHinhThuc?.id,
      invHinhThucName: initHinhThuc?.name,
      invNgayVaoVien: rowSelected?.visitDateStarted
        ? moment(rowSelected?.visitDateStarted).format("YYYY-MM-DDTHH:mm")
        : null,
      invNgayRaVien: rowSelected?.visitDateStopped
        ? moment(rowSelected?.visitDateStopped).format("YYYY-MM-DDTHH:mm")
        : null,
      invCashier: dataUser?.name,
    });
    refForm.current.resetForm();
  };

  useEffect(() => {
    invTypeCode && isTaoPhieu && handleGenerateInvCode();
  }, [invTypeCode]);

  function handleGetDsDVTamUng(arr: any) {
    let arraySelectedTamUng: any = [];
    arr.shift();
    arr.map((item: any) => {
      item?.orderData?.map((itemOrder: any) => {
        !itemOrder?.isPaid &&
          !itemOrder?.isTemporaryRefundable &&
          arraySelectedTamUng.push(itemOrder?.id);
      });
    });
    return arraySelectedTamUng;
  }

  const handleValidateSoTienTamUng = () => {
    if (
      invTypeCode === INV_TYPE_CODE.PHIEU_TAM_UNG.code &&
      paymentInfor?.ttTamUng + paymentInfor?.ttDaThu <=
        paymentInfor?.ttNguoiBenh &&
      invoicesInfor?.invTotal < paymentInfor?.ttConNo &&
      invoicesInfor?.invTotal
    ) {
      toast.warning("Số tiền tạm ứng phải lớn hơn hoặc bằng số tiền còn nợ");
      return true;
    } else if (
      invTypeCode === INV_TYPE_CODE.PHIEU_TAM_UNG.code &&
      !invoicesInfor?.invTotal
    ) {
      toast.warning("Số tiền tạm ứng phải khác 0");
      return true;
    }
    return false;
  };

  const handleSubmitForm = async (values: IPhieu) => {
    if (handleValidateSoTienTamUng()) {
      return;
    }
    if (invTypeCode === INV_TYPE_CODE.PHIEU_THU.code && !arraySelect?.length) {
      return toast.warning("Vui lòng chọn dịch vụ để thanh toán!");
    }
    setIsLoading(true);
    let dsThongTinDVTamUng = handleGetDsDVTamUng(cloneDeep(dsThongTinDvBackup));

    let formatData = {
      ...values,
      ...invoicesInfor,
      invNgayVaoVien: invoicesInfor?.invNgayVaoVien || "",
      invNgayRaVien: invoicesInfor?.invNgayRaVien || "",
      invDate: formatDateDTO(invoicesInfor?.invDate || ""),
      ptPatientId: selectedRow[0]?.ptPatientId,
      visitId: selectedRow[0]?.visitId,
      invTotal: invoicesInfor?.invTotal,
      discount: invoicesInfor?.discount,
      discountPercent: Number(invoicesInfor?.discountPercent),
      orderDataIds:
        invTypeCode === INV_TYPE_CODE.PHIEU_THU.code
          ? arraySelect : invTypeCode === INV_TYPE_CODE.PHIEU_CHI.code ? listDVCancel : dsThongTinDVTamUng,
    };

    try {
      const { data } = await createInvoices(formatData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(intl.formatMessage({ id: "TOAST.CREATE.SUCCESS" }));
        handleReloadData(data?.data);
      } else {
        let message = data?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
        toast.warning(message);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateDiscount = async (values: any) => {
    // setIsLoading(true);

    let formatData = {
      id: values?.id,
      discount: values?.discountClone,
      paymentPtPercent: values?.paymentPtPercentClone,
    };
    try {
      const { data } = await updateDiscount(formatData);
      if (CODE.SUCCESS === data?.code) {
        toast.success("Cập nhật dịch vụ thành công")
        const newList = dsThongTinDv?.map((item: any) => ({
          ...item,
          orderData: item?.orderData?.map((orderItem: any) => {
            if (orderItem?.id === values?.id) {
              let rowEdited = {
                ...orderItem,
                paymentPtPercent: values?.paymentPtPercentClone,
                svcPtTotal: values?.svcPtTotalClone,
                discount: values?.discountClone
              };
              return rowEdited;
            }
            return {
              ...orderItem,
            };
          }),
        }));
        setDsThongTinDv(newList)
        handleGetPaymentInfor();
      } else {
        let message = data?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
        toast.warning(message);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadData = (data: any) => {
    handleGetPaymentInfor();
    getDanhSachPhieu();
    getDanhSachThongTinDichVu(true);
    setInvTypeCode(null);
    setArraySelect([]);
    setIsTaoPhieu(false);
    setShowInforInvoices(false);
    setShowApproveBtn(false);
    setFilter({ ...filter });
    refForm.current.resetForm();
  };

  const getContentSpendCancel = (data: any) => {
    let text = "Hủy dịch vụ ";
    let newArr = dsThongTinDvBackup.flatMap((dsDichVu: any) => dsDichVu?.orderData).filter((item: any) => item?.isCancel && item?.isPaid);
    newArr.map((item: any) => {
      return text += `${item?.termSvcName} - ${formatMoney(item?.svcPtTotal)}; `;
    })
    return text;
  }

  const handleCaculateMoneySpent = () => {
    let totalSpent = 0;
    let newListId: any[] = [];
    let newArr = dsThongTinDvBackup.flatMap((dsDichVu: any) => dsDichVu?.orderData).filter((item: any) => item?.isCancel && item?.isPaid);
    totalSpent = newArr.reduce((sum: any, item: any) => {
      sum = sum + ((!item?.isDaChi ? item?.svcPtTotal : 0) - ((item?.svcPtTotal * item?.discountPercent)/100));
      !item?.isDaChi && newListId.push(item?.id);
      return sum;
    }, 0);

    setListDVCancel(newListId);
    return totalSpent;
  }

  const handleSelectChange = (
    selectOption: ObjectSelectAutocomplete,
    name: string,
    setFieldValue: (
      name: string,
      value: string | number | undefined | null
    ) => void
  ) => {
    if (name === "loaiPhieu") {
      setFieldValue("invTypeId", selectOption?.id);
      setFieldValue("invTypeCode", selectOption?.code);
      setFieldValue("invTypeName", selectOption?.name);
      setFieldValue(
        "discount",
        selectOption?.code !== INV_TYPE_CODE.PHIEU_THU.code
          ? 0
          : invoicesInfor.discount
      );
      setFieldValue(
        "invTotal",
        selectOption?.code === INV_TYPE_CODE.PHIEU_HOAN.code
          ? paymentInfor?.ttTamUng : selectOption?.code === INV_TYPE_CODE.PHIEU_CHI.code ? handleCaculateMoneySpent() : 0
      );
      setFieldValue(
        "invTotalActual",
        selectOption?.code !== INV_TYPE_CODE.PHIEU_THU.code
          ? 0
          : invoicesInfor.invTotalActual
      );
      setInvoicesInfor((prevState: any) => ({
        ...prevState,
        invTypeId: selectOption?.id,
        invTypeCode: selectOption?.code,
        invTypeName: selectOption?.name,
        loaiPhieu: selectOption,
        discount:
          selectOption?.code !== INV_TYPE_CODE.PHIEU_THU.code
            ? 0
            : prevState.discount,
        discountReason:
          selectOption?.code !== INV_TYPE_CODE.PHIEU_THU.code
            ? ""
            : prevState.discountReason,
        invRemark:
          selectOption?.code === INV_TYPE_CODE.PHIEU_TAM_UNG.code
            ? "Thu tạm ứng"
            : selectOption?.code === INV_TYPE_CODE.PHIEU_THU.code
            ? paymentInfor?.defaultContentPaymentReceipt
            : selectOption?.code === INV_TYPE_CODE.PHIEU_HOAN.code
            ? `Chi hoàn ứng cho phiếu ${listDanhSachPhieu
                .filter((item: any) => item.isTemporaryRefundable)
                .map((item: any) => item.invCode)
                .toString()}`
            : selectOption?.code === INV_TYPE_CODE.PHIEU_CHI.code ? getContentSpendCancel(handleCaculateMoneySpent()) : "",
        invTotal:
          selectOption?.code === INV_TYPE_CODE.PHIEU_HOAN.code
            ? paymentInfor?.ttTamUng : selectOption?.code === INV_TYPE_CODE.PHIEU_CHI.code ? handleCaculateMoneySpent() : 0,
        invTotalActual:
          selectOption?.code === INV_TYPE_CODE.PHIEU_THU.code
            ? prevState?.invTotalActual
            : selectOption?.code === INV_TYPE_CODE.PHIEU_HOAN.code
            ? paymentInfor?.ttTamUng
            : 0,
      }));
      setInvTypeCode(selectOption?.code);
      if (selectOption?.code === INV_TYPE_CODE.PHIEU_THU.code) {
        setSelectAllData();
      } else if (selectOption?.code === INV_TYPE_CODE.PHIEU_HOAN.code) {
        setFieldValue("invTotal", paymentInfor?.ttTamUng);
      }
    } else if (name === "hinhThuc") {
      setFieldValue("invHinhThucId", selectOption?.id);
      setFieldValue("invHinhThucName", selectOption?.name);
      setInvoicesInfor({
        ...invoicesInfor,
        invHinhThucId: selectOption?.id,
        invHinhThucName: selectOption?.name,
      });
    }
  };

  const setSelectAllData = () => {
    let dataSelect: any[] = [];
    let dataSelectObject: any[] = [];

    let newArr = handleGetDSDVChuaThanhToan().map((itemData: any) => {
      let newListItem = {
        ...itemData,
        orderData: itemData?.orderData?.map((item: any) => {
          let obj = {
            ...item,
            isSelect: !item?.isCancel && !item?.isPaid ? true : false,
          };
          if (!item?.isCancel && !item?.isPaid) {
            dataSelect.push(item?.id);
            dataSelectObject.push(obj);
          }
          return { ...obj };
        }),
      };
      return newListItem;
    });

    setArraySelect(dataSelect || []);
    setListDVSelect(dataSelectObject || []);
    setListDVDiscount(newArr || []);
  };

  const getDanhSachLoaiPhieu = async () => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: MAX_SIZE,
      type: CATEGORY_TYPE.LOAI_PHIEU.code,
    };
    try {
      const { data } = await getDanhSachLoaiPhieuService(searchObject);

      if (CODE.SUCCESS === data?.code) {
        setDanhSachLoaiPhieu(data?.data?.content || []);
        setDanhSachLoaiPhieuBackup(data?.data?.content || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapDataDSDichVu = (data: any, isFiltered = false) => {
    return data?.map((item: any, index: number) => {
      let dataReturn =
        invTypeCode === INV_TYPE_CODE.PHIEU_THU.code &&
        !isFiltered &&
        !showInforInvoices
          ? item?.orderData?.filter((it: any) => !it?.isPaid)
          : item?.orderData;
      let orderDataClone = dataReturn?.length > 0 ? dataReturn.map((itemOrder: any) => {
        return {
          ...itemOrder,
          svcPtTotalClone:
            itemOrder?.svcPtTotalClone === undefined
              ? itemOrder?.svcPtTotal
              : itemOrder?.svcPtTotalClone,
          paymentPtPercentClone:
            itemOrder?.paymentPtPercentClone === undefined
              ? itemOrder?.paymentPtPercent
              : itemOrder?.paymentPtPercentClone,
          svcPriceClone: itemOrder?.svcPrice,
          svcCollected: itemOrder?.isPaid ? itemOrder?.svcPtTotal : 0,
          termSvcNameClone: (
            <span className="d-flex">
              {itemOrder?.isPaid ? (
                <span
                  className={`${"background-color-blue"} icon-circle-check flex-1`}
                >
                  <i className="bi-check icon-check me-2"></i>
                </span>
              ) : (!itemOrder?.isPaid && invTypeCode === INV_TYPE_CODE.PHIEU_THU.code && !isFiltered && !itemOrder?.isCancel && !showInforInvoices && itemOrder?.id) ? (
                <FormCheck
                  type="checkbox"
                  label=""
                  className="mgr-3 form-check check-box-vp flex-1 "
                  checked={arraySelect.includes(itemOrder?.id)}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setArraySelect([...arraySelect, itemOrder?.id]);
                      setListDVSelect([
                        ...listDVSelect,
                        { ...itemOrder, isSelect: true },
                      ]);
                      handleChangeValueTable(
                        itemOrder,
                        itemOrder?.paymentPtPercent || 0,
                        index,
                        "paymentPtPercentClone"
                      );
                    } else {
                      let prevSelected = arraySelect.filter(
                        (id) => id !== itemOrder?.id
                      );
                      let prevListDVSelect = listDVSelect.map((item) => {
                        if (item.id === itemOrder?.id) {
                          item.discountClone = itemOrder?.discount;
                          item.svcPtTotalClone = itemOrder?.svcTotal;
                          item.paymentPtPercentClone =
                            itemOrder?.paymentPtPercent;
                          return { ...item, isSelect: false };
                        }
                        return item;
                      });
                      setArraySelect(prevSelected);
                      setListDVSelect(prevListDVSelect);
                    }
                  }}
                />
              ) : (
                ""
              )}
              <span
                className={`flex-grow-18 ${
                  itemOrder?.isCancel ? "text-danger" : ""
                } spaces pl-5`}
              >
                {itemOrder?.termSvcName}
              </span>
            </span>
          ),
        };
      }) : [{}];
      return {
        ...item,
        // termClassNameClone: `${index + 1}. ${item?.termClassName}`,
        name: `${index + 1}. ${item?.termClassName}`,
        orderData: orderDataClone,
      };
    });
  };

  const handleGetDSDVChuaThanhToan = () => {
    let dsThongTinDVCheckFlow: any = cloneDeep(dsThongTinDv);
    // if (
    //   dsThongTinDVCheckFlow[0]?.orderData[0]?.termClassId ===
    //     TERM_CLASS_IDS.KHAM_BENH &&
    //   !dsThongTinDVCheckFlow[0]?.orderData[0]?.isPaid
    // ) {
    //   dsThongTinDVCheckFlow = [dsThongTinDVCheckFlow[0]];
    // }
    return dsThongTinDVCheckFlow;
  };

  useEffect(() => {
    if (invTypeCode === INV_TYPE_CODE.PHIEU_THU.code) {
      let dataFormated = handleMapDataDSDichVu(dsThongTinDv);
      setDsThongTinDv(dataFormated || []);
    } else if (
      invTypeCode === INV_TYPE_CODE.PHIEU_HOAN.code ||
      invTypeCode === INV_TYPE_CODE.PHIEU_TAM_UNG.code
    ) {
      setDsThongTinDv(dsThongTinDvFiltered || []);
    } else {
      getDanhSachThongTinDichVu();
    }
  }, [invTypeCode, arraySelect?.length]);

  useEffect(() => {
    handleCalculateInvTotal(
      listDVDiscount?.length ? listDVDiscount : dsThongTinDvBackup
    );
  }, [arraySelect]);

  const handleCalculateInvTotal = (newList: any) => {
    let invTotalCal = 0;

    newList?.map((itemData: any) => {
      itemData?.orderData?.map((item: any) => {
        if (arraySelect?.includes(item?.id)) {
          invTotalCal += item?.svcPtTotalClone || item?.svcPtTotal;
        }
      });
    });
    setFieldValueRef.current("invTotal", invTotalCal);
    setFieldValueRef.current(
      "invRemark",
      invTypeCode === INV_TYPE_CODE.PHIEU_THU.code
        ? paymentInfor?.defaultContentPaymentReceipt
        : "Thu tạm ứng"
    );
    
    let invTotalActualCal = invTotalCal - (invoicesInfor?.discount || 0);

    setInvoicesInfor({
      ...invoicesInfor,
      invTotal: invTotalCal,
      invTotalActual: invTotalActualCal,
      invRepay: invoicesInfor?.invRevenue - invTotalActualCal || 0,
      invRemark:
        invTypeCode === INV_TYPE_CODE.PHIEU_THU.code
          ? paymentInfor?.defaultContentPaymentReceipt
          : "Thu tạm ứng",
    });
  };

  const getDanhSachThongTinDichVu = async (isBackup = false) => {
    setIsLoading(true);
    let searchObject = {
      visitId: selectedRow[0]?.visitId,
      termSvcName: keywordDV,
    };
    try {
      if (selectedRow[0]?.visitId) {
        const { data } = await getDanhSachDichVu(searchObject);

        if (CODE.SUCCESS === data?.code) {
          let dataFormated = handleMapDataDSDichVu(data?.data || []);
          setDsThongTinDv(dataFormated || []);
          if (isBackup) {
            setDsThongTinDvBackup(cloneDeep(dataFormated) || []);
          }
          setDsThongTinDvFiltered(
            handleMapDataDSDichVu(data?.data || [], true)
          );
          handleGetPaymentInfor();
        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const getDanhSachHinhThuc = async () => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 99999999,
      type: CATEGORY_TYPE.HINH_THUC.code,
    };
    try {
      const { data } = await getDanhSachHinhThucService(searchObject);

      if (CODE.SUCCESS === data?.code) {
        setDanhSachHinhThuc(data?.data?.content || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapDataDSPhieu = (data: any) => {
    let dataMap = data?.map((item: any, index: number) => {
      return {
        ...item,
        date: formatDateToDDMMYYYY(item?.invDate),
        price: `${item?.display} ${convertNumberPrice(item?.invTotal)}`,
      };
    });
    return dataMap || [];
  };

  const getDanhSachPhieu = async () => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 99,
      visitId: selectedRow[0]?.visitId,
    };
    try {
      const { data } = await getDanhSachPhieuService(searchObject);
      if (CODE.SUCCESS === data?.code) {
        setListDanhSachPhieu(handleMapDataDSPhieu(data?.data?.content));
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDanhSachHinhThuc();
    getDanhSachLoaiPhieu();
  }, []);

  useEffect(() => {
    if (selectedRow[0]) {
      getDanhSachPhieu();
      setInvTypeCode(null);
      getDanhSachThongTinDichVu(true);
      setIsTaoPhieu(false);
      setShowInforInvoices(false);
      setArraySelect([]);
      // handleCheckIsXuTri();
      setKeywordDV("");
    }
  }, [selectedRow]);

  useEffect(() => {
    //TH BN không có phiếu tạm ứng thì k hiển thị loại phiếu Hoàn ứng
    // const hasPhieuTamUng = listDanhSachPhieu.some((item: any) => item?.invTypeCode === INV_TYPE_CODE.PHIEU_TAM_UNG.code);

    setDanhSachLoaiPhieu(
      !selectedRow[0]?.visitDateStopped
        ? dsLoaiPhieuBackup?.filter(
            (item: any) => item?.code !== INV_TYPE_CODE.PHIEU_HOAN.code
          )
        : dsLoaiPhieuBackup
    );
  }, [listDanhSachPhieu]);

  const handleSetPaymentInfor = () => {
    setPaymentItem([
      {
        name: "Tổng chi phí",
        value: paymentInfor?.ttTong,
        type: "price",
      },
      {
        name: "Giảm dịch vụ",
        value: paymentInfor?.ttDiscountPercent,
        type: "price",
      },
      {
        name: "Bệnh nhân",
        value: paymentInfor?.ttNguoiBenh,
        type: "price",
      },
      {
        name: "Tạm ứng",
        value: paymentInfor?.ttTamUng,
        type: "price",
      },
      {
        name: "Đã chi",
        value: paymentInfor?.ttDaChi,
        type: "price",
      },
      {
        name: "Đã thu",
        value: paymentInfor?.ttDaThu,
        type: "price",
        color: "text-danger",
      },
      {
        name: "Giảm phiếu thu",
        value: paymentInfor?.ttDiscountInv,
        type: "price",
      },
      {
        name:
          (paymentInfor?.ttTamUng + paymentInfor?.ttDaThu) >
          paymentInfor?.ttNguoiBenh
            ? "Còn dư"
            : "Còn nợ",
        value: paymentInfor?.ttConNo,
        type: "price",
      },
      {
        name: "BHYT",
        value: paymentInfor?.ttBhyt,
        type: "price",
      },
    ]);
  };

  useEffect(() => {
    handleSetPaymentInfor();
  }, [paymentInfor]);

  const getInforExpland = () => {
    return (
      // <div className="d-flex flex-space-between">
      //   <div>
      //     <div className="fw-bold text-pri text-center">Hoàn trả</div>
      //     <div className="fw-bold text-danger text-center">
      //       {convertNumberPrice(paymentInfor?.ttDaChi)}
      //     </div>
      //   </div>
        <div>
          <div className="fw-bold text-pri text-center">Số tiền thực thu</div>
            <div className="fw-bold text-danger text-center">
              {paymentInfor?.ttThucTe
                ? convertNumberPrice(paymentInfor?.ttThucTe)
                : 0}
            </div>
          </div>
      // </div>
    );
  };

  useEffect(() => {
    if(showInforInvoices) {
      setDsThongTinDv(dsThongTinDvFiltered)
    }
  }, [showInforInvoices]);

  const handleSelectRowData = async (item: any) => {
    setShowInforInvoices(true);
    setIsLoading(true);

    try {
      const { data } = await getInvoiceByIdService(item?.id);
      if (CODE.SUCCESS === data?.code) {
        let loaiPhieu = dsLoaiPhieu.find(
          (item: any) => item.code === data?.data?.invTypeCode
        );
        let hinhThuc = dsHinhThuc.find(
          (item: any) => item.id === data?.data?.invHinhThucId
        );

        setInvTypeCode(data?.data?.invTypeCode);
        setInvoicesInfor({
          ...data?.data,
          discountPercent: data?.data?.discountPercent,
          loaiPhieu,
          hinhThuc,
        });
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYesClick = () => {
    handleOpenConfirmDialog("Hủy phiếu");
  };

  const handleGetParams = (itemPrint: any) => {
    return {
      id: itemPrint?.id,
      invoiceType: MAP_EXPORT_PATH[itemPrint?.invTypeCode],
    };
  };

  const handleChangeValueTable = (
    row: any,
    value: number,
    index: any,
    name?: any
  ) => {
    let discountClone: any = "";
    let svcPtTotalClone: any = "";
    let paymentPtPercentClone: any = "";
    if (name === "paymentPtPercentClone") {
      let isDiscountPer = value <= 100;
      discountClone = isDiscountPer
        ? Math.ceil(Number(row?.svcQuantity * row?.svcPrice * (value / 100)))
        : Number(value);
      paymentPtPercentClone = value;
    } else if (name === "discountClone") {
      paymentPtPercentClone = Number(
        (value / (row?.svcQuantity * row?.svcPrice)) * 100
      ).toFixed(2);
      discountClone = value;
    }
    svcPtTotalClone = Number(row?.svcTotal - discountClone);
    const newList = dsThongTinDv?.map((item: any) => ({
      ...item,
      orderData: item?.orderData?.map((orderItem: any) => {
        if (orderItem?.id === row?.id) {
          let rowEdited = {
            ...orderItem,
            paymentPtPercentClone: paymentPtPercentClone,
            discountClone: discountClone,
            svcPtTotal: svcPtTotalClone,
            svcPtTotalClone: svcPtTotalClone,
          };
          setRowEdit(rowEdited);
          return rowEdited;
        }
        return {
          ...orderItem,
        };
      }),
    }));

    const newListDVDiscount = listDVDiscount?.map((item: any) => ({
      ...item,
      orderData: item?.orderData?.map((orderItem: any) => {
        if (orderItem?.id === row?.id) {
          let rowEdited = {
            ...orderItem,
            paymentPtPercentClone: paymentPtPercentClone,
            discountClone: discountClone,
            svcPtTotal: svcPtTotalClone,
            svcPtTotalClone: svcPtTotalClone,
          };
          setRowEdit(rowEdited);
          return rowEdited;
        }
        return {
          ...orderItem,
        };
      }),
    }));


    handleCalculateInvTotal(newListDVDiscount);
    let newListDsTTDV = handleMapDataDSDichVu(newList);

    setDsThongTinDv(newListDsTTDV);
    setListDVSelect(newList.flatMap((item: any) => item?.orderData));
    setListDVDiscount(newListDVDiscount || []);
  };

  return (
    <div className="TTVienPhi">
      <div className="bg-white spaces pt-6 pb-10 spaces fl-1">
        <div className="spaces px-10 py-5">
          <InputSearch
            size="sm"
            placeholder="Tìm kiếm"
            value={keywordDV}
            handleChange={handleChange}
            handleSearch={getDanhSachThongTinDichVu}
            handleKeyDown={handleKeyDown}
            className="py-2"
          />
        </div>
        <TableCollapseCustom
          columns={getColumnThongTinVienPhi(
            invoicesInfor?.invTypeCode === INV_TYPE_CODE.PHIEU_THU.code,
            handleChangeValueTable,
            dsThongTinDv,
            setInputFocused,
            inputFocused,
            arraySelect,
            setRowEdit
          )}
          data={dsThongTinDv || []}
          // collapseHeader="termClassNameClone"
          // collapseBody="orderData"
          childrenField="orderData"
          className={`thongTinVienPhi overflow-auto spaces ${
            isTaoPhieu || showInforInvoices
              ? selectedRow[0]?.visitObjectTypeName === PATIENT_TYPE.BHYT.name
                ? "h-calc-578px"
                : invoicesInfor?.invCanceled
                ? "h-calc-580px"
                : invoicesInfor?.invTypeCode === INV_TYPE_CODE.PHIEU_THU.code &&
                  invoicesInfor?.invTemporaryRefundId
                ? "h-calc-545px"
                : "h-calc-535px"
              : "h-calc-400px"
          }`}
          height={``}
          classNameTable="spaces W-2000"
          handleClickRow={() => null}
          handleContextMenu={() => null}
        />
      </div>
      <div>
        <PaymentInfor
          className="price-info"
          paymentData={paymentItem}
          children={getInforExpland()}
        />
        <div className="mt-2 px-2 w-100">
          <DanhSachPhieu
            dsPhieu={listDanhSachPhieu}
            handleSelectRowData={handleSelectRowData}
            title="price"
            className="min-vh-52px"
            isAllowPrint
            fetchExport={inPhieuVienPhi}
            handleGetParams={handleGetParams}
          />
        </div>
        <div className="spaces ml-10 border-top fl-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitForm(values)}
            innerRef={refForm}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              resetForm,
            }) => {
              setFieldValueRef.current = setFieldValue;
              return (
                <Form id="form-vien-phi">
                  <div>
                    <div className="flex-grow-1 mb-2 bg-white pe-3">
                      <div>
                        {(isTaoPhieu || showInforInvoices) && (
                          <Row>
                            {selectedRow[0]?.visitObjectTypeName ===
                              PATIENT_TYPE.BHYT.name && (
                              <>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <TextField
                                      label="Số BHYT"
                                      className="input"
                                      labelClassName="min-w-105px"
                                      name="soBHYT"
                                      type="text"
                                      value={selectedRow[0]?.insCode}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <TextField
                                      label="Nơi sống"
                                      labelClassName="min-w-105px"
                                      className="input"
                                      name="noiSong"
                                      type="text"
                                      value={
                                        selectedRow[0]?.provinceName
                                          ? handleConvertAddress(selectedRow[0])
                                          : selectedRow[0]?.insAddress
                                      }
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col xs={6}>
                                  <div className="label-input-group mt-2">
                                    <TextField
                                      label="KCBBĐ"
                                      labelClassName="min-w-105px"
                                      className="input flex-1"
                                      name="KCBBD"
                                      type="text"
                                      disabled
                                      value={selectedRow[0]?.insKcbbdName}
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <TextField
                                      label="Tuyến BHYT"
                                      labelClassName="min-w-105px"
                                      className="input"
                                      name="tuyenBHYT"
                                      type="text"
                                      disabled
                                      value={
                                        selectedRow[0]?.visitAttributes
                                          ?.examLevelName
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <TextField
                                      label="Hạn thẻ"
                                      labelClassName="min-w-105px"
                                      className="input"
                                      name="hanThe"
                                      type="text"
                                      disabled
                                      value={
                                        selectedRow[0]?.insTo
                                          ? moment(
                                              selectedRow[0]?.insTo
                                            ).format("DD/MM/YYYY")
                                          : null
                                      }
                                    />
                                  </div>
                                </Col>
                              </>
                            )}

                            <Col xs={3}>
                              <div
                                ref={myRef}
                                className="label-input-group mt-2"
                              >
                                <TextField
                                  label="Số phiếu"
                                  labelClassName="min-w-105px"
                                  className="input"
                                  name="invCode"
                                  type="text"
                                  value={invoicesInfor?.invCode}
                                  disabled={true}
                                  onChange={(e: any) => {
                                    setInvoicesInfor({
                                      ...invoicesInfor,
                                      invCode: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Ngày thu"
                                  labelClassName="min-w-105px"
                                  className="input"
                                  name="invDate"
                                  type="text"
                                  value={
                                    invoicesInfor?.invDate
                                      ? moment(invoicesInfor?.invDate).format(
                                          "DD/MM/YYYY"
                                        )
                                      : null
                                  }
                                  disabled={true}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Người thu"
                                  labelClassName="min-w-105px"
                                  className="input"
                                  name="invCashier"
                                  type="text"
                                  value={invoicesInfor?.invCashier}
                                  disabled={true}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="d-flex label-input-group mt-2">
                                <LabelRequired
                                  label="Hình thức"
                                  className="label fw-500 spaces min-w-105px"
                                />
                                {showInforInvoices ? (
                                  <TextField
                                    className="input w-100"
                                    name="hinhThuc"
                                    type="text"
                                    value={invoicesInfor?.hinhThuc?.name}
                                    disabled
                                  />
                                ) : (
                                  <AutocompleteV2
                                    className="autocomplete-custom-tiep-nhan h-26"
                                    options={dsHinhThuc}
                                    name="hinhThuc"
                                    value={invoicesInfor?.hinhThuc}
                                    disabled={showInforInvoices}
                                    onChange={(selectOption) =>
                                      handleSelectChange(
                                        selectOption,
                                        "hinhThuc",
                                        setFieldValue
                                      )
                                    }
                                    errors={errors?.hinhThuc}
                                    touched={touched?.hinhThuc}
                                    menuPlacement="top"
                                  />
                                )}
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="d-flex label-input-group mt-2">
                                <LabelRequired
                                  label="Loại phiếu"
                                  className="label fw-500 spaces min-w-105px"
                                />
                                {showInforInvoices ? (
                                  <TextField
                                    className="input w-100"
                                    name="loaiPhieu"
                                    type="text"
                                    value={invoicesInfor?.loaiPhieu?.name}
                                    disabled
                                  />
                                ) : (
                                  <AutocompleteV2
                                    className="autocomplete-custom-tiep-nhan h-26"
                                    name="loaiPhieu"
                                    value={invoicesInfor?.loaiPhieu}
                                    options={dsLoaiPhieu}
                                    disabled={showInforInvoices}
                                    onChange={(selectOption) =>
                                      handleSelectChange(
                                        selectOption,
                                        "loaiPhieu",
                                        setFieldValue
                                      )
                                    }
                                    errors={errors?.loaiPhieu}
                                    touched={touched?.loaiPhieu}
                                    menuPlacement="top"
                                  />
                                )}
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <NumberField
                                  label="Số tiền"
                                  labelClassName="min-w-105px fw-bold"
                                  className="input no-spinners text-end invTotal"
                                  name="invTotal"
                                  value={invoicesInfor?.invTotal}
                                  disabled={
                                    showInforInvoices ||
                                    invTypeCode ===
                                      INV_TYPE_CODE.PHIEU_THU.code ||
                                    invTypeCode ===
                                      INV_TYPE_CODE.PHIEU_HOAN.code
                                  }
                                  onChange={(value: number) => {
                                    let isPhieuThu =
                                      invTypeCode ===
                                      INV_TYPE_CODE.PHIEU_THU.code;
                                    setFieldValue("invTotal", value);
                                    if (isPhieuThu) {
                                      setFieldValue(
                                        "invTotalActual",
                                        value - (invoicesInfor?.discount || 0)
                                      );
                                    } else {
                                      setFieldValue("invTotalActual", value);
                                    }
                                    setInvoicesInfor({
                                      ...invoicesInfor,
                                      invTotal: value,
                                      invTotalActual: isPhieuThu
                                        ? value - (invoicesInfor?.discount || 0)
                                        : value,
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="d-flex label-input-group mt-2">
                                <TextField
                                  label="Miễn giảm"
                                  labelClassName="min-w-105px"
                                  inputClassName="min-w-60px"
                                  className="input text-end"
                                  name="discountPercent"
                                  placeholder="%"
                                  type="number"
                                  value={invoicesInfor?.discountPercent || ""}
                                  disabled={
                                    showInforInvoices ||
                                    invoicesInfor?.invTypeCode !==
                                      INV_TYPE_CODE.PHIEU_THU.code
                                  }
                                  onChange={(e: any) => {
                                    let isPhieuThu =
                                      invTypeCode ===
                                      INV_TYPE_CODE.PHIEU_THU.code;
                                    let isDiscountPer = e?.target?.value <= 100;
                                    const perDiscount = isDiscountPer ? +(invoicesInfor?.invTotal * (Number(e?.target?.value) / 100)).toFixed(0) : Number(e?.target?.value);
                                    let invTotalActualCal = invoicesInfor?.invTotal - perDiscount;
                                    let invRepayCal = invoicesInfor?.invRevenue - invTotalActualCal;

                                    if (isPhieuThu) {
                                      setFieldValue("invTotalActual", invTotalActualCal);
                                      invoicesInfor?.invRevenue && setFieldValue("invRepay", invRepayCal);
                                    }

                                    setInvoicesInfor({
                                      ...invoicesInfor,
                                      discountPercent: e.target.value,
                                      discount: perDiscount,
                                      invTotalActual: isPhieuThu ? invTotalActualCal : invoicesInfor?.invTotal,
                                      invRepay: invoicesInfor?.invRevenue ? invRepayCal : 0,
                                    });
                                  }}
                                />
                                <NumberField
                                  label=""
                                  labelClassName="min-w-105px"
                                  className="input text-end"
                                  name="discount"
                                  value={invoicesInfor?.discount}
                                  disabled={
                                    showInforInvoices ||
                                    invoicesInfor?.invTypeCode !==
                                      INV_TYPE_CODE.PHIEU_THU.code ||
                                    invoicesInfor?.discountPercent
                                  }
                                  onChange={(value: number) => {
                                    let isPhieuThu =
                                      invTypeCode ===
                                      INV_TYPE_CODE.PHIEU_THU.code;
                                    if (isPhieuThu) {
                                      setFieldValue(
                                        "invTotalActual",
                                        invoicesInfor?.invTotal - value
                                      );
                                    }

                                    setInvoicesInfor({
                                      ...invoicesInfor,
                                      discount: value,
                                      invTotalActual: isPhieuThu
                                        ? value < invoicesInfor?.invTotal
                                          ? invoicesInfor?.invTotal - value
                                          : "0"
                                        : invoicesInfor?.invTotal,
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label={
                                    invTypeCode ===
                                    INV_TYPE_CODE.PHIEU_HOAN.code
                                      ? "Số tiền thực chi"
                                      : "Số tiền thực thu"
                                  }
                                  labelClassName="min-w-105px"
                                  className="input no-spinners text-end invTotal"
                                  name="invTotalActual"
                                  value={
                                    invoicesInfor?.invTotalActual
                                      ? formatMoney(
                                          invoicesInfor?.invTotalActual
                                        )
                                      : ""
                                  }
                                  disabled={true}
                                />
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Nội dung"
                                  labelClassName="min-w-105px"
                                  className="input"
                                  name="invRemark"
                                  value={invoicesInfor?.invRemark}
                                  disabled={showInforInvoices}
                                  // onChange={(e: any) => {
                                  //   setInvoicesInfor({
                                  //     ...invoicesInfor,
                                  //     invRemark: e.target.value,
                                  //   });
                                  // }}
                                />
                              </div>
                            </Col>
                            <Col
                              xs={
                                selectedRow[0]?.visitObjectTypeName ===
                                PATIENT_TYPE.BHYT.name
                                  ? 3
                                  : 6
                              }
                            >
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Lý do miễn giảm"
                                  labelClassName="min-w-105px"
                                  className="input flex-1"
                                  name="discountReason"
                                  value={invoicesInfor?.discountReason}
                                  disabled={
                                    showInforInvoices ||
                                    invoicesInfor?.invTypeCode !==
                                      INV_TYPE_CODE.PHIEU_THU.code
                                  }
                                  onChange={(e: any) => {
                                    setInvoicesInfor({
                                      ...invoicesInfor,
                                      discountReason: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                            {invoicesInfor?.invTypeCode ===
                              INV_TYPE_CODE.PHIEU_THU.code && (
                              <>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <NumberField
                                      label="Tiền nhận"
                                      labelClassName="min-w-105px fw-bold"
                                      className="input no-spinners text-end invTotal"
                                      name="invRevenue"
                                      value={invoicesInfor?.invRevenue}
                                      disabled={showInforInvoices}
                                      onChange={(value: number) => {
                                        setFieldValue("invRevenue", value);
                                        let isMore = value > invoicesInfor?.invTotalActual;
                                        
                                        setInvoicesInfor({
                                          ...invoicesInfor,
                                          invRevenue: value,
                                          invRepay: isMore ? value - invoicesInfor?.invTotalActual : 0,
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="label-input-group mt-2">
                                    <NumberField
                                      label="Trả lại"
                                      labelClassName="min-w-105px fw-bold"
                                      className="input no-spinners text-end invTotal"
                                      name="invRepay"
                                      value={invoicesInfor?.invRepay}
                                      disabled={
                                        showInforInvoices ||
                                        invoicesInfor?.invRevenue
                                      }
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                            {invoicesInfor?.invTypeCode ===
                              INV_TYPE_CODE.PHIEU_THU.code &&
                              invoicesInfor?.invTemporaryRefundId && (
                                <>
                                  <Col xs={3}>
                                    <div className="label-input-group mt-2">
                                      <TextField
                                        label="Số tiền hoàn"
                                        labelClassName="min-w-105px"
                                        className="input no-spinners text-end invTotal"
                                        name="invRefund"
                                        value={
                                          invoicesInfor?.invRefund
                                            ? formatMoney(
                                                invoicesInfor?.invRefund
                                              )
                                            : ""
                                        }
                                        disabled={true}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="label-input-group mt-2">
                                      <TextField
                                        label={
                                          invoicesInfor?.infoRefund === 0
                                            ? "Thu thêm"
                                            : "Trả lại"
                                        }
                                        labelClassName="min-w-105px"
                                        className="input no-spinners text-end invTotal"
                                        name="invGiveBack"
                                        value={
                                          invoicesInfor?.invGiveBack
                                            ? formatMoney(
                                                Math.abs(
                                                  invoicesInfor?.invGiveBack
                                                )
                                              )
                                            : ""
                                        }
                                        disabled={true}
                                      />
                                    </div>
                                  </Col>
                                </>
                              )}
                            {invoicesInfor?.invCanceled && (
                              <>
                                <Col xs={3}>
                                  <div className="d-flex label-input-group mt-2">
                                    <LabelRequired
                                      label="Ngày hủy"
                                      className="label fw-500 spaces min-w-105px"
                                    />
                                    <TextField
                                      className="input w-100"
                                      name="invCanceledDate"
                                      type="text"
                                      value={formatDateTime(
                                        invoicesInfor?.invCanceledDate,
                                        APP_DATE_TIME_FORMAT
                                      )}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="d-flex label-input-group mt-2">
                                    <LabelRequired
                                      label="Người hủy"
                                      className="label fw-500 spaces min-w-105px"
                                    />
                                    <TextField
                                      className="input w-100"
                                      name="invCanceledBy"
                                      type="text"
                                      value={invoicesInfor?.invCanceledBy}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="d-flex label-input-group mt-2">
                                    <LabelRequired
                                      label="Hình thức hủy"
                                      className="label fw-500 spaces min-w-105px"
                                    />
                                    <TextField
                                      className="input w-100"
                                      name="invCanceledType"
                                      type="text"
                                      value={invoicesInfor?.invCanceledFormular}
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="d-flex label-input-group mt-2">
                                    <LabelRequired
                                      label="Lý do hủy"
                                      className="label fw-500 spaces min-w-105px"
                                    />
                                    <TextField
                                      className="input w-100"
                                      name="invCanceledReason"
                                      type="text"
                                      value={invoicesInfor?.invCanceledReason}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-start pb-1">
                      {!isTaoPhieu &&
                        !showInforInvoices &&
                        !paymentInfor?.paymentApprovalTime && (
                          <>
                            <Button
                              className="btn-fill spaces min-w-100px me-5"
                              onClick={handleOnClickTaoPhieu}
                            >
                              Tạo phiếu
                            </Button>
                          </>
                        )}
                      {!showApproveBtn &&
                        paymentInfor?.visitDateStopped &&
                        paymentInfor?.ttConNo === 0 &&
                        !paymentInfor?.paymentApprovalTime && (
                          <>
                            <Button
                              className="btn-fill spaces min-w-100px mx-5"
                              onClick={() => setOpenApprovePopup(true)}
                            >
                              Duyệt thanh toán
                            </Button>
                          </>
                        )}
                      {paymentInfor?.visitDateStopped &&
                        paymentInfor?.paymentApprovalTime && (
                          <>
                            <Button
                              className="btn-fill spaces min-w-130px mx-5"
                              onClick={() => setOpenCancelApprovePopup(true)}
                            >
                              Gỡ duyệt thanh toán
                            </Button>
                          </>
                        )}
                      {isTaoPhieu && !showInforInvoices && (
                        <>
                          <Button
                            className="btn-fill min-w-80px"
                            onClick={() => handleSubmit()}
                          >
                            Lưu phiếu
                          </Button>
                          <Button
                            className="btn-secondary min-w-80px spaces mx-4"
                            onClick={() => {
                              resetForm();
                              setIsTaoPhieu(false);
                              setInvTypeCode(null);
                              setArraySelect([]);
                              setShowApproveBtn(false);
                            }}
                          >
                            Hủy
                          </Button>
                        </>
                      )}
                      {showInforInvoices && (
                        <>
                          {!((paymentInfor?.visitDateStopped && paymentInfor?.paymentApprovalTime) || invoicesInfor?.invCanceled || invTypeCode === INV_TYPE_CODE.PHIEU_CHI.code) && (
                            <Button
                              className="btn-danger min-w-80px spaces mx-4"
                              onClick={() =>
                                invoicesInfor?.invTypeCode ===
                                INV_TYPE_CODE.PHIEU_TAM_UNG.code
                                  ? setOpenConfirmDialog(true)
                                  : setOpenConfirmDialogCustom(true)
                              }
                            >
                              Hủy phiếu
                            </Button>
                          )}
                          {!invoicesInfor?.invCanceled && (
                            <ButtonInPhieu
                              fetchExport={inPhieuVienPhi}
                              params={{
                                id: invoicesInfor.id,
                                invoiceType:
                                  MAP_EXPORT_PATH[invoicesInfor.invTypeCode],
                              }}
                              className="min-w-80px spaces mx-4"
                            />
                          )}
                          <Button
                            className="btn-secondary min-w-80px spaces ml-4 mr-2"
                            onClick={() => {
                              resetForm();
                              setIsTaoPhieu(false);
                              setShowInforInvoices(false);
                              setInvTypeCode(null);
                              setArraySelect([]);
                            }}
                          >
                            Đóng
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {
        <ConfirmDialog
          show={openConfirmDialog}
          close="Hủy"
          yes="Xác nhận"
          onYesClick={handleSubmit}
          onCloseClick={() => setOpenConfirmDialog(false)}
          title="Xác nhận hủy phiếu"
        />
      }
      {openCancelApprovePopup && (
        <PopupCancelPaymentApprove
          onClose={() => setOpenCancelApprovePopup(false)}
          dataUser={dataUser}
          invoicesInfor={selectedRow}
          infoBenhNhan={{
            ...paymentInfor,
            patient: {
              personName: paymentInfor?.personName,
              code: paymentInfor?.code,
              gender: paymentInfor?.gender,
              dobDay: paymentInfor?.dobDay,
              dobMonth: paymentInfor?.dobMonth,
              dobYear: paymentInfor?.dobYear,
              provinceName: paymentInfor?.provinceName,
              districtName: paymentInfor?.districtName,
              subDistrictName: paymentInfor?.subDistrictName,
            },
            visit: {
              visitObjectTypeName: paymentInfor.visitObjectTypeName,
            },
          }}
          handleGetPaymentInfor={handleGetPaymentInfor}
        />
      )}

      {openApprovePopup && (
        <PopupPaymentApprove
          onClose={() => setOpenApprovePopup(false)}
          invoicesInfor={selectedRow}
          handleGetPaymentInfor={handleGetPaymentInfor}
        />
      )}

      <ConfirmDialogCustom
        show={openConfirmDialogCustom}
        title="Xác nhận"
        message={
          <div className="spaces p-0 font-weight-normal">
            <div>
              Phiếu "{invoicesInfor?.invCode}" đang được thanh toán cho các dịch
              vụ sau:
            </div>
            <div className="spaces mt-2">
              {invoicesInfor?.termSvcNames?.map((item: any, index: any) => {
                return <div key={index}>{item}</div>;
              })}
            </div>
            <div className="spaces mt-2">
              Bạn có chắc chắn muốn hủy phiếu này không ?
            </div>
          </div>
        }
        yes="Có"
        close="Không"
        onYesClick={handleYesClick}
        onCloseClick={() => setOpenConfirmDialogCustom(false)}
      />
    </div>
  );
};
export default ThongTinVienPhi;
