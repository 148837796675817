/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { KEY_DS_DANH_MUC_TIEP_DON } from "../../../../app/modules/utils/Constant";
import { deleteIndexedDBItem } from "../../../../app/modules/utils/IndexedDB";
import { localStorageItem } from "../../../../app/modules/utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../../app/modules/auth/core/_consts";

const ClearCache: FC = () => {
  const linkTo = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["oauthClientUrl"] || "/home";

  const handleDeleteLocalStorage = async () => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key?.startsWith('form-')) {
        localStorageItem.remove(key);
      }
    };

    await deleteIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
    window.location.href = linkTo;
  };

  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom"
    >
      <a href="#" className="menu-link px-5" onClick={() => handleDeleteLocalStorage()}>
        <span className="menu-title position-relative">{"Xóa cache"}</span>
      </a>
    </div>
  );
};

export { ClearCache };
