import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import { localStorageItem } from "./LocalStorage";

export const speaker = (() => {
    const handleSpeak = (content: string) => {
        const axios = require('axios');

        const url  = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ttsUrl"] || process.env.REACT_APP_TTS_ENDPOINT);
        
        const headers = {
          'Accept': '*/*',
          'Accept-Language': 'en-US,en;q=0.9,vi;q=0.8',
          'Connection': 'keep-alive',
        };
        
        const data = {
          data: [content],
          event_data: null,
          fn_index: 0,
          trigger_id: 11,
          session_hash: "phatloatong"
        };
        
        axios.post(url, data, { headers })
          .then((response: { data: any; }) => {
            console.log('Response data:', response.data);
          })
          .catch((error: { message: any; }) => {
            console.error('Error:', error.message);
          });
    };

    return {handleSpeak}
})();