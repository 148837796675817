/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { useIntl } from 'react-intl';
import { ClearCache } from './ClearCache'
import ChangePassWordDialog from '../../../../app/modules/phan-he-quan-tri-he-thong/modals/modal-doi-mat-khau/ChangePassWordDialog'
const HeaderUserMenu: FC = () => {
  const intl = useIntl();
  const {currentUser, logout} = useAuth();
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState<boolean>(false);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      {/* <Languages /> */}
      <ClearCache />
      <div className='menu-item px-5 my-1'>
        <a onClick={() => setOpenChangePasswordDialog(true)} className='menu-link px-5'>Đổi mật khẩu</a>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
        {intl.formatMessage({ id: 'LOGOUT' })}
        </a>
      </div>

      {openChangePasswordDialog &&
        <ChangePassWordDialog
          open={openChangePasswordDialog}
          handleCloseDialog={() => setOpenChangePasswordDialog(false)}
        />
      }
    </div>
  )
}

export {HeaderUserMenu}
