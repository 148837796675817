import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import "./styles.scss";
import { createRoot } from 'react-dom/client';

type Props = {
    columns: any[];
    searchData?: any;
    axiosReq?: (searchData?: any) => Promise<AxiosResponse<any>>;
    containerClassName?: string,
    className?: string,
    tableHeight?: number,
    onRowClick?: (row: any, index: number) => void;
    handleContextMenu?: (e: any, row: any) => void;
    dependencies?: any[];
    rowSeclected?: any;
    rowKey?: string;
    urlData?: string;
};

const LazyLoadTable: React.FC<Props> = (props) => {
    const { columns, searchData, axiosReq,
        containerClassName, className, tableHeight, onRowClick, handleContextMenu,
        dependencies = [], rowKey = "id", rowSeclected,
        urlData="data.data.content"
    } = props;
    const { setIsLoading } = useContext(AppContext);
    const CODE_SUCCESS = 200;
    const PAGE_SIZE = 20;
    const DEFAULT_PAGE_INDEX = 1;
    const HEIGHT_UPDATE = 2000;
    const HEIGHT_LOADING = 50;
    const tbodyRef = useRef<HTMLTableSectionElement>(null);
    let idRowSelected: any = null;

    const [loading, setLoading] = useState<boolean>(true);
    const [stopLoad, setStopLoad] = useState<boolean>(false);
    const [hasLoadedMore, setHasLoadedMore] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
    const [clitenWidthTable, setClientWidthTable] = useState(0);

    const handleRowClick = (row: any, index: number) => {
        const elementRowClick = document.getElementById(`lz-row-table-${row?.[rowKey]}`);
        if (idRowSelected && idRowSelected === row?.[rowKey]) {
            idRowSelected = null;
            // onRowClick && onRowClick(null, index);
            elementRowClick && elementRowClick.classList.remove('row-selected');
        } else {
            const selectedRows = tbodyRef.current?.querySelectorAll('tr.row-selected');
            selectedRows?.forEach((tr) => {
                tr.classList.remove('row-selected');
            });
            idRowSelected = row?.[rowKey];
            onRowClick && onRowClick(row, index);
            elementRowClick && elementRowClick.classList.add('row-selected');
        }
    };

    const handleCustomContextMenu = (e: any, row: any) => {
        e.preventDefault();
        handleContextMenu && handleContextMenu(e, row);
    };

    const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (loading || stopLoad) {
            e.preventDefault();
            return;
        }
        const { scrollTop, scrollHeight, clientHeight, clientWidth } = e.currentTarget || {};
        !clitenWidthTable && setClientWidthTable(clientWidth);
        if (scrollHeight === clientHeight) return;
        const heightRemaining = scrollHeight - scrollTop - clientHeight;
        if (heightRemaining < HEIGHT_UPDATE && !hasLoadedMore) {
            setHasLoadedMore(true);
            updateData(pageIndex + 1, stopLoad);
        }
        heightRemaining < HEIGHT_LOADING && setLoading(heightRemaining < HEIGHT_LOADING);
    };

    const clearTooltips = () => {
        const tooltips = document.querySelectorAll('.tooltip');
        tooltips.forEach(tooltip => {
            tooltip.classList.remove('show');
        });
    };
    
    const convertNameUrl = (item: any) => {
        const array = urlData.split(".");
        for (let i = 0; i < array.length; i++) {
            item = item?.[array[i]];
        }
        return item;
    };

    const updateData = async (pIndex: number, stopLoad: boolean) => {
        try {
            setStopLoad(stopLoad);
            if (pIndex === DEFAULT_PAGE_INDEX) {
                setLoading(true);
            };
            setPageIndex(pIndex);
            const response = axiosReq && await axiosReq({ ...searchData, pageIndex: pIndex, pageSize: PAGE_SIZE });
            if (CODE_SUCCESS === response?.data?.code) {
                if (tbodyRef.current && pIndex === DEFAULT_PAGE_INDEX) {
                    tbodyRef.current.innerHTML = ""; //xóa tr hiện tại
                    const tableContainer = tbodyRef.current.parentElement;
                    tableContainer && (tableContainer.scrollTop = 0); //reset scoll
                };
                let newRows = convertNameUrl(response);
                setStopLoad(newRows.length < PAGE_SIZE);
                let currentLength = tbodyRef.current?.childElementCount || 0;
                newRows = newRows.map((row: any, index: number) => ({
                    ...row,
                    index: index + currentLength
                }));
                
                newRows.forEach((row: any, rowIndex: number) => {
                    const tr = document.createElement("tr");
                    tr.id = row?.[rowKey] ? `lz-row-table-${row?.[rowKey]}` : `lz-row-table-${Math.random()}`;
                    tr.className = `${onRowClick ? 'pointer' : ''} ${(row?.[rowKey] === rowSeclected?.[rowKey]) ? 'row-selected' : ''}`;
                    tr.onclick = () => handleRowClick(row, (rowIndex + currentLength));
                    columns.forEach((column) => {
                        const td = document.createElement("td");
                        const content = column.render ? column.render(row, (rowIndex + currentLength)) : row[column.field];
                        React.isValidElement(content) ? createRoot(td).render(content) : td.textContent = content;
                        column.cellStyle && Object.assign(td.style, column.cellStyle);
                        tr.appendChild(td);
                    });
                    // append tr vào body
                    tbodyRef.current?.appendChild(tr);
                    tr.addEventListener("contextmenu", (e: any) => handleCustomContextMenu(e, row));
                });
            }
        } catch (error) {
            console.error("Error updating data:", error);
        } finally {
            setIsLoading(false);
            setLoading(false);
            setHasLoadedMore(false);
            clearTooltips();
        }
    };

    const appendLoading = () => {
        const tr = document.createElement("tr");
        const td = document.createElement("td");
        tr.classList.add("lz-table-loading");
        !clitenWidthTable && td.classList.add("text-center");
        td.setAttribute("colSpan", columns.length.toString());

        const svgNS = "http://www.w3.org/2000/svg";
        const svg = document.createElementNS(svgNS, "svg");
        !!clitenWidthTable && (svg.style.marginLeft = `${Math.ceil(clitenWidthTable / 2)}px`);
        svg.setAttribute("width", "40");
        svg.setAttribute("height", "40");
        svg.setAttribute("viewBox", "0 0 140 140");

        const circle = document.createElementNS(svgNS, "circle");
        circle.setAttribute("id", "spinner");
        circle.setAttribute("cx", "50");
        circle.setAttribute("cy", "50");
        circle.setAttribute("r", "45");

        svg.appendChild(circle);
        td.appendChild(svg);
        tr.appendChild(td);
        tbodyRef.current?.appendChild(tr);
    };

    useEffect(() => {
        if (loading) {
            appendLoading();
        } else {
            const loadingRows = tbodyRef.current?.querySelectorAll(".lz-table-loading");
            loadingRows?.forEach((row) => row.remove());
        }
    }, [loading]);

    useEffect(() => {
        searchData && axiosReq && updateData(DEFAULT_PAGE_INDEX, false);
    }, [searchData, ...dependencies]);

    useEffect(() => {
        return () => {
            const selectedRows = tbodyRef.current?.querySelectorAll('tr');
            selectedRows?.forEach((tr) => {
                $(tr).off("contextmenu");
            });
        };
    }, []);

    return (
        <div className={`${containerClassName} lz-container`}>
            <div
                onScroll={onScroll}
                className={`lz-table-container ${className}`}
                style={tableHeight ? { maxHeight: `${tableHeight}px` } : {}}
            >
                <table className="lz-table">
                    <thead className="lz-table-header">
                        <tr>
                            {columns.map((column, index) => (
                                <th key={column.field + "-" + index} style={column.headerStyle}>
                                    {column.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody ref={tbodyRef} className="lz-table-body" id="lz-table-body"></tbody>
                </table>
            </div>
        </div>
    );
};

export default LazyLoadTable;
