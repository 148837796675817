import { Form, Formik } from 'formik'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import CustomTabMenu from '../../../../component/CustomTabMenu'
import ThongTinChuyenTuyen from './ThongTinChuyenTuyen'
import ThongTinRaVien from './ThongTinRaVien'
import { formatDateDTO } from '../../../../utils/FormatUtils'
import { localStorageItem } from '../../../../utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../../../auth/core/_consts'

type Props = {
  open: boolean
  handleCloseModal: () => void
  handleSubmit: (values: any) => void
  xuTriData?: any
}

const ChuyenTuyenModal = ({
  open,
  handleCloseModal,
  handleSubmit,
  xuTriData
}: Props) => {
  const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);

  const listTab = [
    {
      title: 'Thông tin chuyển tuyển',
      component: <ThongTinChuyenTuyen />
    },
    {
      title: 'Thông tin ra viện',
      component: <ThongTinRaVien />
    },
  ];

  const handleFormSubmit = (values: any) => { 
    let submitData = {
      ...values,
      htTime: formatDateDTO(values?.htTime),
      htVisitStartDate: formatDateDTO(values?.htVisitStartDate),
      htVisitEndDate: formatDateDTO(values?.htVisitEndDate),
      htFor: values?.htFor?.name,
      htModality: values?.htModality?.name,
      htReason: values?.htReason?.name,
      htReasonDoctor: values?.htReasonDoctor?.name,
    };
    handleSubmit(submitData);
  };

  return (
    <Modal
      centered
      className='modal fade'
      role='dialog'
      show={open}
      dialogClassName='modal-xl'
      aria-hidden='true'
    >
      <Formik<any>
        initialValues={{
          htPerson: currentUser?.name,
          htInNetwork: "true",
          htDiagnosis: xuTriData?.icdXPrimary,
          htDiagnosisName: xuTriData?.icdXPrimaryName,
          htComorbidity: xuTriData?.comorbidity,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => (
          <Form id="form-thuoc" >
            <ModalHeader closeButton onHide={handleCloseModal} className='py-4'>
              <h4>Cập nhật phiếu chuyển tuyến</h4>
            </ModalHeader>
            <ModalBody className='spaces pt-0'>
              <CustomTabMenu danhsachTabs={listTab} childrenTab={true} />
            </ModalBody>
            <ModalFooter className='d-flex justify-content-end py-2'>
              <Button className='btn-fill spaces min-w-100'>Cập nhật từ bệnh án</Button>
              <Button className='btn-fill spaces min-w-80' type='submit'>Lưu</Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ChuyenTuyenModal