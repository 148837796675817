import moment from "moment";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row,
  Tooltip
} from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import DatePickerCustom from "../../component/date-picker/DatePickerCustom";
import InputSearch from "../../component/InputSearch";
import LabelRequired from "../../component/LabelRequired";
import SelectTree from "../../component/SelectTree";
import TextValidator from "../../component/TextValidator";
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { convertNumberPrice, formatMoney } from "../../utils/FormatUtils";
import { IDSPhieu, IMenuVienPhi } from "../models/VienPhiInfoModels";
import { getDsPhieuThu } from "../services/PhanHeVienPhiServices";
import { TreeDSPhieu } from "./fakeData";
import TableDanhSachPhieu from "./TableDanhSachPhieu";

type Props = {
  show: boolean;
  onHide: Dispatch<SetStateAction<IMenuVienPhi>>;
  dsPhieu: IDSPhieu[];
};
const DSPhieuTable: FC<Props> = (props) => {
  const { show, onHide, dsPhieu } = props;
  const originalFormat = "YYYY-MM-DD";
  const [keyword, setKeyword] = useState<string>("");
  const { setIsLoading } = useContext(AppContext);
  const [treeData, setTreeData] = useState(TreeDSPhieu);
  const [dsPhieuThu, setDsPhieuThu] = useState<any[]>([]);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [fieldSearch, setFieldSearch] = useState<string>("");
  const [valueSearch, setValueSearch] = useState<string>("");
  const [idSelected, setIdSelected] = useState<string>("");
  const [filter, setFilter] = useState({
    fromDate: moment().format(originalFormat),
    toDate: moment().format(originalFormat),
  });

  const [summary, setSummary] = useState<any>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setKeyword(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    KEY.ENTER === event.key && updatePageData();
  };

  const handleGetSelectedItem = (data: any) => {
    setFieldSearch(data?.parrentCode);
    setValueSearch(data?.name);
  };

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data?.map((item, index) => {
      return {
        ...item,
        code: item?.field,
        name: item?.title,
        filter: item?.items?.map((value: any) => ({
          code: value,
          name: value
        })),
      };
    });
    return convertedData;
  };

  const updatePageData = async () => {
    setIsLoading(true);
    let searchObject = {
      ...filter,
      keyword: keyword,
      field: fieldSearch,
      value: valueSearch,
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: MAX_PAGE_SIZE,
    };
    try {
      let { data } = await getDsPhieuThu(searchObject);
      if (data?.code === CODE.SUCCESS) {
        let treeList = data?.data?.treeList;
        let treeDataConverted = {
          code: "all",
          name: treeList?.title || "",
          filter: handleConvertTreeData(treeList?.items)
        };
        setTreeData(treeDataConverted);
        setDsPhieuThu(data?.data?.invTypes || []);
        setSummary(data?.data?.reportInvoice);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [filter, fieldSearch, valueSearch]);

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() =>
          onHide({
            openChonSoThu: false,
            openDanhSachSoThu: false,
            openTaoMoiSoThu: false,
            openDanhSachPhieu: false,
          })
        }
        size="xl"
      >
        <Modal.Header closeButton className="py-5 header-modal">
          <Modal.Title>Danh sách phiếu thu</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0">
          <div className="d-flex w-100">
            <div className="spaces width-20">
              <div className="d-flex spaces pl-10 mt-10">
                <LabelRequired label="Từ ngày" className="spaces flex-3 min-w-80" />
                <DatePickerCustom
									name="fromDate"
									value={filter.fromDate}
									setDateValue={date => {
										setFilter({
											...filter,
											fromDate: date ?? '',
										});
									}}
                  dateFormatOutput="YYYY-MM-DD"
								/>
              </div>
              <div className="d-flex spaces pl-10 my-10">
                <LabelRequired label="Đến ngày" className="spaces flex-3 min-w-80" />
                <DatePickerCustom
									name="toDate"
									value={filter.toDate}
									setDateValue={date => {
										setFilter({
											...filter,
											toDate: date ?? '',
										});
									}}
                  dateFormatOutput="YYYY-MM-DD"
								/>
              </div>
            <SelectTree
              className="w-100"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              selectTree={treeData}
                getSelectedItem={handleGetSelectedItem}
            />
            </div>
            <div className="spaces p-10 flex-grow-1">
              <Row>
                <Col xs={{ span: 12 }} className="pb-2">
                  <InputSearch
                    handleChange={handleChange}
                    handleSearch={updatePageData}
                    handleKeyDown={handleKeyDown}
                    placeholder="Tìm kiếm"
                    type="text"
                  />
                </Col>
              </Row>
              <div className="h-table border spaces height-80">
                <TableDanhSachPhieu
                  dataDanhSachPhieu={dsPhieuThu} 
                  handleDoubleClick={() => {}}
                />
              </div>
              <div className="flex justify-content-around py-1 pt-3">
                <div className="flex justify-content-between w-100 px-6">
                  <span className="fw-bold color-error">Thực thu</span>
                  <span className="color-error">{formatMoney(summary?.ttThucThu || 0)}</span>
                </div>
                <div className="flex justify-content-between w-100 px-6">
                  <span className="fw-bold">Trong giờ</span>
                  <span className="color-count-number">{formatMoney(summary?.ttTrongGio || 0)}</span>
                </div>
                <div className="flex justify-content-between w-100 px-6">
                  <span className="fw-bold">Miễn giảm</span>
                  <span className="color-count-number">{formatMoney(summary?.ttMienGiam || 0)}</span>
                </div>
                <div className="flex justify-content-between w-100 px-6">
                  <span className="fw-bold">Tạm ứng</span>
                  <span className="color-count-number">{formatMoney(summary?.ttTamUng || 0)}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DSPhieuTable;
