import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Row as ReactTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AppContext } from '../../../appContext/AppContext';
import { KEY_LOCALSTORAGE } from '../../../auth/core/_consts';
import AutocompleteV2 from '../../../component/AutocompleteObjectV2';
import FormInPhieuDialog from '../../../component/button-in-phieu/components/PrintDialog';
import { ConfirmDialog } from '../../../component/ConfirmDialog';
import ContextMenu from '../../../component/ContextMenuV3';
import CustomIconButton from '../../../component/custom-icon-button/CustomIconButton';
import { IconBack, IconButtonSave } from '../../../component/IconSvg';
import InputAutoComplete from '../../../component/input-autocomplete';
import LabelRequired from '../../../component/LabelRequired';
import NumberField from '../../../component/number-field';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { TableCustom } from '../../../component/table/table-custom/TableCustom';
import TextField from '../../../component/TextField';
import { IDanhSachDanhMuc } from '../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import {
	APP_TIMESTAMP_FORMAT,
	CODE,
	CODE_SUCCESS,
	KEY_DS_DANH_MUC_TIEP_DON,
	ORDER_TYPE_ID,
	RESPONSE_MESSAGE,
} from '../../../utils/Constant';
import { formatDateTime, formatMoney } from '../../../utils/FormatUtils';
import { getIndexedDBItem } from '../../../utils/IndexedDB';
import { localStorageItem } from '../../../utils/LocalStorage';
import ICD10 from '../../components/ICD10';
import InfoPatientRight from '../../components/InfoPatientRight';
import { ThuocColumns } from '../../components/modal-tab-thuoc/ThuocColumns';
import SelectThuocButton from '../../components/select-thuoc-button';
import {
	CHI_PHI_THUOC_FIELDS,
	CHI_PHI_THUOC_TYPE,
	CONTEXT_MENU_KE_THUOC_KEY,
	CONTEXT_MENU_SUA_DOI_TUONG_KEYS,
	contextMenuKeThuocOptions,
	HDSD_THUOC_OPTIONS,
	LIEU_DUNG_THUOC_OPTIONS,
	MA_LOAI_DOI_TUONG,
	THOI_DIEM_DUNG_THUOC,
	DRUG_WAREHOUSE_FORM_MAPPER,
} from '../../constants/Thuoc';
import { LOAI_VAT_TU } from '../../constants/VatTu';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { ThuocInfo } from '../../models/DSBenhNhanKhamBenhModels';
import {
	IInPhieuChiDinhThuocParams,
	IKeThuocRequest,
	IKeThuocThongTinChung,
	IKhoThuoc,
	IThoiDiemDung,
	IThuoc,
	IThuocModel,
	IDonThuocMauRequest,
} from '../../models/ThuocModels';
import {
	getDanhSachKhoThuoc,
	getDetailDonThuoc,
	printPhieuChiDinhThuoc,
	saveDonThuoc,
	validateDonThuoc,
} from '../../services/ThuocService';
import { searchVatTu } from '../../services/VatTuService';
import ModalKeVatTu from '../modal-ke-vat-tu/ModalKeVatTu';
import ButtonCapToaChoVe from './components/ButtonCapToaChoVe';
import ButtonDonThuocCu from './components/header/ButtonDonThuocCu';
import NgayKeTuDong from './components/NgayKeTuDong';
import { getThongTinChanDoanSauXuTri } from '../../services/KhamBenhService';
import DonThuocMauButton from './components/DonThuocMauButton';
import DatePickerCustom from '../../../component/date-picker/DatePickerCustom';
import ProvisonalDiagnosis from '../../components/ProvisonalDiagnosis';

type Props = {
	open: boolean;
	handleClose: () => void;
	itemEdit?: any;
	onSave?: () => void;
};

const PageKeThuoc = ({ open, handleClose, itemEdit, onSave }: Props) => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { setIsLoading } = useContext(AppContext);
	const intl = useIntl();

	const formikRef = useRef<FormikProps<IThuocModel>>(null);
	const rowSelectedRef = useRef<any>(null);
	const idDonThuocRef = useRef<string>('');
	const danhSachThuocRef = useRef<any[]>([]);
	const formRef = useRef<HTMLFormElement>(null);
	const khoThuocIdRef = useRef<number>();

	const [danhSachKhoThuoc, setDanhSachKhoThuoc] = useState<IKhoThuoc[]>([]);
	const [thuocSelected, setThuocSelected] = useState<IThuoc>();
	const [tableData, setTableData] = useState<ThuocInfo[]>([]);
	const [listICD10, setListICD10] = useState<any[]>([]);
	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	}>(null);
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

	const [thuocDetail, setThuocDetail] = useState<any>();
	const [khoThuocSelected, setKhoThuocSelected] = useState<IKhoThuoc>();
	const [loaiDoiTuong, setLoaiDoiTuong] = useState<any[]>([]);
	const [openPrintDialog, setOpenPrintDialog] = useState<boolean>(false);
	const [openModalKeVatTu, setOpenModalKeVatTu] = useState(false);
	const [listComorbidity, setListComorbidity] = useState<any[]>([]);
	const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);
	const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
	const [validateMessage, setValidateMessage] = useState<string>('');
	const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState<boolean>(false);

	const { patient, visit, encounterDiagnosis, encounter, component, combined, isConclusion, orderId } =
		benhNhanInfo.thongTinKhamBenh ?? {};
	const userInfo = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
	const validationSchema = Yup.object().shape({
		lieuDung: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
		tongSoThuoc: Yup.number()
			.required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
			.min(1, intl.formatMessage({ id: 'VALIDATION.REQUIRE' }))
			.when('soLuongKhaDung', (soLuongKhaDung, schema) => {
				return schema.max(
					soLuongKhaDung ?? 0,
					'Số lượng thuốc kê đơn không lớn hơn số lượng thuốc khả dụng'
			);
		}),
		// diagnosisNonCoded: Yup.string().when('provisionalDiagnosis', (value, schema) => {
		// 	if (!value) {
		// 		return schema.required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }));
		// 	}
		// 	return schema; // đảm bảo trả về schema nếu điều kiện không khớp
		// 	}).nullable(),
	});
	const initialThuocInfoValue = {
		idThuoc: '',
		maThuoc: '',
		tenThuoc: '',
		actIngName: '',
		soLuongKhaDung: 0,
		donViTinh: '',
		duongDung: '',
		soNgayDung: 0,
		quantityInMorning: 0,
		quantityInNoon: 0,
		quantityInAfternoon: 0,
		quantityInEvening: 0,
		tongSoThuoc: 0,
		lieuDung: '',
		huongDanSuDungThuoc: '',
		index: undefined,
	};
	const initialFormValues: IThuocModel = {
		ngayYLenh: formatDateTime(new Date(), APP_TIMESTAMP_FORMAT) ?? '',
		ghiChu: '',
		khoThuoc: '',
		bacSiDieuTri: userInfo.name,
		loiDanBacSi: '',
		dotDung: '',
		ngayTaiKham: '',
		ngayKeTuDong: '',
		provisionalDiagnosis:
			benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.provisionalDiagnosis,
		provisionalDiagnosisName:
			benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.provisionalDiagnosisName,
		comorbidity: benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.comorbidity,
		...initialThuocInfoValue,
	};
	const thongTinChiPhi = {
		tongChiPhi: visit.ttTong,
		bhyt: visit.ttBhyt,
		benhNhan: visit.ttNguoiBenh,
		tamUng: visit.ttTamUng,
		daThu: visit.ttDaThu,
		mienGiam: visit.ttDiscountInv,
		conDu: visit.ttConNo,
	};
	const tongTienDonThuoc = tableData.reduce((acc: number, row: ThuocInfo) => {
		return acc + (row.deleted ? 0 : row.thanhTien || 0);
	}, 0);
	const tongTienConDu = formatMoney(
		Math.abs(
			thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongTienDonThuoc)
		)
	);
	const isBenhNhanKhamKetHop = combined && component;
	
	useEffect(() => {
		getKhoThuoc();
		getDanhMuc();
	}, []);

	useEffect(() => {
		if (itemEdit?.id) {
			getDonThuoc(itemEdit.id);
		} else {
			handleGetThongTinChanDoan();
		}
	}, [itemEdit]);

	useEffect(() => {
		if (thuocSelected?.id && formikRef.current) {
			for (const [formKey, warehouseKey] of Object.entries(DRUG_WAREHOUSE_FORM_MAPPER)) {
				formikRef.current.setFieldValue(
					formKey,
					thuocSelected[warehouseKey as keyof IThuoc]
				);
			}
		}
	}, [thuocSelected]);

	useEffect(() => {
		if (danhSachKhoThuoc.length && thuocDetail?.id) {
			setKhoThuocSelected(getDetailKhoThuocSelected(thuocDetail?.warehouseId));
		}
	}, [danhSachKhoThuoc.length, thuocDetail?.id]);

	const getKhoThuoc = async () => {
		try {
			const data: IKhoThuoc[] = await getDanhSachKhoThuoc();
			setDanhSachKhoThuoc(data);
			if (data.length === 1) {
				setKhoThuocSelected(data[0]);
				khoThuocIdRef.current = data[0].id;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getDanhMuc = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setListICD10(danhMuc.listICD10);
		setLoaiDoiTuong(danhMuc.listDoiTuong);
	};

	const handleGetThongTinChanDoan = async () => {
		try {
			const { data } = await getThongTinChanDoanSauXuTri(patient?.id, orderId );
			if (data?.code === CODE.SUCCESS) {
				formikRef.current &&
					formikRef.current.setValues({
						...initialFormValues,
						provisionalDiagnosis: data?.data?.[isConclusion ? 'icdXPrimary' : 'provisionalDiagnosis'] || '',
						provisionalDiagnosisName: data?.data?.[isConclusion ? 'icdXPrimaryName' : 'provisionalDiagnosisName'] || '',
						comorbidity: data?.data?.comorbidity || '',
						diagnosisNonCoded: data?.data?.diagnosisNonCoded || '',
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = async (values: IThuocModel, formHelpers: FormikHelpers<any>) => {
		if (!values.tenThuoc) {
			toast.warning('Chưa chọn thuốc kê đơn');
			return;
		}

		let listTemp = [...tableData];
		const rowData: any = {
			...values,
			id: values?.tableRowId,
			maThuoc: values.maThuoc,
			tenThuoc: values.tenThuoc,
			hoatChat: values.actIngName,
			donVi: thuocSelected?.unitofMeasureName || values.donViTinh,
			soLuong: values.tongSoThuoc || 0,
			duongDung: values.duongDung || thuocSelected?.roa,
			lieuDung: values.lieuDung,
			huongDanSuDungThuoc: values.huongDanSuDungThuoc,
			donGia: thuocSelected?.requestedPrice || 0,
			thanhTien:
				thuocSelected && values.tongSoThuoc
					? thuocSelected?.requestedPrice * values.tongSoThuoc
					: 0,
			doiTuong: benhNhanInfo?.thongTinKhamBenh?.visit?.visitObjectTypeName,
			serialNumber: thuocSelected?.serialNumber,
			materialType: thuocSelected?.materialType,
			materialTypeName: thuocSelected?.materialTypeName,
			dosageStrength: thuocSelected?.dosage,
			consignment: thuocSelected?.consignment,
		};

		const rowIndexUpdate = listTemp.findIndex(row => row.index === rowData.index);
		const drugRowExist = listTemp.find(row => row.idThuoc === rowData.idThuoc);

		if (drugRowExist) {
			if (drugRowExist.id) {
				listTemp[drugRowExist.index as number].deleted = true;
			} else {
				listTemp = listTemp.filter(
					(row: ThuocInfo) => row.index !== drugRowExist.index
				);
			}
		}

		if (rowIndexUpdate >= 0) {
			listTemp[rowIndexUpdate] = {
				...listTemp[rowIndexUpdate],
				...rowData,
			};
		} else {
			listTemp = [...listTemp, rowData];
		}

		setTableData(listTemp.map((row, index) => ({ ...row, index })));
		formHelpers.resetForm({
			values: {
				...values,
				...initialThuocInfoValue,
			},
		});
	};
	
	const countTongSoLuongThuoc = (formValues: IThuocModel) => {
		if (!formValues.soNgayDung || isEmptyLieuDungThuoc(formValues)) {
			formikRef.current?.setFieldValue('tongSoThuoc', '');
			return;
		}

		const lieuDungThuocTrenNgay = getTongSoThuocTrenNgay(formValues);
		const tongSoThuoc = formValues.soNgayDung * lieuDungThuocTrenNgay;
		formikRef.current?.setFieldValue('tongSoThuoc', tongSoThuoc);
	};

	const getTongSoThuocTrenNgay = (values: IThuocModel) => {
		return (
			(Number(values.quantityInMorning) || 0) +
			(Number(values.quantityInNoon) || 0) +
			(Number(values.quantityInAfternoon) || 0) +
			(Number(values.quantityInEvening) || 0)
		);
	};

	const isEmptyLieuDungThuoc = (values: IThuocModel) => {
		return [
			values.quantityInMorning,
			values.quantityInNoon,
			values.quantityInAfternoon,
			values.quantityInEvening,
		].every(item => !Boolean(item));
	};

	const getChiPhiThuocValue = (field: CHI_PHI_THUOC_TYPE) => {
		const MAP_FIELD_VALUE = {
			[CHI_PHI_THUOC_FIELDS.TIEN_DON]: tongTienDonThuoc,
			[CHI_PHI_THUOC_FIELDS.TONG_CHI_PHI]: thongTinChiPhi.tongChiPhi + tongTienDonThuoc,
			[CHI_PHI_THUOC_FIELDS.BHYT]: thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.NGUON_KHAC]: 0,
			[CHI_PHI_THUOC_FIELDS.BENH_NHAN]:
				thongTinChiPhi.benhNhan + tongTienDonThuoc - thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.TAM_UNG]: thongTinChiPhi.tamUng,
			[CHI_PHI_THUOC_FIELDS.DA_THU]: thongTinChiPhi.daThu,
			[CHI_PHI_THUOC_FIELDS.MIEN_GIAM]: thongTinChiPhi.mienGiam,
			[CHI_PHI_THUOC_FIELDS.CON_DU]:
				thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongTienDonThuoc),
		};

		return formatMoney(MAP_FIELD_VALUE[field] || 0);
	};

	const onSaveForm = async (callbackFn = onSave) => {
		try {
			const hasDrugData = tableData.filter(row => !row.deleted).length;
			const formValues = formikRef.current?.values;

			if (!hasDrugData) {
				toast.warn('Hiện tại chưa có thuốc được kê cho đơn này');
				return;
			}

			if (!formValues?.provisionalDiagnosisName && !formValues?.diagnosisNonCoded) {
				toast.warn('Chưa nhập thông tin chẩn đoán!');
				return;
			}

			setIsLoading(true);

			const loaiDoiTuongKham = loaiDoiTuong.find(
				record => record.code === visit.visitObjectTypeCode
			);
			const listThuoc: IKeThuocRequest[] = [];

			tableData.forEach((row: any) => {
				listThuoc.push({
					...row,
					visitId: row.visitId || visit.id,
					medicalItemId: String(row.idThuoc) || row.medicalItemId,
					medicalItemCode: row.maThuoc || row.medicalItemCode,
					name: row.tenThuoc,
					dosageForm: row.dosage || row.lieuDung,
					route: row.duongDung,
					usageInstructions: row.huongDanSuDungThuoc,
					unit: row.donVi,
					activeIngredients: row.hoatChat,
					numberOfDays: row.soNgayDung,
					totalQuantity: row.tongSoThuoc,
					unitPrice: row.donGia,
					intoMoney: row.thanhTien,
					visitObjectTypeId: loaiDoiTuongKham.id,
					visitObjectTypeName: loaiDoiTuongKham.name,
					orderTypeId: parseInt(ORDER_TYPE_ID.THUOC),
					patientId: patient.id,
					encounterId: benhNhanInfo.thongTinKhamBenh?.encounter?.id,
					orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
				});
			});

			const { lstMedicalItemOrderRes, ...detailOtherValues } = thuocDetail || {};

			const donThuocRequest: IKeThuocThongTinChung = {
				...detailOtherValues,
				doctorOrderDay: formValues?.ngayYLenh || thuocDetail?.doctorOrderDay,
				note: formValues?.ghiChu,
				warehouseName: formValues?.khoThuocName || khoThuocSelected?.name,
				warehouseId: formValues?.khoThuocId || khoThuocSelected?.id,
				doctorAdvice: formValues?.loiDanBacSi,
				usageTime: formValues?.dotDung,
				reExaminationDate: formValues?.ngayTaiKham
					? new Date(formValues.ngayTaiKham).toISOString()
					: null,
				automaticPrescriptionDate: formValues?.ngayKeTuDong,
				designatedDate: formatDateTime(new Date(), APP_TIMESTAMP_FORMAT),
				patientId: patient.id,
				visitId: visit.id,
				treatmentDoctorUser: userInfo.sub,
				treatmentDoctorName: userInfo.name,
				designatedPerson: userInfo.name,
				designatedPlace: benhNhanInfo?.thongTinKhamBenh?.fulfillDeptName,
				lstMedicalItemOrder: listThuoc,
				encounterId: encounter?.id,
				orderTypeId: parseInt(ORDER_TYPE_ID.THUOC),
				comorbidity: formValues?.comorbidity || encounterDiagnosis.comorbidity,
				provisionalDiagnosis:
					formValues?.provisionalDiagnosis || encounterDiagnosis.provisionalDiagnosis,
				provisionalDiagnosisName:
					formValues?.provisionalDiagnosisName || encounterDiagnosis.provisionalDiagnosisName,
				diagnosisNonCoded:
					formValues?.diagnosisNonCoded || encounterDiagnosis.diagnosisNonCoded,
				orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
			};
			if (!validateMessage) {
				let validateResponse = await validateDonThuoc(donThuocRequest);
				if (validateResponse?.code === CODE.SUCCESS) {
					setValidateMessage('');
				} else {
					let errorMessage =
						validateResponse?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
					setValidateMessage(errorMessage);
					setShouldOpenConfirmDialog(true);
					return;
				}
			}

			const { code, data } = await saveDonThuoc(donThuocRequest);
			if (code === CODE_SUCCESS) {
				toast.success(
					thuocDetail?.id ? RESPONSE_MESSAGE.UPDATE.SUCCESS : RESPONSE_MESSAGE.ADD.SUCCESS
				);
				if (data) {
					idDonThuocRef.current = data;
				}
				idDonThuocRef.current = data;
				callbackFn && callbackFn();
			} else {
				toast.error(data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onClickContextMenu = (value: any) => {
		const actionCode = value.code;

		switch (actionCode) {
			case CONTEXT_MENU_KE_THUOC_KEY.XOA_THUOC:
				setOpenConfirmDelete(true);
				break;
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT_YC:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_VIEN_PHI:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_YEU_CAU:
			case CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_HAO_PHI_KHAC:
				onSuaDoiTuongThuoc(actionCode);
				break;
			default:
				break;
		}
		setContextMenu(null);
	};

	const handleContextMenu = (e: any, row: ReactTableRowProps) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
		rowSelectedRef.current = {
			...row.original,
			index: row.index,
		};
	};

	const onRowDoubleClick = async (row: ReactTableRowProps<any>) => {
		const rowData = row.original;
		const formValues = formikRef.current?.values;
		const warehouseId = formValues!.khoThuocId ?? khoThuocIdRef.current!.toString();

		const detailSelectedThuoc = await getDrugDetail(
			warehouseId,
			rowData.maThuoc || rowData.medicalItemCode
		);

		if (!detailSelectedThuoc) return;

		formikRef.current?.setValues({
			...formValues,
			...rowData,
			index: row.index,
			tableRowId: rowData.id,
			maThuoc: rowData.medicalItemCode || rowData.maThuoc,
		});
		
		setThuocSelected({
			id: rowData.idThuoc || rowData.medicalItemId,
			code: rowData.maThuoc || rowData.medicalItemCode,
			name: rowData.tenThuoc,
			actIngName: rowData.hoatChat,
			requestedPrice: rowData.donGia,
			roa: rowData.duongDung,
			unitofMeasureName: rowData.donViTinh,
			atIngName: '',
			packaging: '',
			usageIns: 0,
			ROA: '',
			ROAName: '',
			iventory: detailSelectedThuoc?.iventory - detailSelectedThuoc.peShipment,
			consignment: detailSelectedThuoc.consignment,
			serialNumber: detailSelectedThuoc?.serialNumber,
			materialType: detailSelectedThuoc?.materialType,
			materialTypeName: detailSelectedThuoc?.materialTypeName,
			dosage: detailSelectedThuoc?.dosage,
		});
	};

	const onDeleteThuoc = () => {
		setOpenConfirmDelete(false);
		let listTemp = [...tableData];
		const rowDelete = rowSelectedRef.current;

		if (rowDelete?.id) {
			listTemp[rowDelete.index].deleted = true;
		} else {
			listTemp = listTemp.filter(
				(row: ThuocInfo, index: number) => index !== rowDelete.index
			);
		}
		setTableData(listTemp);
	};

	const getDonThuoc = async (thuocId: string) => {
		try {
			setIsLoading(true);

			const { code, data } = await getDetailDonThuoc(
				thuocId,
				benhNhanInfo?.thongTinKhamBenh?.orderId
			);

			if (code === CODE.SUCCESS) {
				const formData: IThuocModel = {
					id: data.id,
					ghiChu: data.note,
					khoThuocId: data.warehouseId,
					bacSiDieuTri: data.treatmentDoctorName,
					loiDanBacSi: data.doctorAdvice,
					dotDung: data.usageTime,
					ngayTaiKham: formatLocalDatetimeToString(data.reExaminationDate),
					ngayKeTuDong: data.automaticPrescriptionDate,
					ngayYLenh:
						data.doctorOrderDay ?? formatDateTime(new Date(), APP_TIMESTAMP_FORMAT),
					maThuoc: '',
					tenThuoc: '',
					khoThuoc: '',
					provisionalDiagnosis: data.provisionalDiagnosis,
					provisionalDiagnosisName: data.provisionalDiagnosisName,
					comorbidity: data.comorbidity,
				};
				formikRef.current &&
					formikRef.current.setValues({
						...initialFormValues,
						...formData,
						
					});
				setThuocDetail(data);
				idDonThuocRef.current = data.id;

				if ((data.lstMedicalItemOrderRes || []).length) {
					const listThuoc: (ThuocInfo & Partial<IThuocModel>)[] =
						mapDrugListResponseToTable(data.lstMedicalItemOrderRes);
					setTableData(listThuoc);
				}
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const formatLocalDatetimeToString = (value: Date | null) => {
		if (!value) return '';
		return moment(`${value}Z`).format('YYYY/MM/DD');
	};

	const onSuaDoiTuongThuoc = async (contextMenuValue: string) => {
		const detailThuoc = danhSachThuocRef.current.find(
			row => row.id === rowSelectedRef.current.idThuoc
		);
		const detailLoaiDoiTuong = getDetailLoaiDoiTuongByContextMenu(contextMenuValue);
		const rowUpdate = rowSelectedRef.current;

		const listTemp = tableData.map((row: any) => {
			if (row.index === rowUpdate.index) {
				const donGia = getDonGiaThuocByLoaiDoiTuong(detailThuoc, detailLoaiDoiTuong.code);
				return {
					...row,
					donGia: donGia,
					thanhTien: row.soLuong * donGia,
					visitObjectTypeId: detailLoaiDoiTuong.id,
					visitObjectTypeName: detailLoaiDoiTuong.name,
					doiTuong: detailLoaiDoiTuong.name,
				};
			}
			return row;
		});
		setTableData(listTemp);
	};

	const getDetailLoaiDoiTuong = (field: string, value: string) => {
		return loaiDoiTuong.find(row => row[field] === value);
	};

	const getDetailLoaiDoiTuongByContextMenu = (contextMenu: string) => {
		const MAP_ID_LOAI_DOI_TUONG_CONTEXT_MENU = {
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.BHYT
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT_YC]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.BHYT_YEU_CAU
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_VIEN_PHI]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.VIEN_PHI
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_YEU_CAU]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.YEU_CAU
			),
			[CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_HAO_PHI_KHAC]: getDetailLoaiDoiTuong(
				'code',
				MA_LOAI_DOI_TUONG.HAO_PHI_KHAC
			),
		};

		return MAP_ID_LOAI_DOI_TUONG_CONTEXT_MENU[contextMenu];
	};

	const getDonGiaThuocByLoaiDoiTuong = (thuocData: any, maLoaiDoiTuong: string) => {
		const MAP_DON_GIA_THUOC_LOAI_DOI_TUONG = {
			[MA_LOAI_DOI_TUONG.BHYT]: 'BHYTPrice',
			[MA_LOAI_DOI_TUONG.BHYT_YEU_CAU]: 'BHYTPrice',
			[MA_LOAI_DOI_TUONG.VIEN_PHI]: 'hospitalFeePrice',
			[MA_LOAI_DOI_TUONG.YEU_CAU]: 'requestedPrice',
		};
		return Number(
			thuocData[MAP_DON_GIA_THUOC_LOAI_DOI_TUONG[maLoaiDoiTuong] || 'requestedPrice']
		);
	};

	const mapDrugListResponseToTable = (data: any[]) => {
		const result: any[] = [];
		data.forEach((row: any, index: number) => {
			result.push({
				...row,
				id: row.id,
				tenThuoc: row.name,
				hoatChat: row.activeIngredients,
				donVi: row.unit,
				soLuong: row.totalQuantity,
				duongDung: row.route,
				lieuDung: row.dosageForm,
				donGia: row.unitPrice,
				thanhTien: row.intoMoney,
				doiTuong: row.visitObjectTypeName,
				huongDanSuDungThuoc: row.usageInstructions,
				quantityInMorning: row.quantityInMorning,
				quantityInNoon: row.quantityInNoon,
				quantityInAfternoon: row.quantityInAfternoon,
				quantityInEvening: row.quantityInEvening,
				donViTinh: row.unit,
				tongSoThuoc: row.totalQuantity,
				soNgayDung: row.numberOfDays,
				index,
				idThuoc: row.medicalItemId,
				materialType: row.materialType,
				materialTypeName: row.materialTypeName,
				dosageStrength: row.dosageStrength,
				maThuoc: row.medicalItemCode,
			});
		});
		return result;
	};

	const getDetailKhoThuocSelected = (id: string) => {
		if (!danhSachKhoThuoc.length || !id) return;
		return danhSachKhoThuoc.find(record => record.id === parseInt(id));
	};

	const onDonThuocCuSelect = (selectedData: any) => {
		if (selectedData.provisionalDiagnosis) {
			formikRef.current?.setFieldValue(
				'provisionalDiagnosis',
				selectedData.provisionalDiagnosis
			);
		}

		setKhoThuocSelected(getDetailKhoThuocSelected(selectedData.warehouseId));

		setTableData(mapDrugListResponseToTable(selectedData.medicalItems));
	};

	const columnsActions = {
		Header: (props: any) => (
			<TableCustomHeader<ThuocInfo>
				tableProps={props}
				title={'Thao tác'}
				className="text-center text-light min-w-80px"
			/>
		),
		id: 'actions',
		Cell: ({ ...props }) => (
			<div className="d-flex justify-content-center">
				<CustomIconButton
					variant="edit"
					onClick={() => {
						const row = props.row;
						onRowDoubleClick(row);
					}}
				>
					<i className="bi bi-pencil-square text-navy"></i>
				</CustomIconButton>
				<CustomIconButton
					variant="delete"
					onClick={() => {
						rowSelectedRef.current = {
							...props.row.original,
							index: props.row.index,
						};
						setOpenConfirmDelete(true);
					}}
				>
					<i className="bi bi-trash3-fill text-danger"></i>
				</CustomIconButton>
			</div>
		),
	};

	const getDonMauParams = () => {
		const orderType = parseInt(ORDER_TYPE_ID.THUOC);
		const formValue = JSON.stringify(formikRef.current?.values);
		const tableValue = tableData
			.filter(row => !row.deleted)
			.map(row => ({
				orderType,
				orderTemplate: JSON.stringify(row),
			}));

		return {
			orderType,
			orderTemplate: formValue,
			lstOrderSetMemebers: tableValue,
		};
	};

	const onSelectMauChiDinhThuoc = (data: IDonThuocMauRequest) => {
		const formValue = data.orderTemplate ? JSON.parse(data.orderTemplate) : {};
		const tableValue = data.lstOrderSetMemebers.map((row: any) => ({
			...JSON.parse(row.orderTemplate),
		}));
		setFormikFieldValue('provisionalDiagnosis', formValue.provisionalDiagnosis);
		setFormikFieldValue('provisionalDiagnosisName', formValue.provisionalDiagnosisName);
		setFormikFieldValue('comorbidity', formValue.comorbidity);
		setFormikFieldValue('khoThuocId', formValue.khoThuocId);
		setFormikFieldValue('ghiChu', formValue.ghiChu);
		setFormikFieldValue('loiDanBacSi', formValue.loiDanBacSi);
		setTableData(tableValue);
	};

	const setFormikFieldValue = (name: string, value: any) => {
		formikRef.current?.setFieldValue(name, value);
	};

	const handleOpenSelectICD10 = () => {
		setShouldOpenModalSelectICD10(true);
	};

	const saveDonMauValidation = () => {
		if (!formikRef?.current?.values.provisionalDiagnosis) {
			toast.warn('Chưa nhập chẩn đoán bệnh');
			return false;
		}

		if (!tableData.length) {
			toast.warn('Chưa có thuốc được kê cho mẫu này');
			return false;
		}

		return true;
	};

	const selectDonMauValidation = () => {
		if (tableData.length) {
			toast.warn('Đã có thuốc được chỉ định. Không thể chọn đơn mẫu');
			return false;
		}
		return true;
	};

	const getDrugDetail = async (warehouseId: string, drugCode: string) => {
		try {
			const response = await searchVatTu({
				warehouseId,
				materialGroup: LOAI_VAT_TU.THUOC,
				pageIndex: 1,
				pageSize: 1,
				keyword: drugCode,
				isIventoryNoZero: true,
			});
			return response.length ? response[0] : null;
		} catch (error) {
			console.error(error);
			toast.error('Đã có lỗi xảy ra khi lấy thông tin thuốc');
		}
	};

	return (
		<Modal
			className="modal-thuoc page-full"
			fullscreen
			dialogClassName="h-modal"
			size="xl"
			show={open}
			animation={false}
			centered
			backdropClassName="spaces top-50"
		>
			<Formik<IThuocModel>
				validationSchema={validationSchema}
				onSubmit={(values, formHelpers) => {
					handleSubmit(values, formHelpers);
				}}
				innerRef={formikRef}
				initialValues={initialFormValues}
			>
				{({
					setFieldValue,
					values,
					getFieldProps,
					resetForm,
					setValues,
					errors,
					touched,
					handleSubmit,
				}) => (
					<Form className="spaces h-calc-vh-50" ref={formRef}>
						<input type="hidden" {...getFieldProps('index')} />
						<input type="hidden" {...getFieldProps('tableRowId')} />
						<input type="hidden" {...getFieldProps('maThuoc')} />

						<div className="thuoc-dialog h-100">
							<Modal.Header className="py-3 header-modal">
								<div className="d-flex align-items-center gap-6">
									<Button
										variant="bg-transparent ps-0 d-flex align-items-center"
										onClick={handleClose}
									>
										<IconBack />
										<span className="fw-500 ps-2">Quay lại</span>
									</Button>
									<div className="d-flex spaces gap-10">
										<DonThuocMauButton
											label="Đơn thuốc mẫu"
											getSaveParams={getDonMauParams}
											onSelectMau={onSelectMauChiDinhThuoc}
											orderType={parseInt(ORDER_TYPE_ID.THUOC)}
											onSaveValidationFn={saveDonMauValidation}
											onSelectValidationFn={selectDonMauValidation}
										/>
										<ButtonDonThuocCu
											patientInfo={{
												visitId: visit.id,
											}}
											onSelect={onDonThuocCuSelect}
										/>
										<Button className="btn-fill">
											Sử dụng phác đồ
											<i className="bi bi-caret-down-fill spaces pt-2 pl-4 pr-0 mr-0"></i>
										</Button>
										<Button
											className="btn-fill spaces min-w-76"
											onClick={() => {
												setOpenModalKeVatTu(true);
											}}
										>
											Kê vật tư
										</Button>
									</div>
								</div>
								{benhNhanInfo?.thongTinKhamBenh ? (
									<InfoPatientRight benhNhanInfo={benhNhanInfo} />
								) : (
									<div className="spaces h-65"></div>
								)}
							</Modal.Header>

							<Modal.Body className="py-6 px-0 spaces overflow-hidden spaces h-calc-vh-154">
								<div className="bg-white radius-2 spaces py-10 px-10">
									<div className="d-flex flex-wrap">
										<div className="spaces mb-4">
											<DatePickerCustom
												label="Ngày y lệnh"
												name="ngayYLenh"
												labelClassName="spaces ms-0 min-w-120"
												value={values.ngayYLenh}
												setDateValue={date => {
													setFieldValue('ngayYLenh', date);
												}}
												mode="date-time"
												errors={errors?.ngayYLenh}
												touched={touched?.ngayYLenh}
											/>
										</div>
										<div className="spaces position-relative d-flex align-items-start">
											<Button
												className="bg-white spaces W-85 py-0 pr-5 mb-3"
												onClick={handleOpenSelectICD10}
											>
												<u className="fw-bold text-pri fs-13px">
													Chọn bệnh
												</u>
											</Button>
											<LabelRequired
												label="Chẩn đoán"
												isRequired
												className="min-w-90px ms-2"
											/>
										</div>
										<Row className="flex-grow-1 position-relative">
											<ProvisonalDiagnosis
												encounterDiagnosisValues={values}
												encounterDiagnosisErrors={errors}
												encounterDiagnosisTouched={touched}
												benhNhanInfo={benhNhanInfo}
												listComorbidity={listComorbidity}
												setListComorbidity={setListComorbidity}
												onChange={(data: any) =>
													setValues({
														...values,
														...data,
													})
												}
												handleChangeEncounterDiagnosis={setFieldValue}
											/>
										</Row>
									</div>
									<Row className="spaces mt-4">
										<Col xs={6} className="d-flex">
											<div className="d-flex">
												<LabelRequired
													label="Bệnh kèm theo"
													className="d-inline-block spaces min-w-120-px text-truncate"
												/>
											</div>
											<div className="spaces position-relative w-100">
												<TextField
													className="w-100 d-flex flex-column align-items-start"
													inputClassName="flex-auto spaces h-25 radius-3px pre-line"
													name="comorbidity"
													labelClassName="ms-0 min-w-145px"
													// readOnlyText
												/>
											</div>
										</Col>
										<Col xs={6}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Ghi chú"
												name="ghiChu"
												labelClassName="ms-0 min-w-85px"
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={3} className="d-flex">
											<LabelRequired
												label="Kho Thuốc"
												className="min-w-120-px"
											/>
											<AutocompleteV2
												options={danhSachKhoThuoc}
												name="khoThuoc"
												value={
													getDetailKhoThuocSelected(
														values.khoThuocId || ''
													) || khoThuocSelected
												}
												className="autocomplete-custom-tiep-nhan radius spaces h-26"
												onChange={selectedOption => {
													setKhoThuocSelected(selectedOption);
													setFieldValue('khoThuoc', selectedOption?.code);
													setFieldValue('khoThuocId', selectedOption?.id);
													setFieldValue(
														'khoThuocName',
														selectedOption?.name
													);
												}}
												isDisabled={Boolean(
													tableData.filter(row => !row.deleted).length
												)}
											/>
										</Col>
										<Col xs={3}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="BS điều trị"
												name="bacSiDieuTri"
												labelClassName="spaces ms-0 min-w-120"
												disabled
											/>
										</Col>
										<Col xs={6}>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Lời dặn BS"
												name="loiDanBacSi"
												labelClassName="ms-0 spaces min-w-85"
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={3}>
											<NumberField
												label="Đợt dùng"
												name="dotDung"
												labelClassName="ms-0 min-w-120-px"
											/>
										</Col>
										<Col xs={3}>
											<DatePickerCustom
												label="Ngày tái khám"
												name="ngayTaiKham"
												labelClassName="ms-0 spaces min-w-120"
												value={values.ngayTaiKham ?? null}
												setDateValue={date => {
													setFieldValue(
														'ngayTaiKham',
														date
															? formatDateTime(
																	date,
																	APP_TIMESTAMP_FORMAT
															  )
															: null
													);
												}}
												errors={errors?.ngayTaiKham}
												touched={touched?.ngayTaiKham}
												minDate={new Date()}
											/>
										</Col>
										<Col xs={6}>
											<NgayKeTuDong name="ngayKeTuDong" />
										</Col>
									</Row>
								</div>

								<div className="bg-white radius-2 spaces py-10 px-10 mt-6">
									<Row className="spaces mt-4">
										<Col xs={9} className="position-relative">
											<SelectThuocButton
												value={values.maThuoc}
												setThuocSelected={value => {
													setThuocSelected(value);
													if (formRef.current) {
														formRef.current['soNgayDung-field'].focus();
													}
												}}
												maKhoThuoc={
													values.khoThuocId?.toString() ||
													khoThuocSelected?.id.toString() ||
													''
												}
												label="Tên thuốc"
												labelClassName="min-w-120-px"
												inputValue={values.tenThuoc}
											/>
										</Col>
										<Col>
											<NumberField
												inputClassName="w-100"
												label="SL khả dụng"
												name="soLuongKhaDung"
												labelClassName="ms-0 spaces min-w-95"
												disabled
											/>
										</Col>
										<Col>
											<TextField
												className="spaces w-100"
												inputClassName="w-100"
												label="Đơn vị"
												name="donViTinh"
												labelClassName="ms-0 min-w-56 spaces"
												disabled
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4">
										<Col xs={9} className="d-flex">
											<div className="w-20">
												<NumberField
													label="Số ngày"
													name="soNgayDung"
													labelClassName="ms-0 min-w-120-px"
													onBlur={() => {
														countTongSoLuongThuoc(values);
													}}
												/>
											</div>
											<div className="w-20 ps-5">
												<NumberField
													label="Số lượng"
													name="tongSoThuoc"
													labelClassName="ms-0 min-w-80px"
													required
												/>
											</div>
											<InputAutoComplete
												label="Liều dùng"
												name="lieuDung"
												options={LIEU_DUNG_THUOC_OPTIONS}
												placeholder="Liều dùng/lần , Số lần/ngày"
												className="spaces w-100"
												inputClassName="w-100"
												labelClassName="min-w-85 ms-8"
												required
											/>
										</Col>
										<Col xs={3}>
											<TextField
												inputClassName="w-100"
												label="Đường dùng"
												name="duongDung"
												labelClassName="ms-0 min-w-95px"
												disabled
											/>
										</Col>
									</Row>
									<Row className="spaces mt-4 d-flex flex-nowrap">
										<Col xs={5}>
											<InputAutoComplete
												className="spaces w-100"
												inputClassName="w-100"
												label="Hướng dẫn SD"
												name="huongDanSuDungThuoc"
												placeholder="Khoảng cách dùng, thời điểm dùng, lưu ý"
												labelClassName="ms-0 min-w-120 spaces"
												options={HDSD_THUOC_OPTIONS}
											/>
										</Col>
										<Col xs={4} className="spaces d-flex">
											{THOI_DIEM_DUNG_THUOC.map((option: IThoiDiemDung) => (
												<div className="spaces ps-5" key={option.name}>
													<NumberField
														label={option.label}
														name={option.name}
														labelClassName="ms-0 min-w-55px"
														onBlur={() => {
															countTongSoLuongThuoc(values);
														}}
													/>
												</div>
											))}
										</Col>
										<Col xs={3} className="d-flex justify-content-end">
											<div className="d-flex gap-4">
												<Button className="btn-fill" type="submit">
													<i className="bi bi-check-lg"></i> Xác nhận
												</Button>
												<Button
													className="btn-outline"
													onClick={() => {
														resetForm({
															values: {
																...values,
																...initialThuocInfoValue,
															},
														});
													}}
												>
													<i className="bi bi-x-lg"></i> Hủy bỏ
												</Button>
											</div>
										</Col>
									</Row>
								</div>

								<div className="spaces h-calc-vh-442 bg-white spaces py-10 px-0 radius-2 mt-6 table-ke-thuoc">
									<TableCustom<ThuocInfo>
										data={
											tableData.length
												? tableData.filter(row => !row.deleted)
												: []
										}
										columns={ThuocColumns}
										maxHeight={300}
										handleContextMenu={handleContextMenu}
										handleDoubleClick={onRowDoubleClick}
										columnsActions={columnsActions}
									/>
								</div>
							</Modal.Body>

							<Modal.Footer className="d-flex justify-content-between position-relative spaces bottom-10 px-10 py-4">
								<div className="d-flex align-items-center">
									<div className="border-right h-100 min-w-100 spaces pr-30 d-flex align-items-center justify-content-center flex-column">
										<div className="fw-bold mt-2 text-danger">Tiền đơn</div>
										<div className="text-danger fw-bold text-center">
											{getChiPhiThuocValue('TIEN_DON')}
										</div>
									</div>

									<div className="d-flex pl-10 spaces">
										<div>
											<div className="spaces d-flex width-50 align-items-center mb-10">
												<LabelRequired
													label="Tổng chi phí"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-danger me-5 fw-500">
													{getChiPhiThuocValue('TONG_CHI_PHI')}
												</span>
											</div>
											<div className="spaces d-flex width-50 align-items-center">
												<LabelRequired
													label="Tạm ứng"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{getChiPhiThuocValue('TAM_UNG')}
												</span>
											</div>
										</div>
										<div>
											<div className="spaces d-flex width-50 align-items-center mb-10">
												<LabelRequired
													label="BHYT"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{getChiPhiThuocValue('BHYT')}
												</span>
											</div>
											<div className="spaces d-flex width-50 align-items-center">
												<LabelRequired
													label="Đã thu"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-danger me-5 fw-500">
													{getChiPhiThuocValue('DA_THU')}
												</span>
											</div>
										</div>
										<div>
											<div className="spaces d-flex width-50 align-items-center mb-10">
												<LabelRequired
													label="Nguồn khác"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{getChiPhiThuocValue('NGUON_KHAC')}
												</span>
											</div>
											<div className="spaces d-flex width-50 align-items-center">
												<LabelRequired
													label="Miễn giảm"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{getChiPhiThuocValue('MIEN_GIAM')}
												</span>
											</div>
										</div>
										<div>
											<div className="spaces d-flex width-50 align-items-center mb-10">
												<LabelRequired
													label="Bệnh nhân"
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{getChiPhiThuocValue('BENH_NHAN')}
												</span>
											</div>
											<div className="spaces d-flex width-50 align-items-center">
												<LabelRequired
													label={
														parseInt(
															getChiPhiThuocValue('CON_DU').toString()
														) < 0
															? 'Còn nợ'
															: 'Còn dư'
													}
													className="spaces ms-0 min-w-150"
												/>
												<span className="text-pri me-5 fw-500">
													{tongTienConDu}
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="d-flex gap-3">
									{!isBenhNhanKhamKetHop && (
										<ButtonCapToaChoVe
											patientInfo={{
												patientId: patient.id,
												encounterId: encounter.id,
												orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
												visitId: visit.id,
											}}
											drugInfo={{
												doctorComment: values.loiDanBacSi,
											}}
											onSaveForm={onSaveForm}
											setIsLoading={setIsLoading}
											onClose={handleClose}
										/>
									)}
									<Button
										className="btn-fill"
										onClick={() => {
											onSaveForm(() => {
												setOpenPrintDialog(true);
											});
										}}
									>
										<i className="bi bi-printer" />
										Lưu và in
									</Button>
									<Button
										className="btn-fill"
										onClick={() => {
											onSaveForm();
										}}
									>
										<IconButtonSave /> Lưu lại
									</Button>
								</div>
							</Modal.Footer>
						</div>

						{contextMenu && (
							<ContextMenu
								handleClickOptionContextMenu={onClickContextMenu}
								handleCloseMenu={() => setContextMenu(null)}
								data={contextMenuKeThuocOptions}
								target={contextMenu}
							/>
						)}

						{openConfirmDelete && (
							<ConfirmDialog
								className="z-index-1060"
								show={openConfirmDelete}
								title="Thông báo"
								message="Bạn có chắc chắn muốn xóa thuốc này không ?"
								yes="Xác nhận"
								close="Đóng"
								onCloseClick={() => {
									setOpenConfirmDelete(false);
									rowSelectedRef.current = null;
								}}
								onYesClick={onDeleteThuoc}
							/>
						)}

						{openPrintDialog && (
							<FormInPhieuDialog<IInPhieuChiDinhThuocParams>
								show={openPrintDialog}
								onHide={() => {
									setOpenPrintDialog(false);
									onSave?.();
								}}
								fetchExport={printPhieuChiDinhThuoc}
								params={{
									fileType: 'pdf',
									patientId: patient?.id,
									medicalItemGroupId: idDonThuocRef.current,
									visitId: visit?.id,
									type: parseInt(ORDER_TYPE_ID.THUOC),
								}}
							/>
						)}

						{openModalKeVatTu && (
							<ModalKeVatTu
								open={openModalKeVatTu}
								handleClose={() => {
									setOpenModalKeVatTu(false);
								}}
							/>
						)}
						<ICD10
							shouldOpenModalICD10={shouldOpenModalICD10}
							setShouldOpenModalICD10={setShouldOpenModalICD10}
							shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
							setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
							listICD10={listICD10}
							listComorbidity={listComorbidity}
							setListComorbidity={setListComorbidity}
							selectionModeICD10="radio"
							data={values}
							onChange={value =>
								setValues({
									...values,
									...value,
								})
							}
						/>
						<ConfirmDialog
							className="z-index-1060"
							show={shouldOpenConfirmDialog}
							title="Thông báo"
							message={validateMessage}
							yes="Có"
							close="Không"
							onCloseClick={() => {
								setValidateMessage('');
								setShouldOpenConfirmDialog(false);
							}}
							onYesClick={() => onSaveForm()}
						/>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default PageKeThuoc;
