import axios from "axios";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const API_URL = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_API_URL);
const API_PATH = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"] || process.env.REACT_APP_TENANT_MANAGER_URL);

export const getDanhSachTaiKhoan = async (parameters: any) => {
    let config = {
        params: parameters
    }
    return axios.get(API_URL + "/users", config);
}

export const changePassword = (data: any) => {
  var url = API_PATH + "/users/password/change";
  return axios.patch(url, data);
};