import { ActionButtons } from './type';

export const ACTIONS = [
	'zoomIn',
	'zoomOut',
	'moveUp',
	'moveDown',
	'moveLeft',
	'moveRight',
	'rotateLeft',
	'rotateRight',
	'reset',
	'flipVertical',
	'flipHorizontal',
] as const;

export const ACTION_BUTTONS: ActionButtons = [
	{
		title: 'Phóng to',
		action: 'zoomIn',
		icon: 'bi bi-zoom-in',
	},
	{
		title: 'Thu nhỏ',
		action: 'zoomOut',
		icon: 'bi bi-zoom-out',
	},
	{
		title: 'Di chuyển ảnh lên trên',
		action: 'moveUp',
		icon: 'bi bi-chevron-up',
	},
	{
		title: 'Di chuyển ảnh xuống dưới',
		action: 'moveDown',
		icon: 'bi bi-chevron-down',
	},
	{
		title: 'Di chuyển ảnh sang trái',
		action: 'moveLeft',
		icon: 'bi bi-chevron-left',
	},
	{
		title: 'Di chuyển ảnh sang phải',
		action: 'moveRight',
		icon: 'bi bi-chevron-right',
	},
	{
		title: 'Xoay ảnh sang trái',
		action: 'rotateLeft',
		icon: 'bi bi-arrow-counterclockwise',
	},
	{
		title: 'Xoay ảnh sang phải',
		action: 'rotateRight',
		icon: 'bi bi-arrow-clockwise',
	},
	{
		title: 'Lật ảnh theo chiều dọc',
		action: 'flipVertical',
		icon: 'bi bi-symmetry-horizontal',
	},
	{
		title: 'Lật ảnh theo chiều ngang',
		action: 'flipHorizontal',
		icon: 'bi bi-symmetry-vertical',
	},
	{
		title: 'Đặt lại',
		action: 'reset',
		icon: 'bi bi-eraser',
	},
];

const generateSliderTick = () => {
	const ticks: number[] = [];
	for (let index = -45; index <= 45; index += 10) {
		ticks.push(index);
	}
	return ticks;
};

export const SLIDER_ROTATE_TICKS = generateSliderTick();
