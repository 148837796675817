
const CheckIcon = ({ data, className, color }: any) => {
    let isResultReleased = data?.isResultReleased;
    
    return (
        data?.isCancel ? (
            <div className='d-flex'>
              <i className="bi bi-ban icon-ban spaces min-w-15 mr-10"></i>
              <div>{data?.name}</div>
            </div>
        ) : (
        <div className='d-flex'>
            <div
                className={`${isResultReleased ? "background-color-blue" : "background-color-gray"} icon-circle spaces min-w-15 mr-10 ${className || ""}`}
                style={{ backgroundColor: color }}
            >
               
            </div>
            <div>{data?.name}</div>
        </div>
        )
    )
};

export default CheckIcon;