import { useContext, useEffect, useState } from "react";
import { TableDanhSachBenhNhan } from "./TableDanhSachBenhNhan";
import { AppContext } from "../../../appContext/AppContext";
import { Col, Form, Row, Button, InputGroup, FormCheck } from "react-bootstrap";
import InputSearch from '../../../component/InputSearch';
import { KhamLamSangContext, KhamLamSangContextProps } from "../tab-kham-lam-sang/KhamLamSang"
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import { getListDsNhanVienKLS } from "../../services/KhamDoanHopDongServices"
import TextValidator from "../../../component/TextValidator";
type Props = {
  selectedRow: any;
  handlerCloseHD: any,
  setSelectedRow: (row: any) => void;
  setFilter: (data: any) => void;
};

const DanhSachBenhNhan = (props: Props) => {
  let {
    handlerCloseHD,
    selectedRow,
    setSelectedRow,
    setFilter,
  } = props;
  const { setIsLoading } = useContext(AppContext);
  const { TTHopDong, isSearchNV } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
  const [countPaid, setCountPaid] = useState<any>();
  const [dsBenhNhan, setDsBenhNhan] = useState<any>({
    data: [],
    totalElements: 1,
    totalPages: 1,
    summaries: []
  });  
  const [keyword, setKeyword] = useState<any>(null);
  const [colorFilter, setColorFilter] = useState<any>('');

  const getDanhSachBenhNhan = async () => {
    if (TTHopDong?.id) {
      setIsLoading(true);
      let searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        keyword: keyword,
        contractId: TTHopDong?.id,
        color: encodeURIComponent(colorFilter)
      };
      
      try {
        const { data } = await getListDsNhanVienKLS(searchObject);
        if (CODE.SUCCESS === data?.code) {
          setDsBenhNhan({
            data: data?.data?.content || [],
            totalElements: data?.data?.totalElements,
            totalPages: data?.data?.totalPages,
            summaries: data?.data?.summaries || [],
          });
          setCountPaid({
            notPaidEnough: data?.data?.notPaidEnough,
            paidEnough: data?.data?.paidEnough,
          });
        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      } catch {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      } finally {
        setIsLoading(false);
      }
    } else {
      return setDsBenhNhan({
        data: [],
        totalElements: 1,
        totalPages: 1,
      })
    }
  };

  useEffect(() => {
    getDanhSachBenhNhan();
  }, [TTHopDong?.id, isSearchNV, colorFilter]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEY.ENTER) {
      getDanhSachBenhNhan()
    }
  };

  return (
    <div className="position-relative spaces gap-8 bg-white h-100">
      <div className="justify-content-between spaces pl-8 mb-8">
        <div className="d-flex mb-2 mt-1 ">
          <Col xs="7" className="spaces px-0 pt-0">
            <h4 className="text-title mb-0 fw-bold fs-4 spaces ">{"Danh sách bệnh nhân"}</h4>
          </Col>
          <Col xs="5" className="flex justify-content-end spaces pt-2 px-5 mt-3">
            <Button className="customer-buttom-dk spaces h-30 min-w-120" onClick={handlerCloseHD}>
              <span className="customer-buttom-text">DS Hợp Đồng</span>
            </Button>
          </Col>
        </div>
        <div className="d-flex spaces mb-6 mr-5">
          <div className="flex-auto">
            <InputGroup className="spaces h-29 px-0">
              <TextValidator
                className="spaces h-29 min-w-177"
                name="keyword"
                placeholder="Tìm kiếm"
                value={keyword}
                onChange={(e: any) => {
                  setKeyword(e?.target?.value);
                }}
                onKeyDown={handleKeyPress}
              />
              <Button
                className="btn-fill spaces min-w-105 h-29 rounded-end-1 z-index-1055"
                onClick={getDanhSachBenhNhan}
              >
                Tìm kiếm
              </Button>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-column">
        <TableDanhSachBenhNhan
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          dsBenhNhan={dsBenhNhan?.data}
          countPaid={countPaid}
          summaries={dsBenhNhan?.summaries || []}
          setColorFilter={setColorFilter}
        />
      </div>
    </div >
  );
};

export default DanhSachBenhNhan;
