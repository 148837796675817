import { useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import {
  CODE,
  RESPONSE_MESSAGE,
  SELECTION_MODE,
} from "../../../utils/Constant";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { getDsPhieuChuaTraKQ } from "../../services/KhamBenhService";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

const ModalDsPhieuChuaTraKetQua = ({
  handleClose, message
}: any) => {
  const {
    benhNhanInfo,
  } = useContext(PhanHeTiepDonContext);
  const { setIsLoading } = useContext(AppContext);
  const [dsPhieu, setDsPhieu] = useState<any>();

  const updatePageData = async (filter?: any) => {
    setIsLoading(true);
    try {
      let { data } = await getDsPhieuChuaTraKQ(benhNhanInfo?.thongTinKhamBenh?.orderId);
      if (data?.code === CODE.SUCCESS) {
        setDsPhieu(data?.data || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  const DsBenhNhanColumn: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className="text-center text-light max-w-35 spaces "
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <span className="text-danger">{String(props?.row?.index + 1)}</span>

      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Mã phiếu"}
          className="text-center text-light min-w-100px spaces "
        />
      ),
      id: "Mã phiếu",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.orderGroupCode || "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Ngày y lệnh"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "Ngày y lệnh",
      Cell: ({ ...props }) => {
        let data = moment(props?.data[props?.row?.index]?.indicationTime).format("DD/MM/YYYY - HH:mm") || "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Loại phiếu"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "Loại phiếu",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.orderTypeName || "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Người chỉ định"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "Người chỉ định",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.indicationPerson || "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Phòng chỉ định"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "Phòng chỉ định",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.indicationDept || "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Khoa chỉ định"}
          className="text-center text-light min-w-175px "
        />
      ),
      id: "Khoa chỉ định",
      Cell: ({ ...props }) => {
        let data = "";
        return (
          <span className="text-danger">{data}</span>
        )
      },
    },
  ];

  return (
     <>
      <Modal
        show
        centered
        size="xl"
        className="dialog-background"
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="text-transform-none">Danh sách phiếu chưa trả/xóa kết quả</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="spaces h-calc-vh-400">
            <TableCustom
              data={dsPhieu || []}
              columns={DsBenhNhanColumn}
              selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
              verticalScroll={true}
              rowKey="orderId"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div className="text-danger fw-bold">
            {message}
          </div>
          <Button
            className="btn-secondary spaces W-80"
            onClick={handleClose}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDsPhieuChuaTraKetQua;
