import { formatDateTimeRange } from '@formatjs/intl/src/dateTime';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Column } from 'react-table';
import { TableCustomCell } from '../../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { formatDateAdvanceToString, formatDateTime } from '../../../utils/FormatUtils';
import { IDichVuChuaCoKetQua, LstDetail, Term } from '../../models/ThuocModels';
import TableGroupSelection from '../tab-kham-benh/modal-chon-ket-qua-dich-vu/components/TableGroupSelection';

type Props = {
	open: boolean;
	onClose: () => void;
	data: IDichVuChuaCoKetQua[];
};

const DichVuChuaCoKetQuaDialog = ({ open, onClose, data }: Props) => {
	const [tableData, setTableData] = useState<IDichVuChuaCoKetQua[]>([]);

	useEffect(() => {
		if (data.length) {
			setTableData(convertDataToTree(data));
		}
	}, [data.length]);

	const convertDataToTree = (data: IDichVuChuaCoKetQua[]) => {
		const result: any[] = data.map(record => {
			const { lstDetails, ...recordData } = record;
			return groupConvert(lstDetails, recordData);
		});
		return result.flat(Infinity);
	};

	const groupConvert = (groups: LstDetail[], addtionalData: any) => {
		const result: any[] = groups.map((group: any) => ({
			...addtionalData,
			...termConvert([group.term], addtionalData)[0],
		}));
		return result;
	};

	const termConvert = (terms: Term[], addtionalData: any) => {
		const result: any[] = terms.map(term => ({
			...addtionalData,
			orderName: term.name,
			subRows: term.subs?.length ? termConvert(term.subs, addtionalData) : null,
		}));
		return result;
	};

	const columns: Column<IDichVuChuaCoKetQua>[] = useMemo<Column<IDichVuChuaCoKetQua>[]>(() => {
		return [
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Mã phiếu</span>}
						className="text-center min-w-120px"
					/>
				),
				accessor: 'code',
				Cell: ({
					row: { getToggleRowExpandedProps, depth, canExpand, isExpanded },
					value,
				}) => (
					<div
						style={{
							paddingLeft: depth * 16,
						}}
					>
						{canExpand ? (
							<div className="d-flex align-items-center">
								<span className="spaces pe-8 pr-4" {...getToggleRowExpandedProps()}>
									{isExpanded ? (
										<i className="las la-angle-down text-dark fs-3"></i>
									) : (
										<i className="las la-angle-right text-dark fs-3"></i>
									)}
								</span>
								<span>{value}</span>
							</div>
						) : (
							<div className="d-flex align-items-center spaces pl-21">
								<span>{value}</span>
							</div>
						)}
					</div>
				),
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Ngày y lệnh</span>}
						className="text-center spaces min-w-180"
					/>
				),
				accessor: 'doctorOrderDay',
				Cell: ({ value }) => (
					<TableCustomCell 
						data={value ? formatDateTime(value) : ''}
						className='text-center'
					/>
				),
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Trạng thái</span>}
						className="text-center min-w-150px"
					/>
				),
				accessor: 'status',
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Tên dịch vụ</span>}
						className="text-center min-w-300px"
					/>
				),
				accessor: 'orderName',
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Loại phiếu</span>}
						className="text-center min-w-150px"
					/>
				),
				accessor: 'type',
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Người chỉ định</span>}
						className="text-center min-w-150px"
					/>
				),
				accessor: 'designatedPerson',
			},
			{
				Header: (props: any) => (
					<TableCustomHeader
						tableProps={props}
						title={<span className='text-white'>Khoa chỉ định</span>}
						className="text-center min-w-250px"
					/>
				),
				accessor: 'designatedPlace',
			},
		];
	}, []);

	return (
		<Modal size="xl" show={open} animation centered className="dialog-background">
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Danh sách dịch vụ chưa có kết quả</Modal.Title>
				<Button className="bg-white btn-close" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="py-16 px-24 spaces">
				<TableGroupSelection<IDichVuChuaCoKetQua>
					data={tableData}
					containerClassName="h-400px max-h-500px"
					columns={columns}
				/>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end py-1">
				<Button size="sm" onClick={onClose} className="btn-fill btn-danger">
					Đóng
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DichVuChuaCoKetQuaDialog;
