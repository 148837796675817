import axios from "axios";
import { IParamsSearchLocation, IParamsSimpleCategory, IParamsTermClasses } from "../models/params";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, SEARCH_OBJECT_MAX_SIZE } from "./Constant";
import { localStorageItem } from "./LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

const API_PATH_EMR = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_TERM_CLASSES = API_PATH_EMR + "/term-classes";
const API_PATH_XADMIN = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_API_URL;
const API_PATH_CATEGORY = API_PATH_XADMIN + "/simple-categories";
const API_PATH_PROVINCES = API_PATH_XADMIN + "/provinces";
const API_PATH_DISTRICTS = API_PATH_XADMIN + "/districts";
const API_PATH_SUB_DISTRICTS = API_PATH_XADMIN + "/subdistricts";

export const getSimpleCategory = (params: IParamsSimpleCategory) => {
    let url = API_PATH_CATEGORY + "/search";
    return axios.get(url, { params: {
        ...params,
        ...SEARCH_OBJECT_MAX_SIZE
    } });
};

export const getTermByClassId = (params: {
    classId: number
}) => {
    let url = API_PATH_EMR + "/terms";
    return axios.get(url, { params });
};

export const getTermClasses = (params: IParamsTermClasses) => {
    let url = API_PATH_TERM_CLASSES + "/terms";
    return axios.get(url, { params });
};

export const getAllProvinces = () => {
    const MAX_PAGE_SIZE_PROVINCE = 63;
    let params = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE_PROVINCE
    };
    let url = API_PATH_PROVINCES + "/search";
    return axios.get(url, { params });
};

export const getAllDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getAllSubDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_SUB_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const generateCode = () => {
    let url = API_PATH_EMR + `/generate/codes/patient`
    return axios.get(url);
};

export const getJsonGenerateForms = async (codes: string) => {
    let url = API_PATH_EMR + `/term-models/model-json`;
    return axios.get(url, { params: { codes } });
};

export const getListKhoaPhong = (searchObject: { type: number, level: number } ) => {
    let url = API_PATH_XADMIN + "/departments/search";
    return axios.get(url, {
        params: {
            ...searchObject,
            pageIndex: DEFAULT_PAGE_INDEX,
            pageSize: MAX_PAGE_SIZE,
        }
    });
}

export const cancelService = (data: any) => {
    let url = API_PATH_EMR + `/medical-exam/term/cancel`;
    return axios.post(url, data);
}

export const cancelServiceCLS = (data: any) => {
    let url = API_PATH_EMR + `/medical-exam/term/stop-executing`;
    return axios.post(url, data);
}

