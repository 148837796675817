import clsx from 'clsx';
import { useMemo } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getPDFURLPreviewFromBase64 } from '../../../utils/FormatUtils';
import { IMAGE_ACTIONS } from '../constant';
import { ImageItemProps } from '../types';

const ImageItem = (props: ImageItemProps) => {
	const { title, src, className, bodyClassName, footerClassName, imageClassName, onActionClick } =
		props;

	const isFilePDF = props.fileType === 'application/pdf';
	const srcPreview = useMemo(
		() => (isFilePDF ? getPDFURLPreviewFromBase64(src) : src),
		[src, isFilePDF]
	);

	return (
		<Card className={clsx('spaces bg-white border w-100', className)}>
			{isFilePDF ? (
				<div className="spaces max-h-120 d-block rounded-0 overflow-hidden">
					<iframe
						title={title}
						src={`${srcPreview ? `${srcPreview}#toolbar=0&navpanes=0` : ''}`}
						style={{
							scrollbarWidth: 'none',
							pointerEvents: 'none',
							height: 150,
							width: 'calc(100% + 20px)',
						}}
					/>
				</div>
			) : (
				<Card.Img
					variant="top"
					src={srcPreview}
					alt={title}
					className={clsx(
						'spaces w-100 h-auto max-h-120 d-block rounded-0',
						imageClassName
					)}
					style={{
						flex: '1 1 auto',
					}}
				/>
			)}

			{title && (
				<Card.Body
					className={clsx('', bodyClassName)}
					style={{
						flex: '0 1 auto',
					}}
				>
					<Card.Text>{title}</Card.Text>
				</Card.Body>
			)}

			<Card.Footer
				className={clsx(
					'd-flex align-items-center justify-content-center spaces p-4',
					footerClassName
				)}
			>
				{IMAGE_ACTIONS.map(action => (
					<OverlayTrigger
						key={action.title}
						delay={{ show: 0, hide: 0 }}
						placement="top"
						overlay={<Tooltip id={`tooltip-${action.title}`}>{action.title}</Tooltip>}
					>
						<Button
							className="card-item-action"
							onClick={() => {
								onActionClick?.(action.action, props);
							}}
							hidden={['crop', 'delete'].includes(action.action) && isFilePDF}
						>
							<i className={clsx('card-item-action--icon', action.icon)}></i>
						</Button>
					</OverlayTrigger>
				))}
			</Card.Footer>
		</Card>
	);
};

export default ImageItem;
