import { useContext, useEffect, useState } from "react";
import { AppContext } from "../appContext/AppContext";
import "./PhanHeXetNghiem.scss";
import { danhSachMenuXetNghiem } from "./const/constants";
import LayMauBenhPham from "./tab-lay-mau-benh-pham/LayMauBenhPham";
import { PhanHeXetNghiemContext } from "./context/PhanHeXetNghiemContext";

const PhanHeXetNghiem = () => {
  const { setDSMenu } = useContext(AppContext);
  const [benhNhanInfo, setBenhNhanInfo] = useState<any>();
  const [phieuXetNghiemInfo, setPhieuXetNghiemInfo] = useState<any>();
  const [selectedBenhNhan, setSelectedBenhNhan] = useState<any>();

  useEffect(() => {
    setDSMenu(danhSachMenuXetNghiem);
    return () => {
      setDSMenu([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PhanHeXetNghiemContext.Provider value={{ benhNhanInfo, setBenhNhanInfo, phieuXetNghiemInfo, setPhieuXetNghiemInfo, selectedBenhNhan, setSelectedBenhNhan }}>
      <LayMauBenhPham/>
    </PhanHeXetNghiemContext.Provider>
  );
};

export { PhanHeXetNghiem };

