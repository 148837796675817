import { FC, Suspense, lazy } from "react";
import { useRef, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PhanHeCDHAVaTDCN } from "../modules/phan-he-cdha-va-tdcn/PhanHeCDHAVaTDCN";
import PhanHeKhamBenh from "../modules/phan-he-kham-benh/PhanHeKhamBenh";
import TiepDonNoiTru from "../modules/phan-he-noi-tru/components/tiep-don/TiepDon";
import PhanHeQuanTriHeThong from "../modules/phan-he-quan-tri-he-thong/PhanheQuanTriHeThong";
import { PhanHeVienPhiBaoHiem } from "../modules/phan-he-tiep-nhan-thanh-toan/tab-thanh-toan/ThanhToan";
import { PhanHeXetNghiem } from "../modules/phan-he-xet-nghiem/PhanHeXetNghiem";
import { HomePage } from "../pages/Homepage/HomePage";
import { MenuTestPage } from "../pages/MenuTestPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import PhanHeTiepNhan from "../modules/phan-he-tiep-nhan-thanh-toan/PhanHeTiepNhan";
import NhaThuocVaThuNgan from "../modules/nha-thuoc-va-thu-ngan/NhaThuocVaThuNgan";
import PhanHeHanhChinh from "../modules/phan-he-hanh-chinh/PhanHeHanhChinh";
import PhanHeKhamDoan from "../modules/phan-he-kham-doan/PhanHeKhamDoan";
import PhanHeDieuTri from "../modules/phan-he-dieu-tri/PhanHeDieuTri";
import DatLichHen from "../modules/dat-lich-hen/DatLichHen";
import PhanHeVienPhi from "../modules/phan-he-vien-phi/PhanHeVienPhi";
import { PhanHeChuyenKhoa } from "../modules/phan-he-chuyen-khoa/PhanHeChuyenKhoa";
import ChamSocKhachHang from "../modules/phan-he-cskh/ChamSocKhachHang";
import DanhMuc from "../modules/phan-he-danh-muc/DanhMuc";
import PhanHeLayMau from "../modules/phan-he-lay-mau";
import { KEY_LOCALSTORAGE } from "../../app/modules/auth/core/_consts";
import { localStorageItem } from "../../app/modules/utils/LocalStorage";
import { useNavigate } from "react-router-dom";


const PrivateRoutes = () => {
  const menusByPermission = localStorageItem.get(KEY_LOCALSTORAGE.ID_TOKEN_DECODE)?.["menus"]?.["oceantech-emr-server"] || [];
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!menusByPermission?.length) {
      navigate("/error/403");
    } else {
      // navigate(`${menusByPermission?.[0]?.uri}`);
    }
  }, [menusByPermission?.length])
  return (
    <Routes>
      <Route index element={<Navigate to={`${menusByPermission?.[0]?.uri}`} />} />
      <Route path="/*" element={<Navigate to={`${menusByPermission?.[0]?.uri}`} />} />
      {/* <Route path="/*" element={<HomePage />} /> */}
      {/* <Route index element={<Navigate to="/home" />} */}
      <Route path="home" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path="phan-he-tiep-nhan" element={<PhanHeTiepNhan />} />
        <Route path="fee-and-insurance" element={<PhanHeVienPhiBaoHiem />} />
        <Route path="cdha-tdcn" element={<PhanHeCDHAVaTDCN />} />
        <Route path="kham-benh" element={<PhanHeKhamBenh />} />
        <Route path="/test" element={<PhanHeXetNghiem />} />
        <Route path="phan-he-kham-doan" element={<PhanHeKhamDoan />} />
        <Route path="phan-he-noi-tru/tiep-don" element={<TiepDonNoiTru />} />
        <Route path="phan-he-noi-tru/quan-ly-phong-benh" element={<TiepDonNoiTru />} />
        <Route path="phan-he-noi-tru/quan-ly-giuong-benh" element={<TiepDonNoiTru />} />
        <Route path="quan-tri-he-thong" element={<PhanHeQuanTriHeThong />} />
        <Route path="nha-thuoc-thu-ngan" element={<NhaThuocVaThuNgan />} />
        <Route path="chuyen-khoa" element={<PhanHeChuyenKhoa />} />
        <Route path="hanh-chinh" element={<PhanHeHanhChinh />} />
        <Route path="dieu-tri" element={<PhanHeDieuTri />} />
        <Route path="dat-lich-hen" element={<DatLichHen />} />
        <Route path="vien-phi" element={<PhanHeVienPhi />} />
        <Route path="cham-soc-khach-hang" element={<ChamSocKhachHang />} />
        <Route path="lay-mau-xet-nghiem" element={<PhanHeLayMau />} />
        <Route path="system/emr/catalog" element={<DanhMuc />} />

        {/* Lazy Modules */}
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route path="crafted/pages/profile/*" element={<SuspensedView></SuspensedView>} />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

