import React, { useContext, useEffect, useState } from "react";
import "./KhamLamSang.scss";
import { Col, Row } from "react-bootstrap";
import TabKhamNoi from "./TabKhamNoi";
import TabKhamNgoai from "./TabKhamNgoai";
import TabKhamSanPhuKhoa from "./TabKhamSanPhuKhoa";
import TabKhamTaiMuiHong from "./TabKhamTaiMuiHong";
import TabKhamDaLieu from "./TabKhamDaLieu";
import TabKhamRangHamMat from "./TabKhamRangHamMat";
import TabKhamMat from "./TabKhamMat";
import TabNoiDung from "./TabNoiDung";
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { formatDateTime, formatDateToDDMMYYYY, formatDateToDDMMYYYYHHMM } from "../../../../utils/FormatUtils";
import TabMenu from "../../../../component/TabMenu";
import { getDetailOrderKhamBenh } from "../services/KhamLamSangSevice";
import { APP_DATE_TIME_FORMAT, CODE } from "../../../../utils/Constant";
import { AppContext } from "../../../../appContext/AppContext";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";

type Props = {};

const ThongTinNhanVien = ({ }: Props) => {
  const [eventKey, setEventKey] = useState();
  const { setIsLoading } = useContext(AppContext);
  const { TTNhanVien, setTTNhanVien } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
  const { workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext);
  const handlerSelect = (value: any) => {
    setEventKey(value)
  };

  const getDetailNV = async () => {
    try {
      setIsLoading(true)
      let { data } = await getDetailOrderKhamBenh({
        visitId: TTNhanVien?.visit?.id,
        orderId: TTNhanVien?.orderId,
      });
      
      if (CODE.SUCCESS === data?.code) {    
        setTTNhanVien({
          id: TTNhanVien.id,
          ...data?.data,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (TTNhanVien?.visit?.id && TTNhanVien?.orderId) {
      getDetailNV();
    }
  }, [workFlowStatus.currStatusCode]);

  const listTab = [
    {
      title: 'Nội dung',
      component: <TabNoiDung
      />
    },
    {
      title: 'Khám nội',
      component: <TabKhamNoi
      />
    },
    {
      title: 'Khám ngoại',
      component: <TabKhamNgoai
      />
    },
    {
      title: 'Khám sản phụ khoa',
      component: <TabKhamSanPhuKhoa
      />
    },
    {
      title: 'Khám mắt',
      component: <TabKhamMat
      />
    },
    {
      title: 'Khám tai mũi họng',
      component: <TabKhamTaiMuiHong
      />
    },
    {
      title: 'Khám răng hàm mặt',
      component: <TabKhamRangHamMat
      />
    },
    {
      title: 'Khám da liễu',
      component: <TabKhamDaLieu
      />
    },
  ];
  
  return (
      <div className="spaces w-100  justify-content-center p-10">
        <Row>
          <Col xs="4">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Số phiếu:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.orderGroup?.code || ""}
              </span>
            </div>
          </Col>
          <Col xs="5">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-80">Chẩn đoán:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.orderGroup?.chanDoan || ""}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Ngày y lệnh:</span>
              <span className="fw-light text-truncate">
                {formatDateTime(TTNhanVien?.orderGroup?.indicationTime, APP_DATE_TIME_FORMAT)}
              </span>
            </div>
          </Col>

          <Col xs="4">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Người chỉ định:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.orderGroup?.indicationPerson || ""}
              </span>
            </div>
          </Col>
          <Col xs="5">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Nơi chỉ định:</span>
              <span className="fw-light text-truncate">
                Phòng khám sức khỏe
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-80">Trạng thái: </span>
              <span className="fw-light text-truncate">{TTNhanVien?.status}</span>
            </div>
          </Col>

          <Col xs="4">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Dịch vụ khám:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.term?.name || ""}
              </span>
            </div>
          </Col>
          <Col xs="5">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Bắt đầu khám:</span>
              <span className="fw-light text-truncate">
                {formatDateTime(TTNhanVien?.encounter?.encounterDatetime, APP_DATE_TIME_FORMAT)}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Bác sĩ khám:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.order?.docName || ""}
              </span>
            </div>
          </Col>

          <Col xs="4">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Phòng khám:</span>
              <span className="fw-light text-truncate">
                {"Phòng khám sức khoẻ"}
              </span>
            </div>
          </Col>
          <Col xs="5">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100">Kết thúc khám:</span>
              <span className="fw-light text-truncate">
                {formatDateTime(TTNhanVien?.visit?.dateStopped, APP_DATE_TIME_FORMAT)}
              </span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-90">Bác sĩ trả KQ:</span>
              <span className="fw-light text-truncate">
                {TTNhanVien?.encounter?.createdBy || ""}
              </span>
            </div>
          </Col>
        </Row>
        <div className="bg-white spaces mt-10">
          <TabMenu danhsachTabs={listTab} handlerSelect={handlerSelect} />
        </div>
      </div>
  );
};

export default ThongTinNhanVien;
