import { Form, Modal } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { IBenhNhanV3ChuyenKhoa } from "../../models/PhanHeChuyenKhoaModel";
import CustomTabMenu from "../../../component/CustomTabMenu";
import FormKQ from "./forms/FormKQ";
import {
  getObsValue,
  getSurgeryOrderParaclinical,
  getSurgeryOrderParaclinicalByOrderId,
} from "../../services/PhanHeChuyenKhoaService";
import { CODE, KEY_THUC_HIEN_CHUYEN_KHOA } from "../../../utils/Constant";
import { toast } from "react-toastify";
import FormPTTT from "./forms/FormPTTT";
import TabMenu from "../../../component/TabMenu";
type Props = {
  handleClose: () => void;
  handleTraKetQua: () => void;
  infoBenhNhan?: IBenhNhanV3ChuyenKhoa;
  isViewModal?: boolean;
  workFlowStatus?: any;
  handleSubmitForm: (values: any, currentId?: any, currentTab?: any) => void;
  listICD10: any;
  selectedRow: any;
  setKetQuaCLS: (value: any) => void;
  ketQuaCLS: any;
  isHasData?: boolean;
  setIsHasData?: (value: boolean) => void;
};

const initialValues: any = {
  surgeryInformationEntryDate: "",
  surgeryStartTime: "",
  surgeryEndTime: "",
  surgerySurgicalDescription: "",
};

export default function ModalThucHien({
  handleClose,
  handleTraKetQua,
  infoBenhNhan,
  isViewModal,
  handleSubmitForm,
  listICD10,
  selectedRow,
  setKetQuaCLS,
  ketQuaCLS,
  isHasData,
  setIsHasData = () => {}
}: Props) {
  const { setIsLoading } = useContext(AppContext);
  const [inforResult, setInforResult] = useState<any>({});
  const refForm = useRef<any>();
  const [noiDungMauKetQua, setNoiDungMauKetQua] = useState<string>("");
  const [currentTab, setCurrentTab] = useState(0);
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object({}));

  useEffect(() => {
    setValidationSchema(
      !currentTab
        ? Yup.object({
            surgeryInformationEntryDate: Yup.date()
              .required("Bắt buộc nhập")
              .min(
                infoBenhNhan?.indicationTime,
                "Ngày nhập thông tin không được trước Ngày Y lệnh"
              ),
            surgeryStartTime: Yup.date()
              .required("Bắt buộc nhập")
              .min(
                infoBenhNhan?.indicationTime,
                "Ngày nhập thông tin không được trước Ngày Y lệnh"
              ),
            surgeryEndTime: Yup.date()
              .required("Bắt buộc nhập")
              .min(
                Yup.ref("surgeryStartTime"),
                "Thời gian kết thúc phải sau Thời gian bắt đầu"
              ),
            surgerySurgicalDescription: Yup.string().required("Bắt buộc nhập"),
            surgeryScheduledTime: Yup.date().min(
              infoBenhNhan?.indicationTime,
              "Thời gian PT dự kiến không được trước Ngày Y lệnh"
            ),
            surgeryAnesthesiaInductionTime: Yup.date()
              .min(
                infoBenhNhan?.indicationTime,
                "Thời gian khởi mê không được trước Ngày Y lệnh"
              )
              .max(
                Yup.ref("surgeryStartTime"),
                "Thời gian khởi mê phải trước Thời gian bắt đầu"
              ),
            surgeryDrainRemovalTime: Yup.date().min(
              Yup.ref("surgeryEndTime"),
              "Ngày rút phải sau Thời gian kết thúc"
            ),
            surgerySutureRemovalTime: Yup.date().min(
              Yup.ref("surgeryEndTime"),
              "Ngày cắt chỉ phải sau Thời gian kết thúc"
            ),
          })
        : Yup.object({})
    );
  }, [currentTab]);

  const convertDataToTree = (data: any) => {
    const result: any = data.map((item: any, index: number) => {
      const addtionData = {
        khoaChiDinh: item.fulfillDeptName,
        ngayYLenh: item.indicationTime,
        ngayTraKetQua: item.resultReportingTime,
        orderId: item?.orderId,
      };
      return { ...termConvert([item.term], addtionData)[0], index: index + 1 };
    });
    return result;
  };

  const termConvert = (terms: any, addtionData: any) => {
    const result: any = terms.map((term: any) => ({
      id: term.id.toString(),
      parentId: term.parentId,
      tenDichVu: term.name,
      ketQua: term.obs?.value || "",
      khoaChiDinh: addtionData.khoaChiDinh,
      ngayYLenh: addtionData.ngayYLenh,
      ngayTraKetQua: addtionData.ngayTraKetQua,
      subRows: term.subs?.length ? termConvert(term.subs, addtionData) : null,
      orderId: addtionData?.orderId,
      isSelected: true,
    }));
    return result;
  };
  const handleGetResult = async () => {
    let searchObject = {
      orderId: selectedRow?.orderId,
    };
    let searchObjectKQCLS = selectedRow?.surgeryOrderId
      ? {
          surgeryOrderId: selectedRow?.surgeryOrderId,
        }
      : {
          orderId: selectedRow?.orderId,
        };
    try {
      setIsLoading(true);
      let { data } = await getObsValue(searchObject);
      let funcGetSurgeryOrderParaclinical = selectedRow?.surgeryOrderId
        ? getSurgeryOrderParaclinical
        : getSurgeryOrderParaclinicalByOrderId;
      let { data: dataKQCLS } = await funcGetSurgeryOrderParaclinical(
        searchObjectKQCLS
      );
      const dataConverted = convertDataToTree(dataKQCLS?.data);
      setKetQuaCLS(dataConverted);
      if (data?.code === CODE.SUCCESS) {
        setInforResult(data?.data);
        setNoiDungMauKetQua(data?.data?.result);
      }
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetResult();
  }, []);

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        size="xl"
        centered
        className="modal-xl-bigger modal-chuyen-khoa"
        contentClassName="h-100"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let valuesSubmit = {
              ...values,
              noiDungMauKetQua: noiDungMauKetQua,
              startTime: inforResult?.startTime || null,
              endTime: inforResult?.endTime || null,
              executionTime: inforResult?.executionTime || null,
              minimumTime: inforResult?.minimumTime || null,
              conclusion: inforResult?.conclusion || null
            };
            handleSubmitForm(valuesSubmit, null, currentTab);
          }}
          innerRef={refForm}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
            touched,
            resetForm,
          }) => {
            const danhSachTab = [
              {
                eventKey: "10",
                title: "Thông tin PTTT",
                component: (
                  <FormPTTT
                    handleClose={handleClose}
                    infoBenhNhan={infoBenhNhan}
                    isViewModal={isViewModal}
                    handleSubmit={handleSubmit}
                    inforResult={inforResult}
                    setInforResult={setInforResult}
                    listICD10={listICD10}
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    currentTab={currentTab}
                    selectedRow={selectedRow}
                    setKetQuaCLS={setKetQuaCLS}
                    ketQuaCLS={ketQuaCLS}
                    isHasData={isHasData}
                    setIsHasData={setIsHasData}
                    errors={errors}
                    touched={touched}
                  />
                ),
              },
              {
                eventKey: "11",
                title: "Nhập kết quả",
                component: (
                  <FormKQ
                    handleClose={handleClose}
                    handleTraKetQua={handleTraKetQua}
                    infoBenhNhan={infoBenhNhan}
                    isViewModal={isViewModal}
                    handleSubmit={handleSubmit}
                    noiDungMauKetQua={noiDungMauKetQua}
                    setNoiDungMauKetQua={setNoiDungMauKetQua}
                    inforResult={inforResult}
                    setInforResult={setInforResult}
                  />
                ),
              },
            ];
            return (
              <Form>
                <Modal.Header className="p-4 header-modal header-modal-chuyen-khoa">
                  <Modal.Title>Thực hiện dịch vụ</Modal.Title>
                  <button className="btn-close" onClick={handleClose}></button>
                </Modal.Header>
                <TabMenu
                  danhsachTabs={danhSachTab}
                  handlerSelect={(activeTab: any) => setCurrentTab(+activeTab)}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
