import Fuse from 'fuse.js';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover, Overlay } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { KEY_DS_DANH_MUC_TIEP_DON } from '../../../utils/Constant';
import { getIndexedDBItem } from '../../../utils/IndexedDB';
import { IDanhSachDanhMuc, QRCccdValue } from '../../models/TiepDonModel';

type Props = {
	onReadData: (value: QRCccdValue) => void | Promise<any>;
	disabled?: boolean;
	className?: string;
};

const ScanQRCccdButton = ({ onReadData, disabled }: Props) => {
	const [category, setCategory] = useState<any>([]);

	const inputRef = useRef<HTMLTextAreaElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		getDanhMuc();
	}, []);

	const getDanhMuc = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setCategory(danhMuc);
	};

	const onReadQRCode = () => {
		const QRStringValue = inputRef.current!.value;

		if (!QRStringValue) {
			toast.warn('Chưa có thông tin mã QR');
			inputRef.current?.focus();
			return;
		}

		const value = QRStringValue;

		const rawValue = value.split(/\|/);
		const [soCccd, soCmnd, hoTen, ngayThangNamSinh, gioiTinh, diaChi = '', ngayLamCanCuoc] =
			rawValue;

		const { ngaySinh, thangSinh, namSinh } = convertNgaySinh(ngayThangNamSinh);

		const [town, ward, district, province] = diaChi.split(/,/);

		const genderOption = getCategoryOptionFromName(gioiTinh, category.listGioiTinh);
		const provinceOption = getCategoryOptionFromName(province, category.provinces);
		const districtOption = getCategoryOptionFromName(district, category.districts);
		const wardOption = getCategoryOptionFromName(ward, category.subDistricts);

		onReadData({
			hoTen,
			soCccd,
			soCmnd,
			ngaySinh,
			thangSinh,
			namSinh,
			ngayLamCanCuoc,
			diaChi,
			gioiTinh,
			province,
			district,
			ward,
			town,
			genderOption,
			provinceOption,
			districtOption,
			wardOption,
		});

		buttonRef.current?.click();
	};

	const convertNgaySinh = (value: string = '') => {
		let ngaySinh = null,
			thangSinh = null,
			namSinh = null;

		if (value.length === 8) {
			ngaySinh = value.slice(0, 2);
			thangSinh = value.slice(2, 4);
			namSinh = value.slice(4);
		}

		if (value.length === 6) {
			thangSinh = value.slice(0, 2);
			namSinh = value.slice(2);
		}

		if (value.length === 4) {
			namSinh = value;
		}

		return {
			ngaySinh,
			thangSinh,
			namSinh,
		};
	};

	const getCategoryOptionFromName = (value: string, options: any[]) => {
		const fuse = new Fuse(options, {
			includeScore: true,
			includeMatches: true,
			useExtendedSearch: true,
			ignoreLocation: true,
			isCaseSensitive: true,
			threshold: 0.1,
			keys: ['name'],
		});

		const result = fuse
			.search(`"${value}"`, {
				limit: 1,
			})
			.map(record => record.item);
		return result[0];
	};

	const onReset = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.focus();
		}
	};

	const popover = (
		<Popover id="popover-basic" className="spaces min-w-300">
			<Popover.Body className="spaces w-100 p-8">
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<Form.Label>Thông tin trên mã QR căn cước công dân:</Form.Label>
					<Form.Control
						ref={inputRef}
						as="textarea"
						rows={4}
						autoFocus
						onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
							console.log(e.key);
							if (e.key === 'Enter') {
								onReadQRCode();
							}
						}}
					/>
				</Form.Group>
				<div className="d-flex justify-content-end gap-3">
					<Button className="btn-blue-outlined-custom" onClick={onReset}>
						Quét lại
					</Button>
					<Button className="btn-fill" onClick={onReadQRCode}>
						Xác nhận
					</Button>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger
			delay={{ show: 0, hide: 0 }}
			placement="bottom"
			rootClose
			overlay={popover}
			trigger={['click']}
		>
			<Button ref={buttonRef} className="btn-fill spaces ml-4" disabled={disabled}>
				<i className="bi bi-qr-code-scan spaces mr-0 pr-4 pl-4"></i>
			</Button>
		</OverlayTrigger>
	);
};

export default ScanQRCccdButton;
