import { ReactElement, ReactNode, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { toast } from 'react-toastify';
import { CODE, RESPONSE_MESSAGE } from '../../../../utils/Constant';
import { localStorageItem } from '../../../../utils/LocalStorage';
import ModalLuuMauThuocMoi from '../../../components/modal-ke-thuoc/ModalLuuMauThuocMoi';
import ModalMauChiDinhThuoc from '../../../components/modal-ke-thuoc/ModalMauChiDinhThuoc';
import { IDonThuocMauRequest } from '../../../models/ThuocModels';
import {
	getDanhSachDonThuocMau,
	getDetailDonThuocMau,
	saveDonThuocMau,
} from '../../../services/ThuocService';

const variants = [
	'fill',
	'outline',
	'white',
	'primary',
	'light',
	'secondary',
	'success',
	'info',
	'warning',
	'dark',
	'danger',
] as const;

type Variant = typeof variants[number];

type Props = {
	label: string;
	drop?: DropDirection;
	variant?: Variant;

	tableTitle?: string;
	getRowData?: (row: any) => {};
	columns?: Readonly<any[]>;

	orderType: number;
	getSaveParams: () => Omit<IDonThuocMauRequest, 'name' | 'orderType'>;
	onSaveValidationFn?: () => boolean;
	onSelectValidationFn?: () => boolean;
	onSaveSuccess?: () => void;
	onSelectMau: (data: IDonThuocMauRequest) => void;
	renderAddModalComponent?: ({
		open,
		onSave,
		onClose,
	}: {
		open: boolean;
		onSave: (data: any) => void;
		onClose: () => void;
	}) => ReactElement<any>;
};

const ACTIONS = ['luuDonMau', 'danhSachDonMau'] as const;
type ActionType = typeof ACTIONS[number];

const DonThuocMauButton = ({
	onSelectMau,
	label = 'Đơn thuốc mẫu',
	orderType,
	drop = 'down',
	variant = 'fill',
	tableTitle,
	getRowData,
	columns,
	onSaveValidationFn,
	onSelectValidationFn,
	getSaveParams,
	onSaveSuccess,
	renderAddModalComponent
}: Props) => {
	const [action, setAction] = useState<{
		open: boolean;
		type: ActionType | null;
	}>({
		open: false,
		type: null,
	});

	const [latestData, setLatestData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const userInfo = localStorageItem.get('current-user');

	const getLatestData = async () => {
		try {
			setIsLoading(true);
			const { code, data } = await getDanhSachDonThuocMau({
				createdBy: userInfo?.sub,
				orderType: Number(orderType),
				pageIndex: 1,
				pageSize: 3,
			});
			if (CODE.SUCCESS === code && data) {
				setLatestData(data.content);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onDonMauSelect = async (id: string) => {
		if (onSelectValidationFn && !onSelectValidationFn()) {
			return;
		}

		try {
			const { code, data } = await getDetailDonThuocMau(id);
			if (CODE.SUCCESS === code) {
				onSelectMau(data);
				onCloseModal();
				toast.success('Sử dụng mẫu thành công');
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onCloseModal = () => {
		setAction({
			open: false,
			type: null,
		});
	};

	const onLuuMauButtonClick = () => {
		if (onSaveValidationFn && !onSaveValidationFn()) {
			return;
		}

		setAction({
			open: true,
			type: 'luuDonMau',
		});
	};

	const onDanhSachMauButtonClick = () => {
		setAction({
			open: true,
			type: 'danhSachDonMau',
		});
	};

	const onSave = async (thongTinMau: any) => {
		try {
			const request: IDonThuocMauRequest = {
				name: thongTinMau.tenMau,
				orderType,
				...getSaveParams(),
			};
			const { code } = await saveDonThuocMau(request);
			if (CODE.SUCCESS === code) {
				toast.success('Lưu mẫu thành công');
				onSaveSuccess && onSaveSuccess();
				onCloseModal();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<Dropdown
			className="dropdown-btn menu-icon"
			drop={drop}
			onToggle={(nextShow: boolean) => {
				if (nextShow) getLatestData();
			}}
		>
			<Dropdown.Toggle className={`btn-${variant}`}>
				{label}
				<i className="bi bi-caret-down-fill spaces pl-4 pr-0 mr-0 pt-2"></i>
			</Dropdown.Toggle>

			<Dropdown.Menu
				className={`${drop === 'up' ? ' dropdown-actions-up' : 'dropdown-actions'}`}
			>
				<Dropdown.Item onClick={onLuuMauButtonClick}>
					<div className="ps-5 spaces line-height-30">Lưu vào mẫu mới</div>
				</Dropdown.Item>
				<Dropdown.Item onClick={onDanhSachMauButtonClick}>
					<div className="ps-5 spaces line-height-30">Danh sách các mẫu đã tạo</div>
				</Dropdown.Item>
				<Dropdown.Item disabled className="px-0">
					<div className="ps-5 spaces line-height-30 bg-light">Tổng hợp</div>
				</Dropdown.Item>
				{isLoading ? (
					<Dropdown.Item disabled>
						<div className="ps-5 spaces line-height-30">Đang tải dữ liệu ...</div>
					</Dropdown.Item>
				) : (
					<>
						{!!latestData.length &&
							latestData.map(record => (
								<Dropdown.Item
									key={record.id}
									onClick={() => {
										onDonMauSelect(record.id);
									}}
								>
									<div className="ps-5 spaces line-height-30">{record.name}</div>
								</Dropdown.Item>
							))}
					</>
				)}
			</Dropdown.Menu>

			{action.open && action.type === 'luuDonMau' && (
				<ModalLuuMauThuocMoi
					open={action.open}
					handleClose={onCloseModal}
					onSave={(value: any) => {
						onSave(value);
					}}
				/>
			)}

			{action.open && action.type === 'danhSachDonMau' && (
				<ModalMauChiDinhThuoc
					open={action.open}
					handleClose={onCloseModal}
					onSelect={(value: any) => {
						onDonMauSelect(value);
					}}
					orderType={orderType}
					tableTitle={tableTitle}
					getRowData={getRowData}
					columns={columns}
					renderAddModal={renderAddModalComponent}
				/>
			)}
		</Dropdown>
	);
};

export default DonThuocMauButton;
