import Fuse from 'fuse.js';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CODE, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { vatTuColumn } from '../../../../constants/Columns';
import { getDetailDonThuoc } from '../../../../services/ThuocService';
import DanhSachPhieu from '../../../tab-thuoc/components/table-don-thuoc/DanhSachPhieu';
import SearchField from '../../../tab-thuoc/components/table-don-thuoc/SearchField';
import VatTuList from '../../../tab-thuoc/components/table-don-thuoc/TableDonThuoc';
import { useTabVatTuContext } from '../../context';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';

const TableVatTu = () => {
	const {
		donVatTuList,
		donVatTuSelectedIndex,
		setDonVatTuSelectedIndex,
		setDonVatTuDetail,
		actionsRef,
	} = useTabVatTuContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const initialTableDataRef = useRef<any[]>([]);

	const [tableData, setTableData] = useState<any[]>([]);
	const [danhSachPhieu, setDanhSachPhieu] = useState<any[]>([]);

	useEffect(() => {
		if (!donVatTuList.length) {
			setDanhSachPhieu([]);
			setTableData([]);
			setDonVatTuDetail({});
			return;
		}

		const listPhieu = donVatTuList
			.sort(
				(a: any, b: any) =>
					new Date(b.designatedDate).getTime() - new Date(a.designatedDate).getTime()
			)
			.map((row: any, index) => {
				return {
					...row,
					index,
					date: moment(new Date(row?.designatedDate)).format('DD/MM/YYYY'),
				};
			});
		setDanhSachPhieu(listPhieu);
		getDetailDonVatTu(listPhieu[0]?.id);
	}, [donVatTuList]);

	useEffect(() => {
	   setDonVatTuSelectedIndex(0);
	}, [benhNhanInfo?.thongTinKhamBenh?.orderId]);	

	const getDetailDonVatTu = async (id: string) => {
		try {
			const { code, data } = await getDetailDonThuoc(id, benhNhanInfo?.thongTinKhamBenh?.orderId);
			if (CODE.SUCCESS === code) {
				setDonVatTuDetail(data);
				const listTemp = data.lstMedicalItemOrderRes.map((row: any) => ({
					...row,
					maVatTu: row.medicalItemCode,
					tenVatTu: row.name,
					donVi: row.unit,
					soLuong: row.totalQuantity,
					donGia: row.unitPrice,
					thanhTien: row.intoMoney,
				}));
				setTableData(listTemp);
				initialTableDataRef.current = listTemp;
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};
	actionsRef.current = {
		...actionsRef.current,
		getDetailDonVatTu,
	};

	const onPhieuSelect = (data: any) => {
		getDetailDonVatTu(data.id);
		setDonVatTuSelectedIndex(data.index);
	};

	const onSearch = (keySearch: string) => {
		if (!initialTableDataRef.current) {
			return;
		}

		if (!keySearch) {
			setTableData(initialTableDataRef.current);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['name', 'medicalItemCode'],
			includeMatches: true,
			includeScore: true,
		});
		const result = fuse.search(keySearch).map(record => record.item);
		setTableData(result);
	};

	return (
		<div className="bg-white mb-2">
			<SearchField onSearch={onSearch} />
			<DanhSachPhieu
				data={danhSachPhieu}
				onSelect={onPhieuSelect}
				selectedIndex={donVatTuSelectedIndex}
			/>
			<VatTuList data={tableData} columns={vatTuColumn} />
		</div>
	);
};

export default TableVatTu;
