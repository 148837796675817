import { Formik } from 'formik'
import { FC } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { IHuyPhieu } from '../models/VienPhiInfoModels'
import * as Yup from "yup";
import TextField from '../../component/TextField';
import TextValidator from '../../component/TextValidator';
import LabelRequired from '../../component/LabelRequired';

interface Props {
  show: boolean
  onCloseClick?: () => void
  onYesClick: (values: any) => void
  onCancelClick?: () => void
  title?: string
  message?: string
  yes?: string
  cancel?: string
  close?: string
  className?: string
}

const initialValues: any = {
  canceledReason: ""
};


const ConfirmDialog: FC<Props> = (props) => {
  const { show, onCloseClick, onYesClick, onCancelClick, title, message, yes, cancel, close, className } = props
 const validationSchema = Yup.object({
   invCanceledReason: Yup.string().nullable().required("Bắt buộc nhập"),
 });
  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      centered
      animation
      className={`background__modal dialog-background ${className}`}
    >
      <Modal.Header className="header-modal" closeButton>
        <Modal.Title className="text-white text-uppercase">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onYesClick(values)}
          // innerRef={refForm}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            resetForm,
          }) => (
            <Form id="form-vien-phi">
              <Row>
                <Col xs={12}>
                  <div className="">
                    <LabelRequired
                      label="Lý do hủy phiếu:"
                      className="min-w-80px"
                    />
                    <TextField
                      className="input h-175"
                      name="invCanceledReason"
                      as="textarea"
                    />
                  </div>
                </Col>
              </Row>
              <Modal.Footer className="d-flex justify-content-end p-2 border-top">
                {cancel && (
                  <Button
                    className="spaces btn-secondary px-16"
                    onClick={onCancelClick}
                  >
                    {cancel}
                  </Button>
                )}
                {yes && (
                  <Button
                    className="btn-fill min-w-50px btn btn-primary"
                    onClick={() => handleSubmit()}
                  >
                    {yes}
                  </Button>
                )}
                {close && (
                  <Button
                    className="btn-secondary min-w-80px"
                    onClick={onCloseClick}
                  >
                    {close}
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export { ConfirmDialog }