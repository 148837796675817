import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import { useEffect, useState } from 'react';
import TextField from '../../../../component/TextField';
import { IBenhNhanV3ChuyenKhoa } from '../../../models/PhanHeChuyenKhoaModel';
import AutocompleteV2 from '../../../../component/AutocompleteObjectV2';
import ModalICD10 from '../../../../phan-he-kham-benh/components/modal-icd10/ModalICD10';
import BangDsKetQuaCLS from '../BangDsKetQuaCLS';
import { getTermAnswer, inGiayChungNhan, inPhieuPhauThuat } from '../../../services/PhanHeChuyenKhoaService';
import { localStorageItem } from '../../../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../../../auth/core/_consts';
import { KetQuaDichVu } from '../../../../phan-he-kham-benh/models/ThongTinKhamBenhModel';
import ModalChonKetQuaDichVu from '../ModalChonKetQuaDichVu';
import { IInPhieuParams } from '../../../../models/params';
import ButtonInPhieu from '../../../../component/button-in-phieu';
import LuocDoPTTTField from './LuocDoPTTTField';
import DatePickerCustom from '../../../../component/date-picker/DatePickerCustom';

type Props = {
	handleClose: () => void;
	infoBenhNhan?: IBenhNhanV3ChuyenKhoa;
	isViewModal?: boolean;
	workFlowStatus?: any;
	handleSubmit: () => void;
	inforResult: any;
	setInforResult: (values: any) => void;
	listICD10: any;
	values: any;
	setFieldValue: (name: string, values: any) => void;
	setValues: (values: any) => void;
	currentTab: number;
	selectedRow: any;
	setKetQuaCLS: (value: any) => void;
	ketQuaCLS: any;
	isHasData?: boolean;
	setIsHasData?: (value: boolean) => void;
	errors?: any;
    touched?: any;
};

export default function FormPTTT({
	handleClose,
	infoBenhNhan,
	isViewModal,
	handleSubmit,
	inforResult,
	setInforResult,
	listICD10,
	values,
	setFieldValue,
	setValues,
	currentTab,
	selectedRow,
	setKetQuaCLS,
	ketQuaCLS,
	isHasData,
	setIsHasData = () => {},
	errors,
	touched
}: Props) {
	const [icd10Field, setICD10Field] = useState<string>('');
	const [selectionModeICD10, setSelectionModeICD10] = useState<'checkbox' | 'radio'>();
	const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
	const [openModalChonKetQuaDichVu, setOpenModalChonKetQuaDichVu] = useState<boolean>(false);
	const dataUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
	const handleChangeInfor = (value: any, name: string) => {
		setInforResult({
			...inforResult,
			[name]: value,
		});
	};

	const handleChangeSelect = (data: any) => {
		switch (icd10Field) {
			case 'surgeryInitialDiagnosis':
				setFieldValue('surgeryInitialDiagnosis', data);
				break;
			case 'surgeryComorbidity':
				setFieldValue('surgeryComorbidity', data);
				break;
			case 'surgeryPreoperativeDiagnosis':
				setFieldValue('surgeryPreoperativeDiagnosis', data);
				break;
			case 'surgeryPostoperativeDiagnosis':
				setFieldValue('surgeryPostoperativeDiagnosis', data);
				break;
			default:
				break;
		}
	};

	const handleDoubleClick = () => {};
	useEffect(() => {
		if (
			!values?.surgeryInformationEntryDate &&
			!currentTab &&
			inforResult?.surgeryInformationEntryDate
		) {
			setValues({
				...inforResult,
				surgeryComorbidity: {
					code: inforResult?.surgeryComorbidity,
					name: inforResult?.surgeryComorbidityText,
				},
				surgeryInitialDiagnosis: {
					code: inforResult?.surgeryInitialDiagnosis,
					name: inforResult?.surgeryInitialDiagnosisText,
				},
				surgeryPreoperativeDiagnosis: {
					code: inforResult?.surgeryPreoperativeDiagnosis,
					name: inforResult?.surgeryPreoperativeDiagnosisText,
				},
				surgeryPostoperativeDiagnosis: {
					code: inforResult?.surgeryPostoperativeDiagnosis,
					name: inforResult?.surgeryPostoperativeDiagnosisText,
				},
			});
			setIsHasData(true);
		} else {
			setValues({
				...values,
				surgerySurgicalProcedure: values?.surgerySurgicalProcedure || selectedRow?.name,
				surgerySurgicalMethod: values?.surgerySurgicalMethod || selectedRow?.name,
			});
		}
	}, [inforResult]);

	return (
		<>
			<Modal.Body className="dialog-body dialog-body-PTTT padding-3-20">
				<Row className="w-100">
					<Col xs="12">
						<div className="sub-title-kq fs-16 fw-bold">Thông tin PTTT</div>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Ngày nhập thông tin" isRequired className="" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryInformationEntryDate"
							value={values.surgeryInformationEntryDate}
							setDateValue={date => {
								setFieldValue('surgeryInformationEntryDate', date);
							}}
							errors={errors?.surgeryInformationEntryDate}
							touched={touched?.surgeryInformationEntryDate}
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Người nhập thông tin" className="" />
						<TextField
							name="surgeryInformationEntryPerson"
							className="spaces width-144px"
							labelClassName=""
							disabled
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Thời gian PT dự kiến" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryScheduledTime"
							value={values.surgeryScheduledTime}
							setDateValue={date => {
								setFieldValue('surgeryScheduledTime', date);
							}}
							errors={errors?.surgeryScheduledTime}
							touched={touched?.surgeryScheduledTime}
							disabled={isViewModal}
						/>
					</Col>

					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Thời gian khởi mê" className="" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryAnesthesiaInductionTime"
							value={values.surgeryAnesthesiaInductionTime}
							setDateValue={date => {
								setFieldValue('surgeryAnesthesiaInductionTime', date);
							}}
							errors={errors?.surgeryAnesthesiaInductionTime}
							touched={touched?.surgeryAnesthesiaInductionTime}
							disabled={isViewModal}
							popperPlacement="bottom-start"
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Thời gian bắt đầu" isRequired className="" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryStartTime"
							value={values.surgeryStartTime}
							setDateValue={date => {
								setFieldValue('surgeryStartTime', date);
							}}
							errors={errors?.surgeryStartTime}
							touched={touched?.surgeryStartTime}
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Thời gian kết thúc" className="" isRequired />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryEndTime"
							value={values.surgeryEndTime}
							setDateValue={date => {
								setFieldValue('surgeryEndTime', date);
							}}
							errors={errors?.surgeryEndTime}
							touched={touched?.surgeryEndTime}
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Khoa nhập thông tin" className="" />
						<AutocompleteV2
							options={[]}
							name="surgeryInformationEntryDepartment"
							isDisabled={isViewModal}
							searchFunction={() =>
								getTermAnswer('surgeryInformationEntryDepartment')
							}
							searchObject={{}}
							value={values?.surgeryInformationEntryDepartment}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryInformationEntryDepartment', selectedOption)
							}
						/>
					</Col>

					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phòng nhập thông tin" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={dataUser?.departments}
							name="surgeryInformationEntryRoom"
							value={values?.surgeryInformationEntryRoom}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryInformationEntryRoom', selectedOption)
							}
							// searchFunction={() => getTermAnswer("surgeryInformationEntryRoom")}
							// searchObject={{}}
						/>
					</Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LabelRequired label="Chẩn đoán ban đầu" className="" />
						<Row className="">
							<Col xs="3" className="pe-0 position-relative">
								<TextField
									name="surgeryInitialDiagnosis"
									value={values?.surgeryInitialDiagnosis?.code || ''}
									disabled={isViewModal}
								/>
								<div className="spaces width-4">
									<h6
										className="position-absolute muc-huong cursor-pointer"
										onClick={() => {
											if (isViewModal) return;
											setICD10Field('surgeryInitialDiagnosis');
											setSelectionModeICD10('radio');
											setShouldOpenModalICD10(true);
										}}
									>
										<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
									</h6>
								</div>
							</Col>
							<Col xs="9" className="ps-0">
								<TextField
									name="surgeryInitialDiagnosis"
									value={
										listICD10.find(
											(item: any) =>
												item.code === values?.surgeryInitialDiagnosis?.code
										)?.name || ''
									}
									disabled={isViewModal}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LabelRequired label="Bệnh kèm theo" className="" />
						<Row className="">
							<Col xs="3" className="pe-0 position-relative">
								<TextField
									name="surgeryComorbidity"
									value={values?.surgeryComorbidity?.code || ''}
									disabled={isViewModal}
								/>
								<div className="spaces width-4">
									<h6
										className="position-absolute muc-huong cursor-pointer"
										onClick={() => {
											if (isViewModal) return;
											setICD10Field('surgeryComorbidity');
											setSelectionModeICD10('radio');
											setShouldOpenModalICD10(true);
										}}
									>
										<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
									</h6>
								</div>
							</Col>
							<Col xs="9" className="ps-0">
								<TextField
									name="surgeryComorbidity"
									value={
										listICD10.find(
											(item: any) =>
												item.code === values?.surgeryComorbidity?.code
										)?.name || ''
									}
									disabled={isViewModal}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LabelRequired label="Chẩn đoán trước PT" className="" />
						<Row className="">
							<Col xs="3" className="pe-0 position-relative">
								<TextField
									name="surgeryPreoperativeDiagnosis"
									value={values?.surgeryPreoperativeDiagnosis?.code || ''}
									disabled={isViewModal}
								/>
								<div className="spaces width-4">
									<h6
										className="position-absolute muc-huong cursor-pointer"
										onClick={() => {
											if (isViewModal) return;
											setICD10Field('surgeryPreoperativeDiagnosis');
											setSelectionModeICD10('radio');
											setShouldOpenModalICD10(true);
										}}
									>
										<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
									</h6>
								</div>
							</Col>
							<Col xs="9" className="ps-0">
								<TextField
									name="surgeryPreoperativeDiagnosis"
									value={
										listICD10.find(
											(item: any) =>
												item.code ===
												values?.surgeryPreoperativeDiagnosis?.code
										)?.name || ''
									}
									disabled={isViewModal}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LabelRequired label="Chẩn đoán sau PT" className="" />
						<Row className="">
							<Col xs="3" className="pe-0 position-relative">
								<TextField
									name="surgeryPostoperativeDiagnosis"
									value={values?.surgeryPostoperativeDiagnosis?.code || ''}
									disabled={isViewModal}
								/>
								<div className="spaces width-4">
									<h6
										className="position-absolute muc-huong cursor-pointer"
										onClick={() => {
											if (isViewModal) return;
											setICD10Field('surgeryPostoperativeDiagnosis');
											setSelectionModeICD10('radio');
											setShouldOpenModalICD10(true);
										}}
									>
										<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
									</h6>
								</div>
							</Col>
							<Col xs="9" className="ps-0">
								<TextField
									name="surgeryPostoperativeDiagnosis"
									value={
										listICD10.find(
											(item: any) =>
												item.code ===
												values?.surgeryPostoperativeDiagnosis?.code
										)?.name || ''
									}
									disabled={isViewModal}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Dịch vụ PTTT" className="" />
						<TextField
							name="surgerySurgicalProcedure"
							className="spaces width-144px"
							labelClassName=""
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Cách thức PTTT" className="" />
						<TextField
							className="spaces width-144px"
							labelClassName=""
							name="surgerySurgicalTechnique"
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phương pháp PTTT" className="" />
						<TextField
							name="surgerySurgicalMethod"
							className="spaces width-144px"
							labelClassName=""
							disabled={isViewModal}
						/>
					</Col>

					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Loại PTTT" className="" />
						<AutocompleteV2
							isDisabled={true}
							options={[]}
							name="surgerySurgicalType"
							searchFunction={() => getTermAnswer('surgerySurgicalType')}
							searchObject={{}}
							value={values?.surgerySurgicalType}
							onChange={(selectedOption: any) =>
								setFieldValue('surgerySurgicalType', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input grid-5_9-6_1">
						<LabelRequired label="Tình hình PTTT" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgerySurgicalStatus"
							searchFunction={() => getTermAnswer('surgerySurgicalStatus')}
							searchObject={{}}
							value={values?.surgerySurgicalStatus}
							onChange={(selectedOption: any) =>
								setFieldValue('surgerySurgicalStatus', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phương pháp vô cảm" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgeryAnesthesiaMethod"
							searchFunction={() => getTermAnswer('surgeryAnesthesiaMethod')}
							searchObject={{}}
							value={values?.surgeryAnesthesiaMethod}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryAnesthesiaMethod', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Nhóm máu" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgeryBloodType"
							searchFunction={() => getTermAnswer('surgeryBloodType')}
							searchObject={{}}
							value={values?.surgeryBloodType}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryBloodType', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Yếu tố RH" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgeryRhFactor"
							searchFunction={() => getTermAnswer('surgeryRhFactor')}
							searchObject={{}}
							value={values?.surgeryRhFactor}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryRhFactor', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input grid-5_9-6_1">
						<LabelRequired label="Tai biến PTTT" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgerySurgicalComplication"
							searchFunction={() => getTermAnswer('surgerySurgicalComplication')}
							searchObject={{}}
							value={values?.surgerySurgicalComplication}
							onChange={(selectedOption: any) =>
								setFieldValue('surgerySurgicalComplication', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Tử vong" className="" />
						<AutocompleteV2
							isDisabled={isViewModal}
							options={[]}
							name="surgeryMortality"
							searchFunction={() => getTermAnswer('surgeryMortality')}
							searchObject={{}}
							value={values?.surgeryMortality}
							onChange={(selectedOption: any) =>
								setFieldValue('surgeryMortality', selectedOption)
							}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Dẫn lưu" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryDrain"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Bấc" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryMeche"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Ngày rút" className="" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgeryDrainRemovalTime"
							value={values.surgeryDrainRemovalTime}
							setDateValue={date => {
								setFieldValue('surgeryDrainRemovalTime', date);
							}}
							errors={errors?.surgeryDrainRemovalTime}
							touched={touched?.surgeryDrainRemovalTime}
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Ngày cắt chỉ" className="" />
						<DatePickerCustom
							mode='date-time'
							className="spaces width-144px"
							name="surgerySutureRemovalTime"
							value={values.surgerySutureRemovalTime}
							setDateValue={date => {
								setFieldValue('surgerySutureRemovalTime', date);
							}}
							errors={errors?.surgerySutureRemovalTime}
							touched={touched?.surgerySutureRemovalTime}
							disabled={isViewModal}
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Khác" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryOther"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input"></Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LabelRequired label="Mô tả phẫu thuật" isRequired className="" />
						<TextField
							disabled={isViewModal}
							name="surgerySurgicalDescription"
							as="textarea"
							rows={2}
							className="spaces h-200"
							labelClassName=""
						/>
					</Col>
					<Col xs="6" className="mb-5 grid-2_9-9_4">
						<LuocDoPTTTField
							value={values?.surgerySurgicalProcedureDiagram}
							isView={isViewModal}
							setFieldValue={setFieldValue}
						/>
					</Col>
					<Col xs="12" className="mt-3">
						<div className="sub-title-kq fs-16 fw-bold">Ê kíp phẫu thuật thủ thuật</div>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="PTV/TTB chính 1" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryPrimarySurgeon1st"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="PTV/TTB chính 2" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryPrimarySurgeon2nd"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phụ mổ 1" className="" />
						<TextField
							disabled={isViewModal}
							name="surgerySurgicalAssistant1st"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phụ mổ 2" className="" />
						<TextField
							disabled={isViewModal}
							name="surgerySurgicalAssistant2nd"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phụ mổ 3" className="" />
						<TextField
							disabled={isViewModal}
							name="surgerySurgicalAssistant3rd"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Bác sĩ gây mê" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryAnesthesiologist"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phụ mê 1" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryAnesthesiaAssistant1st"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Phụ mê 2" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryAnesthesiaAssistant2nd"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Giúp việc" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryAssistant"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Dụng cụ viên" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryInstrumentTechnician"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input">
						<LabelRequired label="Kỹ thuật viên" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryTechnician"
							className="spaces width-144px"
							labelClassName=""
						/>
					</Col>
					<Col xs="3" className="mb-5 item-input"></Col>
					<Col xs="12" className="mt-3">
						<div className="sub-title-kq fs-16 fw-bold">Bệnh án</div>
					</Col>
					<Col xs="12" className="mb-5 grid-1_5-11_5">
						<LabelRequired label="Tóm tắt bệnh sử" className="" />
						<TextField
							disabled={isViewModal}
							name="surgeryMedicalHistorySummary"
							as="textarea"
							rows={2}
							className="spaces "
							labelClassName=""
						/>
					</Col>
					<Col xs="12" className="my-1">
						<div className="d-flex justify-content-between mb-1">
							<div className="fw-bold">Kết quả cận lâm sàng</div>
							<Button
								className="bg-white"
								onClick={() => {
									setOpenModalChonKetQuaDichVu(true);
								}}
								disabled={isViewModal}
							>
								<u className="fw-bold text-pri fs-13px">Chọn dịch vụ</u>
							</Button>
						</div>
					</Col>
					<Col xs="12" className="">
						<BangDsKetQuaCLS
							dataKetQuaCLS={ketQuaCLS}
							handleDoubleClick={handleDoubleClick}
						/>
					</Col>
				</Row>
			</Modal.Body>
			{!isViewModal && (
				<Modal.Footer>
					{isHasData && (
						<>
							<ButtonInPhieu<IInPhieuParams>
								typeBtn="btn-outline btn-outline-pttt"
								label="Phiếu phẫu thuật"
								fetchExport={inPhieuPhauThuat}
								params={{
									orderId: selectedRow?.orderId || null,
								}}
							/>
							<ButtonInPhieu<IInPhieuParams>
								typeBtn="btn-outline btn-outline-pttt"
								label="Giấy chứng nhận"
								fetchExport={inGiayChungNhan}
								params={{
									orderId: selectedRow?.orderId || null,
								}}
							/>
						</>
					)}
					<Button className="btn-fill spaces min-w-50" onClick={() => handleSubmit()}>
						Lưu
					</Button>
				</Modal.Footer>
			)}
			{shouldOpenModalICD10 && (
				<ModalICD10
					data={listICD10}
					handleClose={() => setShouldOpenModalICD10(false)}
					setMauSelected={handleChangeSelect}
					selectionMode={selectionModeICD10}
				/>
			)}
			{openModalChonKetQuaDichVu && (
				<ModalChonKetQuaDichVu
					open={openModalChonKetQuaDichVu}
					handleClose={() => setOpenModalChonKetQuaDichVu(false)}
					onSave={(selectedData: KetQuaDichVu[]) => setKetQuaCLS(selectedData)}
					visitId={infoBenhNhan?.visit?.id}
					ketQuaCLS={ketQuaCLS}
					selectedRow={selectedRow}
				/>
			)}
		</>
	);
}
