import React, { useContext, useEffect } from "react";
import { Col, FormCheck, Row } from "react-bootstrap";
import TextField from "../../../../component/TextField";
import { formatDateToDDMMYYYY } from "../../../../utils/FormatUtils";
import "./QuanLyHopDong.scss";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { getDetailFormGenerate } from "../../../services/KhamDoanHopDongServices"
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong"
import { valueStatus } from "../../../constants/PhanHeKhamDoan";

type Props = {
  setValues: any;
  values: any;
  setFieldValue: any;
};

const FormKetLuan = ({ setValues, setFieldValue, values }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { TTNhanVien } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)

  useEffect(() => {
    (values?.visitId || TTNhanVien?.visitId) && detailKhamBenh(values)
  }, [TTNhanVien?.visitId])
  const detailKhamBenh = async (values: any) => {
    try {
      setIsLoading(true);
      let { data } = await getDetailFormGenerate(values?.visitId || TTNhanVien?.visitId);
      
      if (CODE.SUCCESS === data?.code) {
        setValues(data?.data)
        setIsLoading(false);
      } else {
        setValues({})
      }
    } catch (error) {
      setValues({})
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>

      <div className="spaces w-100 form-ket-luan justify-content-center pl-10 pt-5 ">
        <Row>
          <Col xs="4">
            <div className="spaces mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">Ngày bắt đầu:</span>
              <span className="spaces fw-4 text-truncate">
                {formatDateToDDMMYYYY(TTNhanVien?.dateStarted || "")}
              </span>
            </div>
          </Col>
          <Col xs="4">
            <div className="spaces mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">Ngày kết thúc:</span>
              <span className="spaces fw-4 text-truncate">
                {values?.examEndDate || ""}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">Trạng thái khám:</span>
            </div>
          </Col>
          <Col xs="2">
            <FormCheck
              label="Đang khám"
              type='radio'
              className='d-flex me-1'
              name={"dangKham"}
              value={valueStatus.DANG_KHAM}
              checked={values?.examStatus === valueStatus.DANG_KHAM}
              onChange={(e) => setFieldValue("examStatus", e.target.value)}
            />
          </Col>
          <Col xs="2">
            <FormCheck
              label="Đã khám xong"
              type='radio'
              className='d-flex me-1'
              name="daKhamXong"
              value={valueStatus.DA_KHAM_XONG}
              checked={values?.examStatus === valueStatus.DA_KHAM_XONG}
              onChange={(e) => setFieldValue("examStatus", e.target.value)}
            />
          </Col>
          <Col xs="2">
            <FormCheck
              type='radio'
              label="Duyệt bệnh án"
              className='d-flex me-1'
              name="duyetBenhAn"
              value={valueStatus.DUYET_BENH_AN}
              checked={values?.examStatus === valueStatus.DUYET_BENH_AN}
              onChange={(e) => setFieldValue("examStatus", e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="4" className="spaces   mt-10 ">
            <TextField
              label={<div>
                Xếp loại
              </div>}
              name="examClassification"
              labelClassName=" min-w-100px "
              className=""
              value={values?.examClassification || ""}
            />
          </Col>
        </Row>
        <div className="spaces w-100 justify-content-center mt-20 pl-0">
          <TextField
            name="examConclusion"
            className="text-field-label-down-line-hd min-height-100px ml-0"
            as="textarea"
            value={values?.examConclusion || ""}
            label="Kết luận"
          />
        </div>
        <div className="spaces w-100 justify-content-center mt-10">
          <TextField
            name="neededSolve"
            className="text-field-label-down-line-hd min-height-200px ml-0 "
            as="textarea"
            label="Những vấn đề cần giải quyết"
            value={values?.neededSolve || ""}
          />
        </div>
      </div>
    </>
  );
};

export default FormKetLuan;
