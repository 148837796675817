import React, { useContext, useEffect, useState } from "react";
import {
  dsBenhNhan,
} from "./models/VienPhiInfoModels";
import ContextThanhToanProvider from "./context/PhanHeVienPhiContext";
import { Row } from "react-bootstrap";
import DanhSachBenhNhan from "./components/DanhSachBenhNhan";
import ThongTinBenhNhan from "./components/ThongTinBenhNhan";
import ThongTinVienPhi from "./components/ThongTinVienPhi";
import "./PhanHeVienPhi.scss";
import { CODE, DEFAULT_PAGE_INDEX, DEFAULT_TOTAL_ELEMENTS, DEFAULT_TOTAL_PAGES, RESPONSE_MESSAGE } from "../utils/Constant";
import { LIST_ORDER } from "../phan-he-cdha-va-tdcn/constants/Constants";
import { getListPatient, getPaymentDetail, getStatusVienPhi } from "./services/PhanHeVienPhiServices";
import { AppContext } from "../appContext/AppContext";
import { toast } from "react-toastify";
import { formatDateToYYYYMMDD } from "../utils/FormatUtils";

type Props = {};

const PhanHeVienPhi = (props: Props) => {
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [filter, setFilter] = useState<any>(null);
  const [countPaid, setCountPaid] = useState<any>();
  const { setIsLoading, currentTab } = useContext(AppContext);
  const [paymentInfor, setPaymentInfor] = useState<any>(null);

  const [dsBenhNhan, setDsBenhNhan] = useState<dsBenhNhan>({
    data: [],
    totalElements: DEFAULT_TOTAL_ELEMENTS,
    totalPages: DEFAULT_TOTAL_PAGES,
  });

  const getTrangThaiVienPhi = async (filter?: any) => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 9999999,
      keyword: filter?.keyword,
      sortBy: filter?.formal?.code,
      descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
      visitObjectTypeCodes: filter?.typeChecks?.length > 0 ? filter?.typeChecks?.join(",") : "",
      fromDate: formatDateToYYYYMMDD(filter.period?.fromDate),
      toDate: formatDateToYYYYMMDD(filter.period?.toDate),
      deptCode: filter?.deptCode || null,
      filterBy: filter?.filterBy || null,
      filterValue: filter?.filterValue ? "true" : "false"
    };
    try {
      const { data } = await getStatusVienPhi(searchObject);
      if (CODE.SUCCESS === data?.code) {
        setCountPaid({
          notPaidEnough: data?.data?.notPaidEnough,
          paidEnough: data?.data?.paidEnough,
        });
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetPaymentInfor = async () => {
    try {
      let {data} = await getPaymentDetail(selectedRow[0]?.visitId)
      if(data?.code === CODE.SUCCESS) {
        setPaymentInfor(data?.data)
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    filter && getTrangThaiVienPhi(filter);
  }, [filter]);

  return (
    <div className="reception-list bg-gray">
      <ContextThanhToanProvider>
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35">
            <DanhSachBenhNhan
              dsBenhNhan={dsBenhNhan}
              setDsBenhNhan={setDsBenhNhan}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              setFilter={setFilter}
              countPaid={countPaid}
              setCountPaid={setCountPaid}
              // getDanhSachBenhNhan={getDanhSachBenhNhan}
              filter={filter}
            />
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <ThongTinBenhNhan
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              paymentInfor={paymentInfor}
            />
            <ThongTinVienPhi
              selectedRow={selectedRow}
              filter={filter}
              setFilter={setFilter}
              // getDanhSachBenhNhan={getDanhSachBenhNhan}
              paymentInfor={paymentInfor}
              handleGetPaymentInfor={handleGetPaymentInfor}
            />
          </div>
        </Row>
      </ContextThanhToanProvider>
    </div>
  );
};

export default PhanHeVienPhi;
