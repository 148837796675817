import React, { FC, useEffect, useContext } from "react";
import CustomTabMenu from "../../../../component/CustomTabMenu";
import KhamBenh from "./KhamBenh";
import CDHA from "./CDHA";
import XetNghiem from "./XetNghiem";
import "./KhamLamSang.scss"
import { Button } from "react-bootstrap";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE, RESPONSE_MESSAGE } from "../../../../utils/Constant";
import { startKhamBenh, updateWfWorkflows, getWfWorkflows, updateSummaryContract } from "../services/KhamLamSangSevice"
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { toast } from "react-toastify";
import SoKhamSucKhoe from "./SoKhamSucKhoe";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";


type KhamLamSangTabProps = {
  setActiveTab?: any,
}
const KhamLamSangTab: FC<KhamLamSangTabProps> = React.memo(
  ({
    setActiveTab,
  }) => {

    const { setIsLoading } = useContext(AppContext);
    const { TTNhanVien, setTTNhanVien, TTHopDong, setIsSearchNV, isSearchNV } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
    const { setWorkFlowStatus, workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext)

    useEffect(() => {
      TTNhanVien?.orderId && updatePageData();
    }, [TTNhanVien?.orderId]);

    const updatePageData = async () => {
      try {
        let { data } = await getWfWorkflows("clinical", TTNhanVien?.orderId);
        if (data?.code === CODE.SUCCESS) {
          setWorkFlowStatus(data?.data);
        }
      } catch (e) {
        toast.error(RESPONSE_MESSAGE.ERROR);
      }
    };

    const handlerStartKhamBenh = () => {
      const onit = async () => {
        let values = {
          patientId: TTNhanVien?.patient?.id || "",
          visitId: TTNhanVien?.visit?.id || "",
          orderId: TTNhanVien?.orderId || ""
        }
        try {
          setIsLoading(true);
          let { data } = await startKhamBenh(values);
          await updateSummaryContract({ objectId: TTNhanVien?.staff?.id, status: 14 });

          if (CODE.SUCCESS === data?.code) {
            workFlowStatus?.newStatusId && handlerUpdateWorkFlow(false);
            setTTNhanVien({
              ...TTNhanVien,
              encounter: {
                ...TTNhanVien?.encounter,
                id: data?.data?.encounter?.id,
              }
            })
            setIsSearchNV(!isSearchNV);
            setIsLoading(false);
          } else {
            let errorMesage = data?.message || "Xảy ra lỗi, vui lòng thử lại!";
            toast.error(errorMesage);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      onit();
    }

    const handlerUpdateWorkFlow = (conclusionPeExam: boolean) => {
      const onit = async () => {
        let { data } = await updateWfWorkflows("clinical", {
          status: workFlowStatus?.newStatusId,
          objectId: TTNhanVien?.orderId,
          conclusionPeExam: conclusionPeExam
        });
        
        if (CODE.SUCCESS === data?.code) {
          conclusionPeExam && setIsSearchNV(!isSearchNV);
          setTTNhanVien({
            ...TTNhanVien,
            wfTracker: data?.data
          });
          setWorkFlowStatus(data?.data);
        } else {
          toast.warning(data.message);
        }
      };
      onit();
    };

    const listTab = [
      {
        title: 'Khám bệnh',
        component: <KhamBenh />
      },
      {
        title: 'Sổ khám sức khoẻ',
        component: <SoKhamSucKhoe />
      },
      {
        title: 'Xét nghiệm',
        component: <XetNghiem />
      },
      {
        title: 'CĐHA-TDCN',
        component: <CDHA />
      },
    ];

    return (
      <div className="reception-list__container spaces   bg-white">
        <div className="d-flex border-bottom spaces pl-10 pr-15  gap-10">
          <h4 className="text-title-kls fw-bold fs-4 spaces my-5">
            {`Khám lâm sàng | ${TTHopDong?.companyName}`}
          </h4>
          <div className="d-flex justify-content-end align-items-center flex-grow-1">
            {workFlowStatus?.currStatusCode === WF_STATUS.CHUA_KHAM.CODE && TTNhanVien?.orderId &&
              <Button className="btn-fill spaces min-w-105 h-29 " onClick={handlerStartKhamBenh} >
                <span>Bắt đầu khám</span>
              </Button>
            }
            {(workFlowStatus?.currStatusCode === WF_STATUS.DANG_KHAM.CODE || workFlowStatus?.currStatusCode === WF_STATUS.DA_CO_KQ.CODE) && TTNhanVien?.orderId &&
              <Button className="btn-fill spaces min-w-105 h-29 " onClick={() => handlerUpdateWorkFlow(true)} >
                <span>Kết thúc khám</span>
              </Button>
            }
            {/* <WfButton tracker="clinical" objectId={TTNhanVien?.orderId} additionalProps={{ staffId: TTNhanVien?.id }}/> */}
            <Button className="mx-2 btn-fill  spaces min-w-60 h-29 "
            >
              <span>In</span>
            </Button>
          </div>
        </div>
        <div className="bg-white spaces py-5 gap-10">
          <CustomTabMenu classTittle="spaces ml-10" danhsachTabs={listTab} childrenTab={true} />
        </div>
      </div>
    );
  }
);
export default KhamLamSangTab;
