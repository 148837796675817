import clsx from 'clsx';
import 'cropperjs/dist/cropper.css';
import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Cropper, ReactCropperElement } from 'react-cropper';
import ActionButton from '../image-lightbox/components/ActionButton';
import { ACTION_BUTTONS } from './constant';
import { Actions, Props } from './type';

import './style.scss';

const ImageEditor = ({ open, onClose, src, onSave }: Props) => {
	const [tool, setTool] = useState<Actions>('reset');

	const cropperRef = useRef<ReactCropperElement>(null);

	const onSubmit = () => {
		if (cropperRef.current?.cropper) {
			onSave(cropperRef.current?.cropper.getCroppedCanvas({
				imageSmoothingEnabled: true,
				imageSmoothingQuality: 'high'
			}).toDataURL());
			onClose();
		}
	};

	const generateAction = (action: Actions) => {
		const mapActionFn: Record<Actions, () => void> = {
			zoomIn: () => {
				cropperRef.current?.cropper.zoom(0.1);
			},
			zoomOut: () => {
				cropperRef.current?.cropper.zoom(-0.1);
			},
			rotateLeft: () => {
				cropperRef.current?.cropper.rotate(-45);
			},
			rotateRight: () => {
				cropperRef.current?.cropper.rotate(45);
			},
			flipVertical: () => {
				cropperRef.current?.cropper.scaleY(-cropperRef.current.cropper.getData().scaleY);
			},
			flipHorizontal: () => {
				cropperRef.current?.cropper.scaleX(-cropperRef.current.cropper.getData().scaleX);
			},
			reset: () => {
				cropperRef.current?.cropper.reset();
			},
			moveUp: () => {
				cropperRef.current?.cropper.move(0, -20);
			},
			moveDown: () => {
				cropperRef.current?.cropper.move(0, 20);
			},
			moveLeft: () => {
				cropperRef.current?.cropper.move(-20, 0);
			},
			moveRight: () => {
				cropperRef.current?.cropper.move(20, 0);
			},
		};

		return mapActionFn[action];
	};

	const onSliderChange = (value: number) => {
		if (tool === 'zoomIn' || tool === 'zoomOut') {
			cropperRef.current?.cropper.zoomTo(value / 100);
			return;
		}

		if (tool === 'rotateLeft' || tool === 'rotateRight') {
			cropperRef.current?.cropper.rotateTo(value);
			return;
		}
	};

	return (
		<Modal fullscreen show={open} animation centered className="dialog-background">
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Chỉnh sửa hình ảnh</Modal.Title>
				<Button className="btn-close bg-white" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="p-16 spaces">
				<div className="d-flex h-100">
					<div className="spaces w-70 h-100 d-flex flex-column">
						<div
							className="w-100 h-100"
							style={{
								flex: '1 1 300px',
							}}
						>
							<Cropper
								src={src}
								style={{ height: '100%', width: '100%' }}
								initialAspectRatio={16 / 9}
								ref={cropperRef}
								background={false}
								preview=".cropped-preview"
								className="image-editor-cropper"
							/>
						</div>

						<div
							className={clsx(
								'd-flex align-items-center justify-content-center',
								'spaces w-100 p-8 mt-10'
							)}
							style={{
								flex: '0 1 40px',
							}}
						>
							{ACTION_BUTTONS.map(button => (
								<ActionButton
									key={button.action}
									action={button.action}
									title={button.title}
									icon={button.icon}
									onClick={() => {
										generateAction(button.action)();
										setTool(button.action);
									}}
								/>
							))}
						</div>

						{/* <div className="spaces w-50 mt-10 mx-auto">
							{(tool === 'rotateLeft' || tool === 'rotateRight') && (
								<Slider
									min={-45}
									max={45}
									step={1}
									range={SLIDER_ROTATE_TICKS}
									onChange={onSliderChange}
								/>
							)}
						</div> */}
					</div>
					<div className="spaces w-30 ml-10">
						<div
							className="spaces w-100 h-300 cropped-preview border"
							style={{ overflow: 'hidden' }}
						></div>
						<div className="fs-1 text-center">
							<em>Nội dung phần hình ảnh được cắt (xem trước)</em>
						</div>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end py-1">
				<Button className="btn-outline" onClick={onClose}>
					Đóng
				</Button>
				<Button className="btn-fill" type="submit" onClick={onSubmit}>
					Xác nhận
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ImageEditor;
