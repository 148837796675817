import axios from "axios";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { SearchObject } from "../../phan-he-tiep-nhan-thanh-toan/models/PhanHeTiepNhanModel";
import { localStorageItem } from "../../utils/LocalStorage";
import { urlParamsConfig } from "../../utils/ParamsUtils";


const API_PATH = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;


export const searchByPage = (searchObject: SearchObject) => {
    let url = API_PATH + "/customer-care/search";
    return axios.get(urlParamsConfig(url, searchObject));
};

export const getDsDichVu = (visitId: string) => {
    let url = API_PATH + "/customer-care/orders";
    return axios.get(urlParamsConfig(url, { visitId }));
};

export const getDsThuoc = (visitId: string) => {
    let url = API_PATH + "/customer-care/drugs";
    return axios.get(urlParamsConfig(url, { visitId }));
};