import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FormikErrors, FormikTouched } from "formik";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { KEY_DS_DANH_MUC_TIEP_DON } from "../../utils/Constant";
import SelectTableVirtualized from "./select-thuoc-button/SelectTableVirtualized";
import TextField from "../../component/TextField";

type Props = {
  encounterDiagnosisValues: any;
  encounterDiagnosisErrors: FormikErrors<any>;
  encounterDiagnosisTouched: FormikTouched<any>;
  benhNhanInfo: any;
  listComorbidity: any;
  setListComorbidity: (value: any) => void;
  disabled?: boolean;
  icd10Field?: string;
  comorbidityField?: string;
  onChange: (value: any) => void;
  handleChangeEncounterDiagnosis: (name: any, value: any) => void;
};

const ProvisonalDiagnosis = (props: Props) => {
  const {
    encounterDiagnosisValues,
    encounterDiagnosisErrors,
    encounterDiagnosisTouched,
    benhNhanInfo,
    listComorbidity,
    setListComorbidity,
    disabled = false,
    icd10Field = "provisionalDiagnosis",
    comorbidityField = "comorbidity",
    onChange,
    handleChangeEncounterDiagnosis,
  } = props;
  const [listICD10, setListICD10] = useState<any>([]);

  useEffect(() => {
    handleGetListICD10();
  }, []);

  const handleSelectedICD10 = (value: any) => {
    let valueAdd = {
      termName: value?.rowData?.name,
      termCode: value?.rowData?.code,
      isDiseaseMain: true,
    };
    let newArr = [];
    let arrAfter = [];
    let hasDiagnosis = listComorbidity?.some((item: any) => {
      return item.termCode === value?.rowData?.code;
    });
    if (!hasDiagnosis) {
      if (listComorbidity?.length) {
        newArr = [
          ...listComorbidity?.filter((item: any) => {
            return !item?.isDiseaseMain;
          }),
          valueAdd,
        ];
      } else {
        newArr = [
          {
            ...valueAdd,
          },
        ];
      }
      arrAfter = newArr.filter((item: any) => !item?.isDiseaseMain);
    } else {
      newArr = listComorbidity?.map((item: any) => {
        if (item.termCode === value?.rowData?.code) {
          item.isDiseaseMain = true;
        } else {
          item.isDiseaseMain = false;
        }
        return item;
      });
      arrAfter = newArr.filter((item: any) => !item?.isDiseaseMain);
    }
    setListComorbidity(newArr);
    onChange({
      [icd10Field]: value?.rowData?.code,
      [icd10Field + "Name"]: value?.rowData?.name,
      [comorbidityField]: arrAfter?.map((item: any) => item?.termName).join("; "),
    });
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  const columnSelectTable = [
    {
      name: "",
      field: "radio",
      width: 50,
      textAlign: "center",
    },
    {
      name: "ICD10",
      field: "code",
      width: 200,
      textAlign: "center",
    },
    {
      name: "Tên bệnh",
      field: "name",
      width: 892,
      textAlign: "left",
    },
  ];

  // console.log("errors", encounterDiagnosisErrors);
  
  
  return (
    <>
      <Col xs="3" className="pe-0">
        <SelectTableVirtualized
          value={encounterDiagnosisValues?.[icd10Field] || ""}
          setICD10Selected={(value) => {
            handleSelectedICD10(value);
          }}
          columns={columnSelectTable}
          data={listICD10}
          label=""
          labelClassName="min-w-120-px"
          disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined || disabled}
          keyword="code"
          name={icd10Field}
          touched={encounterDiagnosisTouched?.[icd10Field]}
          errors={encounterDiagnosisErrors?.[icd10Field]}
        />
      </Col>
      <Col xs="9" className="ps-0">
        <SelectTableVirtualized
          value={
            listICD10.find(
              (item: any) =>
                item.code === encounterDiagnosisValues?.[icd10Field]
            )?.name ||
            encounterDiagnosisValues?.[icd10Field + "Name"] ||
            ""
          }
          setICD10Selected={(value) => {
            handleSelectedICD10(value);
          }}
          columns={columnSelectTable}
          data={listICD10}
          label=""
          labelClassName="min-w-120-px"
          disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined || disabled}
          keyword="name"
          name={icd10Field + "Name"}
          touched={encounterDiagnosisTouched?.[icd10Field + "Name"]}
          errors={encounterDiagnosisErrors?.[icd10Field + "Name"]}
        />
      </Col>
      <Col xs="12" className="">
        <TextField
          className="spaces pt-2"
          name="diagnosisNonCoded"
          value={encounterDiagnosisValues?.diagnosisNonCoded || ''}
          onChange={(e: any) =>
            handleChangeEncounterDiagnosis(e.target.name, e.target.value)
          }
          disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined || disabled}
          placeholder="Chẩn đoán ngoài ICD10"
          touched={encounterDiagnosisTouched?.diagnosisNonCoded}
          errors={encounterDiagnosisErrors?.diagnosisNonCoded}
        />
      </Col>
    </>
  );
};

export default ProvisonalDiagnosis;
