import { useEffect } from "react";
import LabelRequired from "../../../../component/LabelRequired";
import TextField from "../../../../component/TextField";
import "./KhamLamSang.scss";

type Props = {
  setValues?: any;
  values?: any;
  isView?: boolean;
  content?: any;
};

const FormKhamMat = ({ setValues, values, isView = false, content }: Props) => {
  
  useEffect(() => {
    setValues(content)
  }, [content])

  return (

    <div className="bg-white  modelKhamBenh spaces h-calc-vh-340 mb-6 fl-1">
      <div className="spaces mt-5 mx-10">
        <LabelRequired
          label="Kết quả khám thị lực:"
          className="min-w-200px text-title"
        />
      </div>
      <div className=" flex spaces w-100 ml-30">
        <i className="customer-icon-dot bi bi-circle-fill"></i>
        <LabelRequired
          label="Không kính"
          className="min-w-100px text-title"
        />
        <TextField
          name="LEUncorrected"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Mắt trái"
          disabled={isView}
          value={values?.LEUncorrected || ''}
        />
        <TextField
          name="LRUncorrected"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Mắt phải"
          disabled={isView}
          value={values?.LRUncorrected || ''}
        />
      </div>
      <div className=" flex spaces w-100 ml-30">
        <i className="customer-icon-dot bi bi-circle-fill"></i>
        <LabelRequired
          label="Có kính"
          className="min-w-100px text-title"
        />
        <TextField
          name="LRWithGlasses"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Mắt trái"
          disabled={isView}
          value={values?.LRWithGlasses || ''}
        />
        <TextField
          name="LEWithGlasses"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Mắt phải"
          disabled={isView}
          value={values?.LEWithGlasses || ""}
        />
      </div>
      <div className="spaces w-100 justify-content-center mt-10">
        <TextField
          name="otherEyeConditions"
          className="text-field-label-down-line min-height-90px"
          labelClassName="ps-2"
          as="textarea"
          disabled={isView}
          value={values?.otherEyeConditions || ""}
          label="Các bệnh khác về mắt(nếu có)"
        />
      </div>
      <div className="flex spaces w-100 pt-10">
        <TextField
          name="eyeClassification"
          className="text-field-label-down-line min-height-90px w-100"
          labelClassName="ps-2"
          as="textarea"
          label="Phân loại"
          disabled={isView}
          value={values?.eyeClassification || ""}
        />
        <TextField
          name="eyeConclusion"
          className="text-field-label-down-line min-height-90px w-100"
          labelClassName="ps-2"
          as="textarea"
          label="Kết luận"
          disabled={isView}
          value={values?.eyeConclusion || ""}
        />
      </div>

    </div>
  );
};

export default FormKhamMat;
