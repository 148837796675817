import { FC, useEffect, useRef, useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import "../table.scss";
import "./TableCollapseCustom.scss";
import { generateSecureRandomId } from "../../../utils/AppFunction";

interface Props {
    data: any[];
    columns: any[];
    className?: string;
    classNameTable?: string;
    height?: any;
    minHeight?: any;
    maxHeight?: any;
    handleDoubleClick?: (row: any) => void;
    parentField?: string;
    childrenField?: string;
    startColumn?: number;
    handleClickRow?: (data: any) => void;
    isFromTabXN?: boolean;
    handleContextMenu?: (e: any, row: any) => void;
    isOtherColorHeader?: boolean;
}

const TableCollapseCustom: FC<Props> = (props) => {
    let {
        data,
        columns,
        className,
        classNameTable,
        height,
        minHeight,
        maxHeight,
        startColumn = 0,
    } = props;
    const [styles, setStyles] = useState<object>({});
    const [startWidth, setStartWidth] = useState<number>(0);
    const refTableHeader = useRef<any>(null);

    useEffect(() => {
        setStyles({
            ...styles,
            minHeight: minHeight,
            maxHeight: maxHeight,
            height: height,
        });
    }, [minHeight, maxHeight, height]);

    function getColumnWidths(n: number) {
        let totalWidth = 0;

        for (let i = 0; i < n; i++) {
            const cell = refTableHeader.current.cells[i];
            const width = cell.getBoundingClientRect().width;
            totalWidth += width;
        }
        setStartWidth(totalWidth);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    getColumnWidths(startColumn);
                }
            },
            { threshold: 0 }
        );

        if (refTableHeader.current) {
            observer.observe(refTableHeader.current);
        }

        return () => {
            if (refTableHeader.current) {
                observer.unobserve(refTableHeader.current);
            }
        };
    }, []);

    return (
        <div
            className={`table-responsive table-scroll-y table-collapse ${className}`}
            style={styles}
        >
            <Table
                className={`table table-position align-middle table-row-dashed fs-6 gy-5 dataTable no-footer border-bottom ${classNameTable}`}
            >
                <thead className="header-sticky">
                    <tr ref={refTableHeader} className="text-muted fw-bolder text-uppercase gs-0 border bg-pri">
                        {columns?.map((column) => (
                            <th
                                className={`text-uppercase fw-bold text-white cursor-pointer bg-pri text-center p-7 h-35 ${column?.className || ""
                                    }`}
                                key={generateSecureRandomId()}
                            >
                                {column?.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-gray fw-bold bg-white">
                    {data?.map((pItem: any, pIndex: number) => {
                        return (
                            <CollapseRow
                                key={pIndex}
                                rowData={pItem}
                                eventKey={pIndex}
                                level={0}
                                startWidth={startWidth}
                                listIndex={[pIndex]}
                                {...props}
                            />
                        );
                    })}
                    {data?.length === 0 && (
                        <tr>
                            <td colSpan={columns.length} className="text-center">
                                Không có dữ liệu
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export { TableCollapseCustom };

const CollapseRow = ({ rowData, eventKey, level, startWidth, listIndex, ...props }: any) => {

    let {
        columns,
        handleClickRow = () => { },
        handleContextMenu,
        height,
        minHeight,
        maxHeight,
        parentField = "terms",
        childrenField = "subs",
        handleDoubleClick = () => { },
        startColumn = 0,
        isFromTabXN = false,
        isOtherColorHeader = false,
    } = props;
    const [rowId, setRowId] = useState<any>("");
    const [styles, setStyles] = useState<object>({});
    const hasParrent = rowData?.[parentField] && rowData?.[parentField]?.length;
    const hasChildren = rowData?.[childrenField] && rowData?.[childrenField]?.length;
    useEffect(() => {
        setStyles({
            ...styles,
            minHeight: minHeight,
            maxHeight: maxHeight,
            height: height,
        });
    }, [minHeight, maxHeight, height]);

    const renderFieldValue = (data: any, field: string) => {
        let result = data;
        field.split(".").forEach((fieldItem) => {
            result = result?.[fieldItem];
        });
        return result;
    };

    const handleCustomContextMenu = (e: any, row: any) => {
        e.preventDefault();
        handleContextMenu && handleContextMenu(e, row);
    };

    return <tr
        key={generateSecureRandomId()}
        onClick={() => handleClickRow(rowData)}
        onDoubleClick={(e) => {
            e.stopPropagation();
            handleDoubleClick && handleDoubleClick(rowData);
        }}
    >
        <td colSpan={columns.length} className="p-0 border-top-0">
            {(hasParrent || hasChildren) ? (
                <Accordion alwaysOpen defaultActiveKey={[eventKey.toString()]}>
                    <Accordion.Item eventKey={eventKey.toString()} className="border-0">
                        {(isFromTabXN && level) ? 
                            <Accordion.Header className="collapse-row">
                                <Table className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-children">
                                    <tbody>
                                        <tr
                                            className={`${handleClickRow ? "clickable-row" : ""
                                                } ${rowId === rowData.id ? "bg-secondary" : ""}`}
                                        >
                                            {columns?.map(
                                                (column: any, cIndex: number) => (
                                                    <td
                                                        key={cIndex}
                                                        className={`spaces py-0 cursor-pointer
                                                        ${level && "fw-normal"}
                                                        ${column?.className || ""}
                                                        ${column?.cellClassName || ""}
                                                        ${cIndex === startColumn && "collapse-cell"}`}
                                                        onContextMenu={(e) =>
                                                            handleCustomContextMenu(e, rowData)
                                                        }
                                                    >
                                                        {column?.render
                                                            ? column.render(rowData, listIndex, cIndex)
                                                            : renderFieldValue(rowData, column?.field)}
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Header> : <Accordion.Header className={`collapse-header-${level}`}
                            style={{ marginLeft: `${startWidth}px` }}
                        >
                            <h6 
                                className={`m-0 ps-1 ${level && "fw-normal"}`} 
                                onContextMenu={(e) => !rowData?.attributes?.isComponent && handleCustomContextMenu(e, rowData)}
                                style={{ color: isOtherColorHeader && rowData?.statusColor }}
                            >
                                {hasParrent ? rowData?.details : rowData?.name}
                            </h6>
                        </Accordion.Header>}
                        
                        <Accordion.Body className="p-0">
                            {rowData?.[hasParrent ? parentField : childrenField]?.map((item: any, index: number) => {
                                return <Table className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-children" key={index}>
                                    <tbody>
                                        <CollapseRow
                                            key={index}
                                            rowData={item}
                                            level={level + 1}
                                            eventKey={eventKey + "-" + index}
                                            startWidth={startWidth}
                                            listIndex={[...listIndex, index]}
                                            {...props}
                                        />
                                    </tbody>
                                </Table>
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : (
                <Table className="table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-children">
                    <tbody>
                        <tr
                            className={`${handleClickRow ? "clickable-row" : ""
                                } ${rowId === rowData.id ? "bg-secondary" : ""} 
                                collapse-header-${level}`}
                        >
                            {columns?.map(
                                (column: any, cIndex: number) => (
                                    <td
                                        key={cIndex}
                                        className={`spaces py-0 cursor-pointer
                                            ${level && "fw-normal"}
                                            ${column?.className || ""}
                                            ${column?.cellClassName || ""}
                                            ${cIndex === startColumn && "collapse-cell"}
                                        `}
                                        onContextMenu={(e) =>
                                            handleCustomContextMenu(e, rowData)
                                        }
                                    >
                                        {column?.render
                                            ? column.render(rowData, listIndex, cIndex)
                                            : renderFieldValue(rowData, column?.field)}
                                    </td>
                                )
                            )}
                        </tr>
                    </tbody>
                </Table>
            )}
        </td>
    </tr>
}