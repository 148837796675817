import { Button, Modal } from 'react-bootstrap';
import { FC, SetStateAction } from 'react';
import { ConfirmReasonDialog } from '../../component/ConfirmReasonDialog';

interface DynamicProps {
  data: any,
  setData: SetStateAction<any>,
  handleCancelService?: (value: any) => void
}

const DynamicComponentXN: FC<DynamicProps> = (props) => {
  const { data, setData, handleCancelService } = props;
  const Default = () => {
    return <div>Không có nội dung</div>;
  };

  const Confirm = () => {
    const onOK = () => {
      data.submit();
      setData(null);
    };

    return (
      <Modal centered show={data?.componentName === 'Confirm'} size="sm" onHide={() => setData(null)}>
        <Modal.Title className="py-5 header-modal"> Xác nhận </Modal.Title>
        <Modal.Body className="spaces px-15">{`Bạn chắc chắn muốn ${data.nameReq} ?`}</Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill" onClick={onOK}>
            Có
          </Button>
          <Button className="btn-outline" onClick={() => setData(null)}>
            Không
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ConfirmCancel = () => {

    return (
      <ConfirmReasonDialog
        show={data?.componentName === 'ConfirmCancel'}
        close="Hủy"
        yes="Lưu"
        onYesClick={(values) => {
          handleCancelService?.(values);
          setData(null);
        }}
        onCloseClick={() => setData(null)}
        title="Không thực hiện dịch vụ"
        name="canceledReason"
        label="Lý do không thực hiện dịch vụ"
      />
    );
  };

  const components: { [key: string]: FC<any> } = {
    Confirm: Confirm,
    ConfirmCancel: ConfirmCancel,
  };

  const DynamicContent = components[data?.componentName] || Default;

  return <DynamicContent {...data} />;
};

export default DynamicComponentXN;