import { Formik } from "formik";
import {
  FocusEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import TextField from "../../../component/TextField";
import { CODE, STATUS_CANCEL } from "../../../utils/Constant";
import WfContextMenu from "../../../workflows/components/WfContextMenu";
import { WfConstTracker } from "../../../workflows/Constant";
import { convertListDichVuXetNgiem } from "../../common";
import {
  DATA_TYPE_XN,
} from "../../const/constants";
import { PhanHeXetNghiemContext } from "../../context/PhanHeXetNghiemContext";
import { getPhieuXetNghemInfo, saveObsValue } from "../../service/tiepNhan";
import DialogChuyenDV from "./menu-dich-vu/DialogChuyenDV";

const BangLayMauBenhPham = (props: any) => {
  const { benhNhanInfo, phieuXetNghiemInfo, setPhieuXetNghiemInfo } =
    useContext(PhanHeXetNghiemContext);
  const { actionClick, rowSelect, setRowSelect } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [openChuyenDV, setOpenChuyenDV] = useState(false);
  const [dataKQ, setDataKQ] = useState<any>([]);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [configurationContextMenu, setConfigurationContextMenu] = useState<any>(null);

  useEffect(() => {
    setDataKQ(phieuXetNghiemInfo?.dsXetNghiem || []);
  }, [phieuXetNghiemInfo]);

  const handleChuyenPhongDV = (phong: any) => {
    setOpenChuyenDV(false);
    for (let i = 0; i < dataKQ.length; i++) {
      if (dataKQ[i].tenXetNghiem === rowSelect?.original?.tenXetNghiem) {
        dataKQ[i].tenXetNghiem = phong?.[0]?.original?.tenPhong;
        break;
      }
    }
    setDataKQ(dataKQ);
    toast.success("Chuyển phòng dịch vụ thành công");
  };

  const handleGetDsDichVu = async () => {
    const { data: dataXN } = await getPhieuXetNghemInfo(
      benhNhanInfo?.testGroupId
    );
    if (CODE.SUCCESS === dataXN?.code) {
      const thongTinPhieu = dataXN?.data;
      const dsXetNghiem = convertListDichVuXetNgiem(thongTinPhieu.ordersList);
      setPhieuXetNghiemInfo({
        thongTinPhieu,
        dsXetNghiem,
      });
    }
  }

  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData.obs?.value) {
        setFieldLoading({ [rowData.id]: true });
        const submitData = {
          personId: benhNhanInfo?.patient?.personId,
          termId: rowData.termId || rowData.id,
          orderId: rowData?.orderId,
          value: value.id || value,
        };
        await saveObsValue(submitData);
        await handleGetDsDichVu();
      }
    } catch (error) {
      console.error(error);
      toast.warning("Lỗi lưu kết quả, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectedRowIndex && !event?.target?.closest(".textfield-container")) {
        setSelectedRowIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedRowIndex]);

  const generateInput = (rowData: any) => {
    let dataType = rowData?.term?.datatypeId || rowData?.datatypeId;
    let isTestStart =
      phieuXetNghiemInfo.thongTinPhieu.state.currStatusCode === "test_start";
    switch (dataType) {
      case DATA_TYPE_XN.TEXT:
        return (
          <div className="textfield-container d-flex align-items-center w-100">
            <TextField
              className="w-100"
              inputClassName="w-100"
              type="text"
              name="obsValue"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                if (
                  +e?.target?.value !== +rowData?.obs?.value &&
                  (e?.target?.value || rowData?.obs?.value)
                ) {
                  handleSaveObs(rowData, e?.target?.value);
                  setSelectedRowIndex(null);
                }
              }}
              autoFocus={true}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.DATETIME:
      case DATA_TYPE_XN.DATE:
        return (
          <div className="d-flex align-items-center">
            <input
              type={
                dataType === DATA_TYPE_XN.DATETIME ? "datetime-local" : "date"
              }
              className="w-100"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e) => {
                handleSaveObs(rowData, e.target.value);
              }}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.BOOLEAN:
        return (
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={rowData?.obs?.value === "true"}
              disabled={!isTestStart}
              onChange={(e) => {
                handleSaveObs(rowData, e.target.checked);
              }}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.NUMERIC:
        return (
          <div className="textfield-container d-flex align-items-center w-100">
            <TextField
              className="w-100"
              inputClassName="w-100"
              type="number"
              name="obsValue"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                if (
                  +e?.target?.value !== +rowData?.obs?.value &&
                  (e?.target?.value || rowData?.obs?.value)
                ) {
                  handleSaveObs(rowData, e?.target?.value);
                  setSelectedRowIndex(null);
                }
              }}
              autoFocus={true}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.CODE:
      case DATA_TYPE_XN.PARENT_CODE:
        return (
          <div className="textfield-container d-flex align-items-center">
            <AutocompleteV2
              options={rowData?.answers || []}
              value={rowData?.answers.find(
                (item: any) => item.name === rowData?.obs?.value
              )}
              name="obsValue"
              valueField="name"
              onChange={(selectedOption) => {
                handleSaveObs(rowData, selectedOption);
                setSelectedRowIndex(null);
              }}
              isDisabled={!isTestStart}
              className="autocomplete-custom-tiep-nhan radius spaces width-100 h-25"
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelectedRowIndex(null);
  }, [benhNhanInfo?.testGroupId]);

  const columnThongTinXetNghiem = [
    {
      title: "Mã DV",
      field: "code",
      className: "spaces width-11 text-center",
      render: (rowData: any) => {
        return (
          <div
            className={`${
              (rowData?.obs?.value > rowData?.numeric?.hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < rowData?.numeric?.lowNormal
                ? "lowNormal"
                : "")
            }`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.code}
          </div>
        );
      },
    },
    {
      title: "Tên xét nghiệm",
      field: "name",
      className: "spaces width-30",
      render: (rowData: any) => {
        return (
          <div
            className={`${
              (rowData?.obs?.value > rowData?.numeric?.hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < rowData?.numeric?.lowNormal
                ? "lowNormal"
                : "")
            }`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.name}
          </div>
        );
      },
    },
    {
      title: "Kết Quả",
      field: "obs.value",
      className: "spaces width-13 text-center",
      render: (rowData: any, listIndex: any[], colIndex: number) => {
        return (
          <Formik initialValues={{}} onSubmit={() => {}}>
            {selectedRowIndex === rowData?.id && benhNhanInfo?.isPerform && rowData?.statusCode === STATUS_CANCEL.EXCUTING ? (
              generateInput(rowData)
            ) : (
              <div
                className={`h-31 d-flex align-items-center justify-content-center ${
                  (rowData?.obs?.value > rowData?.numeric?.hiNormal
                    ? "hiNormal"
                    : "") +
                  (rowData?.obs?.value < rowData?.numeric?.lowNormal
                    ? "lowNormal"
                    : "")
                }`}
                style={{ color: rowData?.statusColor }}
                onClick={() => setSelectedRowIndex(rowData?.id)}
              >
                {rowData?.obs?.value}
              </div>
            )}
          </Formik>
        );
      },
    },
    {
      title: "KQ cũ",
      field: "obs.value",
      className: "spaces width-11 text-center",
      render: (rowData: any, listIndex: any[], colIndex: number) => {
        let res: any = props?.listPreviousResult?.find((item: any) => item?.id === rowData?.id);
        return (
          <div
            className={`${(rowData?.obs?.value > rowData?.numeric?.hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < rowData?.numeric?.lowNormal
                ? "lowNormal"
                : "")
              }`}
            style={{ color: rowData?.statusColor }}
          >
            {res?.value || ""}
          </div>
        );
      },
    },
    {
      title: "",
      field: "HL",
      className: "spaces width-4 text-center",
      render: (rowData: any) => {
        return (
          <div
            className={`${
              (rowData?.obs?.value > rowData?.numeric?.hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < rowData?.numeric?.lowNormal
                ? "lowNormal"
                : "")
            }`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.obs?.value > rowData?.numeric?.hiNormal ? "H" : ""}
            {rowData?.obs?.value < rowData?.numeric?.lowNormal ? "L" : ""}
          </div>
        );
      },
    },
    {
      title: "Đơn vị",
      field: "donVi",
      className: "spaces width-6 text-center",
      render: (rowData: any) => {
        return (
          <div
            className={`${
              (rowData?.obs?.value > rowData?.numeric?.hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < rowData?.numeric?.lowNormal
                ? "lowNormal"
                : "")
            }`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.numeric?.units}
          </div>
        );
      },
    },
    {
      title: "GTBT",
      field: "gtbt",
      className: "spaces width-6 text-center",
      render: (rowData: any) => {
        let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
        const validValue = (value: any) => {
          return value || value === 0;
        };
        let result = "";
        let lowNormal = rowData?.numeric?.lowNormal;
        let hiNormal = rowData?.numeric?.hiNormal;
        if (isText) {
          result = rowData?.numeric?.label || "";
        } else if (validValue(lowNormal) && validValue(hiNormal)) {
          result = (lowNormal === hiNormal) ? lowNormal : lowNormal + " - " + hiNormal;
        } else if (validValue(lowNormal) && !validValue(hiNormal)) {
          result = "> " + lowNormal;
        } else if (!validValue(lowNormal) && validValue(hiNormal)) {
          result = "< " + hiNormal;
        };
        return (
          <div
            className={`${
              (rowData?.obs?.value > hiNormal
                ? "hiNormal"
                : "") +
              (rowData?.obs?.value < lowNormal
                ? "lowNormal"
                : "")
            }`}
            style={{ color: rowData?.statusColor }}
          >
            {result || ""}
          </div>
        );
      },
    },
    { title: "Loại bệnh phẩm", field: "type", className: "spaces width-10" },
    { title: "Ghi chú", field: "description", className: "spaces width-10" },
  ];

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setRowSelect(row);
    if (row?.additionalConfiguration) {
      setConfigurationContextMenu(row?.additionalConfiguration);
      setContextMenu({ x: e.clientX, y: e.clientY });
    } else {
      setConfigurationContextMenu(null);
    };
  };

  return (
    <>
      <TableCollapseCustom
        columns={columnThongTinXetNghiem}
        data={dataKQ || []}
        className="overflow-auto spaces"
        classNameTable="spaces W-1000"
        height="calc(100vh - 315px)"
        childrenField="subs"
        handleContextMenu={handleContextMenu}
        isFromTabXN
      />
      {openChuyenDV && (
        <DialogChuyenDV
          handleClose={() => setOpenChuyenDV(false)}
          handleChuyenPhongDV={handleChuyenPhongDV}
        />
      )}

      {contextMenu && (
        <WfContextMenu
          tracker={WfConstTracker.ORDER_CIS}
          configuration={configurationContextMenu}
          wfWorkflowsReq={{
            objectId: rowSelect?.orderId,
            status: rowSelect?.oldStatusId,
          }}
          title="Phiếu thực hiện"
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          handleClick={actionClick}
        />
      )}
    </>
  );
};

export default BangLayMauBenhPham;
