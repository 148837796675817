import { Button, Col, FormCheck, Modal, Row } from 'react-bootstrap'
import LabelRequired from '../../../component/LabelRequired'
import Autocomplete from '../../../component/AutocompleteObject';
import { Form, Formik } from 'formik';
import TextField from '../../../component/TextField';
import DatePickerCustom from '../../../component/date-picker/DatePickerCustom';

type Props = {
  show: boolean;
  handleCloseDialog: () => void;
}

const ModalCapNhatThongTinTuVong = ({ show, handleCloseDialog }: Props) => {
  return (
    <Modal centered show={show} onHide={handleCloseDialog} size="xl">
      <Formik<any>
        initialValues={{
          cancellationType: null,
          cancellationReason: '',
        }}
        onSubmit={(values: any) => { }}
      >
        {({
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          values,
          setValues,
          handleChange
        }) => (
          <Form id="form-thuoc">
            <Modal.Header closeButton>
              <Modal.Title>
                Cập nhật thông tin tử vong
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row className="mb-1">
                <Col xl={5} className='d-flex'>
                  <LabelRequired className='spaces min-w-175' label="Thời gian ra viện"/>
                  <DatePickerCustom
                      mode='date-time'
                      name="raVien"
                      value={values?.raVien}
                      setDateValue={date => {
                          setFieldValue('raVien', date);
                      }}
                      errors={errors?.raVien}
                      touched={touched?.raVien}
                  />
                </Col>

                <Col xl={4} className='d-flex'>
                  <LabelRequired className='spaces min-w-140' label="Thời điểm tử vong"/>
                  <DatePickerCustom
                      mode='date-time'
                      name="tuVong"
                      value={values?.tuVong}
                      setDateValue={date => {
                          setFieldValue('tuVong', date);
                      }}
                      errors={errors?.tuVong}
                      touched={touched?.tuVong}
                  />
                </Col>

                <Col xl={3}>
                  <FormCheck type="checkbox" label="Cấp giấy chứng tử" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xl={5} className='d-flex'>
                  <LabelRequired className='spaces min-w-175' label="Thời gian tử vong"/>
                  <DatePickerCustom
                      mode='date-time'
                      name="tuVong"
                      value={values?.tuVong}
                      setDateValue={date => {
                          setFieldValue('tuVong', date);
                      }}
                      errors={errors?.tuVong}
                      touched={touched?.tuVong}
                  />
                </Col>

                <Col xl={7} className='d-flex'>
                  <LabelRequired className='spaces W-140' label="Nguyên nhân" />
                  <Autocomplete options={[]} className='flex-grow-1'/>
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xs={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Nguyên nhân chính" />
                  <Row className="align-items-center flex-grow-1">
                    <Col xs="3" className="pe-0 position-relative">
                      <TextField
                        name="provisionalDiagnosis"
                        value={""}
                      />
                      <div className="spaces width-4">
                        <h6
                          className="position-absolute muc-huong cursor-pointer"
                          onClick={() => { }}
                        >
                          <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                        </h6>
                      </div>
                    </Col>
                    <Col xs="9" className="ps-0">
                      <TextField
                        name="provisionalDiagnosis"
                        value={""}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xl={5} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="" />
                  <FormCheck type="checkbox" label="Khám nghiệm tử thi" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xl={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Chẩn đoán giải phẫu tử thi" />
                  <TextField name="name" className="flex-grow-1"/>
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xl={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Quá trình bệnh lý và diễn biến lâm sàng"/>
                  <TextField name="name" as="textarea" rows={3} 
                    className="resize-none flex-grow-1"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xs={12} className='d-flex flex-end'>
                  <Button
                    className="bg-white spaces px-2"
                  >
                    <u className="fw-bold color-17A2B8 fs-13px">Chọn kết quả</u>
                  </Button>
                </Col>
                <Col xs={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Tóm tắt kết quả CLS có giá trị chẩn đoán" />
                  <TextField name="name" as="textarea" rows={3} 
                    className="resize-none flex-grow-1"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xs={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Phương pháp điều trị" />
                  <TextField name="name" as="textarea" rows={3} 
                    className="resize-none flex-grow-1"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col xs={12} className='d-flex'>
                  <LabelRequired className='spaces W-175' label="Lời dặn của bác sĩ" />
                  <TextField name="name" as="textarea" rows={3} 
                    className="resize-none flex-grow-1"
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button className='btn-fill'>Cập nhật từ bệnh án</Button>
              <Button className='btn-fill'>Lưu</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalCapNhatThongTinTuVong