import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { formatDateToDDMMYYYY, formatDateAdvanceToString, formatMoney } from "../../utils/FormatUtils";
// import { ColumnConfig } from "../../component/table/table-generate/ColumnConfig";
// import { generateColumns } from "../../component/table/table-generate/GenerateColumns";


export const columnsNGT: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã người giới thiệu"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "introducerCode",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.introducerCode || ""}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên người giới thiệu"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "introducerName",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.introducerName || ""}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="SĐT người giới thiệu"
                className=" text-center text-white align-middle bg-pri min-w-300px"
            />
        ),
        id: "introducerPhoneNumber",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.introducerPhoneNumber || ""}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mức hưởng"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "conmissionRate",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.conmissionRate || ""}
            />
        ),
    },
];

export const columnsNSX: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã nước sản xuất"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên nước sản xuất"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Ghi chú"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "notes",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className=" s-2"
                data={props.data[props.row.index]?.notes}
            />
        ),
    },
];

export const columnsHSX: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="STT"
                className="text-center text-light max-w-35"
            />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center "
                data={String(props?.row?.index + 1)}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Mã hãng SX"
                className="text-white text-center align-middle min-w-100px"
            />
        ),
        id: "code",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={props.data[props.row.index]?.code}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Tên hãng SX"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "name",
        Cell: ({ ...props }) => (
            <TableCustomCell
                data={props.data[props.row.index]?.name}
                className="text-left"
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Địa chỉ"
                className=" text-center text-white align-middle bg-pri min-w-300px"
            />
        ),
        id: "address",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.address}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Điện thoại"
                className="text-center text-white align-middle bg-pri min-w-100px"
            />
        ),
        id: "SDT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="s-2"
                data={props.data[props.row.index]?.SDT}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title="Ghi chú"
                className=" text-center text-white align-middle bg-pri min-w-200px"
            />
        ),
        id: "notes",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className=" s-2"
                data={props.data[props.row.index]?.notes}
            />
        ),
    },
];
