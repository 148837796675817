import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';

type ContextProps = {
	selectedRow: any;
	setSelectedRow: Dispatch<SetStateAction<any>>;
	editRowIndex: any;
	setEditRowIndex: Dispatch<SetStateAction<any>>;
};

type ContextProviderProps = {
	children: ReactNode;
};

const TableSelectVatTuContext = createContext<ContextProps>({
	selectedRow: null,
	setSelectedRow: () => {},
	editRowIndex: null,
	setEditRowIndex: () => { },
});

export const TableSelectVatTuContextProvider = ({ children }: ContextProviderProps) => {
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [editRowIndex, setEditRowIndex] = useState<any>(null);

	return (
		<TableSelectVatTuContext.Provider
			value={{
				selectedRow,
				setSelectedRow,
				editRowIndex,
				setEditRowIndex,
			}}
		>
			{children}
		</TableSelectVatTuContext.Provider>
	);
};

export const useTableSelectVatTuContext = () => useContext(TableSelectVatTuContext);
