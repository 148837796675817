



import { FC, useState } from "react"
import { Button, Col, FormCheck, Modal, Row } from "react-bootstrap"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import LabelRequired from "../../../component/LabelRequired"
import { useIntl } from "react-intl";
import { toast } from "react-toastify"
import TextField from "../../../component/TextField"
import { changePassword } from "../../services/TabTaiKhoanServices"
import { CODE_SUCCESS } from "../../../utils/Constant"

type Props = {
  open: boolean,
  handleCloseDialog: () => void
}

const ChangePassWordDialog = (props: Props) => {
  const { handleCloseDialog, open } = props;
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    isShowPassword: false,
  })

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
    password: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).notOneOf([Yup.ref('oldPassword'), null], 'Mật khẩu mới không được trùng mật khẩu cũ!').nullable(),
    confirmPassword: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).oneOf([Yup.ref('password'), null], 'Mật khẩu không khớp!').nullable(),
  })

  const handleSubmitForm = async (values: any) => {
    try {
      const { data } = await changePassword(values);
      console.log("res");
      if (data.code === CODE_SUCCESS) {
        toast.success("Đổi mật khẩu thành công!");
        handleCloseDialog();
        // window.location.href = `${localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["ssoUrl"]}`;
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Modal centered show={open}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmitForm(values)}
      >
        {({
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          values,
          setValues,
        }) => (
          <ChangePassWordForm
            handleSubmit={handleSubmit}
            handleClose={handleCloseDialog}
            touched={touched}
            errors={errors}
            values={values}
            setValues={setValues}
          />
        )}
      </Formik>
    </Modal>
  )
}

const ChangePassWordForm: FC<any> = (props) => {
  const { handleSubmit, handleClose, touched, errors, values, setValues } = props;
  const [type, setType] = useState<string>('password');

  const handTextChange = (
    name: string,
    value: string
  ) => {
    // setFieldValue(name, value);
    setValues({
      ...values,
      [name]: value,
    });
  }

  const handleShowPassword = (e: any) => {
    if (e.target.checked) {
      setType("text");
    } else {
      setType("password");
    }

    setValues({
      ...values,
      isShowPassword: e.target.checked,
    })
  }

  return (
    <Form>
      <Modal.Header className='p-4 header-modal modal-header'>
        <Modal.Title className='text-cyan text-uppercase'>Đổi mật khẩu</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4">
        <Row className="mb-3">
          <Col xs={12}>
            <LabelRequired
              label="Mật khẩu cũ"
            />
            <TextField
              label=""
              className="input"
              name="oldPassword"
              type={type}
              value={values?.oldPassword || ''}
              onChange={(e: any) => {
                handTextChange(e.target.name, e.target.value);
              }}
              errors={errors?.oldPassword}
              touched={touched?.oldPassword}

            />
          </Col>
          <Col xs={12} className="spaces pt-7">
            <LabelRequired
              label="Mật khẩu mới"
            />
            <TextField
              label=""
              className="input"
              name="password"
              type={type}
              value={values?.password || ''}
              onChange={(e: any) => {
                handTextChange(e.target.name, e.target.value);
              }}
              errors={errors?.password}
              touched={touched?.password}
            />
          </Col>
          <Col xs={12} className="spaces pt-7">
            <LabelRequired
              label="Nhập lại mật khẩu mới"
            />
            <TextField
              label=""
              className="input"
              name="confirmPassword"
              type={type}
              value={values?.confirmPassword || ''}
              onChange={(e: any) => {
                handTextChange(e.target.name, e.target.value);
              }}
              errors={errors?.confirmPassword}
              touched={touched?.confirmPassword}
            />
          </Col>
          <Col xs={12} className="spaces pt-7">
            <FormCheck
              type="checkbox"
              label="Hiển thị mật khẩu"
              checked={values?.isShowPassword}
              onChange={(e: any) => handleShowPassword(e)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='pt-2 pb-2 justify-content-center'>
        <Button
          className='btn-fill min-w-60px'
          onClick={(e: any) => {
            handleSubmit();
          }}
        >
          Lưu
        </Button>
        <Button
          className='btn-outline min-w-60px'
          onClick={handleClose}
        >
          Hủy
        </Button>
      </Modal.Footer>
    </Form>
  )
}

export default ChangePassWordDialog;
