import Fuse from 'fuse.js';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CODE, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { columnsDanhSachDonThuoc } from '../../../../columns/tab-thuoc/ColumnTabThuoc';
import { WORKFLOW_MEDICAL_ITEM_STATUS_CODE } from '../../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { getDetailDonThuoc } from '../../../../services/ThuocService';
import { useTabThuocContext } from '../../context';
import DanhSachPhieuThuoc from './DanhSachPhieu';
import SearchField from './SearchField';
import TableDonThuoc from './TableDonThuoc';

const DonThuoc = () => {
	const {
		donThuocInfo,
		danhSachDonThuoc,
		setDonThuocInfo,
		donThuocSelectedIndex,
		setDonThuocSelectedIndex,
		actionsRef,
	} = useTabThuocContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const initialTableDataRef = useRef<any[]>([]);

	const [tableData, setTableData] = useState<any[]>([]);
	const [danhSachPhieu, setDanhSachPhieu] = useState<any[]>([]);

	useEffect(() => {
		if (!danhSachDonThuoc.length) {
			setTableData([]);
			setDanhSachPhieu([]);
			setDonThuocInfo({});
			return;
		}
		
		const listPhieu = danhSachDonThuoc
			.sort(
				(a: any, b: any) =>
					new Date(b.designatedDate).getTime() - new Date(a.designatedDate).getTime()
			)
			.map((row: any, index) => {
				return {
					...row,
					index,
					date: moment(new Date(row?.designatedDate)).format('DD/MM/YYYY'),
				};
			});
		setDanhSachPhieu(listPhieu);
		getDetail(listPhieu[0]?.id);
	}, [danhSachDonThuoc]);

	useEffect(() => {
	   setDonThuocSelectedIndex(0);
	}, [benhNhanInfo?.thongTinKhamBenh?.orderId]);

	const getDetail = async (donThuocId: string) => {
		try {
			const { code, data } = await getDetailDonThuoc(
				donThuocId,
				benhNhanInfo?.thongTinKhamBenh?.orderId
			);
			if (CODE.SUCCESS === code && data) {
				const { lstMedicalItemOrderRes = [] } = data;
				const isDaXuatKho =
					WORKFLOW_MEDICAL_ITEM_STATUS_CODE.DA_XUAT_KHO === data?.statusCode;
				setDonThuocInfo(data);
				const listThuoc = lstMedicalItemOrderRes.map((row: any) => ({
					...row,
					thuocLinh: isDaXuatKho ? row.totalQuantity : '',
				}));
				setTableData(listThuoc);
				initialTableDataRef.current = listThuoc;
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};
	actionsRef.current = {
		...actionsRef.current,
		getDetailDonThuoc: getDetail,
	};

	const onPhieuSelect = (data: any) => {
		if (donThuocInfo.id === data?.id) {
			return;
		}
		getDetail(data?.id);
		setDonThuocSelectedIndex(data.index);
	};

	const onSearch = (keySearch: string) => {
		if (!initialTableDataRef.current) {
			return;
		}

		if (!keySearch) {
			setTableData(initialTableDataRef.current);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['name', 'medicalItemCode'],
			threshold: 0.5,
			includeScore: true,
			includeMatches: true,
		});
		const result = fuse.search(keySearch).map(record => record.item);
		setTableData(result);
	};

	return (
		<div className="bg-white mb-2">
			<SearchField onSearch={onSearch} />
			<DanhSachPhieuThuoc
				data={danhSachPhieu}
				onSelect={onPhieuSelect}
				selectedIndex={donThuocSelectedIndex}
			/>
			<TableDonThuoc data={tableData} columns={columnsDanhSachDonThuoc} />
		</div>
	);
};

export default DonThuoc;
