import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../utils/Constant';
import '../../PhanHeKhamBenh.scss';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import TableDonThuoc from './components/table-don-thuoc';
import ThaoTacDonThuoc from './components/thao-tac-don-thuoc';
import ThongTinPhieu from './components/thong-tin-phieu';

import { TabThuocContextProvider } from './context';
import moment from 'moment';
import { getDrugGroupListByVisitId } from '../../services/ThuocService';

export type KhamBenh = {
	thongTinKhamBenh?: any;
	setThongTinKhamBenh: ((data: any) => void) | undefined;
};

type Props = {
	danhSachPhieu?: boolean;
};

export const TabThuoc = ({ danhSachPhieu }: Props) => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { orderId } = benhNhanInfo?.thongTinKhamBenh;

	const [danhSachDonThuoc, setDanhSachDonThuoc] = useState<object[]>([]);

	useEffect(() => {
		if (orderId) {
			getDanhSachDonThuoc();
		}
	}, [orderId, benhNhanInfo?.updateTab]);

	const getDanhSachDonThuoc = async () => {
		try {
			const { code, data } = await getDrugGroupListByVisitId({
				orderId,
				type: ORDER_TYPE_ID.THUOC,
			});

			if (CODE.SUCCESS === code) {
				setDanhSachDonThuoc(data);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<Row className="h-100">
			<div>
				<TabThuocContextProvider
					values={{
						danhSachDonThuoc,
					}}
				>
					<ThongTinPhieu />
					<TableDonThuoc />
					<ThaoTacDonThuoc getDanhSachDonThuoc={getDanhSachDonThuoc} />
				</TabThuocContextProvider>
			</div>
		</Row>
	);
};

export default TabThuoc;
