import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import TextField from '../../../../component/TextField';
import LabelRequired from '../../../../component/LabelRequired';

type Props = {
	value: string;
	name: string;
	isView?: boolean;
	onChange: (name: string, value: string) => void;
	onSuggestClick: () => void;
	onDeleteClick: () => void;
};

const GoiYField = ({ value, name, isView, onChange, onSuggestClick, onDeleteClick }: Props) => {
	const [innerValue, setInnerValue] = useState<string>(value);

	return (
		<Col xs={12}>
			<div className="d-flex gap-3 mt-2">
				<LabelRequired
					isRequired
					label="Kết luận"
					className="label fw-500 spaces fw-bold min-w-50"
				/>
				<TextField
					className="input spaces flex-10"
					name={name}
					labelClassName="max-w-90px"
					value={innerValue}
					disabled={isView}
					onChange={(e: any) => {
						setInnerValue(e.target.value);
					}}
					onBlur={() => {
						onChange(name, innerValue);
					}}
				/>

				{!isView && (
					<span className="spaces flex-2 d-flex gap-3">
						<Button className="btn-fill min-w-75px me-3" onClick={onSuggestClick}>
							Gợi ý
						</Button>
						<Button className="btn-danger min-w-75px" onClick={onDeleteClick}>
							Xoá
						</Button>
					</span>
				)}
			</div>
		</Col>
	);
};

export default GoiYField;
