
export const columnsThongTinPhongKham = [
  {
    name: "Phòng",
    field: "name",
    cellStyle: {
      paddingLeft: "10px",
      minWidth: "225px",
      textAlign: "start",
    },
  },
  {
    name: "Hẹn",
    field: "appointments",
    cellStyle: {
      textAlign: "start",
    },
  },
  {
    name: "ĐK",
    field: "admissions",
    cellStyle: {
      textAlign: "start",
    },
  },
  {
    name: "Khám",
    field: "examinations",
    cellStyle: {
      textAlign: "start",
    },
  },
  {
    name: "Tổng",
    field: "total",
    cellStyle: {
      textAlign: "start",
    },
  },
];