/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout } from '../modules/auth'
import { App } from '../App'
import { TMenu } from "../../app/pages/Homepage/constant";
import { KEY_LOCALSTORAGE } from "../../app/modules/auth/core/_consts";
import { localStorageItem } from "../../app/modules/utils/LocalStorage";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const menusByPermission = localStorageItem.get(KEY_LOCALSTORAGE.ID_TOKEN_DECODE)?.["menus"]?.["oceantech-emr-server"] || [];
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to={`${menusByPermission?.[0]?.uri}`} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
