export const ACTIONS = [
	{
		title: 'Phóng to',
		action: 'zoomIn',
		icon: 'bi bi-zoom-in',
	},
	{
		title: 'Thu nhỏ',
		action: 'zoomOut',
		icon: 'bi bi-zoom-out',
	},
	{
		title: 'Xoay ảnh sang trái',
		action: 'rotateLeft',
		icon: 'bi bi-arrow-counterclockwise',
	},
	{
		title: 'Xoay ảnh sang phải',
		action: 'rotateRight',
		icon: 'bi bi-arrow-clockwise',
	},
];

export const IMAGE_PLACEHOLDER_PATH = '/media/images/image-placeholder.png';
