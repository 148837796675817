import { Formik } from "formik";
import { FC, ReactNode, createContext } from "react";
import TableDSDanhMuc from "./component/TableDSDanhMuc";

export interface DanhMucContextProps { };

interface TheKhoProps {
    warehouseId?: number | undefined;
    customTitle?: ReactNode;
}

const initialContext = {};

export const DanhMucContext = createContext<DanhMucContextProps>(initialContext)

export const DanhMuc: FC<TheKhoProps> = (props) => {
    const { warehouseId, customTitle } = props;
    return (
        <Formik<any>
            initialValues={{}}
            onSubmit={() => { }}
        >
            <DanhMucContext.Provider
                value={{}}
            >
                <div className="bg-white">

                    <div className="flex-1 flex flex-column">
                        <div className="py-1 ">
                            <TableDSDanhMuc
                            />
                        </div>
                    </div>


                </div>

            </DanhMucContext.Provider>
        </Formik>
    )
}

export default DanhMuc;
