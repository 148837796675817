import { useEffect } from "react";
import LabelRequired from "../../../../component/LabelRequired";
import TextField from "../../../../component/TextField";
import "./KhamLamSang.scss";

type Props = {
  setValues?: any;
  values?: any;
  isView?: boolean;
  content?: any;
};

const FormKhamTaiMuiHong = ({ setValues, values, isView = false, content }: Props) => {

  useEffect(() => {
    setValues(content)
  }, [content])

  return (

    <div className="bg-white  modelKhamBenh spaces h-calc-vh-340 mb-6 fl-1">
      <div className="spaces mt-5 mx-10">
        <LabelRequired
          label="Kết quả khám thính lực:"
          className="min-w-200px text-title"
        />
      </div>
      <div className=" flex spaces w-100 ml-30">
        <i className="customer-icon-dot bi bi-circle-fill"></i>
        <LabelRequired
          label="Tai trái"
          className="min-w-100px text-title"
        />
        <TextField
          name="leftEarNormal"
          labelClassName="ps-2 spaces min-w-90 ms-8"
          label="Nói thường"
          disabled={isView}
          value={values?.leftEarNormal || ''}
        />
        <TextField
          name="leftEarWhisperTest"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Nói thầm"
          disabled={isView}
          value={values?.leftEarWhisperTest || ''}
        />
      </div>
      <div className=" flex spaces w-100 ml-30">
        <i className="customer-icon-dot bi bi-circle-fill"></i>
        <LabelRequired
          label="Tai phải"
          className="min-w-100px text-title"
        />
        <TextField
          name="rightEarNormal"
          labelClassName="ps-2 spaces min-w-90 ms-8"
          label="Nói thường"
          disabled={isView}
          value={values?.rightEarNormal || ''}
        />
        <TextField
          name="rightEarWhisperTest"
          labelClassName="ps-2 spaces min-w-80 ms-8"
          label="Nói thầm"
          disabled={isView}
          value={values?.rightEarWhisperTest || ""}
        />
      </div>
      <div className="spaces w-100 justify-content-center mt-10">
        <TextField
          name="ENTOtherConditions"
          className="text-field-label-down-line min-height-90px"
          labelClassName="ps-2"
          as="textarea"
          disabled={isView}
          value={values?.ENTOtherConditions || ""}
          label="Các bệnh khác về tai mũi họng(nếu có)"
        />
      </div>
      <div className="flex spaces w-100 pt-10">
        <TextField
          name="ENTClassification"
          className="text-field-label-down-line min-height-90px w-100"
          labelClassName="ps-2"
          as="textarea"
          label="Phân loại"
          disabled={isView}
          value={values?.ENTClassification || ""}
        />
        <TextField
          name="ENTConclusion"
          className="text-field-label-down-line min-height-90px w-100"
          labelClassName="ps-2"
          as="textarea"
          label="Kết luận"
          disabled={isView}
          value={values?.ENTConclusion || ""}
        />
      </div>

    </div>
  );
};

export default FormKhamTaiMuiHong;
