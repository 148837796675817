export const convertListDichVuXetNgiem = (data: any[]) => {
	return data.map((item: any) => {
		return {
			name: item.parentName,
			subs: item?.orders.map((term: any) => ({
				...term,
				...term?.term,
				termOrderId: term?.id,
				subs: term?.term?.subs?.map((sub: any) => ({
					...sub,
					orderId: term?.orderId,
					statusCode: term?.statusCode,
					statusColor: term?.statusColor,
				})),
			})),
		};
	});
};
