import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../../../appContext/AppContext";
import { columnThongTinGoiKham } from "../../../constants/PhanHeKhamDoan";
import { formatDateTime, formatDateToDDMMYYYY } from "../../../../utils/FormatUtils";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  LIST_COLOR_TRANG_THAI_PHIEU,
  RESPONSE_MESSAGE,
  CODE_SUCCESS,
  ERROR_MESSAGE,
  ORDER_TYPE_ID,
  APP_DATE_TIME_FORMAT,
} from "../../../../utils/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import { getListDichVuByOrder, getDsOrderGroup } from "../../../services/KhamDoanHopDongServices"
import { TableCollapseCustom } from "../../../../component/table/table-collapse/TableCollapseCustom";
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import DanhSachPhieu from "../../../../component/DanhSachPhieu";
import ButtonInPhieu from "../../../../component/button-in-phieu";
import { inPhieuChiDinhCLS, inPhieuPhauThuatXNCLS } from "../../../../phan-he-kham-benh/services/PhanHeTiepDonServer";

type TabXetNghiemProps = {
};
const XetNghiem: FC<TabXetNghiemProps> = React.memo(
  ({
  }) => {
    const { setIsLoading, currentTab } = useContext(AppContext);
    const [detailTTOrder, setDetailTTOrder] = useState<any>({});
    const { TTNhanVien } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
    const [dsGoiKham, setDsGoiKham] = useState<any>({
      data: [],
      totalElements: 1,
      totalPages: 1,
    });
    const [dsPhieu, setDsPhieu] = useState<any>([]);

    useEffect(() => {
      TTNhanVien?.id && handleGetListOrderGroup(TTNhanVien?.id)
    }, [TTNhanVien?.id]);
    
    const handleGetListOrderGroup = async (id: any) => {
      setDsGoiKham({
        data: [],
        totalElements: 1,
        totalPages: 1
      }
      )

      try {
        const response = await getDsOrderGroup({
          typeId: 2
        }, id);
        if (response?.data?.code === CODE_SUCCESS) {
          let dsPhieuConverted = response?.data?.data?.map((item: any) => {
            return {
              ...item,
              date: item?.indicationTime ? moment(item.indicationTime).format("DD/MM/YYYY") : "",
            };
          });
          setDsPhieu(dsPhieuConverted)
        } else {
          toast.error(ERROR_MESSAGE);
        }
      } catch (e) {
        toast.error(ERROR_MESSAGE);
      }
    }
    const handlerSelectOrder = (row: any) => {
      setDetailTTOrder(row);
      getDetailOrder(row);
    }
    const getDetailOrder = async (filter?: any) => {
      // if (TTHopDong?.id) {
      setIsLoading(true);
      let searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: 9999999,
        typeId: 2,
        orderGroupId: filter?.id
        // isIncludeTerms: true,
      };
      try {
        const { data } = await getListDichVuByOrder(searchObject, TTNhanVien?.id);
        if (CODE.SUCCESS === data?.code) {
          setDsGoiKham({
            data: data?.data || [],
            totalElements: data?.data?.totalElements,
            totalPages: data?.data?.totalPages,
          });

        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      } catch {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      } finally {
        setIsLoading(false);
      }
      // } else {
      //   setDsGoiKham({
      //     data: [],
      //     totalElements: 1,
      //     totalPages: 1,
      //   });
      // }
    };
    return (
      <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
        {/* <div className="spaces w-100"> */}
        <div className="spaces w-100 justify-content-center mt-5">
          <Row className="border-bottom mx-0">
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Mã phiếu:</span>
                <span className="fw-light text-truncate">
                  {detailTTOrder?.code || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Ngày y lệnh:</span>
                <span className="fw-light text-truncate">
                  {formatDateTime(detailTTOrder?.dateStarted, APP_DATE_TIME_FORMAT)}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Ngày chỉ định:</span>
                <span className="fw-light text-truncate">
                  {formatDateTime(detailTTOrder?.indicationTime, APP_DATE_TIME_FORMAT)}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Người chỉ định:</span>
                <span className="fw-light text-truncate">
                  {detailTTOrder?.indicationPerson || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Nơi chỉ định:</span>
                <span className="fw-light text-truncate">
                  {/* {TTHopDong?.contactPerson || ""} */}
                  Phòng khám sức khoẻ
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Chẩn đoán:</span>
                <span className="fw-light text-truncate">
                  {/* {detailTTOrder?.createdBy || ""} */}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="spaces  p-5">

          </Row>
          <div className="bg-white spaces spaces fl-1 ">
            {dsPhieu?.length > 0 &&
              <div className="spaces ml-7 ">
                <DanhSachPhieu
                  className="mb-1"
                  handleSelectRowData={handlerSelectOrder}
                  dsPhieu={dsPhieu || []}
                  // handleContextMenu={(e, row) =>
                  //  {}
                  // }
                  listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
                  isGetFirstData
                />
              </div>
            }
            <TableCollapseCustom
              columns={columnThongTinGoiKham}
              data={dsGoiKham?.data || []}
              childrenField="subs"
              className={`overflow-auto`}
              height="calc(100vh - 360px)"
              startColumn={0}
            />
          </div>
          <div className="d-flex justify-content-start p-2 gap-3 bg-white">
            <div className="d-flex">
              <ButtonInPhieu
                className="spaces mx-3"
                label="Phiếu chỉ định (F1)"
                fetchExport={inPhieuChiDinhCLS}
                params={{
                  visitId: TTNhanVien?.visit?.id,
                  patientId: TTNhanVien?.patient?.id,
                  orderGroupId: detailTTOrder?.id,
                  orderTypeId: ORDER_TYPE_ID.XET_NGHIEM
                }}
                // keyOpenPhieuIn={keyOpenPhieuIn}
                // setKeyOpenPhieuIn={setKeyOpenPhieuIn}
              />
              <ButtonInPhieu
                label="Phiếu kết quả (F2)"
                className="spaces mx-3"
                fetchExport={inPhieuPhauThuatXNCLS}
                params={{
                  orderGroupId: detailTTOrder?.id,
                  type: ORDER_TYPE_ID.XET_NGHIEM,
                }}
                // keyOpenPhieuIn={keyOpenPhieuIn}
                // setKeyOpenPhieuIn={setKeyOpenPhieuIn}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div >
    );
  }
);
export default XetNghiem;
