import { useEffect, useState } from "react";
import { Button, FormCheck, InputGroup, FormControl } from "react-bootstrap";
import { TYPE_CHECKS } from "../../phan-he-vien-phi/const/VienPhiConstant";
import Autocomplete from "../AutocompleteObjectV2";
import MenuCustom from "../menu-custom/MenuCustom";
import TextValidator from "../TextValidator";
import "./filterSidebar.scss";
import { Form, Formik } from "formik";
import ModalSearchAdvanced from "../modal-search-advanced/ModalSearchAdvanced";
import LabelRequired from "../LabelRequired";
import DatePickerCustom from "../../component/date-picker/DatePickerCustom";

export const TYPE_SEARCH = {
  TODAY: "TODAY",
  CURR_WEEK: "CURR_WEEK",
  CURR_MONTH: "CURR_MONTH",
  YESTERDAT: "YESTERDAT",
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAY",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAY",
  NEXT_THREE_DAYS: "NEXT_THREE_DAYS",
  NEXT_SEVEN_DAYS: "NEXT_SEVEN_DAYS",
  SEARCH_ADVANCED: "SEARCH_ADVANCED",
};

const LIST_ORDER = [
  {
    id: 1,
    value: "ASCENDING",
    name: "Tăng dần",
  },
  {
    id: 2,
    value: "DERCEASE",
    name: "Giảm dần",
  },
];

export const LIST_PERIOD = [
  { type: TYPE_SEARCH.TODAY, name: "Trong Ngày" },
  { type: TYPE_SEARCH.CURR_WEEK, name: "Trong Tuần" },
  { type: TYPE_SEARCH.CURR_MONTH, name: "Trong Tháng" },
  { type: null, name: "" },
  { type: TYPE_SEARCH.YESTERDAT, name: "Hôm Qua" },
  { type: TYPE_SEARCH.LAST_SEVEN_DAYS, name: "7 Ngày Gần đây" },
  { type: TYPE_SEARCH.LAST_THIRTY_DAYS, name: "30 Ngày Gần đây" },
  // { type: null, name: "" },
  // { type: TYPE_SEARCH.NEXT_THREE_DAYS, name: "3 Ngày Tới" },
  // { type: TYPE_SEARCH.NEXT_SEVEN_DAYS, name: "7 Ngày Tới" }
  // { type: null, name: "" },
  // { type: null, name: "Tìm Kiếm Theo Mã Bệnh Nhân" },
  { type: null, name: "" },
  { type: TYPE_SEARCH.SEARCH_ADVANCED, name: "(Tìm Kiếm Nâng Cao)" },
];

type Props = {
  title: string;
  formalList?: any[]; // list hình thức
  formCheckList?: any[]; // list formcheck
  listPhieuIn?: any[];
  onSearch: any;
  typeCheckBHYT?: any;
  typeCheckVP?: any;
  setTypeCheckBHYT?: any;
  setTypeCheckVP?: any;
  isFilterByType?: boolean;
  listMenuItem?: any[];
  handleSelectOption?: any;
  formalDefault?: any;
  isShowMenuItem?: boolean;
  isShowSort?: boolean;
  isFilterByRadio?: boolean;
  isShowInPhieuBtn?: boolean;
  disableInPhieuBtn?: boolean;
  handleGoiBenhNhan?: () => void;
  extraAction?: React.ReactNode;
  additionalTextSearch?: {
    name: string;
    placeholder: string;
  };
};

const FilterSidebar = (props: Props) => {
  const {
    title,
    formalList = LIST_ORDER,
    formCheckList = [],
    onSearch,
    isFilterByType,
    listMenuItem,
    handleSelectOption,
    formalDefault,
    isShowMenuItem = true,
    isShowSort = true,
    isFilterByRadio = false,
    isShowInPhieuBtn = false,
    disableInPhieuBtn = false,
    listPhieuIn = [],
    handleGoiBenhNhan,
    extraAction,
    additionalTextSearch,
  } = props;
  const [keyword, setKeyword] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [formal, setFormal] = useState<any>(formalDefault || null);
  const [order, setOrder] = useState<any>(LIST_ORDER[1]);
  const [period, setPeriod] = useState<any>(null);
  const [typeChecks, setTypeChecks] = useState<any[]>([]);
  const [typeRadio, setTypeRadio] = useState<any>({
    filterStartDate: true,
    filterEndDate: false,
  });
  const [shouldOpenModalSearchAdvanced, setShouldOpenModalSearchAdvanced] =
    useState<boolean>(false);
  const [additionalFieldSearch, setAdditionalFieldSearch] = useState<string>('');

  useEffect(() => {
    onSearch({
		keyword,
		formal,
		order,
		period,
		typeChecks,
		toDate,
		fromDate,
		typeRadio,
		additionalFieldSearch,
	});
  }, [order, formal, period, typeChecks, toDate, fromDate]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch({
        keyword,
        formal,
        order,
        period,
        typeChecks,
        typeRadio,
        toDate, 
        fromDate,
        additionalFieldSearch
      });
    }
  };

  const getPeriod = (type: string) => {
    let fromDate = new Date();
    let toDate = new Date();

    switch (type) {
      case TYPE_SEARCH.TODAY:
        break;
      case TYPE_SEARCH.CURR_WEEK:
        const dayOfWeek = fromDate.getDay();
        fromDate.setDate(
          fromDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
        );
        toDate.setDate(
          toDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek)
        );
        break;
      case TYPE_SEARCH.CURR_MONTH:
        fromDate.setDate(1);
        toDate.setMonth(toDate.getMonth() + 1);
        toDate.setDate(0);
        break;
      case TYPE_SEARCH.YESTERDAT:
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate() - 1);
        break;
      case TYPE_SEARCH.LAST_SEVEN_DAYS:
        fromDate.setDate(fromDate.getDate() - 6);
        break;
      case TYPE_SEARCH.LAST_THIRTY_DAYS:
        fromDate.setDate(fromDate.getDate() - 29);
        break;
      case TYPE_SEARCH.NEXT_THREE_DAYS:
        fromDate.setDate(fromDate.getDate() + 1);
        toDate.setDate(toDate.getDate() + 3);
        break;
      case TYPE_SEARCH.NEXT_SEVEN_DAYS:
        fromDate.setDate(fromDate.getDate() + 1);
        toDate.setDate(toDate.getDate() + 7);
        break;
      case TYPE_SEARCH.SEARCH_ADVANCED:
        setShouldOpenModalSearchAdvanced(true);
        break;
      default:
        break;
    }
    return {
      fromDate,
      toDate,
    };
  };

  const handleTypeSearch = (type: string) => {
    let newPeriod = getPeriod(type);
    setPeriod(newPeriod);
  };

  useEffect(() => {
    if (keyword?.trim() === "" && additionalFieldSearch?.trim() === "") {
      onSearch({
        keyword,
        formal,
        order,
        period,
        typeChecks,
        typeRadio,
        additionalFieldSearch
      });
    }
  }, [keyword, additionalFieldSearch]);

  const handleTypeCheckChange = (type: string, checked: boolean) => {
    if (checked) {
      setTypeChecks((prev) => [...prev, type]);
    } else {
      setTypeChecks((prev) => prev.filter((t) => t !== type));
    }
  };

  const handleTypeRadioChange = (item: any, e: any) => {
    if (item?.name === "filterStartDate") {
      setTypeRadio({
        filterStartDate: true,
        filterEndDate: false,
      });
    } else {
      setTypeRadio({
        filterStartDate: false,
        filterEndDate: true,
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between spaces px-12">
        <h4 className="text-title fw-bold fs-4 spaces mb-0">{title}</h4>
        <div className="d-flex align-items-center ">
          {handleGoiBenhNhan && (
            <Button className="btn-outline spaces min-w-80 h-25 mr-10 mb-2"
              onClick={() => handleGoiBenhNhan()}
            >
              Gọi BN
            </Button>
          )}
          {isShowInPhieuBtn && (
            <Button
              className="btn-outline position-relative spaces min-w-80 h-25 mb-2 z-index-1055"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              disabled={disableInPhieuBtn}
            >
              <i className="bi bi-printer" /> In
              <ul className="dropdown-menu filter-dropdown">
                {listPhieuIn.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className="dropdown-item"
                      onClick={() => {}}
                    >
                      {item.button}
                    </li>
                  )
                })}
              </ul>
            </Button>
          )}
          {extraAction}
          {isShowMenuItem && (
            <MenuCustom
              handleSelectOption={handleSelectOption}
              listMenuItem={listMenuItem}
              menuLabel={
                <div className="cursor-pointer">
                  <i className="bi bi-list text-title fs-1 d-block spaces h-25 ml-10"></i>
                </div>
              }
            />
          )}
        </div>
      </div>
      {isShowSort && (
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="d-flex spaces px-12 gap-5 pb-10 align-items-center">
              <h4 className="fw-bold fs-4 spaces W-80 mb-0">Sắp xếp</h4>
              <div className="d-flex align-items-center flex-grow-1">
                <Autocomplete
                  options={formalList}
                  value={formal}
                  name="formal"
                  onChange={(selectedOption) => {
                    setFormal(selectedOption);
                  }}
                  className="radius spaces w-100 h-29 pe-2"
                  placeholder="Chọn hình thức"
                />
                <Autocomplete
                  options={LIST_ORDER}
                  value={order}
                  name="formal"
                  onChange={(selectedOption) => {
                    setOrder(selectedOption);
                  }}
                  className="radius spaces w-100 h-29"
                />
              </div>
            </div>
          </Form>
        </Formik>
      )}
      {isFilterByType && (
        <div className="d-flex spaces px-12 gap-10">
          {TYPE_CHECKS.map((check, index) => (
            <FormCheck
              key={check.type}
              type="checkbox"
              label={check.label}
              className="d-flex align-items-center spaces gap-7"
              checked={typeChecks.includes(check.type)}
              onChange={(e) =>
                handleTypeCheckChange(check.type, e.target.checked)
              }
            />
          ))}
        </div>
      )}
      {isFilterByRadio && (
        <>
          <div className="d-flex spaces px-12 gap-10">
            {formCheckList.map((item) => (
              <FormCheck
                key={item.type}
                type="radio"
                label={item.label}
                className="d-flex align-items-center spaces gap-7"
                checked={typeRadio[item?.name]}
                onChange={(e) => handleTypeRadioChange(item, e)}
              />
            ))}
          </div>
          <div className="d-flex align-items-center spaces px-12">
            <LabelRequired className="min-w-65px fw-bold" label="Từ ngày" />
            <DatePickerCustom
              name='toDate'
              value={toDate || ""}
              dateFormatOutput="YYYY-MM-DD"
              setDateValue={(date: string | null) => setToDate(date)}
            />
          </div>
          <div className="d-flex align-items-center spaces px-12">
            <LabelRequired className="min-w-65px fw-bold" label="Đến ngày" />
            <DatePickerCustom
              name='fromDate'
              value={fromDate || ""}
              dateFormatOutput="YYYY-MM-DD"
              setDateValue={(date: string | null) => setFromDate(date)}
            />
          </div>
        </>
      )}
      <div className="d-flex spaces px-12 gap-10">
        <div className="flex-auto">
          <InputGroup className="spaces h-29 px-0 flex-nowrap">
            {additionalTextSearch && 
              <TextValidator
                className="spaces h-29 flex-0"
                classGroup='spaces w-80px'
                value={additionalFieldSearch}
                onKeyDown={handleKeyPress}
                onChange={(e: any) => {
                  setAdditionalFieldSearch(e?.target?.value);
                }}
                {...additionalTextSearch}
              />
            }
            <TextValidator
              className="spaces h-29"
              name="keyword"
              placeholder="Tìm kiếm"
              value={keyword}
              onChange={(e: any) => {
                setKeyword(e?.target?.value);
              }}
              onKeyDown={handleKeyPress}
            />
            <Button
              className="btn-fill spaces min-w-90 h-29 rounded-end-1 z-index-99"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Tìm kiếm</span>
              {/* <i className="bi bi-caret-down-fill ms-2 fs-7 " /> */}
              <ul className="dropdown-menu filter-dropdown">
                {LIST_PERIOD.map(
                  (
                    item: {
                      type: string | null;
                      name: string;
                    },
                    index: number
                  ) => {
                    return !!item.name.trim() ? (
                      <li
                        key={index}
                        className="dropdown-item"
                        onClick={() => {
                          if (item.type) {
                            let newPeriod = getPeriod(item.type);
                            item.type !== TYPE_SEARCH.SEARCH_ADVANCED &&
                              setPeriod(newPeriod);
                          }
                        }}
                      >
                        {item.name}
                      </li>
                    ) : (
                      <li key={index} className="dropdown-divider"></li>
                    );
                  }
                )}
              </ul>
            </Button>
          </InputGroup>
        </div>
      </div>
      {shouldOpenModalSearchAdvanced && (
        <ModalSearchAdvanced
          getPeriod={getPeriod}
          setPeriod={setPeriod}
          handleCloseModal={() => setShouldOpenModalSearchAdvanced(false)}
        />
      )}
    </>
  );
};
export default FilterSidebar;
