import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import TextField from "../../../component/TextField";
import LabelRequired from "../../../component/LabelRequired";
import { Formik } from "formik";
import { IApprovalVienPhi } from "../../models/VienPhiInfoModels";
import * as Yup from "yup";
import { formatDateDTO } from "../../../utils/FormatUtils";
import { paymentApproval, paymentCancelApproval } from "../../services/PhanHeVienPhiServices";
import { CODE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";

type Props = {
  onClose: () => void;
  invoicesInfor: any[];
	handleGetPaymentInfor: () => void;
};

const initialValues: IApprovalVienPhi = {
  paymentApprovalTime: "",
};

const PopupPaymentApprove = (props: Props) => {
  const { onClose, invoicesInfor, handleGetPaymentInfor } = props;
  const [dataInfor, setDataInfor] = useState<any>();

  useEffect(() => {
    setDataInfor({
      ...dataInfor,
      paymentApprovalTime: formatDateDTO(new Date()),
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const dataPayload = {
        ...dataInfor,
        visitId: invoicesInfor[0]?.visitId
      };

      const { data } = await paymentApproval(dataPayload);
      if (CODE.SUCCESS === data?.code) {
        toast.success("Duyệt thanh toán thành công!");
        onClose();
				handleGetPaymentInfor();
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
		<Formik initialValues={initialValues} onSubmit={() => handleSubmit()}>
			{({ handleSubmit, setFieldValue }) => (
				<Modal
					show
					animation
					centered
					className="dialog-background"
					dialogClassName="modal-md"
				>
					<Modal.Header className="py-3 header-modal">
						<Modal.Title className="text-pri">Duyệt thanh toán</Modal.Title>
						<button className="btn-close" onClick={onClose}></button>
					</Modal.Header>
					<Modal.Body className="spaces p-10">
						<Form>
							<div className="d-flex align-items-center spaces h-32 mb-3 w-60">
								<LabelRequired
									className="min-w-125px fw-bold"
									label="Ngày yêu cầu"
								/>
								<DatePickerCustom
									name="paymentApprovalTime"
									value={dataInfor?.paymentApprovalTime}
									setDateValue={date => {
										setDataInfor({
											...dataInfor,
											paymentApprovalTime: date,
										});
										setFieldValue('paymentApprovalTime', date);
									}}
									mode="date-time"
									disabled
								/>
							</div>
						</Form>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-end py-1">
						<Button
							className="btn-fill min-w-80px"
							type="submit"
							onClick={() => handleSubmit()}
						>
							<span>Lưu</span>
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
  );
};

export default PopupPaymentApprove;
