import { AxiosResponse } from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import FormInPhieuDialog from './components/PrintDialog';

type ButtonInProps<T> = {
	label?: ReactNode;
	fetchExport: (params: T) => Promise<AxiosResponse<Blob, any>>;
	params: T;
	className?: string;
	keyOpenPhieuIn?: string;
	typeBtn?: string;
	setKeyOpenPhieuIn?: (data: string) => void;
	keyBtn?: string;
	isOnlyText?: boolean;
};

const ButtonIn = <T extends object>({
	label = 'In phiếu',
	fetchExport,
	params,
	className,
	keyOpenPhieuIn,
	setKeyOpenPhieuIn,
	typeBtn,
	keyBtn,
	isOnlyText = false,
}: ButtonInProps<T>) => {
	const [openPrintDialog, setOpenPrintDialog] = useState(false);

	useEffect(() => {
		keyOpenPhieuIn && keyBtn === keyOpenPhieuIn && setOpenPrintDialog(true);
		setKeyOpenPhieuIn?.("");
	}, [keyOpenPhieuIn])

	return (
		<>
			{ 
				!isOnlyText ? (
					<Button
						className={`${typeBtn ? typeBtn : "btn-fill"} ${className}`}
						onClick={() => {
							setOpenPrintDialog(true);
						}}
					>
						{label}
					</Button>
				) : <span onClick={() => setOpenPrintDialog(true)}>{label}</span>
			}

			{openPrintDialog && (
				<FormInPhieuDialog<T>
					show={openPrintDialog}
					onHide={() => {
						setOpenPrintDialog(false);
					}}
					fetchExport={fetchExport}
					params={params}
				/>
			)}
		</>
	);
};

export default ButtonIn;
