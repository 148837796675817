import LayMauBenhPham from './components/FormLayMau';
import { PhanHeLayMauContextProvider } from './context/PhanHeLayMauContext';
import '../phan-he-xet-nghiem/PhanHeXetNghiem.scss';

const PhanHeLayMau = () => {
	return (
		<PhanHeLayMauContextProvider>
			<LayMauBenhPham />
		</PhanHeLayMauContextProvider>
	);
};

export default PhanHeLayMau;
