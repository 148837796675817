import { useFormikContext } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../../../../appContext/AppContext";
import { getTermClasses } from "../../../../../../utils/ServicesUtils";
import ContextMenu from "../../../../../../component/ContextMenuV3";
import { IconBack, IconMenu } from "../../../../../../component/IconSvg";
import InputSearch from "../../../../../../component/InputSearch";
import SelectTree from "../../../../../../component/SelectTree";
import SimpleTable from "../../../../../../component/table/simple-table/SimpleTable";
import { IContextMenu } from "../../../../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import {
  CODE_MENU,
  KEY,
  SELECTION_MODE,
  TERM_CLASS_IDS
} from "../../../../../../utils/Constant";
import { formatMoney } from "../../../../../../utils/FormatUtils";
import {
  DsDichVuColumn,
  TreeChiDinhDichVu,
  dsDichVuColumnsSimple
} from "./ChiDinhDichVuColumn";
import "../../QuanLyHopDong.scss";
import { TableCustom } from "../../../../../../component/table/table-custom/TableCustom";
import { ConfirmDialog } from "../../../../../../component/ConfirmDialog";
import { handleSearchByFuse } from "../../../../../../utils/AppFunction";
import { RowMouseEventHandlerParams } from "react-virtualized";
import { useDebounce } from "../../../../../../../../_metronic/helpers";

interface Props {
  handleClose: any;
  dieuTri?: boolean;
  listTermsEdit: any[];
}

const FormChiDinhDichVu: FC<Props> = (props) => {
  let { handleClose, listTermsEdit } = props;
  let { values, setFieldValue } = useFormikContext<any>();
  const { setIsLoading } = useContext(AppContext);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [dsDichVuChiDinh, setDSDichVuChiDinh] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [indexRowContext, setIndexRowContext] = useState<number>();
  const [listDichVu, setListDichVu] = useState<any[]>([]);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] =
    useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>("");
  const [listDichVuSearch, setListDichVuSearch] = useState<any[]>([]);
  const [listResponseService, setListResponseService] = useState<any>([]);
  const textSearchDebounce = useDebounce(textSearch.trim(), 300);
  
  const updateColumnDSDichVuChiDinh = (column: string, newValue: any) => {
    setDSDichVuChiDinh(
      dsDichVuChiDinh?.map((item) => {
        if (item?.maDichVu === selectedRow?.maDichVu) {
          item[column] = newValue;
        }
        return item;
      })
    );
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.SUA_SO_LUONG.MOT]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.MOT),
      [CODE_MENU.SUA_SO_LUONG.HAI]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.HAI),
      [CODE_MENU.SUA_SO_LUONG.BA]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BA),
      [CODE_MENU.SUA_SO_LUONG.BON]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BON),
      [CODE_MENU.SUA_GIA]: () =>
        toast.warning(
          `Dịch vụ [${selectedRow?.tenDichVu}] không được sửa đơn giá`
        ),
      [CODE_MENU.XOA_DICH_VU]: () => setShouldOpenConfirmDialog(true)
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const dropListChiDinhDichVu: IContextMenu[] = [
    {
      title: "Dịch vụ"
    },
    {
      icon: <i className="bi bi-x-lg text-danger"></i>,
      code: CODE_MENU.XOA_DICH_VU,
      name: "Xóa dịch vụ"
    }
  ];

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setSelectedRow(row?.original);
    setContextMenu({ x: e.clientX, y: e.clientY });
    setIndexRowContext(row?.index);
  };

  const handleOpenComfirmDialog = () => {
    setShouldOpenConfirmDialog(true);
  }

  const handleDelete = () => {
    let newArr = [...values?.terms];
    if (indexRowContext || indexRowContext === 0) {
      newArr.splice(indexRowContext, 1);
    }
    handleSelectService(newArr);
    setSelectedRow(newArr);
    setShouldOpenConfirmDialog(false);
  };

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.terms &&
          item?.terms?.length && {
            filter: handleConvertTreeData(item?.terms || [])
          })
      };
    });
    return convertedData;
  };

  const handleGetSelectedItem = (data: any) => {
    if (data) {
      setListDichVu(data?.subs || []);
      setListDichVuSearch(data?.subs || []);
      setTextSearch("");
    } else {
      //Khi click lần 2 vào loại dịch vụ đó thì set ds dịch vụ về full dịch vụ
      setListDichVu(
        listResponseService.flatMap((items: any) =>
          items?.terms.flatMap((item: any) => item?.subs)
        )
      );
      setListDichVuSearch(listResponseService.flatMap((items: any) =>
        items?.terms.flatMap((item: any) => item?.subs)
      ))
    }
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);

      let { KHAM_BENH, XET_NGHIEM, CDHA } = TERM_CLASS_IDS;
      let { data } = await getTermClasses({
        classIds: `${KHAM_BENH},${XET_NGHIEM},${CDHA}`
      });
      let treeDataConverted = {
        code: "all",
        name: "Tất cả dịch vụ",
        icon: <IconMenu />,
        filter: handleConvertTreeData(data?.data || [])
      };
      setTreeData(treeDataConverted);
      setListResponseService(data?.data);

      //Mới đầu vào hiện full list dịch vụ
      setListDichVu(data?.data?.flatMap((items: any) => items?.terms.flatMap((term: any) => term?.subs)));
      setListDichVuSearch(data?.data?.flatMap((items: any) => items?.terms.flatMap((term: any) => term?.subs)))
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  useEffect(() => {
    if (listTermsEdit?.length > 0) {
      handleSelectService(listTermsEdit);
    }
  }, [listTermsEdit]);

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    rowData = {
      ...rowData,
      svcFee: formatMoney(rowData?.attributes?.svcFee) || 0,
      svcFeeHospital: formatMoney(rowData?.attributes?.svcFeeHospital) || 0,
      svcFeeIns: formatMoney(rowData?.attributes?.svcFeeIns) || 0,
      svcQuantity: rowData?.attributes?.svcQuantity || 1,
      svcPrice: formatMoney(rowData?.attributes?.svcFee) || 0,
      svcTotal: formatMoney(rowData?.attributes?.svcTotal) || 0,
      deptLaboratoryName: rowData?.attributes?.deptLaboratoryName || rowData?.attributes?.deptClinicName,
    };
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };
  
  const handleCaculateFee = (data: any) => {
    let objResult = {
      tong: 0,
      benhNhan: 0,
      conNo: 0
    };
    data?.map((item: any) => {
      objResult = {
        tong: objResult.tong + Number(item?.attributes?.svcFeeHospital),
        benhNhan: 0,
        conNo: 0
      };
    });
    return objResult;
  };

  const handleSelectService = (data: any) => {
    const objFee = handleCaculateFee(data);
    setFieldValue("terms", data);
    setFieldValue("total", objFee);
  };

  useEffect(() => {
    listDichVuSearch.length > 0 && handleSearchByFuse(listDichVuSearch, textSearchDebounce ?? '', setListDichVu);
  }, [textSearchDebounce]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value ? `'${value}` : '';
    setTextSearch(newValue);
  };

  const handleOnRowClickCheckbox = (rowData: any) => {
    if(!rowData) return;

    let _listData = [...listDichVu];
    _listData[rowData.index].isChecked = true;
    let updatedSelectedRows = [...selectedRow];
    const selectedItem = _listData[rowData.index];

    if (!rowData?.rowData?.isChecked) {
      updatedSelectedRows.push(selectedItem);
    } else {
      updatedSelectedRows = updatedSelectedRows.filter(
        (item: any) => item?.["code"] !== selectedItem?.["code"]
      );
    }
    
    handleSelectService(updatedSelectedRows);
    setListDichVu([..._listData]);
    setSelectedRow(updatedSelectedRows);
  }
  
  return (
    <div className="">
      <Modal.Header className="py-3 header-modal">
        <div className="d-flex gap-6">
          <div
            className="d-flex align-items-center gap-3 cursor-pointer"
            onClick={handleClose}
          >
            <IconBack />
            <span className="fw-500">Quay lại</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="py-6 px-0  h-calc-vh-130 spaces scroll-hidden">
        <div className="d-flex h-100">
          <div className="spaces width-20 bg-white radius-2">
            <div className="spaces px-10 pt-6 h-42">
              <InputSearch
                placeholder="Tìm kiếm"
                handleChange={() => {}}
                className="spaces h-25 pr-4 radius-3"
              />
            </div>
            <SelectTree
              className="w-100 h-198 border py-2 ps-4 border-top-0  border-bottom-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData}
            />
          </div>
          <div className="d-flex spaces width-80 bg-white radius-2">
            {/* <div className="spaces flex-grow-1 bg-white px-0 radius-2 mt-6 table-ke-thuoc"> */}
            <div className="d-flex flex-column spaces width-100  ">
              <div className="bg-white">
                <div className="spaces px-10 mt-6 h-35 ">
                  <InputSearch             
                    placeholder="Tìm kiếm"
                    handleChange={handleChange}
                    className="spaces h-25 pr-4 radius-3"
                  />
                </div>
                <div className="min-h-200 spaces bg-white mb-6 border-bottom">
                  <SimpleTable
                    data={listDichVu || []}
                    columns={dsDichVuColumnsSimple}
                    height={400}
                    width={1000}
                    scrollable={true}
                    type={SELECTION_MODE.MULTI_TABLE}
                    setDataChecked={handleSelectService}
                    dataChecked={values?.terms || []}
                    handleRenderCell={handleRenderCell}
                    onRowClick={(rowData: RowMouseEventHandlerParams) => {
                      handleOnRowClickCheckbox(rowData);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
                <div className="d-flex spaces pt-10 pb-6 px-10 text-pri fw-bold">
                  Phiếu chỉ định
                </div>
                <div className="flex-grow-1 spaces h-calc-vh-710">
                  <TableCustom
                    className="h-100"
                    columns={DsDichVuColumn(handleOpenComfirmDialog, setIndexRowContext)}
                    data={values?.terms || []}
                    height={215}
                  />
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="height-footer ">
        <Button className="btn-fill min-w-100px mb-2" type="submit">
          Chọn
        </Button>
      </Modal.Footer>
      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={dropListChiDinhDichVu}
          target={contextMenu}
        />
      )}

      <ConfirmDialog
        className="z-index-1060"
        show={shouldOpenConfirmDialog}
        title="Thông báo"
        message="Bạn có chắc chắn muốn bỏ dịch vụ này không ?"
        yes="Xác nhận"
        close="Đóng"
        onCloseClick={() => setShouldOpenConfirmDialog(false)}
        onYesClick={() => handleDelete()}
      />
    </div>
  );
};

export default FormChiDinhDichVu;
