import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { IconButtonSave } from '../../../component/IconSvg';
import TextField from '../../../component/TextField';

type Props = {
	open: boolean;
	handleClose: () => void;
	onSave?: (data: IForm) => void;
};

type IForm = {
	nhomDonMau?: string;
	tenMau: string;
};

const ModalLuuMauThuocMoi = ({ open, handleClose, onSave }: Props) => {
	const intl = useIntl();

	const validationSchema = Yup.object({
		tenMau: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
	});

	const onSubmit = (values: IForm) => {
		onSave && onSave(values);
	};

	return (
		<Modal show={open} animation centered className="dialog-background">
			<Formik<IForm>
				initialValues={{
					tenMau: '',
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				<Form>
					<Modal.Header className="py-3 header-modal">
						<Modal.Title className="text-pri">Lưu mẫu mới</Modal.Title>
						<Button className="btn-close bg-white" onClick={handleClose}></Button>
					</Modal.Header>

					<Modal.Body className="py-16 px-24 spaces">
						<div className="spaces my-15">
							<TextField
								label="Tên mẫu"
								name="tenMau"
								labelClassName="pe-10 spaces min-w-100"
							/>
						</div>
					</Modal.Body>

					<Modal.Footer className="d-flex justify-content-end">
						<Button className="btn-outline" onClick={handleClose}>
							Đóng
						</Button>
						<Button className="btn-fill" type="submit">
							<IconButtonSave />
							Lưu
						</Button>
					</Modal.Footer>
				</Form>
			</Formik>
		</Modal>
	);
};

export default ModalLuuMauThuocMoi;
