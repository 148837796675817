import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import { Figure, Image, Modal } from 'react-bootstrap';
import { Cropper, ReactCropperElement } from 'react-cropper';
import ActionButton from './components/ActionButton';
import { ACTIONS, IMAGE_PLACEHOLDER_PATH } from './constant';

import 'cropperjs/dist/cropper.css';
import './style.scss';
import { FILE_EXTENSION } from '../../utils/Constant';
import { getPDFURLPreviewFromBase64 } from '../../utils/FormatUtils';
import IframePreview from './components/IframePreview';

type Props = {
	open: boolean;
	onClose: () => void;
	images: any[];
	indexActive?: number;
};

const LightBox = ({ open, onClose, images, indexActive = 0 }: Props) => {
	const [index, setIndex] = useState<number>(indexActive);

	const cropperRef = useRef<ReactCropperElement>(null);

	const imageActive = images[index];
	const isFilePDF = imageActive?.fileType === FILE_EXTENSION.PDF;

	const generateAction = (action: string) => {
		const mapActionFn: Record<string, () => void> = {
			zoomIn: () => {
				cropperRef.current?.cropper.zoom(0.1);
			},
			zoomOut: () => {
				cropperRef.current?.cropper.zoom(-0.1);
			},
			rotateLeft: () => {
				cropperRef.current?.cropper.rotate(-45);
			},
			rotateRight: () => {
				cropperRef.current?.cropper.rotate(45);
			},
			exit: () => {
				onClose();
			},
			back: () => {
				if (images.length === 1) return;
				setIndex(index === 0 ? images.length - 1 : index - 1);
			},
			next: () => {
				if (images.length === 1) return;
				setIndex(index === images.length - 1 ? 0 : index + 1);
			},
		};

		return mapActionFn[action];
	};

	return (
		<Modal
			show={open}
			onHide={onClose}
			fullscreen
			animation
			contentClassName="max-h-100"
			className="oct-image-lightbox"
		>
			<Modal.Header className="p-4">
				<div className="w-100 d-flex justify-content-between align-items-center">
					<span className="d-block text-white fs-2 fw-bold spaces pl-8">
						{index + 1}/{images.length}
					</span>
					<div>
						{!isFilePDF &&
							ACTIONS.map(button => (
								<ActionButton
									key={button.action}
									action={button.action}
									title={button.title}
									onClick={generateAction(button.action)}
									icon={button.icon}
								/>
							))}
						<ActionButton title="Đóng" onClick={onClose} icon="bi bi-x-lg" />
					</div>
				</div>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex flex-column">
					<div
						className="d-flex justify-content-between spaces pb-10"
						style={{
							flex: '1 1 auto',
							height: 'calc(100vh - 140px)',
						}}
					>
						<div className="d-flex align-items-center">
							<ActionButton
								icon="bi bi-chevron-left"
								title="Quay lại ảnh trước"
								onClick={generateAction('back')}
								className="spaces px-16 py-28 radius-2"
							/>
						</div>
						<Figure className="flex-1 text-center d-flex justify-content-center">
							{isFilePDF ? (
								<div className="spaces px-16 w-100 h-100">
									<IframePreview src={imageActive?.src} />
								</div>
							) : (
								<Cropper
									src={imageActive?.src}
									alt={imageActive?.alt}
									style={{ height: '100%', width: '70%' }}
									className="spaces radius-1 mb-0"
									initialAspectRatio={16 / 9}
									guides={false}
									background={false}
									modal={false}
									autoCrop={false}
									dragMode="move"
									ref={cropperRef}
								/>
							)}

							{imageActive?.alt && <Figure.Caption>{imageActive.alt}</Figure.Caption>}
						</Figure>
						<div className="d-flex align-items-center">
							<ActionButton
								icon="bi bi-chevron-right"
								title="Xem ảnh tiếp theo"
								onClick={generateAction('next')}
								className="spaces px-16 py-28 radius-1"
							/>
						</div>
					</div>

					<div
						className="d-flex align-items-center"
						style={{
							position: 'fixed',
							bottom: 8,
							minHeight: '90px',
							width: '100%',
							overflowX: 'auto',
						}}
					>
						{!!images.length &&
							images.map((image, thumbnailIndex) => (
								<>
									{image?.fileType === FILE_EXTENSION.PDF ? (
										<div
											className={clsx(
												'oct-image-lightbox-thumbnail overflow-hidden',
												{
													'oct-image-lightbox-thumbnail--active':
														index === thumbnailIndex,
												}
											)}
											onClick={() => {
												setIndex(thumbnailIndex);
											}}
										>
											<IframePreview
												src={image.src}
												style={{
													pointerEvents: 'none',
													width: 170,
												}}
											/>
										</div>
									) : (
										<Image
											key={thumbnailIndex}
											src={
												image?.fileType === FILE_EXTENSION.PDF
													? IMAGE_PLACEHOLDER_PATH
													: image.src
											}
											alt={image.alt}
											className={clsx('oct-image-lightbox-thumbnail', {
												'oct-image-lightbox-thumbnail--active':
													index === thumbnailIndex,
											})}
											onClick={() => {
												setIndex(thumbnailIndex);
											}}
										/>
									)}
								</>
							))}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LightBox;
