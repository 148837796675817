import React, { FC, useContext, useEffect } from 'react'
import { Button, Col, Modal, Row, Form as BootstrapForm } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useIntl } from 'react-intl';
import AutocompleteV2 from '../../../component/AutocompleteObjectV2';
import TextField from '../../../component/TextField';
import LabelRequired from '../../../component/LabelRequired';
import { getThongTinNghiOm, saveThongTinNghiOm } from '../../services/PhanHeTiepDonServer';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD } from '../../../utils/FormatUtils';
import { CODE, RESPONSE_MESSAGE } from '../../../utils/Constant';
import { toast } from 'react-toastify';
import moment from 'moment';


type Props = {
  handleCloseModal: () => void;
}

const ModalThongTinNghiOm: FC<Props> = (props: Props) => {
  let { handleCloseModal } = props;
  const intl = useIntl();
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
  const formikRef = React.useRef<any>();
  // const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);

  const handleSubmitForm = async (values: any) => {
    try {
      let submitData = {
        slDiagnosisAndTreatmentMethod: values?.slDiagnosisAndTreatmentMethod,
        slNumberOfLeaveDays: values?.slNumberOfLeaveDays,
        slStartDate: formatDateToDDMMYYYY(values?.slStartDate),
        slEndDate: formatDateToDDMMYYYY(values?.slEndDate),
        slDoctorName: values?.slDoctorName,
        slDepartmentHead: values?.slDepartmentHead,
        slRepresentative: values?.slRepresentative,
        slInsCode: values?.slInsCode,
        slSocialInsCode: values?.slSocialInsCode,
        slWorkplace: values?.slWorkplace,
        slFatherName: values?.slFatherName,
        slMotherName: values?.slMotherName,
        slPregnancyTermination: values?.slPregnancyTermination,
        slGestationalAge: values?.slGestationalAge,
        slPregnancyTerminationReason: values?.slPregnancyTerminationReason,
        slNotes: values?.slNotes,
      };
      let { data } = await saveThongTinNghiOm(benhNhanInfo?.thongTinKhamBenh?.visitId, submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success("Lưu thành công");
        handleCloseModal();
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const validationSchema = Yup.object().shape({});

  const handleGetThongTinNghiOm = async () => {
    try {
      let { data } = await getThongTinNghiOm(benhNhanInfo?.thongTinKhamBenh?.visitId);
      if (CODE.SUCCESS === data?.code) {
        formikRef?.current?.setValues({
          ...data?.data,
          slStartDate: moment(data?.data?.slStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          slEndDate: moment(data?.data?.slEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        });
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetThongTinNghiOm();
  }, []);

  return (
    <Modal
      dialogClassName="h-modal"
      size="xl"
      show={true}
      animation
      centered
    >
      <Formik<any>
        initialValues={{}}
        onSubmit={(values: any) => handleSubmitForm(values)}
        innerRef={formikRef}
      >
        {({
          touched,
          errors,
          setFieldValue,
          values,
          handleChange
        }) => (
          <Form id="form-thuoc">
            <div className="thuoc-dialog">
              <Modal.Header className="py-3 header-modal">
                <Modal.Title className="text-pri">Thông tin nghỉ ốm</Modal.Title>
                <button
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </Modal.Header>
              <Modal.Body className="px-24 py-0 spaces overflow-hidden">
                <Row>
                  <Col xs="12" className='spaces mb-6'>
                    <div className="spaces mt-5 mb-n2 d-flex flex-row justify-content-between">
                      <LabelRequired label="Chẩn đoán và phương pháp điều trị" className="min-w-125px" />
                      <Button
                        className="bg-white spaces mb-2 px-2"
                      >
                        <u className="fw-bold color-17A2B8 fs-13px">Cập nhật từ bệnh án</u>
                      </Button>
                    </div>
                    <TextField
                      as="textarea"
                      rows={4}
                      name="slDiagnosisAndTreatmentMethod"
                      value={values.slDiagnosisAndTreatmentMethod || ""}
                      onChange={handleChange}
                      className="w-100 resize-none"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <LabelRequired label="Số ngày nghỉ" className="spaces min-w-80" />
                    <TextField
                      name="slNumberOfLeaveDays"
                      value={values?.slNumberOfLeaveDays || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <LabelRequired label="Từ ngày" className="spaces min-w-80" />
                    <TextField
                      name="slStartDate"
                      type="date"
                      value={values?.slStartDate || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <LabelRequired label="Đến ngày" className="spaces min-w-80" />
                    <TextField
                      name="slEndDate"
                      type="date"
                      value={values?.slEndDate || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="3" className='spaces mb-6'>
                    <LabelRequired label="Bác sĩ" className="spaces min-w-135" />
                    <AutocompleteV2
                      options={[]}
                      value={values?.slDoctorName || null}
                      name="slDoctorName"
                      urlData="data.data.0.terms"
                      onChange={(selectedOption) => setFieldValue("slDoctorName", selectedOption)}
                      touched={touched?.slDoctorName}
                      errors={errors?.slDoctorName}
                      className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                    />
                  </Col>
                  <Col xs="3" className='spaces mb-6'>
                    <LabelRequired label="Thủ trưởng đơn vị" className="spaces min-w-135 align-items-start mt-2" />
                    <AutocompleteV2
                      options={[]}
                      value={values?.slDepartmentHead || null}
                      name="slDepartmentHead"
                      urlData="data.data.0.terms"
                      onChange={(selectedOption) => setFieldValue("slDepartmentHead", selectedOption)}
                      touched={touched?.slDepartmentHead}
                      errors={errors?.slDepartmentHead}
                      className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                    />
                  </Col>
                  <Col xs="4" className='spaces mb-6'>
                    <LabelRequired label="Người đại diện" className="spaces min-w-80" />
                    <TextField
                      name="slRepresentative"
                      value={values?.slRepresentative || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <LabelRequired label="Số thẻ BHYT" className="spaces min-w-80" />
                    <TextField
                      name="slInsCode"
                      value={values?.slInsCode || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <LabelRequired label="Mã số BHXH" className="spaces min-w-80" />
                    <TextField
                      name="slSocialInsCode"
                      value={values?.slSocialInsCode || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="12" className='spaces mb-6'>
                    <div className="spaces mt-5 mb-n2 d-flex flex-row justify-content-between">
                      <LabelRequired label="Đơn vị làm việc" className="min-w-125px" />
                      <Button
                        className="bg-white spaces mb-2 px-2"
                      >
                        <u className="fw-bold color-17A2B8 fs-13px">Chọn nơi làm việc</u>
                      </Button>
                    </div>
                    <TextField
                      as="textarea"
                      rows={2}
                      name="slWorkplace"
                      value={values?.slWorkplace || ""}
                      onChange={handleChange}
                      className="w-100 resize-none"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className='spaces mb-6 mt-10'>
                    <em>Thông tin cha mẹ (chỉ áp dụng đối với trường hợp là trẻ em dưới 7 tuổi)</em>
                  </Col>
                  <Col xs="4" className='spaces mb-6'>
                    <LabelRequired label="Họ tên cha" className="spaces min-w-80" />
                    <TextField
                      name="slFatherName"
                      value={values?.slFatherName || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="4" className='spaces mb-6'>
                    <LabelRequired label="Họ tên mẹ" className="spaces min-w-80" />
                    <TextField
                      name="slMotherName"
                      value={values?.slMotherName || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs></Col>
                  <Col xs="4" className='d-flex align-items-center justify-content-between spaces mb-6'>
                    <BootstrapForm.Check
                      type="checkbox" 
                      label="Đình chỉ thai nghén"
                      name="slPregnancyTermination"
                      checked={values?.slPregnancyTermination || false}
                      onChange={(e) => setFieldValue("slPregnancyTermination", e.target.checked)}
                    />
                    <LabelRequired label="Tuổi thai" />
                  </Col>
                  <Col xs="2" className='spaces mb-6'>
                    <TextField
                      name="slGestationalAge"
                      value={values?.slGestationalAge || ""}
                      onChange={handleChange}
                      className="w-100"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs className='d-flex align-items-center'>Tuần</Col>
                  <Col xs="12" className='spaces mb-6'>
                    <LabelRequired label="Nguyên nhân đình chỉ thai nghén" className="spaces min-w-80" />
                    <TextField
                      as="textarea"
                      rows={2}
                      name="slPregnancyTerminationReason"
                      value={values?.slPregnancyTerminationReason || ""}
                      onChange={handleChange}
                      className="w-100 resize-none"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                  <Col xs="12" className='spaces mb-6'>
                    <LabelRequired label="Ghi chú" className="spaces min-w-80" />
                    <TextField
                      as="textarea"
                      rows={4}
                      name="slNotes"
                      value={values?.slNotes || ""}
                      onChange={handleChange}
                      className="w-100 resize-none"
                      labelClassName="spaces min-w-80"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-fill spaces min-w-80"
                  type="submit"
                >
                  Lưu
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalThongTinNghiOm