import { Field, useField } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AutocompleteV2 from '../../../../component/AutocompleteObjectV2';
import { IconButtonSave } from '../../../../component/IconSvg';
import LabelRequired from '../../../../component/LabelRequired';
import SimpleTable from '../../../../component/table/simple-table/SimpleTable';
import SearchField from '../../../components/tab-kham-benh/modal-chon-ket-qua-dich-vu/components/SearchField';
import { RowMouseEventHandlerParams } from 'react-virtualized';

type ModalProps = {
	open: boolean;
	onClose: () => void;
	onSave: (value: DateType[]) => void;
};

type DateType = {
	name: string;
	value: string;
	dayOfWeek: string;
	shortName: string;
};

type TimeType = {
	value: string;
	name: string;
};

type Props = {
	name: string;
};

const NgayKeTuDong = ({ name }: Props) => {
	const [openSelectDialog, setOpenSelectDialog] = useState(false);
	const [value, setValue] = useState<string>('');

	const [field, meta, helper] = useField(name);

	useEffect(() => {
		if (field.value) {
			setValue(
				field.value
					.split(',')
					.map((item: string) => moment(new Date(item)).format('DD/MM'))
					.join(', ')
					.trim()
			);
		}
	}, [field.value]);

	const onSelect = (selectedDate: DateType[]) => {
		const dataArraySorted = sortDate(selectedDate);

		setValue(
			dataArraySorted
				.map((item: DateType) => item.shortName)
				.join(', ')
				.trim()
		);
		helper.setValue(dataArraySorted.map((item: DateType) => item.value).join(','));
		setOpenSelectDialog(false);
	};

	const sortDate = (dateArray: any[]) => {
		return dateArray
			.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime())
			.map(row => row);
	};

	return (
		<div className="d-flex align-items-center">
			<Field as="input" hidden {...field} />
			<LabelRequired label="Kê tự động cho các ngày" className="spaces min-w-165" />
			<Button
				onClick={() => {
					setOpenSelectDialog(true);
				}}
				className="p-0 btn-fill"
			>
				<em>
					<i className="bi bi-calendar4-week m-0 px-0 pe-2"></i>
					<span>{!value ? 'Chọn ngày kê tự động' : value}</span>
				</em>
			</Button>
			{openSelectDialog && (
				<ModalChonNgay
					open={openSelectDialog}
					onClose={() => {
						setOpenSelectDialog(false);
					}}
					onSave={onSelect}
				/>
			)}
		</div>
	);
};

const ModalChonNgay = ({ open, onClose, onSave }: ModalProps) => {
	const initialDataRef = useRef<DateType[]>([]);

	const [tableData, setTableData] = useState<DateType[]>([]);
	const [value, setValue] = useState<any[]>([]);
	const [timeOptions, setTimeOptions] = useState<TimeType[]>([]);
	const [timeValue, setTimeValue] = useState('');

	useEffect(() => {
		initData();
	}, []);

	const initData = () => {
		const startDayOfWeek = moment().startOf('week').toISOString();
		const endDate = moment(startDayOfWeek).add(2, 'week');
		const countDayDiff = moment(endDate).diff(moment(), 'day');
		const DAY_OF_WEEK = ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];
		let index = 1;
		let date = moment();
		const dateOptions: DateType[] = [];
		const timeOptions: TimeType[] = [];

		while (index <= countDayDiff) {
			date = date.add(1, 'day');
			dateOptions.push({
				name: `Ngày ${date.format('DD/MM/YYYY')}`,
				value: date.toISOString(),
				dayOfWeek: DAY_OF_WEEK[date.day()],
				shortName: date.format('DD/MM'),
			});
			index += 1;
		}
		initialDataRef.current = dateOptions;
		setTableData(dateOptions);

		for (let hour = 0; hour < 24; hour++) {
			for (let minute = 0; minute < 60; minute += 5) {
				const option = `${hour < 10 ? `0${hour}` : hour}:${
					minute < 10 ? `0${minute}` : minute
				}`;
				timeOptions.push({
					name: option,
					value: option,
				});
			}
		}
		setTimeOptions(timeOptions);
	};

	const onSearch = (key: string) => {};

	const columns = useMemo(
		() => [
			{
				name: '',
				field: 'checkbox',
				width: 50,
				textAlign: 'center',
			},
			{
				name: 'Ngày',
				field: 'name',
				width: 500,
			},
			{
				name: 'Thứ',
				field: 'dayOfWeek',
				width: 100,
			},
		],
		[]
	);

	const onRenderCell = (columnData: any, dataKey: string, rowData: any) => {
		return rowData[dataKey];
	};

	const onSubmit = () => {
		const [hour, minute] = timeValue.split(':');

		const result = value.map((record: DateType) => ({
			...record,
			value: moment(record.value)
				.set({
					hour: Number(hour),
					minute: Number(minute),
				})
				.toISOString(),
		}));
		onSave(result);
	};

	return (
		<Modal size="lg" show={open} animation centered className="dialog-background">
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Chọn ngày</Modal.Title>
				<Button className="btn-close bg-white" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="py-16 px-24 spaces">
				<SearchField onSearch={onSearch} />
				<SimpleTable
					data={tableData}
					columns={columns}
					height={400}
					width={1000}
					scrollable={true}
					setDataChecked={setValue}
					dataChecked={value}
					uniquePrefix="value"
					handleRenderCell={onRenderCell}
					onRowClick={({rowData}:RowMouseEventHandlerParams) => {
						let selected = [...value];
						const rowExist = selected.some((row:any) => row.value === rowData.value);
						if(rowExist) {
							selected = selected.filter((row:any) => row.value !== rowData.value);
						} else {
							selected.push(rowData);
						}
						setValue(selected);
					}}
				/>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-between py-1">
				<div className="d-flex">
					<LabelRequired label="Thời gian chỉ định" className="spaces min-w-150" />
					<div className="w-100px">
						<AutocompleteV2
							options={timeOptions}
							name="thoiGianChiDinh"
							className="autocomplete-custom-tiep-nhan radius spaces h-26"
							value={timeOptions.find(item => item.value === timeValue)}
							onChange={option => {
								setTimeValue(option.value);
							}}
						/>
					</div>
				</div>
				<Button className="btn-fill" onClick={onSubmit}>
					<IconButtonSave />
					<span>Lưu</span>
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default NgayKeTuDong;
