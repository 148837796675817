const DB_NAME = "MyDatabase";
const DB_VERSION = 2;
const STORE_NAME = "DS_DANH_MUC";

const openDatabase = () => {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            reject(event.target);
        };

        request.onsuccess = (event) => {
            resolve(request.result);
        };

        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: "key" });
            }
        };
    });
}

const addIndexedDBItem = async (key: string, value: any, callback:any) => {
    try {
        const db = await openDatabase();
        return new Promise<void>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], "readwrite");
            const store = transaction.objectStore(STORE_NAME);
            let valueStore = store.get(key);
            let request: any = {};
            
            if (valueStore) {
                store.put({ key, value });
            } else {
                request = store.add({ key, value });
            }

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    } finally {
        callback && callback(false)
    }
}

const getIndexedDBItem = async (key: string) => {
    try {
        const db = await openDatabase();
        return new Promise<any>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME]);
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get(key);

            request.onsuccess = () => {
                resolve(request.result?.value);
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    }
}

const deleteIndexedDBItem = async (key: string) => {
    try {
        const db = await openDatabase();
        return new Promise<void>((resolve, reject) => {
            const transaction = db.transaction([STORE_NAME], "readwrite");
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(key);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = () => {
                reject(request.error);
            };
        });
    } catch (error) {
        console.error(error);
    }
}


export { addIndexedDBItem, getIndexedDBItem, deleteIndexedDBItem };
