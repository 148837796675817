import { Form, Formik } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { formatDateToYYYYMMDD } from '../../utils/FormatUtils';
import DatePickerCustom from '../date-picker/DatePickerCustom';
import { LIST_PERIOD, TYPE_SEARCH } from '../filter-sidebar/FilterSidebar';
import LabelRequired from '../LabelRequired';
import TextField from '../TextField';
import "./ModalSearchAdvanced.scss";

type Props = {
  getPeriod: (type: string) => ({
    fromDate: Date
    toDate: Date
  })
  setPeriod: (period: any) => void
  handleCloseModal: () => void
}

const ModalSearchAdvanced = (props: Props) => {
  const { handleCloseModal, getPeriod, setPeriod } = props;

  const initialValues: any = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  const handleSubmit = (values: any) => {
    setPeriod(values);
    handleCloseModal();
  };

  return (
    <Modal
      className='modal fade'
      role='dialog'
      show={true}
      dialogClassName='modal-serach-advanced'
      aria-hidden='true'
      centered
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setValues, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton onHide={handleCloseModal} className="p-4 header-modal">
              <Modal.Title className='text-pri'>Tùy chọn thời gian</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={6} className="d-flex mb-3 align-items-center text-lable-input">
                  <LabelRequired label="Từ ngày" className="min-w-60px" />
                    <DatePickerCustom
                      name="fromDate"
                      value={values?.fromDate}
                      setDateValue={date => {
                        setFieldValue('fromDate', date);
                      }}
										/>
                </Col>
                <Col xs={6} className="d-flex mb-3 align-items-center text-lable-input">
                  <LabelRequired label="Đến ngày" className="min-w-70px" />
                  <DatePickerCustom
                    name="toDate"
                    value={values?.toDate}
                    setDateValue={date => {
                      setFieldValue('toDate', date);
                    }}
									/>
                </Col>
              </Row>
              <Row className='spaces mx-40'>
                {LIST_PERIOD
                  .filter(item => item.type && item.type !== TYPE_SEARCH.SEARCH_ADVANCED)
                  .map(item => (
                    <Col xs="4">
                      <Button
                        className="bg-white spaces mb-2 px-2"
                        onClick={() => {
                          let period = getPeriod(item.type as string);
                          setValues({
                            fromDate: period?.fromDate,
                            toDate: period?.toDate
                          });
                        }}
                      >
                        <u className="fw-bold text-pri fs-13px">{item.name || ""}</u>
                      </Button>
                    </Col>
                  ))}
              </Row>
            </Modal.Body>
            <Modal.Footer className='pt-2 pb-2 justify-content-end'>
              <Button
                type='submit'
                className='btn-fill min-w-80px'
              >
                Xác nhận
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalSearchAdvanced