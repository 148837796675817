import React, { FC, useContext, useEffect, useState } from "react";
import "./QuanLyHopDong.scss";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../../../appContext/AppContext";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { LIST_COLOR_TRANG_THAI_PHIEU, CODE_SUCCESS, ERROR_MESSAGE, APP_DATE_TIME_FORMAT } from "../../../../utils/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import { getListOrderKhamBenh } from "../../../services/KhamDoanHopDongServices"
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong"
import DanhSachPhieu from "../../../../component/DanhSachPhieu";
import { formatDateTime } from "../../../../utils/FormatUtils";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";


type TabKhamBenhProps = {
};
const TabKhamBenh: FC<TabKhamBenhProps> = React.memo(
  ({
  }) => {
    const { TTNhanVien } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const [ttGoiKham, setTTGoiKham] = useState<any>({});
    const [dsPhieu, setDsPhieu] = useState<any>([]);
    useEffect(() => {
      TTNhanVien?.id && handleGetListOrderGroup(TTNhanVien?.id)
    }, [TTNhanVien?.id]);
    const handleGetListOrderGroup = async (id: any) => {
      try {
        const response = await getListOrderKhamBenh({
          typeId: 6,
          id: id
        });
        if (response?.data?.code === CODE_SUCCESS) {
          let dsPhieuConverted = response?.data?.data?.map((item: any) => {
            return {
              ...item,
              date: moment(item?.indicationTime).format("DD/MM/YYYY"),
            };
          });
          setDsPhieu(dsPhieuConverted)
        } else {
          setDsPhieu([])
          toast.error(ERROR_MESSAGE);
        }
      } catch (e) {
        toast.error(ERROR_MESSAGE);
      }
    }
    const handlerSelectOrder = (row: any) => {
      setTTGoiKham(row)
    }

    return (
      <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
        <div className="spaces w-100 justify-content-center mt-5">
          <Row className="border-bottom mx-0">
            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Mã phiếu:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.group?.code || ""}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Ngày y lệnh:</span>
                <span className="spaces fw-4 text-truncate">
                  {formatDateTime(ttGoiKham?.group?.indicationTime, APP_DATE_TIME_FORMAT)}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Người chỉ định:</span>
                <span className="spaces fw-4 text-truncate">
                  {TTNhanVien?.createdBy || ""}
                </span>
              </div>
            </Col>
            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Nơi chỉ định:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.group?.noiChiDinh || "Phòng khám sức khoẻ"}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Trạng thái:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.wf?.currStatusCode === WF_STATUS.DANG_KHAM.CODE
                    ? "Đang khám"
                    : ttGoiKham?.wf?.currStatusCode === WF_STATUS.KET_THUC_KHAM.CODE
                    ? "Kết thúc khám"
                    : "Chưa khám"}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Nơi thực hiện:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.term?.attributes?.deptClinicName || ""}
                </span>
              </div>
            </Col>


            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Chẩn đoán:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.chanDoan || ""}
                </span>
              </div>
            </Col>

            <Col xs="6">
              <div className="spaces mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">Dịch vụ khám:</span>
                <span className="spaces fw-4 text-truncate">
                  {ttGoiKham?.term?.name || ""}
                </span>
              </div>
            </Col>

          </Row>
          <Row className="spaces  p-5">

          </Row>
          <div className="bg-white spaces fl-1">
            {dsPhieu?.length > 0 &&
              <div className="spaces ml-7">
                <DanhSachPhieu
                  className="mb-1 "
                  handleSelectRowData={handlerSelectOrder}
                  dsPhieu={dsPhieu || []}
                  listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
                  isGetFirstData
                />
              </div>
            }
            <div className="bg-white  modelKhamBenh spaces h-calc-vh-340  ml-10 mr-15 mt-1 fl-1">
              <div className="spaces mb-10">
                <CKEditor
                  editor={ClassicEditor}
                  disabled={true}
                  data={ttGoiKham?.obs?.content || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
);
export default TabKhamBenh;
