import { IKhamDoan } from "../models/KhamDoanModel";
import { AddHopDong } from "../models/KhamDoanModel";

export const initialValuesKhamDoan: IKhamDoan = {
  contractName: "",
  companyName: "",
  address: "",
  taxNumber: "",
  contactPerson: "",
  referenceName: "",
  referenceEmail: "",
  paymentMethod: {
    id:"",
    name:""
  },
  contractValue: 0,
  startDate: "",
  endDate: "",
  paymentMethodName:"",
  paymentMethodId:"",
};

export const initialValuesThemHD: AddHopDong = {
  contractName: "",
  companyName: "",
  address: "",
  taxNumber: "",
  contactPerson: "",
  referenceName: "",
  referenceEmail: "",
  paymentMethod: undefined,
  contractValue: null,
  startDate: "",
  endDate: "",
  paymentMethodName:"",
  paymentMethodId:"",
  note:""
};

export const KEY_TAB_THONG_TIN_HD = {
  QL_HOP_DONG: "0",
  KHAM_LAM_SANG: "1",
  THANH_TOAN: "2",
};

const columnThongTinGoiKham = [
  { title: "TT", field: "status", className: "spaces w-5 text-center" },
  { title: "Tên dịch vụ", field: "name", className: "spaces w-30" },
  {
    title: "Số lượng",
    field: "svcUnit",
    className: "spaces w-10 text-center",
    render: (rowData: any) => {
      const DEFAULT_QUANTITY = 1;
      return DEFAULT_QUANTITY;
    }
  },
  {
    title: "Ghi chú",
    field: "amount",
    className: "spaces w-20 text-center"
  },
  {
    title: "Kết luận",
    field: "obs.value",
    className: "spaces w-20"
  },
  {
    title: "Đối tượng",
    field: "svcPriceClone",
    className: "spaces w-15"
  }
];

export const columnsThongTinXetNghiem = [
  { title: "Tên xét nghiệm", field: "name", className: "spaces width-40" },
  {
    title: "SL",
    field: "quantity",
    className: "spaces width-10 text-center",
    render: (rowData: any) => {
      const DEFAULT_QUANTITY = 1;
      return DEFAULT_QUANTITY;
    }
  },
  {
    title: "Kết quả",
    field: "ketQua",
    className: "spaces width-20",
    render: (rowData: any) => {
      return rowData?.obs?.value || "";
    }
  },
  { title: "Loại bệnh phẩm", field: "type", className: "spaces width-15" },
  { title: "Ghi chú", field: "note", className: "spaces width-15" }
];

export const valueStatus = {
  DANG_KHAM:"dangKham",
  DA_KHAM_XONG:"daKhamXong",
  DUYET_BENH_AN:"duyetBenhAn",

}

export const WF_STATUS = {
  CHUA_DEN_KHAM: {
    CODE: 'not_examined',
    NAME: 'Chưa đến khám',
  },
  DA_DEN_KHAM: {
    CODE: 'examined',
    NAME: 'Đã đến khám',
  },
  CHUA_KHAM: {
    CODE: 'clinical_new',
    NAME: 'Chưa đến khám',
  },
  DANG_KHAM: {
    CODE: 'clinical_start',
    NAME: 'Đang khám',
  },
  DA_KHAM_XONG: {
    CODE: 'enough_result',
    NAME: 'Đã khám xong',
  },
  DA_CO_KQ: {
    CODE: 'clinical_result_order',
    NAME: 'Đã có kết quả',
  },
  KET_THUC_KHAM: {
    CODE: 'clinical_conclusion',
    NAME: 'Kết thúc khám',
  },
  DUYET_BENH_AN: {
    CODE: 'returned',
    NAME: 'Duyệt bệnh án',
  }
}

export const TYPE_RECEIPT = {
  PHIEU_THU: 'PHIEU_THU',
  TAM_UNG: 'TAM_UNG',
  PHIEU_HOAN: "PHIEU_HOAN"
}

export const filterDateList = [
  { label: "Ngày bắt đầu", type: 1, name: "filterStartDate" },
  { label: "Ngày kết thúc", type: 2, name: "filterEndDate"  }
];

export { columnThongTinGoiKham };
