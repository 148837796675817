import clsx from 'clsx';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDebounce } from '../../../../../_metronic/helpers';
import '../../../component/input-autocomplete/style.scss';
import { RESPONSE_MESSAGE } from '../../../utils/Constant';
import { LOAI_VAT_TU } from '../../constants/VatTu';
import { IThuoc } from '../../models/ThuocModels';
import { searchVatTu } from '../../services/VatTuService';
import { COLUMNS } from './Column';
import TableSelect from './TableSelect';

type Props = {
	value: string;
	maKhoThuoc: string;
	setThuocSelected: (value: IThuoc) => void;
	label?: string;
	containerClassName?: string;
	inputClassName?: string;
	labelClassName?: string;
	initialDanhSachThuoc?: any[];
	inputValue?: string;
};

const SelectTableButton = ({
	value,
	maKhoThuoc,
	setThuocSelected,
	containerClassName,
	inputClassName,
	labelClassName,
	label,
	initialDanhSachThuoc,
	inputValue = '',
	...props
}: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const cacheRef = useRef<any[]>([]);
	const isLoadedRef = useRef(false);

	const [tableData, setTableData] = useState<any[]>([]);
	const [isShow, setIsShow] = useState<boolean>(false);
	const [query, setQuery] = useState<string>('');
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	const [isLoading, setIsLoading] = useState(false);

	const queryDebounce = useDebounce(query, 600);
	const rowSelected = tableData[selectedIndex];

	useEffect(() => {
		window.addEventListener('click', checkMouseClickOutSide, true);

		return () => {
			window.removeEventListener('click', checkMouseClickOutSide, true);
		};
	}, []);

	useEffect(() => {
		isLoadedRef.current = false;
	}, [maKhoThuoc]);

	useEffect(() => {
		if (maKhoThuoc) {
			if (!queryDebounce && cacheRef.current.length) {
				setTableData(cacheRef.current);
				return;
			}

			getDanhSachThuoc(queryDebounce);
		}
	}, [maKhoThuoc, queryDebounce]);

	useEffect(() => {
		setQuery(inputValue);
	}, [inputValue]);

	const checkMouseClickOutSide = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			hidePopup();
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setQuery(inputValue);
	};

	const getDanhSachThuoc = async (keySearch = '') => {
		try {
			setIsLoading(true);
			const response = await searchVatTu({
				warehouseId: maKhoThuoc,
				materialGroup: LOAI_VAT_TU.THUOC,
				pageIndex: 1,
				pageSize: 50,
				keyword: keySearch,
				isIventoryNoZero: true,
			});
			const listTemp = response.map(record => ({
				...record,
				requestedPrice: record.servicePrice,
				hospitalFeePrice: record.hospitalPrice,
				BHYTPrice: record.bhytPrice,
				iventory: record.iventory - record.peShipment,
			}));
			setTableData(listTemp);
			if (!isLoadedRef.current) {
				cacheRef.current = listTemp;
			}
			isLoadedRef.current = true;
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onRowSelect = (rowData: any) => {
		if (!rowData) return;
		setThuocSelected({
			...rowData,
			roa: rowData.ROAName,
		});
		setQuery(rowData.name);
		setSelectedIndex(0);
		setIsShow(false);
	};

	const onContainerKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		const dataLength = tableData.length;

		switch (e.key) {
			case 'ArrowDown':
				if (!isShow) {
					setIsShow(true);
					return;
				}
				setSelectedIndex(selectedIndex === dataLength - 1 ? 0 : selectedIndex + 1);
				break;
			case 'ArrowUp':
				setSelectedIndex(selectedIndex === 0 ? dataLength - 1 : selectedIndex - 1);
				break;
			case 'Enter':
				e.preventDefault();
				onRowSelect(rowSelected);
				break;
			case 'Escape':
			case 'Tab':
				hidePopup();
				break;
			case 'Home':
				setSelectedIndex(0);
				break;
			case 'End':
				setSelectedIndex(dataLength - 1);
				break;

			default:
				break;
		}
	};

	const renderTable = () => {
		return (
			<Popover
				style={{
					minWidth: inputRef.current?.offsetWidth,
				}}
				className="input-autocomplete-popover"
			>
				<Popover.Body className="p-8 spaces">
					<TableSelect
						data={tableData}
						columns={COLUMNS}
						containerClassName="spaces max-h-250"
						selectedRowIndex={selectedIndex}
						onSelect={row => {
							onRowSelect(row);
						}}
						isLoading={isLoading}
					/>
				</Popover.Body>
			</Popover>
		);
	};

	const hidePopup = () => {
		setIsShow(false);
	};

	return (
		<div className={containerClassName} ref={containerRef} onKeyDown={onContainerKeyDown}>
			<div className="d-flex align-items-center">
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0',
						labelClassName
					)}
				>
					{label}
				</Form.Label>
				<Form.Control
					className={clsx('spaces px-4 customs-input', inputClassName)}
					ref={inputRef}
					value={query}
					onChange={onSearch}
					onFocus={() => {
						setIsShow(true);
					}}
					disabled={!maKhoThuoc}
					{...props}
				/>
			</div>

			<Overlay
				placement="bottom-start"
				target={inputRef.current}
				container={containerRef.current}
				offset={[0, 3]}
				show={isShow}
			>
				{renderTable()}
			</Overlay>
		</div>
	);
};

export default SelectTableButton;
