import { OctTable } from "@oceantech/oceantech-ui";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { getDsThuoc } from "../services/ChamSocKhachHangService";

type Props = {
    benhNhanSelected: any;
    handleClose: any;
};

const ModalDsThuoc = ({
    benhNhanSelected,
    handleClose
}: Props) => {
    const { setIsLoading } = useContext(AppContext);
    const [data, setData] = useState<any>();

    const updatePageData = async () => {
        setIsLoading(true);
        try {
            let { data } = await getDsThuoc(benhNhanSelected?.visitId);
            if (data?.code === CODE.SUCCESS) {
                setData(data?.data || []);
            } else {
                toast.warning(RESPONSE_MESSAGE.ERROR);
            }
            setIsLoading(false);
        } catch (e) {
            toast.error(RESPONSE_MESSAGE.ERROR);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        updatePageData();
    }, []);

    const columns = [
        {
            name: "STT",
            field: "",
            headerStyle: {
                minWidth: "50px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => <span>{stt}</span>,
        },
        {
            name: "Tên thuốc",
            field: "name",
            headerStyle: {
                minWidth: "250px",
            },
        },
        {
            name: "Mã thuốc",
            field: "code",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Đơn vị",
            field: "unit",
            headerStyle: {
                minWidth: "80px",
            },
        },
        {
            name: "Kê",
            field: "totalQuantity",
            headerStyle: {
                minWidth: "80px",
            },
        },
        {
            name: "Ngày dùng",
            field: "numberOfDays",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Đường dùng",
            field: "route",
            headerStyle: {
                minWidth: "200px",
            },
        },
        {
            name: "Hướng dẫn sử dụng",
            field: "usageInstructions",
            headerStyle: {
                minWidth: "350px",
            },
            cellStyle: {
                paddingRight: "8px",
            }
        },
    ];

    return (
        <>
            <Modal
                show
                centered
                size="xl"
                className="dialog-background"
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title className="text-transform-none">Danh sách thuốc</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="spaces h-calc-vh-400">
                        <OctTable
                            id="list-patients"
                            className="h-100 table-background-th"
                            data={data || []}
                            columns={columns}
                            //   searchObject={objectSearch}
                            //   setSearchObject={setObjectSearch}
                            notDelete={true}
                            notEdit={false}
                            noToolbar={true}
                            //   totalPages={configTable.totalPages}
                            //   totalElements={configTable.totalElements}
                            //   numberOfElements={configTable.numberOfElements}
                            //   handleDoubleClick={handleSelectBenhNhan}
                            //   setDataChecked={setSelectedBenhNhan}
                            //   selectionMode={TYPE.SINGLE_NO_RADIO_BUTTON}
                            fixedColumnsCount={-1}
                            isShowSelection={false}
                            clearToolbar={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button
                        className="btn-secondary spaces W-80"
                        onClick={handleClose}
                    >
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalDsThuoc;
