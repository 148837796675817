import axios from 'axios'
import { urlParamsConfig } from '../../utils/ParamsUtils';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
import { IInPhieuParams } from '../../models/params';
const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

export const surgeryGroups = (searchObject: any) => {
  let url = BASE_URL + `/surgery-groups/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getInforBnChuyenKhoa = (id: string) => {
  let url = BASE_URL + `/surgery-groups/${id}/patient`
  return axios.get(url);
}

export const getInforPhieuChuyenKhoa = (id: string) => {
  let url = BASE_URL + `/surgery-groups/${id}/detail`
  return axios.get(url);
}

export const updateObsValue = (data: any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.put(url, data);
};

export const createObsValue = (data : any) => {
  let url = BASE_URL + `/surgery-obs`
  return axios.post(url, data);
};

export const createSurgeryOrderParaclinical = (data : any) => {
  let url = BASE_URL + `/surgery-order-paraclinical`
  return axios.post(url, data);
};

export const getObsValue = (searchObject : any) => {
  let url = BASE_URL + `/surgery-obs`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getSurgeryOrderParaclinical = (searchObject : any) => {
  let url = BASE_URL + `/surgery-order-paraclinical`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getSurgeryOrderParaclinicalByOrderId = (searchObject : any) => {
  let url = BASE_URL + `/surgery-order-paraclinical/by-order-id`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getStatusSummary = () => {
  let url = BASE_URL + `/wf-workflows/surgery/status/summary`
  return axios.get(url);
}

export const getTermAnswer = (termCode: string) => {
  let url = BASE_URL + `/term-answers/by-term-code?termCode=${termCode}`
  return axios.get(url);
}

export const getDsKetQuaDichVu = (encounterId: string) => {
  let url = BASE_URL + `/orders/by-encounter?encounterId=${encounterId}`
  return axios.get(url);
}

export const inPhieuPhauThuat = async (params: IInPhieuParams) => {
	let url = BASE_URL + "/export/surgery/result";
	return await axios.post<Blob>(url, params, { responseType: 'blob' });
  };
  
export const inGiayChungNhan = async (params: IInPhieuParams) => {
  let url = BASE_URL + "/export/surgery/certificate";
  return await axios.post<Blob>(url, params, { responseType: 'blob' });
  };
  