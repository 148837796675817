import { Formik, useFormikContext, getIn } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AutocompleteObjectV2 from "../../../component/AutocompleteObjectV2";
import ButtonInPhieu from "../../../component/button-in-phieu";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import {
  APP_TIMESTAMP_FORMAT,
  CODE,
  KEY_DS_DANH_MUC_TIEP_DON,
  PATH_NAME,
  RESPONSE_MESSAGE,
  VARIABLE_STRING,
} from "../../../utils/Constant";
import { formatDateDTO, formatDateTime } from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import WfButton from "../../../workflows/components/WfButton";
import { WfConstTracker } from "../../../workflows/Constant";
import ICD10 from "../../components/ICD10";
import ModalICD10 from "../../components/modal-icd10/ModalICD10";
import { MEDICAL_CONCLUSION_MODALITY } from "../../constants/phan-he-kham-benh/ConstantPhanHeKhamBenh";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import ModalDsPhieuChuaTraKetQua from "../../modals/modal-dsdv-chua-tra-ket-qua/ModalDsDvChuaTraKetQua";
import DynamicModal from "../../modals/modal-tab-xu-tri/DynamicModal";
import ModalCapToaChoVe from "../../modals/modal-tab-xu-tri/ModalCapToaChoVe";
import ChuyenPhongKhamModal from "../../modals/modal-tab-xu-tri/ModalChuyenPhongKham";
import "../../PhanHeKhamBenh.scss";
import {
  getDataXuTriService,
  updateXuTri,
} from "../../services/KhamBenhService";
import { inPhieuChuyenPK } from "../../services/PhanHeTiepDonServer";
import ChuyenTuyenModal from "../../modals/modal-tab-xu-tri/modal-chuyen-tuyen/ModalChuyenTuyen";
import ModalCapNhatThongTinTuVong from "../../modals/modal-tab-xu-tri/ModalCapNhatThongTinTuVong";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";
import { RESPONSE_STATUS_CODE } from "../../../auth/core/_consts";
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import ProvisonalDiagnosis from "../../components/ProvisonalDiagnosis";
import { ConfirmDialog } from "../../../component/ConfirmDialog";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

type Props = {
  handleSubmit: (values: any, workflowCallbackFn?:() => void) => any;
  dataInfor: any;
  setDataInfor: (data: any) => void;
  listHTXT: any;
  listKQ: any;
  setListHTXT: (data: any) => void;
  setModalityDetails: (data: any) => void;
  modalityDetails: any;
  listICD10: any;
  updateValidationSchema?: (schema: Yup.AnyObjectSchema) => void;
};

export const TabXuTri = (props: Props) => {
  let {
    handleSubmit,
    dataInfor,
    setDataInfor,
    listHTXT,
    listKQ,
    setModalityDetails,
    modalityDetails,
    listICD10,
  } = props;
  const { values, setFieldValue, errors, touched, submitForm } = useFormikContext<any>();
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } = useContext(PhanHeTiepDonContext);
  
  let encounterDiagnosisValues = values?.encounterDiagnosis;

  const { pathname } = useLocation();

  const [nameComp, setNameComp] = useState<string | undefined>("");

  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
  >("radio");
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [shouldOpenModalYHCT, setShouldOpenModalYHCT] =
    useState<boolean>(false);
  const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] =
    useState<boolean>(false);
  const [listComorbidity, setListComorbidity] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleSelectOption = (option: any, name: string) => {
    setFieldValue(name, option);
    if (!option) return;
    setNameComp(option?.code);
  };

  const handleCloseModal = () => {
    setNameComp("");
  };

  const handleSubmitModal = (values: any) => {
    setModalityDetails({
      ...modalityDetails,
      ...values,
    });
    setDataInfor({
      ...dataInfor,
      doctorComment: values.doctorComment,
    });
    handleCloseModal();
  };

  const compArray = {
    [MEDICAL_CONCLUSION_MODALITY.NHAP_VIEN.code]: "",
    [MEDICAL_CONCLUSION_MODALITY.CHUYEN_TUYEN.code]: (
      <ChuyenTuyenModal
        open={true}
        xuTriData={encounterDiagnosisValues}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={(data: any) => {
          setFieldValue("encounterDiagnosis", {
            ...encounterDiagnosisValues,
            icdXPrimary: data?.htDiagnosis,
            icdXPrimaryName: data?.htDiagnosisName,
            comorbidity: data?.htComorbidity,
          });
          handleSubmitModal(data);
        }}
      />
    ),
    [MEDICAL_CONCLUSION_MODALITY.HET_DOT_NGOAI_TRU.code]: "",
    [MEDICAL_CONCLUSION_MODALITY.CHUYEN_PHONG.code]: (
      <ChuyenPhongKhamModal
        open={true}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={(values: any) => handleSubmitModal(values)}
        modalityDetails={modalityDetails}
        setModalityDetails={setModalityDetails}
      />
    ),
    [MEDICAL_CONCLUSION_MODALITY.CAP_TOA.code]: (
      <ModalCapToaChoVe
        open={true}
        handleCloseModal={() => {
          handleCloseModal();
        }}
        handleSubmit={handleSubmitModal}
        benhNhanInfo={{
          visitId: benhNhanInfo?.thongTinKhamBenh?.visit?.id,
        }}
      />
    ),
    [MEDICAL_CONCLUSION_MODALITY.TU_VONG.code]: (
      <ModalCapNhatThongTinTuVong
        show={true}
        handleCloseDialog={() => {
          handleCloseModal();
        }}
      />
    ),
  };

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setFieldValue("encounterDiagnosis", {
      ...encounterDiagnosisValues,
      [name]: value,
    });
  };

  const handleChangeSelect = (data: any) => {
    switch (icd10Field) {
      case "icdXPrimary":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        setDataInfor({
          ...dataInfor,
          icdXPrimary: data,
        });
        break;
      case "traditionalMedicinePrimary":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        setDataInfor({
          ...dataInfor,
          traditionalMedicinePrimary: data,
        });
        break;
      case "comorbidity":
        let comorbidity = data?.map((item: any) => item?.name).join("; ");
        handleChangeEncounterDiagnosis(`${icd10Field}`, comorbidity || "");
        setDataInfor({
          ...dataInfor,
          comorbidity: comorbidity,
        });
        break;
      case "traditionalMedicineComorbidity":
        let traditionalMedicineComorbidity = data
          ?.map((item: any) => item?.name)
          .join("; ");
        handleChangeEncounterDiagnosis(
          `${icd10Field}`,
          traditionalMedicineComorbidity || ""
        );
        setDataInfor({
          ...dataInfor,
          traditionalMedicineComorbidity: traditionalMedicineComorbidity,
        });
        break;
      default:
        break;
    }
  };

  const handleOpenSelectICD10 = () => {
    setShouldOpenModalSelectICD10(true);
  };

  const getDataXuTri = async () => {
    try {
      if (benhNhanInfo?.thongTinKhamBenh?.orderId) {
        let { data } = await getDataXuTriService(
          benhNhanInfo?.thongTinKhamBenh?.orderId
        );
        if (data?.code === CODE.SUCCESS) {
          const medicalConclusion = listKQ?.find((item: any) => {
            return (
              item?.code ===
              data?.data?.encounterDiagnosis?.medicalConclusionCode
            );
          });
          
          const hinhThucXutri = listHTXT?.find(
            (item: any) =>
              item?.code ===
              data?.data?.encounterDiagnosis?.medicalConclusionModalityCode
          );

          setDataInfor({
            ...data?.data?.encounterDiagnosis,
            icdXPrimary: {
              code: data?.data?.encounterDiagnosis?.icdXPrimary
            },
            traditionalMedicinePrimary: {
              code: data?.data?.encounterDiagnosis?.traditionalMedicinePrimary,
            },
            medicalConclusion,
            medicalConclusionModality: hinhThucXutri,
            medicalConclusionDate:
              data?.data?.encounterDiagnosis?.medicalConclusionDate ||
              formatDateDTO(new Date()),
            isClinicalTransfer: data?.data?.isClinicalTransfer,
            isConclusion: data?.data?.isConclusion,
          });
          setFieldValue("encounterDiagnosis", {
            ...data?.data?.encounterDiagnosis,
            icdXPrimary: data?.data?.encounterDiagnosis?.icdXPrimary,
            icdXPrimaryName: data?.data?.encounterDiagnosis?.icdXPrimaryName,
            hinhThucXutri,
            medicalConclusion
          });
          setIsDisabled(data?.data?.isDisabled);
        }
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra");
    }
  };

  useEffect(() => {
    if (listKQ.length && listHTXT.length) getDataXuTri();
  }, [
    benhNhanInfo?.shouldUpdate,
    benhNhanInfo?.thongTinKhamBenh?.orderId,
    listKQ.length,
    listHTXT.length,
  ]);

  const onSubmit = (wfCallbackFn: () => void) => {
		submitForm();
    
		const isFormValid = !Object.keys(errors).length;
		if (!isFormValid) return;

		handleSubmit(values, wfCallbackFn);
  };

  const handleChangeICD10 = (data: any) => {
    console.log("data", data);
    
    setFieldValue('encounterDiagnosis', {
      ...encounterDiagnosisValues,
      ...data,
    });
  };

  const handleChangeDianosgisICD10 = (name: string, value: any) => {
    setFieldValue('encounterDiagnosis', {
      ...encounterDiagnosisValues,
      [name]: value,
    });
    setDataInfor({
      ...dataInfor,
      [name]: value,
    });
  };
  
  return (
    <Form>
      <div
        className={`spaces h-calc-vh-${
          PATH_NAME.KHAM_BENH === pathname ? 300 : 169
        } border-bottom p-7 bg-white`}
      > 
        <Row className="mb-1 pt-5">
          <Col xs={4} className="spaces mt-1">
            <div className="mb-3">
              <div className="d-flex justify-content-between mb-1">
                <LabelRequired label="Chẩn đoán xác định" />
                {!dataInfor?.isVisitStopped && (
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <u className="color-17A2B8">Chọn chẩn đoán</u>
                  </a>
                )}
              </div>

              <TextField
                as="textarea"
                rows={2}
                name="finalDiagnosis"
                value={dataInfor?.finalDiagnosis || ""}
                disabled={isDisabled}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    finalDiagnosis: e.target.value,
                  });
                  setFieldValue("finalDiagnosis", e.target.value);
                }}
              />
            </div>

            <div className="mb-2">
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Nguyên nhân - ICD10"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="icdXCause"
                disabled={isDisabled}
                value={dataInfor?.icdXCause || ""}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    icdXCause: e.target.value,
                  });
                  setFieldValue("icdXCause", e.target.value);
                }}
              />
            </div>

            <div className="d-flex gap-2 mb-2">
              <div className="flex-1 w-49">
                <div className="d-flex">
                  <LabelRequired label="Thời gian xử trí" />
                </div>
                <DatePickerCustom
                  mode="date-time"
                  name="medicalConclusionDate"
                  value={dataInfor?.medicalConclusionDate}
                  setDateValue={(date) => {
                    setFieldValue("medicalConclusionDate", date);
                    setDataInfor({
                      ...dataInfor,
                      medicalConclusionDate: date,
                    });
                  }}
                  errors={errors?.medicalConclusionDate}
                  touched={touched?.medicalConclusionDate}
                  disabled={isDisabled}
                />
              </div>
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Kết quả điều trị" />
                </div>
                <AutocompleteObjectV2
                  options={listKQ || []}
                  name="medicalConclusion"
                  value={dataInfor?.medicalConclusion}
                  isDisabled={isDisabled}
                  onChange={(option) => {
                    setFieldValue("encounterDiagnosis.medicalConclusion", option);
                    setDataInfor({
                      ...dataInfor,
                      medicalConclusion: option,
                      medicalConclusionCode: option?.code,
                      medicalConclusionName: option?.name,
                    });
                  }}
                  errors={getIn(errors, 'encounterDiagnosis.medicalConclusion')}
                  touched={getIn(touched, 'encounterDiagnosis.medicalConclusion')}
                />
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Hình thức xử trí" />
                </div>
                <AutocompleteObjectV2
                  options={listHTXT || []}
                  name="hinhThucXutri"
                  isDisabled={
                    isDisabled || benhNhanInfo?.thongTinKhamBenh?.component
                  }
                  value={dataInfor?.medicalConclusionModality}
                  onChange={(option) => {
                    handleSelectOption(option, "medicalConclusionModality");
                    setDataInfor({
                      ...dataInfor,
                      hinhThucXutri: option,
                      medicalConclusionModalityCode: option?.code,
                      medicalConclusionModalityName: option?.name,
                    });
                    setFieldValue("encounterDiagnosis.hinhThucXutri", option);
                  }}
                  errors={getIn(errors, 'encounterDiagnosis.hinhThucXutri')}
                  touched={getIn(touched, 'encounterDiagnosis.hinhThucXutri')}
                />
              </div>
              <div className="spaces pt-22 flex-1 text-truncate">
                {modalityDetails?.clinic || ""}
              </div>
            </div>
          </Col>

          <Col xs={8} className="ps-0">
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <LabelRequired
                  label="Bệnh chính - ICD10"
                  className="min-w-150px"
                />
                {!dataInfor?.isVisitStopped && (
                  <Button
                    className="bg-white"
                    onClick={handleOpenSelectICD10}
                    disabled={isDisabled}
                  >
                    <u className="color-17A2B8">Chọn bệnh</u>
                  </Button>
                )}
              </div>
              <Row className="spaces pe-0 align-items-center position-relative">
                <ProvisonalDiagnosis
                  encounterDiagnosisValues={encounterDiagnosisValues}
                  encounterDiagnosisErrors={getIn(errors, 'encounterDiagnosis')}
                  encounterDiagnosisTouched={getIn(touched, 'encounterDiagnosis')}
                  benhNhanInfo={benhNhanInfo}
                  listComorbidity={listComorbidity}
                  setListComorbidity={setListComorbidity}
                  disabled={isDisabled}
                  icd10Field="icdXPrimary"
                  onChange={handleChangeICD10}
							    handleChangeEncounterDiagnosis={handleChangeDianosgisICD10}
                />
              </Row>
            </div>

            <div className="mb-2">
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - ICD10"
                    className="min-w-150px"
                  />
                </div>
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="comorbidity"
                  as="textarea"
                  rows={2}
                  value={values?.encounterDiagnosis?.comorbidity || ""}
                  disabled={isDisabled}
                />
              </Row>
            </div>
            <div className="mb-2">
              <div className="d-flex ">
                <LabelRequired
                  label="Bệnh chính - YHCT"
                  className="min-w-150px"
                />
              </div>
              <Row className="spaces pe-0 align-items-center">
                <Col xs="3" className="pe-0 position-relative">
                  <TextField
                    name="traditionalMedicinePrimary"
                    value={dataInfor?.traditionalMedicinePrimary?.code || ""}
                    disabled={isDisabled}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        if (!isDisabled) {
                          setICD10Field("traditionalMedicinePrimary");
                          setSelectionModeICD10("radio");
                          setShouldOpenModalYHCT(true);
                        }
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
                <Col xs="9" className="ps-0">
                  <TextField
                    name="traditionalMedicinePrimary"
                    value={
                      listICD10.find(
                        (item: any) =>
                          item.code ===
                          dataInfor?.traditionalMedicinePrimary?.code
                      )?.name || ""
                    }
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - YHCT"
                    className="min-w-150px"
                  />
                </div>
                {!isDisabled && (
                  <Button
                    className="bg-white"
                    onClick={() => {
                      setICD10Field("traditionalMedicineComorbidity");
                      setSelectionModeICD10("checkbox");
                      setShouldOpenModalYHCT(true);
                    }}
                    disabled={false}
                  >
                    <u className="color-17A2B8">Chọn bệnh kèm theo</u>
                  </Button>
                )}
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="traditionalMedicineComorbidity"
                  as="textarea"
                  rows={2}
                  value={dataInfor?.traditionalMedicineComorbidity || ""}
                  disabled={isDisabled}
                />
              </Row>
            </div>
          </Col>
        </Row>

        <div className="d-flex">
          {values?.medicalConclusionModality?.code ===
            VARIABLE_STRING.CHUYEN_KHOA && (
            <div className="w-100">
              <LabelRequired label="Tên khoa" />
              {/* <AutocompleteObjectV2 options={[]} /> */}
            </div>
          )}
        </div>

        <Row>
          <Col xs={12} className="spaces mt-1">
            <div>
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Lời dặn của bác sĩ"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="doctorComment"
                value={dataInfor?.doctorComment || ""}
                disabled={isDisabled}
                onChange={(e: any) => {
                  setDataInfor({
                    ...dataInfor,
                    doctorComment: e.target.value,
                  });
                  setFieldValue("doctorComment", e.target.value);
                }}
                errors={getIn(errors, 'encounterDiagnosis.doctorComment')}
                touched={getIn(touched, 'encounterDiagnosis.doctorComment')}
              />
            </div>
          </Col>
        </Row>

        <div className="d-flex gap-3 py-2 bg-white flex-start mt-10">
          {
            !dataInfor?.isConclusion &&
            benhNhanInfo?.thongTinKhamBenh?.orderId &&
            benhNhanInfo?.thongTinKhamBenh?.isMedicalExamStarted &&
             (
              <WfButton
                tracker={WfConstTracker.CLINICAL}
                objectId={benhNhanInfo?.thongTinKhamBenh?.orderId}
                forceRender={dataInfor?.isConclusion}
                prefixFunction={(_, wfCallbackFn) => {
                  onSubmit(wfCallbackFn);
                }}
                additionalFunc={() => {
                  setBenhNhanInfo({
                    ...benhNhanInfo,
                    shouldUpdate: !benhNhanInfo?.shouldUpdate,
                  });
                  statusChangedEventFunc?.();
                }}
              ></WfButton>
            )}

            {benhNhanInfo?.thongTinKhamBenh?.orderId &&
              dataInfor?.isClinicalTransfer && (
                <ButtonInPhieu
                  label="In phiếu"
                  className="min-w-100px"
                  fetchExport={inPhieuChuyenPK}
                  params={{
                    encounterId: benhNhanInfo?.thongTinKhamBenh?.encounter?.id,
                    orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
                    isClinicalTransfer: true,
                  }}
                />
            )}
        </div>
      </div>

      <DynamicModal compArray={compArray} nameComp={nameComp} />

      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeSelect}
          selectionMode={selectionModeICD10}
        />
      )}
      {shouldOpenModalYHCT && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalYHCT(false)}
          setMauSelected={handleChangeSelect}
          selectionMode={selectionModeICD10}
        />
      )}

      <ICD10
        shouldOpenModalICD10={shouldOpenModalICD10}
        setShouldOpenModalICD10={setShouldOpenModalICD10}
        shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
        setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
        listICD10={listICD10}
        listComorbidity={listComorbidity}
        setListComorbidity={setListComorbidity}
        icd10Field="icdXPrimary"
        selectionModeICD10={selectionModeICD10}
        data={encounterDiagnosisValues}
        onChange={handleChangeICD10}
      />
    </Form>
  );
};

const TabXuTriHOC = () => {
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

  const [dataInfor, setDataInfor] = useState<any>();
  const [listHTXT, setListHTXT] = useState<any[]>([]);
  const [listKQ, setListKQ] = useState<any[]>([]);
  const [modalityDetails, setModalityDetails] = useState<any>({});
  const [listICD10, setListICD10] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [shouldOpenModalDsPhieu, setShouldOpenModalDsPhieu] =
    useState<boolean>(false);

  const intl = useIntl();
  const { combined, component } = benhNhanInfo?.thongTinKhamBenh || {};
  const isBenhNhanKhamKetHop = combined && component;
  const [notification, setNotification] = useState<string>("")

  const initialValidationSchema = Yup.object({
    encounterDiagnosis: Yup.object({
      icdXPrimary: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
      hinhThucXutri: isBenhNhanKhamKetHop 
        ? Yup.mixed().nullable()
        : Yup.mixed().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    })
  });

  const [validationSchema, setValidationSchema] = useState(initialValidationSchema);

  useEffect(() => {
    handleGetDanhMuc();
  }, []);

  useEffect(() => {
    setModalityDetails({});
  }, [dataInfor?.hinhThucXutri]);

  const handleSubmitXuTri = async (values: any, workflowCallbackFn?: () => void) => {
    try {
      let isClinicalTransfer =
        !benhNhanInfo?.thongTinKhamBenh?.component &&
        values?.medicalConclusionModality?.code ===
          MEDICAL_CONCLUSION_MODALITY.CHUYEN_PHONG.code;
      let transferOrderReq = modalityDetails?.transferOrderReq || {};
      let encounterDiagnosis = {
        id: dataInfor?.id,
        ...dataInfor,
        finalDiagnosis: values?.finalDiagnosis || dataInfor?.finalDiagnosis,
        icdXCause: values?.icdXCause || dataInfor?.icdXCause,
        icdXPrimary:
          values?.encounterDiagnosis?.icdXPrimary ||
          dataInfor?.icdXPrimary,
        icdXPrimaryName: values?.encounterDiagnosis?.icdXPrimaryName || "",
        diagnosisNonCoded: values?.encounterDiagnosis?.diagnosisNonCoded || dataInfor?.diagnosisNonCoded,
        comorbidity:
          values?.encounterDiagnosis?.comorbidity || dataInfor?.comorbidity,
        traditionalMedicinePrimary:
          values?.encounterDiagnosis?.traditionalMedicinePrimary ||
          dataInfor?.traditionalMedicinePrimary?.code,
        traditionalMedicineComorbidity:
          values?.encounterDiagnosis?.traditionalMedicineComorbidity ||
          dataInfor?.traditionalMedicineComorbidity,
        medicalConclusionDate:
          values?.medicalConclusionDate || dataInfor?.medicalConclusionDate,
        medicalConclusion:
          values?.medicalConclusion?.code || dataInfor?.medicalConclusion?.code,
        medicalConclusionModality:
          values?.medicalConclusionModality?.code ||
          dataInfor?.medicalConclusionModality?.code,
        doctorComment: values?.doctorComment || dataInfor?.doctorComment,
      };
      

      delete modalityDetails?.transferOrderReq;
      let { data } = await updateXuTri({
        orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
        modalityDetails: modalityDetails,
        encounterDiagnosis: encounterDiagnosis,
        patientId: benhNhanInfo?.thongTinKhamBenh?.patient?.id,
        visitId: benhNhanInfo?.thongTinKhamBenh?.visit?.id,
        encounterId: benhNhanInfo?.thongTinKhamBenh?.encounter?.id,
        isClinicalTransfer,
        ...(isClinicalTransfer ? { transferOrderReq } : {}),
      });
      if (CODE.SUCCESS === data.code) {
        workflowCallbackFn?.();
        toast.success("Thành Công");

        setDataInfor({
          ...dataInfor,
          isClinicalTransfer,
        });
      } else {
        let errorMessage =
          data?.data?.[0]?.errorMessage || RESPONSE_MESSAGE.ERROR;
        if (data?.code === RESPONSE_STATUS_CODE.INVALID_VALUE) {
          setNotification(errorMessage || "")
        } else if (data?.code === RESPONSE_STATUS_CODE.BAD_REQUEST) {
          setMessage(errorMessage);
          setShouldOpenModalDsPhieu(true);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  const handleGetDanhMuc = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
      setListHTXT(res?.listHTXT || []);
      setListKQ(res?.listKQDT || []);
    } catch (error) {
      console.error(error);
    }
  };

  const updateValidationSchema = (schema: Yup.AnyObjectSchema) => {
    setValidationSchema(initialValidationSchema.concat(schema));
  };

  return (
      <Formik
        initialValues={{
          medicalConclusionModality: null,
          medicalConclusionDate: formatDateTime(
            new Date(),
            APP_TIMESTAMP_FORMAT
          ),
        }}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        <div>
          <TabXuTri
            dataInfor={dataInfor}
            setDataInfor={setDataInfor}
            listHTXT={listHTXT}
            setListHTXT={setListHTXT}
            listKQ={listKQ}
            setModalityDetails={setModalityDetails}
            modalityDetails={modalityDetails}
            listICD10={listICD10}
            handleSubmit={handleSubmitXuTri}
            updateValidationSchema={updateValidationSchema}
          />
          {shouldOpenModalDsPhieu && (
            <ModalDsPhieuChuaTraKetQua
              handleClose={() => setShouldOpenModalDsPhieu(false)}
              message={message}
            />
          )}
          {
            <ConfirmDialog
              title="Thông báo"
              yes="OK"
              onYesClick={() => setNotification("")}
              message={notification}
              show={Boolean(notification)}
              onCloseClick={() => setNotification("")}
            />
          }
        </div>
      </Formik>
  );
};

export default TabXuTriHOC;
