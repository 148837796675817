import { Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import AutocompleteObjectV2 from "../../../component/AutocompleteObjectV2";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { CODE, TERM_CLASS_IDS } from "../../../utils/Constant";
import { formatDateDTO } from "../../../utils/FormatUtils";
import { localStorageItem } from "../../../utils/LocalStorage";
import { getTermClasses } from "../../../utils/ServicesUtils";
import { LOAI_MO_LAI_BENH_AN } from "../../constants/KhamBenh";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { fetchMoLaiBenhAn } from "../../services/KhamBenhService";
import InfoPatientRight from "../InfoPatientRight";
interface Props {
    open: boolean;
    handleClose: Dispatch<SetStateAction<boolean>>;
}

const ModalMoLaiBenhAn: FC<Props> = (props) => {
    let { open, handleClose } = props;
    const intl = useIntl();
    const { setIsLoading } = useContext(AppContext);
    const { statusChangedEventFunc, setBenhNhanInfo, benhNhanInfo } = useContext(PhanHeTiepDonContext);

    const { visitId, orderId, encounter, encounterDiagnosis, combined, component } = benhNhanInfo?.thongTinKhamBenh || {};
    const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
    const isBenhNhanKhamKetHop = combined && component;

    const handleSubmitForm = async (values: any) => {
        setIsLoading(true)
        try {
            let requestBody = {
                orderId,
                visitId,
                encounterId: encounter?.id,
                encounterDiagnosisId: encounterDiagnosis?.id,
                cancellationReason: values?.cancellationReason,
                cancellationTypeCode: values?.cancellationType?.code,
                cancellationTypeName: values?.cancellationType?.name,
                cancellationPerson: currentUser?.name,
            }
            let { data } = await fetchMoLaiBenhAn(requestBody)
            if (data?.code === CODE.SUCCESS) {
                toast.success("Mở lại bệnh án thành công");
                handleClose(true)
                statusChangedEventFunc?.();
                setBenhNhanInfo({
                    ...benhNhanInfo,
                    thongTinKhamBenh: {
                        ...benhNhanInfo?.thongTinKhamBenh,
                        isMedicalExamStarted: true
                    },
                    shouldUpdate: !benhNhanInfo.shouldUpdate,
                });
            } else {
                let errorMesage = data?.data?.[0]?.errorMessage || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.error("Xảy ra lỗi, vui lòng thử lại!")
        }
        finally {
            setIsLoading(false)
        }
    };

    const validationSchema = Yup.object().shape({
        cancellationType: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        cancellationReason: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    });
    
    return (
        <Modal
            className="modal-mo-lai-benh-an"
            dialogClassName="h-modal"
            size="xl"
            show={open}
            animation
            centered
        >
            <Formik<any>
                initialValues={{
                    cancellationType: null,
                    cancellationReason: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values: any) => handleSubmitForm(values)}
            >
                {({
                    handleSubmit,
                    touched,
                    errors,
                    setFieldValue,
                    values,
                    setValues,
                    handleChange
                }) => (
                    <Form id="form-thuoc">
                        <div className="thuoc-dialog">
                            <Modal.Header className="py-3 header-modal">
                                <Modal.Title className="text-pri">Mở lại bệnh án</Modal.Title>
                                <button
                                    className="btn-close"
                                    onClick={() => handleClose(false)}
                                ></button>
                            </Modal.Header>
                            <Modal.Body className="px-24 py-0 spaces overflow-hidden">
                                <Row className="mb-5 py-2 mx-nega-24">
                                    <InfoPatientRight benhNhanInfo={benhNhanInfo} />
                                </Row>
                                <Row>
                                    <Col xs="8" className="spaces mb-4">
                                        <DatePickerCustom
                                            label="Ngày yêu cầu"
                                            labelClassName="min-w-135"
                                            name="indicationTime"
                                            value={formatDateDTO(new Date())}
                                            mode="date-time"
                                            disabled
								        />
                                    </Col>
                                    <Col xs="8" className="spaces mb-4">
                                        <TextField
                                            label="Người yêu cầu"
                                            name="combinedVisitReason"
                                            className="w-100"
                                            labelClassName="spaces min-w-135"
                                            value={currentUser?.name || ""}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="12" className="d-flex spaces mb-4">
                                        <LabelRequired label="Loại mở bệnh án" isRequired className="spaces min-w-135" />
                                        <AutocompleteObjectV2
                                            options={
                                                isBenhNhanKhamKetHop 
                                                ? [LOAI_MO_LAI_BENH_AN.MO_TAM_KHONG_XU_TRI_LAI]
                                                : []
                                            }
                                            value={values?.cancellationType || null}
                                            name="cancellationType"
                                            searchFunction={isBenhNhanKhamKetHop ? undefined : getTermClasses}
                                            searchObject={{ classIds: TERM_CLASS_IDS.LOAI_MO_BENH_AN }}
                                            urlData="data.data.0.terms" 
                                            onChange={(selectedOption) => setFieldValue("cancellationType", selectedOption)}
                                            touched={touched?.cancellationType}
                                            errors={errors?.cancellationType}
                                            className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
                                        />
                                    </Col>
                                    <Col xs="12" className="mb-5 d-flex">
                                        <LabelRequired label="Lý do mở bệnh án" isRequired className="spaces min-w-135 align-items-start mt-2" />
                                        <TextField
                                            name="cancellationReason"
                                            as="textarea"
                                            value={values?.cancellationReason}
                                            className="input h-175 w-100"
                                            onChange={handleChange}
                                            touched={touched?.cancellationReason}
                                            errors={errors?.cancellationReason}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    className="btn-fill spaces min-w-80"
                                    type="submit"
                                >
                                    Lưu
                                </Button>
                            </Modal.Footer>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalMoLaiBenhAn;
