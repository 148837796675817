import clsx from 'clsx';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { KEY } from '../../utils/Constant';

type Cell = {
	type?: 'string' | 'number' | 'all';
	name: string;
	length: number;
	required?: boolean;
};

type Props = {
	cells: Cell[];
	onChange?: (value: any) => void;
	value: string;
	className?: string;
	inputClassName?: string;
	labelClassName?: string;
	label?: string;
	required?: boolean;
};

const InputCellGroup = ({
	cells,
	value,
	onChange,
	className,
	inputClassName,
	labelClassName,
	label,
	required,
}: Props) => {
	const [fieldValue, setFieldValue] = useState<any>({});

	useEffect(() => {
		if (value) {
			const result: any = {};
			let cellIndex = cells[0].length;
			cells.forEach(cell => {
				result[cell.name] = value.slice(0, cellIndex);
				cellIndex = cell.length;
			});
			setFieldValue(result);
		}
	}, [value]);

	const onCellChange = (e: ChangeEvent<HTMLInputElement>, cell: Cell, index: number) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		const isValidNextCell =
			target.nextSibling && index < cells.length - 1 && value.length === cell.length;
		const isValidBackCell = target.previousSibling && index > 0 && !value;

		setFieldValue({
			[target.name]: value,
		});

		if (isValidNextCell) {
			onNextCell(target);
		}

		if (isValidBackCell) {
			onBackCell(target);
		}
	};

	const onBackCell = (target: HTMLInputElement) => {
		const previousSibling = target.previousSibling as HTMLInputElement;
		previousSibling.focus();
	};

	const onNextCell = (target: HTMLInputElement) => {
		const nextSibling = target.nextSibling as HTMLInputElement;
		nextSibling.focus();
	};

	const onCellKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		const target = e.target as HTMLInputElement;

		switch (e.key) {
			case KEY.DELETE:
				setFieldValue({
					...fieldValue,
					[target.name]: '',
				});
				break;

			case KEY.ARROW_LEFT:
				if (index >= 0) {
					onNextCell(target);
				}
				break;

			case KEY.ARROW_RIGHT:
				if (index < cells.length - 1) {
					onBackCell(target);
				}
				break;

			case KEY.BACKSPACE:
				if (!target.value && index > 0) {
					onBackCell(target);
				}
				break;

			default:
				break;
		}
	};

	return (
		<Form.Group className={clsx('d-flex oct-field-wrapper oct-otp-input', className)}>
			<Form.Label
				className={clsx(
					'spaces text-lable-input max-content-width mb-0 me-2 min-w-fit-content',
					labelClassName
				)}
				dangerouslySetInnerHTML={{
					__html: required
						? `${label}<strong class='text-danger ps-1'>(*)</strong>`
						: `${label}`,
				}}
			></Form.Label>

			<div className="d-flex align-items-end">
				{cells.map((cell: Cell, index: number) => (
					<Form.Control
						key={cell.name}
						value={fieldValue[cell.name]}
						type={cell.type}
						maxLength={cell.length}
						style={{
							flex: cell.length,
						}}
						className={clsx(
							'spaces mr-4 p-6 h-29 text-center radius-4',
							inputClassName
						)}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							onCellChange(e, cell, index)
						}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onCellKeyDown(e, index)}
					/>
				))}
			</div>
		</Form.Group>
	);
};

export default InputCellGroup;
