import { useFormikContext } from "formik";
import { FC, useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AppContext } from "../../../../../appContext/AppContext";
import AutocompleteObjectV2 from "../../../../../component/AutocompleteObjectV2";
import LabelRequired from "../../../../../component/LabelRequired";
import TextField from "../../../../../component/TextField";
import { CODE } from "../../../../../utils/Constant";
import { getByIdGoiKham } from "../../../../services/KhamDoanHopDongServices";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../QuanLyHopDong";
import "../QuanLyHopDong.scss";
import NumberField from "../../../../../component/number-field";

type Props = {

};

const FormThemMoiGoiKham: FC<Props> = (props) => {
    const { } = props;
    const { isClickOpenEditGK, dataEditGK, setDataEditGK } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setIsLoading } = useContext(AppContext);
    const { setValues, setFieldValue, values, errors, touched } = useFormikContext<any>();

    const gioiTinh = [
        { id: 0, name: "Tất cả" },
        { id: 1, name: "Nam" },
        { id: 2, name: "Nữ" },
        { id: 3, name: "Không xác định" },
    ]  

    useEffect(() => {
        isClickOpenEditGK && handlerEditGoiKham(dataEditGK)
    }, [isClickOpenEditGK]);

    useEffect(() => {
        setFieldValue("gender", values?.gender || gioiTinh[0])
    }, [values?.gender]);

    const handlerEditGoiKham = async (value: any) => {
        try {
            const { data } = await getByIdGoiKham(value?.id);
            if (CODE.SUCCESS === data?.code) {
                const resultData = data?.data
                resultData.gender = {
                    id: data?.data?.gender,
                    name: data?.data?.gender
                }
                resultData.staffGroup = {
                    id: data?.data?.staffGroupId,
                    name: data?.data?.staffGroupName
                }
                resultData.division = {
                    id: data?.data?.divisionId,
                    name: data?.data?.divisionName
                }
                setDataEditGK(resultData)
                setValues(resultData);
            } else {
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    const handleChangeVisit = (name: string, value: any) => {
        setFieldValue(name, value);
    };

    return (
        <Row className="mx-1 pl-0 border-bottom">
            <Col xs="6" className=" mb-5 mt-5">
                <TextField
                    label={<div>
                        Mã gói khám
                        <span className="text-danger"> (*)</span>
                    </div>}
                    name="code"
                    labelClassName="min-w-130px"
                />
            </Col>
            <Col xs="6" className="mb-5 mt-5">
                <TextField
                    label={<div>
                        Tên gói khám
                        <span className="text-danger"> (*)</span>
                    </div>}
                    name="name"
                    labelClassName=" min-w-130px"
                />
            </Col>
            <Col xs="6" className="mb-5">
                <NumberField
                  label={
                    <div>
                      Số tiền
                    </div>
                  }
                  name="amount"
                  labelClassName="min-w-130px"
                />
            </Col>
            <Col xs="6" className=" flex mb-5">
                <LabelRequired className="min-w-130px" label="Giới tính" isRequired />
                <AutocompleteObjectV2
                    options={gioiTinh}
                    value={values?.gender || {}}
                    name="gender"
                    onChange={(selectedOption) =>
                        handleChangeVisit("gender", selectedOption)
                    }
                    isClearable={false}
                    className="spaces h-29 autocomplete-custom-tiep-nhan"
                    touched={touched?.gender}
                    errors={errors?.gender}
                />
            </Col>
        </Row>
    );
};
export default FormThemMoiGoiKham;

