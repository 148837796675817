import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';

type ActionRef = {
	getDetailDonThuoc: (id: string) => any;
	danhSachThuoc?: any[];
}

type TabThuocContextProps = {
	danhSachDonThuoc: any[];
	donThuocInfo: any;
	setDonThuocInfo: Dispatch<SetStateAction<object>>;
	donThuocSelectedIndex: number;
	setDonThuocSelectedIndex: Dispatch<SetStateAction<number>>;
	actionsRef: React.MutableRefObject<ActionRef>;
};

type TabThuocContextProviderProps = {
	children: ReactNode;
	values: {
		danhSachDonThuoc: object[];
	};
};

const initialContextValue: TabThuocContextProps = {
	danhSachDonThuoc: [],
	donThuocInfo: {},
	setDonThuocInfo: () => {},
	donThuocSelectedIndex: 0,
	setDonThuocSelectedIndex: () =>{},
	actionsRef: {
		current: {
			getDetailDonThuoc: () =>{},
			danhSachThuoc: []
		}
	}
};

const TabThuocContext = createContext<TabThuocContextProps>(initialContextValue);

export const TabThuocContextProvider = ({ children, values }: TabThuocContextProviderProps) => {
	const [donThuocInfo, setDonThuocInfo] = useState<object>({});
	const [donThuocSelectedIndex, setDonThuocSelectedIndex] = useState<number>(0);
	let actionRef = useRef<ActionRef>({
		getDetailDonThuoc: () => {},
		danhSachThuoc: []
	});

	useEffect(() => {
		if (!values.danhSachDonThuoc.length) {
			setDonThuocInfo({});
		}
	}, [values.danhSachDonThuoc.length]);

	return (
		<TabThuocContext.Provider
			value={{
				danhSachDonThuoc: values.danhSachDonThuoc,
				donThuocInfo,
				setDonThuocInfo,
				donThuocSelectedIndex,
				setDonThuocSelectedIndex,
				actionsRef: actionRef
			}}
		>
			{children}
		</TabThuocContext.Provider>
	);
};

export const useTabThuocContext = () => useContext(TabThuocContext);
