import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ButtonInPhieu from '../../../../../component/button-in-phieu';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import { CODE, KEY, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import WfButton from '../../../../../workflows/components/WfButton';
import { WfConstTrackerClinical } from '../../../../../workflows/Constant';
import { WORKFLOW_MEDICAL_ITEM_STATUS_CODE } from '../../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import ModalKeVatTu from '../../../../modals/modal-ke-vat-tu/ModalKeVatTu';
import { IInPhieuChiDinhThuocParams } from '../../../../models/ThuocModels';
import {
	deleteDonThuoc,
	huyGuiDonThuoc,
	printPhieuChiDinhThuoc,
	saveDonThuoc,
} from '../../../../services/ThuocService';
import { useTabVatTuContext } from '../../context';

type ActionType = 'edit' | 'delete' | 'cancel';

type DialogActionProps = {
	open: boolean;
	action: ActionType;
};

type Props = {
	getDanhSachDonVatTu: () => void;
};

const ThaoTacPhieuVatTu = ({ getDanhSachDonVatTu }: Props) => {
	const { donVatTuDetail, setDonVatTuSelectedIndex, actionsRef } = useTabVatTuContext();
	const { benhNhanInfo, statusChangedEventFunc } = useContext(PhanHeTiepDonContext);

	const { patient, visit, encounter } = benhNhanInfo?.thongTinKhamBenh || {};
	const isKetThucLanKham = benhNhanInfo?.thongTinKhamBenh?.isConclusion;
	const isXuatKho = [
		WORKFLOW_MEDICAL_ITEM_STATUS_CODE.CHO_XUAT_KHO,
		WORKFLOW_MEDICAL_ITEM_STATUS_CODE.DA_XUAT_KHO,
	].includes(donVatTuDetail?.statusCode);

	const [guiPhieu, setGuiPhieu] = useState(false);
	const [dialogAction, setDialogAction] = useState<DialogActionProps>({
		open: false,
		action: 'edit',
	});
	const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>('');
	const [isUpdateWorkflow, setIsUpdateWorkflow] = useState(false);

	const onDeleteDonVatTu = async () => {
		try {
			const response = await deleteDonThuoc(donVatTuDetail.id);
			if (CODE.SUCCESS === response.code) {
				toast.success(RESPONSE_MESSAGE.DELETE.SUCCESS);
				setDialogAction({
					open: false,
					action: 'edit',
				});
				getDanhSachDonVatTu();
				setDonVatTuSelectedIndex(0);
				statusChangedEventFunc?.();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const handlePressKeyShortcuts = (e: any) => {
		switch (e.key) {
			case KEY.F3:
				e.preventDefault();
				setKeyOpenPhieuIn('F3');
				break;
			default:
				return;
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handlePressKeyShortcuts);
		return () => {
			window.removeEventListener('keydown', handlePressKeyShortcuts);
		};
	}, []);

	useEffect(() => {
		setGuiPhieu(donVatTuDetail?.statusCode === WORKFLOW_MEDICAL_ITEM_STATUS_CODE.GUI_DON);
	}, [donVatTuDetail?.statusCode]);

	const WorkFlowButton = () => {
		return (
			<WfButton
				tracker={WfConstTrackerClinical.MEDICAL_ITEMS}
				objectId={donVatTuDetail?.id}
				prefixFunction={(statusCode: string, wfCallbackFn: () => void) => {
					if (WORKFLOW_MEDICAL_ITEM_STATUS_CODE.CHUA_GUI_DON === statusCode) {
						onGuiDonVatTu(wfCallbackFn);
						return;
					}

					onHuyGuiDonVatTu(wfCallbackFn);
				}}
				additionalFunc={(status: any) => {
					onUpdateWorkflowStatus(status);
				}}
				forceRender={isUpdateWorkflow}
			></WfButton>
		);
	};

	const onGuiDonVatTu = async (callbackFn: () => void) => {
		try {
			const { code } = await saveDonThuoc({
				...donVatTuDetail,
				encounterId: encounter?.id,
				orderTypeId: parseInt(ORDER_TYPE_ID.VAT_TU),
				orderId: benhNhanInfo.thongTinKhamBenh?.orderId,
				lstMedicalItemOrder: donVatTuDetail?.lstMedicalItemOrderRes,
			});
			if (CODE.SUCCESS === code) {
				toast.success('Gửi đơn vật tư thành công');
				callbackFn();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onHuyGuiDonVatTu = async (callbackFn: () => void) => {
		try {
			const { code } = await huyGuiDonThuoc(donVatTuDetail?.id);
			if (CODE.SUCCESS === code) {
				toast.success('Huỷ gửi đơn vật tư thành công');
				callbackFn();
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onUpdateWorkflowStatus = (workflowStatus: string) => {
		setGuiPhieu(workflowStatus === WORKFLOW_MEDICAL_ITEM_STATUS_CODE.GUI_DON);
		actionsRef.current.getDetailDonVatTu(donVatTuDetail?.id);
	};

	const PrintButton = () => {
		return (
			<ButtonInPhieu<IInPhieuChiDinhThuocParams>
				fetchExport={printPhieuChiDinhThuoc}
				params={{
					fileType: 'pdf',
					patientId: patient?.id,
					medicalItemGroupId: donVatTuDetail?.id,
					visitId: visit?.id,
					type: parseInt(ORDER_TYPE_ID.VAT_TU),
				}}
				label="In đơn vật tư (F3)"
				keyOpenPhieuIn={keyOpenPhieuIn}
				setKeyOpenPhieuIn={setKeyOpenPhieuIn}
				keyBtn={KEY.F3}
			/>
		);
	};

	if (!donVatTuDetail?.id) return <div className="bg-white spaces min-h-50"></div>;

	if (isXuatKho) {
		return <div className="d-flex gap-3 bg-white p-2">{PrintButton()}</div>;
	}

	return (
		<div className="d-flex gap-3 justify-content-start p-2 bg-white">
			{guiPhieu ? (
				<div className="d-flex gap-3 justify-content-center">
					{PrintButton()}
					{donVatTuDetail?.isAllowModify && WorkFlowButton()}
				</div>
			) : <>
				{donVatTuDetail?.isAllowModify && <div className="d-flex gap-3 justify-content-center">
					<Button
						className="btn-navy-outlined"
						onClick={() => {
							setDialogAction({
								open: true,
								action: 'edit',
							});
						}}
					>
						Sửa phiếu
					</Button>
					{WorkFlowButton()}
					<Button
						className="btn-secondary"
						onClick={() => {
							setDialogAction({
								open: true,
								action: 'delete',
							});
						}}
					>
						Xóa phiếu
					</Button>
				</div>}
			</>}

			{dialogAction.open && dialogAction.action === 'edit' && (
				<ModalKeVatTu
					open={dialogAction.open}
					handleClose={() => {
						setDialogAction({
							open: false,
							action: 'edit',
						});
					}}
					onSave={() => {
						setIsUpdateWorkflow(!isUpdateWorkflow);
						actionsRef.current.getDetailDonVatTu(donVatTuDetail?.id);
					}}
					itemEdit={donVatTuDetail}
				/>
			)}

			{dialogAction.open && ['delete'].includes(dialogAction.action) && (
				<ConfirmDialog
					className="z-index-1060"
					show={dialogAction.open}
					title="Thông báo"
					message={`Bạn có chắc chắn muốn xóa đơn vật tư này không ?`}
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setDialogAction({
							open: false,
							action: 'edit',
						});
					}}
					onYesClick={onDeleteDonVatTu}
				/>
			)}
		</div>
	);
};

export default ThaoTacPhieuVatTu;
