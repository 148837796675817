import { useEffect, useState } from 'react';
import { TableCollapseCustom } from '../../component/table/table-collapse/TableCollapseCustom';
import { COLUMN_BANG_LAY_MAU } from '../constants';
import { usePhanHeLayMauContext } from '../context/PhanHeLayMauContext';

const BangLayMauBenhPham = (props: any) => {
	const { phieuLayMauInfo } = usePhanHeLayMauContext();
	const [dataKQ, setDataKQ] = useState<any>([]);  

	useEffect(() => {
		setDataKQ(phieuLayMauInfo?.danhSachMau || []);
	}, [phieuLayMauInfo?.danhSachMau]);

	return (
		<>
			<TableCollapseCustom
				columns={COLUMN_BANG_LAY_MAU}
				data={dataKQ || []}
				className="overflow-auto spaces"
				classNameTable="spaces W-1000"
				height="calc(100vh - 275px)"
				childrenField="subs"
				isFromTabXN
				isOtherColorHeader
			/>
		</>
	);
};

export default BangLayMauBenhPham;
