import { useContext, useState } from "react";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import BenhAnNgoaiTru from "../../modals/modal-benh-an-ngoai-tru/BenhAnNgoaiTru";
import PageChiDinhDichVu from "../../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu";
import ChuyenPhongKhamModal from "../../modals/modal-chuyen-phong-kham/ChuyenPhongKhamModal";
import BienBanHoiChanModal from "../../modals/modal-hoi-chan/BienBanHoiChanModal";
import PageKeThuoc from "../../modals/modal-ke-thuoc/PageKeThuoc";
import ModalKeVatTu from "../../modals/modal-ke-vat-tu/ModalKeVatTu";
import { IBenhNhan } from "../../models/DSBenhNhanKhamBenhModels";
import ThongTinChiPhi from "../ThongTinChiPhi";
import TableDsBenhNhanKhamBenh from "./TableDsBenhNhanKhamBenh";

export const DSBenhNhanKhamBenh = (props:any) => {
  const { filter, setFilter } = props;
  const [shouldOpenThuocModal, setShouldOpenThuocModal] =
    useState<boolean>(false);
  const [shouldOpenChuyenPKModal, setShouldOpenChuyenModalPK] =
    useState<boolean>(false);
  const [shouldOpenBenhAnNgoaiTruDialog, setShouldOpenBenhAnNgoaiTruDialog] =
    useState<boolean>(false);
  const [shouldOpenHoiChanDialog, setShouldOpenHoiChanDialog] =
    useState<boolean>(false);
  const [shouldOpenChiDinhDichVuModal, setShouldOpenChiDinhDichVuModal] = useState<boolean>(false);
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

  const [openCapNhatMauChiDinhDialog, setOpenCapNhatMauChiDinhDialog] =
    useState<boolean>(false);

  const [openCapNhatMauChiDinhVatTuDialog, setOpenCapNhatMauChiDinhVatTuDialog] =
    useState<boolean>(false);
  const handleCloseModal = () => {
    setShouldOpenThuocModal(false);
    setShouldOpenChuyenModalPK(false);
  };

  const handleCloseHoiChan = () => {
    setShouldOpenHoiChanDialog(false);
  };
  const handleOpenChiDinhDichVu = () => {
    setShouldOpenChiDinhDichVuModal(true);
  };
  const handleCloseChiDinhDichVu = () => {
    setShouldOpenChiDinhDichVuModal(false);
  };

  const handleCloseDialog = () => {
    setShouldOpenBenhAnNgoaiTruDialog(false);
  };

  return (
    <div className="bangDSBenhNhan p-0 position-relative">
      <div className="flex-1 flex flex-column spaces pt-10">
          <TableDsBenhNhanKhamBenh
            statusChangedEvent={props.statusChangedEvent}
            filter={filter}
            setFilter={setFilter}
          />
        {/* <div className="h-100">
        </div> */}
      </div>

      {shouldOpenBenhAnNgoaiTruDialog && (
        <BenhAnNgoaiTru
          handleClose={handleCloseDialog}
          open={shouldOpenBenhAnNgoaiTruDialog}
        />
      )}

      {shouldOpenChuyenPKModal && (
        <ChuyenPhongKhamModal
          shouldOpenChuyenPKModal={shouldOpenChuyenPKModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {shouldOpenHoiChanDialog && (
        <BienBanHoiChanModal
          shouldOpenHoiChanDialog={shouldOpenHoiChanDialog}
          handleCloseHoiChan={handleCloseHoiChan}
        />
      )}
      {shouldOpenChiDinhDichVuModal && (
        <PageChiDinhDichVu
          handleClose={handleCloseChiDinhDichVu}
        />
      )}
      {openCapNhatMauChiDinhDialog &&
        <PageKeThuoc
          open={openCapNhatMauChiDinhDialog}
          handleClose={() => { setOpenCapNhatMauChiDinhDialog(false) }
          } />}
      {openCapNhatMauChiDinhVatTuDialog &&
        <ModalKeVatTu
          open={openCapNhatMauChiDinhVatTuDialog}
          handleClose={() => { setOpenCapNhatMauChiDinhVatTuDialog(false) }} />
      }
    </div>
  );
};

export default DSBenhNhanKhamBenh;
